import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

import Header from "../Elements/HeaderInner";
import Footer from "../Elements/Footer";

import TeacherSidebar from "../Elements/TeacherSidebar";
import {
  getTeacherCurrentStudent,
  getTeacherPastStudent,
} from "../../Utils/Utils";
import RatingComponent from "../common_components/RatingComponent";
import axios from "axios";

class MyStudents extends React.Component {
  constructor() {
    super();
    this.state = {
      active_show: "active",
      following_show: "",
      completed_show: "",

      current_students: { data: [] },
      past_students: { data: [] },

      page: 1,
      per_page: 5,
      delay: 1,
      search_term: "",

      tab_page: 1,
      tab_per_page: 5,
      tab_delay: 1,
      tab_search_term: "",

      step: "mystudents",
    };
  }

  async componentDidMount() {
    this.setState({
      current_students: await getTeacherCurrentStudent(
        this.state.page,
        this.state.per_page,
        this.state.delay,
        this.state.search_term
      ),
      past_students: await getTeacherPastStudent(
        this.state.tab_page,
        this.state.tab_per_page,
        this.state.tab_delay,
        this.state.tab_search_term
      ),
    });
  }

  initiateChat = (student_id, teacher_name) => {
    const access_token = localStorage.getItem('token');
    var response = [];
    axios
      .post(window.$API_URL + 'teacher/initiatechat', {
        student_id: student_id
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {
        response = success.data.room_id;
        this.props.history.push('/myconversationteacher/message/' + response + '/?name=' + teacher_name + '&room_id=' + response);
      }).catch((error) => {
        response = [];
      });
  }

  render() {
    return (
      <div>
        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <TeacherSidebar step={this.state.step} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent">
                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li className={this.state.active_show}>
                    <Link
                      onClick={(e) =>
                        this.setState({
                          active_show: "active",
                          following_show: "",
                          completed_show: "",
                        })
                      }
                    >
                      Current
                    </Link>
                  </li>
                  {/* <li className={this.state.following_show}>
                                        <Link onClick={(e) => this.setState({ active_show: "", following_show: "active", completed_show: "" })} >Following (28)</Link>
                                    </li> */}
                  <li className={this.state.completed_show}>
                    <Link
                      onClick={(e) =>
                        this.setState({
                          active_show: "",
                          following_show: "",
                          completed_show: "active",
                        })
                      }
                    >
                      History
                    </Link>
                  </li>
                  {/* <li class="pull-right">
                                        <Form.Control className="dash-maincontent-search" type="text" placeholder="Search student" />
                                    </li> */}
                </ul>

                <div class="tab-content">
                  <div
                    role="tabpanel"
                    className={this.state.active_show + " tab-pane"}
                    id="current"
                  >
                    {this.state.current_students.data
                      ? this.state.current_students.data.map((row) => (
                        <>
                          <div class="dash-myteachers-module">
                            <div class="pull-left">
                              <img
                                src={(row.class_student.image_fullpath != null) ? row.class_student.image_fullpath : '/img/uploadprofilepic.svg'}
                                class="img-circle"
                                alt=""
                              />
                            </div>
                            <div class="media-body">
                              <h5>
                                {row.class_student.first_name +
                                  " " +
                                  row.class_student.last_name}
                              </h5>
                              <p>
                                {row.class_data.category_name
                                  ? row.class_data.category_name.name
                                  : ""}
                              </p>
                              <div class="dash-mycourses-module-bot">
                                <div
                                  onClick={(e) =>
                                    this.initiateChat(
                                      row.class_student.id,
                                      row.class_student.first_name +
                                      " " +
                                      row.class_student.last_name
                                    )
                                  }
                                  className="btn btn-default dash-mycourses-module-bot-bt-out"
                                >
                                  Contact
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                      : ""}

                    {this.state.current_students.data.length == 0 ? (
                      <div class="dash-mycourses-module">
                        <p>No current students been found.</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    role="tabpanel"
                    className={this.state.completed_show + " tab-pane"}
                    id="history"
                  >
                    {this.state.past_students.data
                      ? this.state.past_students.data.map((row) => (
                        <>
                          <div class="dash-myteachers-module">
                            <div class="pull-left">
                              <img
                                src={(row.class_student.image_fullpath != null) ? row.class_student.image_fullpath : '/img/uploadprofilepic.svg'}
                                class="img-circle"
                                alt=""
                              />
                            </div>
                            <div class="media-body">
                              <h5>
                                {row.class_student.first_name +
                                  " " +
                                  row.class_student.last_name}
                              </h5>
                              <p>
                                {row.class_data.category_name
                                  ? row.class_data.category_name.name
                                  : ""}
                              </p>
                              <div class="dash-mycourses-module-bot">
                                <div
                                  onClick={(e) =>
                                    this.initiateChat(
                                      row.class_student.id,
                                      row.class_student.first_name +
                                      " " +
                                      row.class_student.last_name
                                    )
                                  } className="btn btn-default dash-mycourses-module-bot-bt-out">
                                  Contact
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                      : ""}

                    {this.state.past_students.data.length == 0 ? (
                      <div class="dash-mycourses-module">
                        <p>No past students been found.</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default MyStudents;
