import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

class LandingJoinCommunity extends React.Component {
  showSignup = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: false });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
  }
  showSignupTeacher = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: true });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
  }
  render() {
    return (
      (this.props.profile_data === null)
        ?
        <div class="container">
          <div class="section-cta">
            <div class="row">

              <div class="col-md-6 col-sm-6">
                <h1>Join our community.<br />Sign up for free.</h1>
              </div>

              <div class="col-md-6 col-sm-6 section-cta-right">
                <Button variant="default" className="bt-custom" onClick={(e) => this.showSignup(e)}>
                  <Link >START LEARNING</Link>
                </Button>
                <Button variant="default" className="bt-custom" onClick={(e) => this.showSignupTeacher(e)}>
                  <Link >START TEACHING
                </Link>
                </Button>
              </div>

            </div>
          </div>
        </div>
        :
        ''
    );
  }
}

const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingJoinCommunity);
