import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';

import StudentSidebar from '../Elements/StudentSidebar';
import CourseRowComponent from '../common_components/CourseRowComponent';
import { getStudentCompletedCourses, getStudentActiveCourses, fetchBalance } from '../../Utils/Utils';
import Pagination from "react-js-pagination";
import moment from 'moment';
import CourseRating from '../common_components/CourseRating'
import { connect } from 'react-redux';
import CourseReport from '../common_components/CourseReport'
import AvailaibleBalanceComponent from '../common_components/AvailableBalanceComponent';

//require("bootstrap/less/bootstrap.less");

class MyCourses extends React.Component {
  constructor() {
    super();
    this.changeSearch = this.changeSearch.bind(this);
    this.changeSearchActive = this.changeSearchActive.bind(this);
    this.state = {
      active_show: 'active',
      completed_show: '',
      page: 1,
      per_page: 5,
      delay: 1,
      search_term: '',
      page_active: 1,
      per_page_active: 5,
      delay_active: 1,
      search_term_active: '',
      active_courses: { data: [] },
      completed_courses: { data: [] },
      typing: false,
      typingTimeout: 0,
      balance: 0,
      user_timezone: null,
    };

  }

  async componentDidMount() {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    await this.getUserProfile();
    this.setState({
      active_courses: await getStudentActiveCourses(this.state.page_active, this.state.per_page_active, this.state.delay_active, this.state.search_term_active),
      completed_courses: await getStudentCompletedCourses(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      balance: await fetchBalance()
    });
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
  }

  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {
      this.setState({
        user_timezone: response.data.timezone_name.name
      });
    }).catch((error) => {
      this.setState({
        user_timezone: null
      });
    });
  }

  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term: search_term,
          completed_courses: await getStudentCompletedCourses(1, this.state.per_page, this.state.delay, search_term)
        });
      }, 1500)
    });
  }

  changeSearchActive = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term_active: search_term,
          active_courses: await getStudentActiveCourses(1, this.state.per_page_active, this.state.delay_active, search_term)
        });
      }, 1500)
    });
  }

  async handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber
    })
    this.setState({
      page: pageNumber,
      completed_courses: await getStudentCompletedCourses(pageNumber, this.state.per_page, this.state.delay, this.state.search_term)
    });
  }

  async handlePageChangeActive(pageNumber) {
    this.setState({
      page: pageNumber
    })
    this.setState({
      page: pageNumber,
      active_courses: await getStudentActiveCourses(pageNumber, this.state.per_page_active, this.state.delay_active, this.state.search_term_active)
    });
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <StudentSidebar current_page={'mycourses'} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent">

                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li className={this.state.active_show}>
                    <Link onClick={(e) => this.setState({ active_show: "active", completed_show: "" })}>Active</Link>
                  </li>
                  <li className={this.state.completed_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", completed_show: "active" })} >Completed</Link>
                  </li>
                  <li class="pull-right">
                    {

                      (this.state.active_show)
                        ?
                        <Form.Control
                          className="dash-maincontent-search"
                          type="text"
                          placeholder="Search Active Courses"
                          onChange={this.changeSearchActive}

                        />
                        :
                        <Form.Control
                          className="dash-maincontent-search"
                          type="text"
                          placeholder="Search Completed Courses"
                          onChange={this.changeSearch}
                        />

                    }

                  </li>
                </ul>

                <div class="tab-content">

                  <div role="tabpanel" className={this.state.active_show + " tab-pane"} id="activecourses">
                    <AvailaibleBalanceComponent balance={this.state.balance} />
                    {
                      (this.state.active_courses.data.length === 0)
                        ?
                        <div className="dash-mycourses-module">
                          <p>You have no active current courses.</p>
                        </div>
                        :
                        this.state.active_courses.data.map((com_course) => (
                          <>
                            <CourseRowComponent
                              user_timezone={this.state.user_timezone}
                              com_course={com_course}
                              is_completed={false} />
                          </>
                        ))
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.active_courses.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.active_courses.page}
                            itemsCountPerPage={this.state.active_courses.per_page}
                            totalItemsCount={this.state.active_courses.total}
                            pageRangeDisplayed={this.state.active_courses.total_pages}
                            onChange={this.handlePageChangeActive.bind(this)}
                          />
                        </div>
                        :
                        ''
                    }
                  </div>

                  <div role="tabpanel" className={this.state.completed_show + " tab-pane"} id="completedcourses">
                    <AvailaibleBalanceComponent balance={this.state.balance} />
                    {
                      (this.state.completed_courses.data.length === 0)
                        ?
                        <div className="dash-mycourses-module">
                          <p>You have no current completed courses.</p>
                        </div>
                        :
                        this.state.completed_courses.data.map((com_course) => (
                          <>
                            <CourseRowComponent
                              user_timezone={this.state.user_timezone}
                              com_course={com_course}
                              is_completed={true} />
                          </>
                        ))
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.completed_courses.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.completed_courses.page}
                            itemsCountPerPage={this.state.completed_courses.per_page}
                            totalItemsCount={this.state.completed_courses.total}
                            pageRangeDisplayed={this.state.completed_courses.total_pages}
                            onChange={this.handlePageChange.bind(this)}
                          />
                        </div>
                        :
                        ''
                    }

                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
        {
          (this.props.course_rating_json !== null) ?
            <CourseRating course_rating_json={this.props.course_rating_json} /> :
            ''
        }
        {
          (this.props.course_report_json !== null) ?
            <CourseReport course_report_json={this.props.course_report_json} /> :
            ''
        }
        <Footer />
      </div>

    );
  }

}
const mapStateToProps = (state) => {
  return {
    course_rating_json: state.course_rating_json,
    course_report_json: state.course_report_json
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCourses);
