import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';


class CourseRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      review: '',
      rating: 1
    };
  }

  ratingClose = () => {
    this.props.dispatch({ type: 'COURSE_RATING_JSON', payload: null });
  }

  changeRating = (newRating) => {
    console.log(newRating);
    this.setState({
      rating: newRating
    });
  }

  lessonRating = async (id, class_id, teacher_id, rating, review) => {
    //  alert(id);
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'student/ratelesson',
      {
        lesson_id: this.props.course_rating_json.id,
        class_id: this.props.course_rating_json.class_data.id,
        teacher_id: this.props.course_rating_json.class_data.class_teacher.id,
        rating: this.state.rating,
        review: this.state.review
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        loading: false,
        alert: "",
        rating: 1,
        review: ""
      });
      NotificationManager.success('Your rating has been added successfully.');
      setTimeout(() => window.location.reload(), 3000);
    }).catch((error) => {

      this.setState({ loading: false });
      NotificationManager.error(error.response.data.message, 'Error');
    });

  }

  render() {
    return (
      <>
        <SweetAlert
          warning
          showCancel={true}
          showConfirm={true}
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          title="Lesson Rating Confirmation"
          onConfirm={(e) => this.lessonRating()}
          onCancel={(e) => this.ratingClose()}
          customClass='smallerIcon signup-maincontent input'
        >
          <div class="coursedetail-main-module"><p>You have requested to Rate the Lesson Title <b>{this.props.course_rating_json.class_data.title}</b> taught by <b>{this.props.course_rating_json.class_data.class_teacher.first_name + ' ' + this.props.course_rating_json.class_data.class_teacher.last_name}</b>. <br /></p></div>
          <StarRatings
            rating={this.state.rating}
            starRatedColor="blue"
            changeRating={(e) => this.changeRating(e)}
            numberOfStars={5}
            name='rating'
          />

          <p>Write a Review</p>
          <Form.Control
            as="textarea"
            cols="40"
            placeholder="Write Your review here"
            onChange={(e) => this.setState({ review: e.target.value })}
          >{this.state.review}</Form.Control>
        </SweetAlert>
      </>

    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(CourseRating);


