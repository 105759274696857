import React from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Modal } from 'react-bootstrap';
import { getTeacherAvailability } from '../../../Utils/Utils';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';


class AvailabilityCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      booking_slot_time: "",
      booking_slot_time_end: "",
    };
  }

  async componentDidMount() {
    this.setState({
      events: await getTeacherAvailability(this.props.slug, this.props.user_timezone)
    })
  }
  checkWalletBalance = async (e) => {
    this.setState({
      loading: 1
    });
    const token = localStorage.token;
    return new Promise((resolve, reject) => {
      const response = axios.post(
        window.$API_URL + 'payment/checkwalletbalanceforcourse',
        {
          lesson_slug: this.props.slug
        },
        { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
      ).then((response) => {
        resolve(response.data.is_sufficient_balance);
      }).catch((error) => {
        this.setState({
          loading: 0
        });
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data).forEach((key) => {
              this.state.returnError.push(error.response.data[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error("Some Problem Occured in API", 'Validation Error');
          }
        }
      });
    });
  }
  confirmLessonBooking = async (slug, start, end) => {
    this.checkWalletBalance().then((response) => {
      if (response == 0) {
        this.setState({
          loading: 0
        });
        const getAlert = () => (<SweetAlert
          error
          showCancel={false}
          confirmBtnText="Add Credits"
          confirmBtnBsStyle="error"
          title="Credits Not Available"
          onConfirm={(e) => this.redirectToCredits(e)}
        >
          There are not enough credits available. Please add Credits in Wallet
        </SweetAlert>);
        this.setState({
          alert: getAlert()
        });
      } else {
        this.setState({
          loading: 1
        });
        const token = localStorage.token;
        const response = axios.post(
          window.$API_URL + 'student/booklessonslot',
          {
            slug: slug,
            start_time: start,
            end_time: end
          },
          { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then((response) => {
          this.setState({
            loading: 0,
            alert: ''
          });
          if (response.data.status == "success") {
            const getAlert = () => (<SweetAlert
              success
              showCancel={false}
              confirmBtnText="Proceed to Dashboard"
              confirmBtnBsStyle="success"
              title="Lesson Booked."
              onConfirm={(e) => window.location.href = '/student/lessons'}
            >
              Congratulations! Your lesson booking request has been sent to teacher.
            </SweetAlert>);
            this.setState({
              alert: getAlert()
            });
          }
        }).catch((error) => {
          this.setState({
            loading: 0
          });
          if (!error.response) {
            NotificationManager.error("Some Problem Occured in API", 'Validation Error');
            return;
          }
          if (error.response.data) {
            if (error.response.status === 422) {
              this.state.returnError = [];
              Object.keys(error.response.data).forEach((key) => {
                this.state.returnError.push(error.response.data[key]);
              });
              this.state.showing = true;
              this.setState({ returnError: this.state.returnError, showing: this.state.showing });
              let errorList2 = this.state.returnError.map((char, i) => {
                return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
              });
              NotificationManager.error(errorList2, 'Validation Error');
            } else {
              NotificationManager.error("Some Problem Occured in API", 'Validation Error');
            }

          } else {
            NotificationManager.error("Some Problem Occured in API", 'Validation Error');
          }
        });
      }
    });

  }

  handleEventClick = ({ event, el }) => {
    console.log(event.start);
    var currentMoment = moment(event.start);
    console.log(currentMoment)
    var endMoment = moment(event.end);
    var option_display = [];
    var key = 1;
    option_display.push(<option key={'option-' + key} value={currentMoment.format('YYYY-MM-DD H:mm:ss')}>{currentMoment.format('YYYY-MM-DD H:mm:ss')}</option>);
    while (currentMoment.isBefore(endMoment, 'mins')) {
      key++;
      currentMoment.add(30, 'minutes');
      console.log(`Loop at ${currentMoment.format('YYYY-MM-DD H:mm:ss')}`);
      option_display.push(<option key={'option-' + key} value={currentMoment.format('YYYY-MM-DD H:mm:ss')}>{currentMoment.format('YYYY-MM-DD H:mm:ss')}</option>);

    }
    const getAlert = () => (<SweetAlert
      showCancel={true}
      confirmBtnText="Proceed to Booking"
      confirmBtnBsStyle="success"
      title="Please select a time slot"
      onConfirm={(e) => this.lessonBookingUpdate()}
      onCancel={() => this.setState({ alert: "" })}
    >
      This is a { this.props.course_detail.lesson_duration} minutes class. <br />

      <select onChange={(e) => this.changeSlotTime(e)} className="form-control" key={'date-select-key'}>
        <option value=''>Please select a time slot</option>
        {
          option_display
        }
      </select>
    </SweetAlert>);
    this.setState({
      alert: getAlert()
    });
  }
  lessonBookingUpdate = () => {
    if (this.state.booking_slot_time == "" || this.state.booking_slot_time_end == "") {
      NotificationManager.error("Please Select a Time Slot", 'Error');
    } else {
      this.handleSelect({ start: new Date(this.state.booking_slot_time), end: new Date(this.state.booking_slot_time_end) });
    }
  }
  changeSlotTime = (e) => {
    console.log(e.target.value);
    if (e.target.value == "") {
      console.log('empty-' + e.target.value);
      this.setState({
        booking_slot_time: "",
        booking_slot_time_end: ""
      });
    } else {
      var start_time_defined_formatted = moment(e.target.value).format();
      var start_time_defined = moment(e.target.value);
      var end_time_defined = start_time_defined.add(this.props.course_detail.lesson_duration, 'minutes');
      var end_time_defined_formatted = moment(end_time_defined).format();
      this.setState({
        booking_slot_time: start_time_defined_formatted,
        booking_slot_time_end: end_time_defined_formatted
      });
    }
    return;
  }

  handleSelect = ({ start, end }) => {
    //start date
    console.log(start + " - " + end);
    var start_tmp = moment(start);
    var end_date_temp2 = start_tmp.format();
    var end_date_day = start_tmp.format('D');
    var end_date_month = start_tmp.format('M');
    var end_date_year = start_tmp.format('YYYY');
    var end_hour = start_tmp.format('HH');
    var end_min = start_tmp.format('mm');
    var start_day_string = end_date_year + "-" + end_date_month + "-" + end_date_day + " " + end_hour + ":" + end_min + ":00";

    var end_tmp = moment(end);
    var end_date_temp2 = end_tmp.format();
    var end_date_day = end_tmp.format('D');
    var end_date_month = end_tmp.format('M');
    var end_date_year = end_tmp.format('YYYY');
    var end_hour = end_tmp.format('HH');
    var end_min = end_tmp.format('mm');
    var end_day_string = end_date_year + "-" + end_date_month + "-" + end_date_day + " " + end_hour + ":" + end_min + ":00";

    var fmt = "YYYY-MM-DD h:mm:ss";  // must match the input
    var zone = this.props.user_timezone;
    var m = moment.tz(start_day_string, fmt, zone);
    var m_end = moment.tz(end_day_string, fmt, zone);

    var start_final_tosend = m.utc().format();
    var end_final_tosend = m_end.utc().format();
    var timeStart = start.getTime();
    var timeEnd = end.getTime();
    var hourDiff = timeEnd - timeStart; //in ms
    var secDiff = hourDiff / 1000; //in s
    var minDiff = hourDiff / 60 / 1000;
    var hDiff = hourDiff / 3600 / 1000; //in hours
    if (minDiff == this.props.course_detail.lesson_duration) {
      this.setState({
        loading: 1
      });
      const token = localStorage.token;
      const response = axios.post(
        window.$API_URL + 'student/getteacherslot',
        {
          slug: this.props.slug,
          start_time: start_final_tosend,
          end_time: end_final_tosend
        },
        { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
      ).then((response) => {
        this.setState({
          loading: 0
        });
        if (response.data.response.available && (!response.data.response.booked_by_other) && (!response.data.response.booked_by_me)) {
          const getAlert = () => (<SweetAlert
            success
            showCancel={true}
            confirmBtnText="Continue"
            confirmBtnBsStyle="success"
            title="Slot Available"
            onConfirm={(e) => this.confirmLessonBooking(this.props.slug, start_final_tosend, end_final_tosend)}
            onCancel={() => this.setState({ alert: "" })}
          >
            Congrats! The selected slot is available for booking.
          </SweetAlert>);
          this.setState({
            alert: getAlert()
          });
        } else {
          const getAlert = () => (<SweetAlert
            warning
            showCancel={false}
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title="No Slot Available"
            onConfirm={(e) => this.setState({ alert: "" })}
          >
            Sorry! The selected slot is not available for booking the session.
          </SweetAlert>);
          this.setState({
            alert: getAlert()
          });
        }
      }).catch((error) => {
        this.setState({
          loading: 0
        });
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data).forEach((key) => {
              this.state.returnError.push(error.response.data[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error("Some Problem Occured in API", 'Validation Error');
          }
        }
      });
    } else {
      NotificationManager.error("Please Select " + this.props.course_detail.lesson_duration + " mins Slot!!", 'Slot Error');
    }
  }

  render() {
    return (
      <>

        <Modal
          animation={false}
          show={true}
          onHide={this.props.toggleAvailabilityCalendar}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            Teacher Availability
        </Modal.Header>

          <Modal.Body>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              //timeZone={'UTC'}
              initialView='timeGridWeek'
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={false}
              eventStartEditable={false}
              eventResizableFromStart={false}
              eventDurationEditable={false}
              weekends={this.state.weekendsVisible}
              events={this.state.events}
              initialEvents={this.state.myEventsList} // alternatively, use the `events` setting to fetch from a feed
              select={this.handleDateSelect}
              dateClick={this.handleDateClick}
              //  eventContent={renderEventContent} // custom render function
              eventClick={this.handleEventClick}
              eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed

            />
            {this.state.alert}
          </Modal.Body>
        </Modal>



      </>

    );
  }

}

export default AvailabilityCalendar;
