import React from 'react';
class SignupModal extends React.Component {
	render() {
		return (
			<div>
                </div>
		);
	}
}

export default SignupModal;