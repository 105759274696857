import React from 'react';
import HeaderInner from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import { getStudentListForMessage } from '../../Utils/Utils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ScrollToBottom from 'react-scroll-to-bottom';
import queryString from 'query-string';
import { css } from 'glamor';
import { NotificationManager } from 'react-notifications';
import OutgoingMessage from './component/OutgoingMessage';
import IncomingMessage from './component/IncomingMessage';
import io from 'socket.io-client';
import { connect } from 'react-redux';

const ROOT_CSS = css({
  height: '350',
  width: '100%'
});
class MyConversationTeacherMessage extends React.Component {
  constructor() {
    super();
    this.state = {
      participant_list: [],
      room: '',
      messages: [],
      endpoint: window.$SOCKET_URL,
      user_token: localStorage.token,
      file_attachment: null,
      student_id: '',
      teacher_profile_picture: null,
      name: '',
      response: false,
      is_show_more_visible: true,
      offset: 0,
      other_user_name: null
    };

  }

  componentDidMount() {
    this.initChatMessage(0);
  }
  async componentWillUnmount() {
    this.socket.emit('disconnect-manual');
    this.socket.off();
  }
  initChatMessage = async (message_offset) => {
    const data = queryString.parse(this.props.location.search);
    this.setState({
      participant_list: await getStudentListForMessage(),
      name: data.name,
      room: data.room_id,
    })
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    await this.fetchMessageGroupData(this.state.room);
    await this.fetchMessagesFromDatabase(message_offset);
    this.socket = io(this.state.endpoint, {
      path: '/mysocket',
      secure: true,
  });
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
    this.socket.emit('join', {
      name: this.state.name,
      room: this.state.room
    }, (errorCallback) => {
    });

    this.socket.on('message', (message) => {
      if (message.user !== 'admin') {
        this.state.messages.push({ message: message.text, sender_id: message.sender_id, is_read: 0, is_attachment: message.is_attachment, created_at: new Date() });
        this.setState({
          messages: this.state.messages
        });
      }
      if (this.state.student_id != '') {
        this.markMessageAsRead(this.state.room, this.state.student_id);
      }
    });
  }
  sendMessage = async (message) => {
    if (this.state.message.trim() != '') {
      var response = this.socket.emit('sendMessage', { message: this.state.message.trim(), user_token: this.state.user_token, group_id: this.state.room, is_attachment: 0, name: this.state.name, room: this.state.room, sender_id: this.props.profile_data.id }, () => {
      });
      if (this.state.file_attachment != null) {

        this.socket.emit('sendMessage', { message: this.state.file_attachment.trim(), user_token: this.state.user_token, group_id: this.state.room, is_attachment: 1, name: this.state.name, room: this.state.room }, () => {

        });
      }
      this.setState({
        message: '',
        file_attachment: null
      });
    } else if (this.state.file_attachment != null) {
      this.socket.emit('sendMessage', { message: this.state.file_attachment.trim(), user_token: this.state.user_token, group_id: this.state.room, is_attachment: 1, name: this.state.name, room: this.state.room }, () => {
      });
      this.setState({
        file_attachment: null
      });
    }
  }
  fetchMessagesFromDatabase = async (message_offset = 0, is_append = false) => {
    if (is_append === true) {
      this.props.dispatch(
        { type: 'SHOW_LOADER_TOGGLE', payload: true }
      );
    }
    const token = localStorage.token;
    const response = axios.post(
      window.$API_URL + 'teacher/fetchchatmessage',
      {
        group_id: this.state.room,
        offset: message_offset
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((response) => {
      if (is_append === true) {
        var prev_message = this.state.messages;
        console.log(prev_message);
        response.data.data.map((new_message) => {
          prev_message.unshift(new_message)
        })
        this.setState({
          messages: prev_message
        });
        this.props.dispatch(
          { type: 'SHOW_LOADER_TOGGLE', payload: false }
        );
      } else {
        this.setState({
          messages: response.data.data
        });
      }

      if (response.data.data.length < 50) {
        this.setState({
          is_show_more_visible: false
        });
      }
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (!error.response) {
        NotificationManager.error("Some Problem Occured in API", 'Validation Error');
        return;
      }
      else if (error.response.data) {
        NotificationManager.error("Some Problem Occured in API", 'Validation Error');
      }
    });
  }
  markMessageAsRead = async (group_id, instrutor_id) => {
    const token = localStorage.token;
    const response = axios.post(
      window.$API_URL + 'teacher/markmessagereadforteacher',
      {
        group_id: group_id,
        student_id: instrutor_id
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).catch((error) => {
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("Some Problem Occured in Messaging API", 'Validation Error');
        }
      } else {
        NotificationManager.error("Some Problem Occured in API", 'Validation Error');
      }
    });
  }
  fetchMessageGroupData = async (group_id) => {
    this.setState({
      loading: 1
    });
    const token = localStorage.token;
    const response = await axios.post(
      window.$API_URL + 'teacher/fetchgroupmessagedata',
      {
        group_id: group_id,

      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((response) => {
      this.setState({
        teacher_profile_picture: response.data.data.filename,
        student_id: response.data.data.primary_student_id,
        other_user_name: (response.data.data.student_name !== null) ? response.data.data.student_name.first_name + ' ' + response.data.data.student_name.last_name : null
      });
      this.markMessageAsRead(group_id, response.data.data.primary_student_id);
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: 0
      });
      if (!error.response) {
        NotificationManager.error("Some Problem Occured in API1", 'Validation Error');
        return;
      }
      else if (error.response.data) {
        NotificationManager.error("Some Problem Occured in API", 'Validation Error');
      }
    });
  }

  loadMoreMessages = (e) => {
    console.log(e);
    var current_offset = this.state.offset;
    this.setState({
      offset: (current_offset + 1)
    })
    this.fetchMessagesFromDatabase(current_offset + 1, true);
  }

  initiateChat = (student_id, teacher_name) => {
    const access_token = localStorage.getItem('token');
    var response = [];
    axios
      .post(window.$API_URL + 'teacher/initiatechat', {
        student_id: student_id
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {
        response = success.data.room_id;
        this.props.history.push('/myconversationteacher/message/' + response + '/?name=' + teacher_name + '&room_id=' + response);
        this.initChatMessage();
      }).catch((error) => {
        response = [];
      });
  }

  render() {
    return (
      <div>

        <HeaderInner />

        <div className="container" style={{ minHeight: '300px' }}>
          <div className="breadcrumb-custom">
            <a href="#">Home</a> » My Conversation
          </div>
          {(this.props.profile_data !== null)
            ?

            <div className="row">

              <div className="col-md-4 col-lg-4">

                <div className="dash-maincontent">
                  <ul className="nav nav-tabs dash-tabs" role="tablist">
                    <li className={"active"}>
                      <Link >Inbox</Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div role="tabpanel" className={"active tab-pane"} id="current">
                      {
                        (this.state.participant_list.length === 0)
                          ?
                          <a>No Particiants to Show ....</a>
                          :
                          this.state.participant_list.map((connect) => (
                            <div
                              className={(connect.id === this.state.student_id) ? 'dash-myteachers-module pointer active_message' : 'dash-myteachers-module pointer'}
                              onClick={(e) => this.initiateChat(connect.id, connect.first_name + ' ' + connect.last_name)}
                            >
                              <div className="pull-left">
                                <img src={(connect.image !== null) ? connect.profile_picture : '/img/uploadprofilepic.svg'} className="img-circle" alt="" />
                              </div>
                              <div className="media-body">
                                <h5>{connect.first_name + ' ' + connect.last_name}</h5>
                                <p>Click to Start Conversation</p>
                              </div>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-md-8 col-lg-8">
                <div className="dash-maincontent">
                  <ul className="nav nav-tabs dash-tabs" role="tablist">
                    <li className={"active"}>
                      <Link >{(this.state.other_user_name !== null) ? 'Conversation with ' + this.state.other_user_name : 'Messaging'} </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div role="tabpanel" className={"active tab-pane inbox_msg"} id="">


                      <div className="mesgs">

                        <div className="msg_history">

                          <ScrollToBottom className={ROOT_CSS}>
                            {(this.state.is_show_more_visible) ? <a className="pointer" onClick={(e) => this.loadMoreMessages(e)}>Show More Messages ....</a> : ''}

                            <br />
                            {
                              (this.state.messages.length === 0)
                                ?
                                <a>No Messages to Show ....</a>
                                :
                                this.state.messages.map((message, i) => (
                                  (message.sender_id !== this.props.profile_data.id) ?
                                    <IncomingMessage
                                      key={i}
                                      message={message}
                                      teacher_profile_picture={this.state.teacher_profile_picture}
                                    /> :
                                    <OutgoingMessage key={i} message={message} />
                                ))
                            }
                          </ScrollToBottom>
                        </div>
                        <div className="type_msg">
                          <div className="input_msg_write">
                            <input
                              type="text"
                              className="write_msg"
                              placeholder="Type a message"
                              value={this.state.message}
                              onChange={(e) => this.setState({
                                message: e.target.value
                              })}
                              onKeyPress={event => event.key == 'Enter'
                                ?
                                this.sendMessage() :
                                ''
                              }
                            />
                            <button onClick={(e) => this.sendMessage()} className="msg_send_btn" data-id="623e21f8-a353-4816-b03e-a1c44fe7d4d1" type="button"><i className="fa fa-comment" aria-hidden="true"></i></button>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            :
            ''}

        </div>

        <Footer />
      </div >

    );
  }

}
const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyConversationTeacherMessage);
