import React from 'react';

import Header from './Elements/HeaderInner';
import Footer from './Elements/Footer';
import LoadingSpinner from './Elements/LoadingSpinner';

import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

class MyNotifications extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: 0,
            notificationListTemp: [],
            notificationList: [],
            notificationCount: 0,
            showMoreButton: 1,
            toDisplay: 10,
            offset: 0,
            alert: "",
        };

    }

    async componentDidMount() {
        this.fetchNotifications();
    }

    loadMoreNotifications = async (e) => {
        e.preventDefault();
        this.state.offset = this.state.offset + this.state.toDisplay;
        this.setState({
            offset: this.state.offset,
            limit: this.state.toDisplay,
        });

        this.fetchNotifications();
    }


    fetchNotifications = () => {
        this.setState({
            loading: 1
        });
        const token = localStorage.token;
        const response = axios.post(
            window.$API_URL + 'getmynotifications',
            {
                offset: this.state.offset,
                limit: this.state.toDisplay,
            },
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then((response) => {


            this.setState({
                loading: 0
            });

            response.data.data.rows.map(entry => {
                if(entry.notification_type == 'welcome-notification'){
                    entry.notification_type_text = 'Welcome to ConferenceMe';
                }else if(entry.notification_type == 'book-lesson'){
                    entry.notification_type_text = 'Lesson Booked';
                }else if(entry.notification_type == 'accept-lesson'){
                    entry.notification_type_text = 'Lesson Accepted';
                }else if(entry.notification_type == 'reject-lesson'){
                    entry.notification_type_text = 'Lesson Rejected';
                }else if(entry.notification_type == 'cancel-lesson' || entry.notification_type == 'cancel-lesson-student'){
                    entry.notification_type_text = 'Session Cancelled';
                }else if(entry.notification_type == 'book-course'){
                    entry.notification_type_text = 'Course Booked';
                }else if(entry.notification_type == 'reject-dispute-student'){
                    entry.notification_type_text = 'Dispute Request Rejected';
                }else if(entry.notification_type == 'reject-dispute-teacher'){
                    entry.notification_type_text = "Student's Dispute Request Rejected";
                }else if(entry.notification_type == 'accept-dispute-student'){
                    entry.notification_type_text = 'Dispute Request Accpted';
                }else if(entry.notification_type == 'accept-dispute-teacher'){
                    entry.notification_type_text = "Student's Dispute Request Accepted";
                }
                
                this.state.notificationListTemp.push(entry);
            })

            console.log(this.state.notificationListTemp);

            this.setState({
                notificationList: this.state.notificationListTemp,
                notificationCount: response.data.data.count
            });

            if (this.state.notificationListTemp.length >= response.data.data.count) {
                this.setState({
                    showMoreButton: 0
                });
            } else {
                this.setState({
                    showMoreButton: 1
                });
            }

        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (!error.response) {
                NotificationManager.error(error, 'Validation Error');
                return;
            }
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data).forEach((key) => {
                        this.state.returnError.push(error.response.data[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("Some Problem Occured in API", 'Validation Error');
                }

            } else {
                NotificationManager.error("Some Problem Occured in API", 'Validation Error');
            }
        });


    }

    removeNotification = (e, id) => {
        e.preventDefault();
        const getAlert = () => (<SweetAlert
            warning
            showCancel={true}
            showConfirm={true}
            confirmBtnText="Yes"
            cancelBtnText="No"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="warning"
            title="Remove Notification Confirmation"
            onConfirm={(e) => this.deleteNotification(id)}
            onCancel={(e) => this.setState({ alert: "" })}
        >
            Are you sure you want to cancel this notification?
        </SweetAlert>);
        this.setState({
            alert: getAlert()
        });

    }


    deleteNotification = (id) => {
        //  alert(id);
        const token = localStorage.token;
        this.setState({ loading: true });
        const response = axios.post(
            window.$API_URL + 'student/removenotification',
            {
                notification_id: id,
            },
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then((success) => {
            this.setState({
                loading: false,
                alert: ""
            });
            NotificationManager.success('Notification removed successfully.');
            setTimeout(() => window.location.reload(), 3000);
        }).catch((error) => {
            this.setState({ loading: false });
            NotificationManager.error("Some Error occured While Accepting the Lesson!! Please Try Again", 'Error');
        });

    }


    render() {
        return (
            <div>

                <Header />

                <div class="container">

                    <div class="dash-maincontent notifications-center">

                        <h3>Notifications</h3>

                        {
                            this.state.notificationList.length > 0 ? this.state.notificationList.map((notification_list, index) => (
                                <div class="notification-module">
                                    <a href="#" className="close closebtn" onClick={(e) => this.removeNotification(e, notification_list.id)}>x</a>
                                    <h5>{notification_list.notification_type_text}</h5>
                                    <p>{notification_list.message}</p>
                                </div>
                            )) : <div className="show_more ">There are no notifications.</div>
                        }


                        <div class="notifications-center-bot text-right">

                            {
                                (this.state.showMoreButton) ? <div className="show_more "><a href="#" onClick={(e) => this.loadMoreNotifications(e)} className=" mt-10 bt-custom btn btn-default">Show more</a></div>
                                 : 
                              ''
                            }
                        </div>

                    </div>

                </div>

                <Footer />
                {this.state.alert}
                {this.state.loading ? <LoadingSpinner /> : ''}
            </div>

        );
    }

}
export default MyNotifications;
