import React from 'react';
import axios from 'axios';
import { Link, } from 'react-router-dom';
import { InputGroup, Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import queryString from 'query-string';
import StudentSidebarTwo from '../Elements/StudentSidebarTwo';
import LoadingSpinner from '../Elements/LoadingSpinner';
import { getLocationPayment } from '../../Utils/Utils';
import SelectSearch from 'react-select-search';

class StudentAddMoneyWallet extends React.Component {
  constructor() {
    super();
    this.state = {

      cardnumber: '',
      expiry: '',
      cvc: '',
      payment_type: 'stripe',
      billing_zip: '',
      billing_company: '',
      billing_address: '',
      billing_country: '',
      billing_city: '',
      billing_first_name: '',
      billing_last_name: '',
      billing_state: '',

      mailing_zip: '',
      mailing_company: '',
      mailing_address: '',
      mailing_country: '',
      mailing_city: '',
      mailing_first_name: '',
      mailing_last_name: '',
      mailing_state: '',

      amount: '',
      cardholder_first_name: '',
      cardholder_last_name: '',

      mailing_same: true,

      stripe_show: "active",
      card_show: "",
      paypal_show: "",

      billing_show: "active",
      mailing_show: "",

      location_data: [],
    };

  }

  async componentDidMount() {
    const data = queryString.parse(this.props.location.search);
    if (data.short !== undefined) {
      this.setState({
        amount: data.short
      });
    }

    this.setState({
      location_data: await getLocationPayment(),
    });

  }

  handleCheckClick = () => {
    this.setState({ mailing_same: !this.state.mailing_same });
  }

  locationSelect = (e) => {
    console.log(e);
    this.setState({
      billing_country: e
    })
  }

  locationSelectMailing = (e) => {
    console.log(e);
    this.setState({
      mailing_country: e
    })
  }

  purchaseCardPayment = () => {
    const access_token = localStorage.getItem('token');
    this.setState({
      loading: 1
    });

    axios
      .post(window.$API_URL + 'payment/addcreditsstudent', {
        billing_country: this.state.billing_country,
        billing_zip: this.state.billing_zip,
        billing_address: this.state.billing_address,
        billing_company: this.state.billing_company,
        billing_city: this.state.billing_city,
        billing_first_name: this.state.billing_first_name,
        billing_last_name: this.state.billing_last_name,
        billing_state: this.state.billing_state,

        mailing_country: this.state.mailing_country,
        mailing_zip: this.state.mailing_zip,
        mailing_address: this.state.mailing_address,
        mailing_company: this.state.mailing_company,
        mailing_city: this.state.mailing_city,
        mailing_first_name: this.state.mailing_first_name,
        mailing_last_name: this.state.mailing_last_name,
        mailing_state: this.state.mailing_state,

        mailing_same: this.state.mailing_same,

        cardnumber: this.state.cardnumber,
        cvc: this.state.cvc,
        expiry: this.state.expiry,
        payment_type: this.state.payment_type,
        amount: this.state.amount,
        cardholder_first_name: this.state.cardholder_first_name,
        cardholder_last_name: this.state.cardholder_last_name,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {


        if (this.state.payment_type == 'paypal') {
          console.log(success.data.data);
          window.location.href = success.data.data;
        } else {

          this.setState({
            loading: 0
          });
          this.props.dispatch({
            type: 'SWEET_ALERT_SUCCESS_JSON', payload: {
              title: 'Payment Successfull',
              content: 'Thanks for the payment. The request amount has been added to your wallet.',
              showCancel: false,
              showConfirm: true,
              confirmBtnText: 'Okay',
              confirmBtnBsStyle: 'success',
              success_callback: () => window.location.href = '/student/financialcenter',
              cancel_callback: () => ''
            }
          });
        }

      }).catch((error) => {
        this.setState({
          loading: 0
        });
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data.response).forEach((key) => {
              this.state.returnError.push(error.response.data.response[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error(error.response.data.message ? error.response.data.message : "Some Error occured while making the payment. Please contact helpdesk if amount has been deducted!", 'Validation Error');
          }
        }

      });

  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace-ttl">
            <h3>Add Money to Wallet</h3>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-3">
              <StudentSidebarTwo current_page={'mywallet'} />
            </div>

            <div className="col-md-9 col-sm-9">
              <div className="signup-maincontent">

                <div className="col-md-12 col-sm-12" style={{ marginBottom: '20px' }}>
                  <ul class="nav nav-tabs dash-tabs" role="tablist">
                    <li className={this.state.stripe_show}>
                      <Link onClick={(e) => this.setState({ stripe_show: "active", card_show: "", paypal_show: "", payment_type: "stripe" })}>Credit Card</Link>
                    </li>
                    {/* <li className={this.state.card_show}>
                      <Link onClick={(e) => this.setState({  stripe_show: "", card_show: "active", paypal_show: "", payment_type: "card" })}>Credit Card</Link>
                    </li> */}
                    <li className={this.state.paypal_show}>
                      <Link onClick={(e) => this.setState({ stripe_show: "", card_show: "", paypal_show: "active", payment_type: "paypal" })}>Paypal</Link>
                    </li>



                  </ul>
                </div>


                <div class="tab-content">

                  <div role="tabpanel" className={this.state.stripe_show + " tab-pane"} id="stripee">
                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Card Holder First Name *" onChange={(e) => this.setState({ cardholder_first_name: e.target.value, billing_first_name: e.target.value })} />
                      </Form.Group>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Card Holder Last Name *" onChange={(e) => this.setState({ cardholder_last_name: e.target.value, billing_last_name: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">

                      <Form.Group>
                        <div className="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-usd"></i></span>
                          <Form.Control
                            type="text"
                            placeholder="Amount *"
                            onChange={(e) => this.setState({ amount: e.target.value })}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Card Number *" onChange={(e) => this.setState({ cardnumber: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Expiration Date *" onChange={(e) => this.setState({ expiry: e.target.value })} />

                        <small class="text-muted"><i>Eg: 08/2021 (If Expiry is August. 2021)</i></small>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="CVC *" onChange={(e) => this.setState({ cvc: e.target.value })} />
                      </Form.Group>
                    </div>


                  </div>

                  <div role="tabpanel" className={this.state.card_show + " tab-pane"} id="cardd">
                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Card Holder First Name *" onChange={(e) => this.setState({ cardholder_first_name: e.target.value, billing_first_name: e.target.value })} />
                      </Form.Group>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Card Holder Last Name *" onChange={(e) => this.setState({ cardholder_last_name: e.target.value, billing_last_name: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">

                      <Form.Group>
                        <div className="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-usd"></i></span>
                          <Form.Control
                            type="text"
                            placeholder="Amount *"
                            onChange={(e) => this.setState({ amount: e.target.value })}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Card Number *" onChange={(e) => this.setState({ cardnumber: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Expiration Date *" onChange={(e) => this.setState({ expiry: e.target.value })} />

                        <small class="text-muted"><i>Eg: 08/2021 (If Expiry is August. 2021)</i></small>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="CVC *" onChange={(e) => this.setState({ cvc: e.target.value })} />
                      </Form.Group>
                    </div>


                  </div>

                  <div role="tabpanel" className={this.state.paypal_show + " tab-pane"} id="paypall">


                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <div className="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-usd"></i></span>
                          <Form.Control
                            type="text"
                            placeholder="Amount *"
                            onChange={(e) => this.setState({ amount: e.target.value })}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      &nbsp;
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-12 col-sm-12">
                      <small class="text-muted"><i>This will redirect you to Paypal website. You need to complete payment and it will redirect back you here on the platform.</i></small>
                    </div>
                  </div>

                </div>

                <div className="clearfix"></div>

                <div style={{ display: this.state.paypal_show == 'active' ? 'block' : 'block' }}>
                  <div className="col-md-12 col-sm-12" style={{ marginBottom: '20px' }}>
                    <ul class="nav nav-tabs dash-tabs" role="tablist">

                      <li className={this.state.billing_show}>
                        <Link onClick={(e) => this.setState({ billing_show: "active", mailing_show: "" })}>Billing Address</Link>
                      </li>
                      <li className={this.state.mailing_show}>
                        <Link onClick={(e) => this.setState({ billing_show: "", mailing_show: "active" })}>Mailing Address</Link>
                      </li>



                    </ul>
                  </div>


                  <div class="tab-content">

                    <div role="tabpanel" className={this.state.billing_show + " tab-pane"} id="billing_info">
                      <div className="col-md-6 col-sm-12">
                        <Form.Group>
                          <Form.Control type="text" placeholder="First Name *" value={this.state.billing_first_name} onChange={(e) => this.setState({ billing_first_name: e.target.value })} />
                        </Form.Group>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <Form.Group>
                          <Form.Control type="text" placeholder="Last Name *" value={this.state.billing_last_name} onChange={(e) => this.setState({ billing_last_name: e.target.value })} />
                        </Form.Group>
                      </div>



                      <div className="col-md-12 col-sm-12">
                        <Form.Group>
                          <Form.Control as="textarea" rows="3" placeholder="Address *" onChange={(e) => this.setState({ billing_address: e.target.value })} >

                          </Form.Control>
                        </Form.Group>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <Form.Group>
                          <Form.Control type="text" placeholder="City *" onChange={(e) => this.setState({ billing_city: e.target.value })} />
                        </Form.Group>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <Form.Group>
                          <Form.Control type="text" placeholder="State *" onChange={(e) => this.setState({ billing_state: e.target.value })} />
                        </Form.Group>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <Form.Group>
                          <Form.Control type="text" placeholder="Zip *" onChange={(e) => this.setState({ billing_zip: e.target.value })} />
                        </Form.Group>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <Form.Group>
                          <div className="btn-group btn-block">
                            <SelectSearch
                              options={this.state.location_data}
                              //value={this.state.billing_country}
                              name="country"
                              placeholder="Choose your Country *"
                              className="select-search fullWidth"
                              search={true}
                              onChange={this.locationSelect}
                            />
                          </div>
                        </Form.Group>

                      </div>
                    </div>

                    <div role="tabpanel" className={this.state.mailing_show + " tab-pane"} id="mailing_info">

                      <div className="col-md-12 col-sm-12">
                        <Form.Group>
                          <InputGroup.Checkbox checked={this.state.mailing_same} onChange={this.handleCheckClick} className="check-square" aria-label="Check if Mailing Address same as Billing Address" /> Uncheck if mailing address and billing address are different.
                        </Form.Group>
                      </div>

                      <div style={{ display: this.state.mailing_same == true ? 'none' : 'block' }}>
                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <Form.Control type="text" placeholder="First Name *" value={this.state.mailing_first_name} onChange={(e) => this.setState({ billing_first_name: e.target.value })} />
                          </Form.Group>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <Form.Control type="text" placeholder="Last Name *" value={this.state.mailing_last_name} onChange={(e) => this.setState({ billing_last_name: e.target.value })} />
                          </Form.Group>
                        </div>



                        <div className="col-md-12 col-sm-12">
                          <Form.Group>
                            <Form.Control as="textarea" rows="3" placeholder="Address *" onChange={(e) => this.setState({ mailing_address: e.target.value })} >

                            </Form.Control>
                          </Form.Group>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <Form.Control type="text" placeholder="City *" onChange={(e) => this.setState({ mailing_city: e.target.value })} />
                          </Form.Group>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <Form.Control type="text" placeholder="State *" onChange={(e) => this.setState({ mailing_state: e.target.value })} />
                          </Form.Group>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <Form.Control type="text" placeholder="Zip *" onChange={(e) => this.setState({ mailing_zip: e.target.value })} />
                          </Form.Group>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <div className="btn-group btn-block">
                              <SelectSearch
                                options={this.state.location_data}
                                value={this.state.mailing_country}
                                name="country"
                                placeholder="Choose your Country *"
                                className="select-search fullWidth"
                                search={true}
                                onChange={this.locationSelectMailing}
                              />
                            </div>
                          </Form.Group>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>



                <div className="signup-maincontent-footer">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-6">

                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6 text-right">
                      <button
                        className="btn btn-default bt-custom"
                        role="button"
                        onClick={(e) => this.purchaseCardPayment()}
                      >Pay
        </button>
                    </div>
                  </div>
                </div>



              </div>
            </div>

          </div>
        </div>


        {this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div >

    );
  }

}
const mapStateToProps = (state) => {
  return {
    sweet_alert_success_json: state.sweet_alert_success_json,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentAddMoneyWallet);
