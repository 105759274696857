import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';
import { transactionGet, fetchBalance } from '../../Utils/Utils';
import moment from 'moment';
import Pagination from "react-js-pagination";
import AvailaibleBalanceComponent from '../common_components/AvailableBalanceComponent';

import StudentSidebarTwo from '../Elements/StudentSidebarTwo';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';


class StudentFinancialCenter extends React.Component {
  constructor() {
    super();
    this.changeSearch = this.changeSearch.bind(this);
    this.state = {
      page: 1,
      per_page: 10,
      delay: 1,
      search_term: '',
      transactions: { data: [] },
      balance: 0,
      deleteAlert: "",
    };

  }

  async componentDidMount() {
    this.setState({
      transactions: await transactionGet(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      balance: await fetchBalance()
    });
  }

  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term: search_term,
          transactions: await transactionGet(1, this.state.per_page, this.state.delay, search_term)
        });
      }, 1500)
    });
  }

  async handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber,
      transactions: { data: [] }
    })
    this.setState({
      page: pageNumber,
      transactions: await transactionGet(pageNumber, this.state.per_page, this.state.delay, this.state.search_term)
    });
  }

  refundInitiate = (id, amount, balance) => {
    if(amount > balance){
      var refundAmount = balance;
      var text = "You have utilized amount from this transaction. Refund will be processed with remaining amount. $ "+ refundAmount;
    }else{
      var refundAmount = amount;
      var text = "Refund will be processed with this transaction. The refund amount is $ "+ refundAmount;
    }
    const getAlertDelete = () => (<SweetAlert
      warning
      showCancel
      confirmBtnText="Yes, Refund!"
      confirmBtnBsStyle="danger"
      title="Do you want to initiate Refund?"
      onConfirm={(e) => this.refundProcess(id, refundAmount)}
      onCancel={() => this.removePopup()}
      focusCancelBtn
    >
       {text}
    </SweetAlert>);
    this.setState({
      deleteAlert: getAlertDelete()
    });
  }
  refundProcess = (id, refundAmount) => {
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'student/initiaterefund',
      {
        wallet_overview_id: id,
        refund_amount: refundAmount,
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then(async (success) => {
      NotificationManager.success(success.data.message, 'Success');
      this.setState({
        deleteAlert: ""
      });
      this.handlePageChange(1);
      this.setState({
        balance: await fetchBalance()
      });
      this.setState({ loading: false });
      //window.location.reload();
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While updating the Status!! Please Try Again", 'Validation Error');
    });
  }

  removePopup = () => {
    this.setState({
      deleteAlert: ""
    });
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <StudentSidebarTwo current_page={'mywallet'} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent">
                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li class="active">
                    <a href="#history" data-toggle="tab">Transaction history</a>
                  </li>

                  <li class="pull-right">
                    <Form.Control
                      className="dash-maincontent-search"
                      type="text"
                      placeholder="Search Transactions"
                      onChange={this.changeSearch}
                    />
                  </li>
                </ul>

                <div class="tab-content">

                  <input type="text" class="form-control visible-xs dash-maincontent-search-xsonly" placeholder="Search" />

                  <div role="tabpanel" class="tab-pane active" id="history">
                    <AvailaibleBalanceComponent balance={this.state.balance} />

                    {
                      (this.state.transactions.data.length === 0)
                        ?
                        <div class="dash-myteachers-module mywallet-module">
                          <div class="row">
                            <div class="col-md-8 col-sm-8 col-xs-8">
                              <div class="pull-left">
                                <div class="numberCircle"><i class="far fa-credit-card"></i></div>
                              </div>
                              <div class="media-body">
                                <h5>No Transaction Yet</h5>
                                <p>
                                  Waiting for your first Purchase
                                </p>

                              </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-4">
                              <div class="num">
                                $ 0
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        this.state.transactions.data.map((trans) => (

                          <div class="dash-myteachers-module mywallet-module">
                            <div class="row">
                              <div class="col-md-8 col-sm-8 col-xs-8">
                                <div class="pull-left">

                                  {(trans.paymentmode === 'paypal')
                                    ?
                                    <div class="numberCircle"><i class="fab fa-paypal"></i></div>
                                    : (trans.paymentmode === "card")
                                      ? <div class="numberCircle"><i class="far fa-credit-card"></i></div>
                                      : <div class="numberCircle"><i class="fas fa-book"></i></div>}

                                </div>
                                <div class="media-body">
                                  <h5>{trans.transaction_group}</h5>
                                  <p>{trans.transaction_description} on {moment(trans.createdAt).format("MMM DD,YYYY hh:mm A")}</p>
                                  {
                                    (trans.order_number !== null)
                                      ?
                                      <p>Order Number : {trans.order_number}</p>
                                      :
                                      ''
                                  }
                                  {
                                    (trans.transaction_reference !== null)
                                      ?
                                      <p>Transaction Reference : {trans.transaction_reference}</p>
                                      :
                                      ''
                                  }
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-4">
                                <div class="num">
                                  $ {trans.amount}
                                </div>
                                <br />
                                {
                                  (trans.is_credit == 1 && this.state.balance > 0 && trans.is_refund_initiated == 0 && trans.transaction_group == 'Added Money To Wallet')
                                    ?
                                    <Link className="btn btn-default dash-mycourses-module-bot-bt-out pull-right" 
                                          onClick={() => this.refundInitiate(trans.id, trans.amount, this.state.balance)}>
                                      Refund
                                    </Link>
                                    :
                                    ''
                                }
                              </div>
                            </div>
                          </div>


                        ))
                    }
                    {
                      (this.state.transactions.data.length > 0)
                        ?
                        <div class="text-right">
                          <Pagination
                            activePage={this.state.transactions.page}
                            itemsCountPerPage={this.state.transactions.per_page}
                            totalItemsCount={this.state.transactions.total}
                            pageRangeDisplayed={this.state.transactions.total_pages}
                            onChange={this.handlePageChange.bind(this)}
                          />
                        </div>
                        :
                        ''
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        {this.state.deleteAlert}
        {this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div >

    );
  }

}
export default StudentFinancialCenter;
