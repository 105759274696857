import React from 'react';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { Form } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
class ContactUs extends React.Component {
  constructor(props) {
    super();
    this.state = {
      subject: '',
      name: '',
      contact: '',
      message: '',
      email: ''
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  contactUs = () => {
    const access_token = localStorage.getItem('token');
    axios
      .post(window.$API_URL + 'contactus', {
        subject: this.state.subject,
        name: this.state.name,
        email: this.state.email,
        contact: this.state.contact,
        message: this.state.message,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {

        NotificationManager.success('Your support request has been sent successfully.');
        setTimeout(() => window.location.reload(), 3000);

      }).catch((error) => {
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data.response).forEach((key) => {
              this.state.returnError.push(error.response.data.response[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error("Some Error occured while making the payment. Please contact helpdesk if amount has been deducted!", 'Validation Error');
          }
        }

      });

  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="terms">
            <div class="container">

              <h3>Contact Us</h3>
              <div className="signup-maincontent row">
                <div className="col-md-6 col-sm-12">
                  <Form.Group>
                    <Form.Control type="text" placeholder="Name" onChange={(e) => this.setState({ name: e.target.value })} />
                  </Form.Group>
                </div>

                <div className="col-md-6 col-sm-12">
                  <Form.Group>
                    <Form.Control type="text" placeholder="Email" onChange={(e) => this.setState({ email: e.target.value })} />
                  </Form.Group>
                </div>

                <div className="col-md-6 col-sm-12">
                  <Form.Group>
                    <Form.Control type="text" placeholder="Contact" onChange={(e) => this.setState({ contact: e.target.value })} />
                  </Form.Group>
                </div>

                <div className="col-md-6 col-sm-12">
                  <Form.Group>
                    <Form.Control type="text" placeholder="Subject" onChange={(e) => this.setState({ subject: e.target.value })} />
                  </Form.Group>
                </div>

                <div className="col-md-12 col-sm-12">
                  <Form.Group>
                    <Form.Control as="textarea" rows="3" placeholder="Message" onChange={(e) => this.setState({ message: e.target.value })} >

                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                  <button
                    className="btn btn-default bt-custom"
                    role="button"
                    onClick={(e) => this.contactUs()}
                  >Submit Query
                          </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default ContactUs;