import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class LandingCreateCourse extends React.Component {
  showSignup = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: false });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
  }
  showSignupTeacher = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: true });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
  }
  render() {
    return (
      <div class="section section-createcourse max-container">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-7">
              <h1 class="wow fadeIn" style={{ animationDelay: "0.2s" }} >Creating your <br /> course is fast & easy</h1>
              <ul>
                <li class="wow fadeInRight" style={{ animationDelay: "0.2s" }} >
                  <div class="pull-left"><img src="img/createcourse/ico_heart.svg" alt="" /></div>
                  <div class="media-body">Teach what you are passionate about</div>
                </li>
                <li class="wow fadeInRight" style={{ animationDelay: "0.4s" }} >
                  <div class="pull-left"><img src="img/createcourse/ico_check.svg" alt="" /></div>
                  <div class="media-body">Create a single lesson or an entire course</div>
                </li>
                <li class="wow fadeInRight" style={{ animationDelay: "0.6s" }} >
                  <div class="pull-left"><img src="img/createcourse/ico_money.svg" alt="" /></div>
                  <div class="media-body">Set your own rates</div>
                </li>
                <li class="wow fadeInRight" style={{ animationDelay: "0.8s" }} >
                  <div class="pull-left"><img src="img/createcourse/ico_calendar.svg" alt="" /></div>
                  <div class="media-body">Flexible course scheduling options</div>
                </li>
                <li class="wow fadeInRight" style={{ animationDelay: "1s" }} >
                  <div class="pull-left"><img src="img/createcourse/ico_users.svg" alt="" /></div>
                  <div class="media-body">Offer private group courses</div>
                </li>

              </ul>
              <Link  onClick={(e) => this.showSignupTeacher(e)}>Create your course <i class="zmdi zmdi-long-arrow-right"></i></Link>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingCreateCourse);