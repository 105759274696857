import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';

import TeacherRegistrationSidebar from '../Elements/TeacherRegistrationSidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';


class TeacherStepOne extends React.Component {
  constructor() {
    super();
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      skype_id: '',
      contact_no: '',
      dob: '',
      gender: '',

      page_type: 'add',
      startDate: new Date('2001'),
      stepselector: 'stepone'
    };

  }

  componentDidMount() {
    this.getTeacherInformation();
  }

  getTeacherInformation = async () => {
    const token = localStorage.token;

    this.setState({
      loading: 1
    });
    const response = axios.get(
      window.$API_URL + 'teacher/teacherprofileget',
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then(async (response) => {
      this.setState({
        loading: 0
      });

      var teacherdata = response.data.response;


      this.setState({
        first_name: teacherdata.first_name,
        last_name: teacherdata.last_name,
        email: teacherdata.email,
        skype_id: teacherdata.skype_id,
        contact_no: teacherdata.contact_no,
        gender: teacherdata.gender,
        startDate: teacherdata.dob ? new Date(teacherdata.dob) : new Date(),

      });

      if (teacherdata.first_name) {
        this.setState({
          page_type: 'edit'
        });
      }


    }).catch((error) => {
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
        }

      }
      this.setState({
        loading: 0
      });
    })
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  handleChange = date => {
    this.setState({
      startDate: date,
      dob: date
    });
  };

  genderSelect = (e) => {
    this.setState({
      gender: e
    })
  }

  processStepOne = async (e) => {
    const access_token = localStorage.getItem('token');
    var dob_selected = this.formatDate(this.state.startDate);
    this.setState({
      loading: 1
    });
    await axios.post(
      window.$API_URL + 'teacher/teacherprofileupdateone',
      {
        'first_name': this.state.first_name,
        'last_name': this.state.last_name,
        'contact_no': this.state.contact_no,
        'skype_id': this.state.skype_id,
        'dob': dob_selected,
        'gender': this.state.gender,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        }
      }
    ).then((response) => {
      this.setState({
        loading: 0
      });
      NotificationManager.success('Your personal info has been updated successfully.', 'Success');
      setTimeout(() => {
        window.location.href = '/teacher/steptwo';
      }, 500);

      // if (this.state.page_type == 'add') {
      //   setTimeout(() => {
      //     window.location.href = '/teacher/steptwo';
      //   }, 500);
      // } else {
      //   setTimeout(() => {
      //     window.location.href = '/teacher/myprofile';
      //   }, 500);
      // }
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (error.response.status === 422) {
        this.state.returnError = [];
        Object.keys(error.response.data.response).forEach((key) => {
          this.state.returnError.push(error.response.data.response[key]);
        });
        let errorList2 = this.state.returnError.map((char, i) => {
          return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
        });
        NotificationManager.error(errorList2, 'Validation Error');
      } else {
        NotificationManager.error(error.response.data.message, 'Validation Error');
      }
    });
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace">
            <div class="signupspace-ttl">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-8">
                  {this.state.page_type == 'add' ? <h3><b>Sign up</b> Teacher account</h3> : <h3><b>Update</b> Teacher account</h3>}

                </div>
                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4">
                <TeacherRegistrationSidebar step={this.state.stepselector} />
              </div>

              <div class="col-md-9 col-sm-8">
                <div class="signup-maincontent">
                  <Form.Group>
                    <Form.Control type="text" value={this.state.first_name} placeholder="First name" onChange={(e) => this.setState({ first_name: e.target.value })} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control type="text" value={this.state.last_name} placeholder="Last name" onChange={(e) => this.setState({ last_name: e.target.value })} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control type="text" value={this.state.email} disabled="disabled" placeholder="Email Address" onChange={(e) => this.setState({ email: e.target.value })} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control type="text" value={this.state.skype_id} placeholder="Skype ID" onChange={(e) => this.setState({ skype_id: e.target.value })} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control type="text" value={this.state.contact_no} placeholder="Mobile phone" onChange={(e) => this.setState({ contact_no: e.target.value })} />
                  </Form.Group>





                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={[{
                          value: 'Male',
                          name: 'Male'
                        }, {
                          value: 'Female',
                          name: 'Female'
                        }]}
                        value={this.state.gender}
                        name="gender"
                        placeholder="Choose your Gender"
                        className="select-search fullWidth"
                        search={false}
                        onChange={this.genderSelect}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                      className="form-control"
                      wrapperClassName="fullWidth"
                    />
                  </Form.Group>

                  <div class="signup-maincontent-footer">
                    <div class="row">
                      <div class="col-md-6 col-sm-6 col-xs-6">

                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                        <Button onClick={this.processStepOne} variant="default" className="bt-custom ">Next</Button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <Footer />
        {this.state.loading ? <LoadingSpinner /> : ''}
      </div>

    );
  }

}
export default TeacherStepOne;
