import React from 'react';
import { Link, } from 'react-router-dom';
class CourseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  countRating = (lesson_rating) => {
    var rating = 0;
    var count = 0;
    lesson_rating.forEach((data) => {
      rating = rating + data.rating;
      count++;
    })
    if (count === 0) {
      return (5).toFixed(1);
    } else {
      return (rating / count).toFixed(1);
    }
  }

  render() {

    return (
      <>

        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="coursespage-module">
            <Link to={"/coursedetail/" + this.props.course_detail.slug}>
              <img
                src={(this.props.course_detail.cover_picture !== null) ? this.props.course_detail.banner : '/img/course_pic01.jpg'}
                class="img-responsive cover-picture"
                alt=""
              />
            </Link>
            <Link to={"/coursedetail/" + this.props.course_detail.slug}>
              <img
                src={(this.props.course_detail.class_teacher.image !== null) ? this.props.course_detail.class_teacher.profile_picture : "/img/uploadprofilepic.svg"}
                class="coursespage-module-profilepic img-circle"
                alt=""
              />
            </Link>
            <div class="coursespage-module-txt">
              <Link to={"/coursedetail/" + this.props.course_detail.slug} style={{ textDecoration: "none" }}><h6>{this.props.course_detail.category_name.name}</h6></Link>
              <h4><Link to={"/coursedetail/" + this.props.course_detail.slug} style={{ textDecoration: "none" }}>{this.props.course_detail.title}</Link></h4>
              <p>By <Link to={"/teacher/" + this.props.course_detail.class_teacher.slug}>{this.props.course_detail.class_teacher.first_name + " " + this.props.course_detail.class_teacher.last_name}</Link></p>
            </div>
            <div class="coursespage-module-bot">
              <ul class="list-inline coursespage-module-bot-icons">

                <li> {
                  (this.props.course_detail.class_schedules !== undefined) ?
                    <><i class="far fa-copy" title="Total Classes"></i>{this.props.course_detail.class_schedules.length}</> : <><i class="fas fa-clock" title="Total Length"></i> {this.props.course_detail.lesson_duration}</>
                }</li>

                {
                  (this.props.course_detail.offering_type === 2)
                    ?
                    <li><i class="far fa-star"></i> {this.countRating(this.props.course_detail.lesson_rating)}</li>
                    :
                    ''
                }


                <li><i class="fas fa-globe"></i> {this.props.course_detail.class_language.name}</li>
              </ul>
              <div class="coursespage-module-bot-price">
                <div class="row gutter-20">
                  <div class="col-md-6 col-sm-6 col-xs-10">
                    <Link to={"/coursedetail/" + this.props.course_detail.slug}><h5>$ {this.props.course_detail.price}</h5></Link>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                    <Link to={"/coursedetail/" + this.props.course_detail.slug}><i class="fas fa-chevron-right"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }

}
export default CourseCard;
