import React from 'react';
import { connect } from 'react-redux';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import PopularCourses from './components/PopularCourses';
import BrowseCategories from './components/BrowseCategories';
import NewCourses from './components/NewCourses';
import PrivateLesson from './components/PrivateLesson';
import LanguageCourses from './components/LanguageCourses';
import { fetchPopularCourses, fetchNewCourses, newPrivateLessons, languageCourses, getCategory } from '../../Utils/Utils'
class CoursesList extends React.Component {
  constructor() {
    super();
    this.state = {
      popular_courses: { data: [] },
      new_courses: { data: [] },
      private_lessons: { data: [] },
      language_courses: { rows: [] },
      categories: []
    };
  }

  async componentDidMount() {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      categories: await getCategory(),
      popular_courses: await fetchPopularCourses(),

    })
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );

    this.setState({
      new_courses: await fetchNewCourses(),
      private_lessons: await newPrivateLessons(),
      language_courses: await languageCourses(),

    })
  }


  render() {
    return (
      <div>

        <Header />


        {
          (this.state.categories.length > 0)
            ?
            <BrowseCategories categories={this.state.categories} />
            :
            ''
        }

        {
          (this.state.new_courses.data.length > 0)
            ?
            <NewCourses courses={this.state.new_courses.data} />
            :
            ''
        }

        <div class="container">
          <div class="coursespage-cta">
            <div class="row">

              <div class="col-md-6 col-sm-6">
                <h1>Need undivided attention?</h1>
                <p>Book a private lesson.</p>
              </div>

              <div class="col-md-6 col-sm-6 coursespage-cta-right">
                <img src="img/ico_bann.svg" alt="" />
              </div>

            </div>
          </div>
        </div>
        {
          (this.state.private_lessons.data.length > 0)
            ?
            <PrivateLesson courses={this.state.private_lessons.data} />
            :
            ''
        }

        {
          (this.state.language_courses.rows.length > 0)
            ?
            <LanguageCourses courses={this.state.language_courses.rows} />
            :
            ''
        }

        {
          (this.state.popular_courses.data.length > 0)
            ?
            <PopularCourses courses={this.state.popular_courses.data} />
            :
            ''
        }
        <Footer />
      </div>

    );
  }

}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(CoursesList);
