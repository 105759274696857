import { createStore } from 'redux'
const initialState = {
    profile_data: null,
    login_show: false,
    register_show: false,
    register_force_teacher_show: false,
    isLoggedIn: false,
    isLoading: true,
    user_type_id: 0,
    sweet_alert_success_json: null,
    sweet_alert_failed_json: null,
    course_rating_json: null,
    course_report_json: null,
    show_loader: false
}

const reducer = (state = initialState, action) => {
    if (action.type === 'LOGIN_SHOW') {
        return Object.assign({}, state, {
            login_show: action.payload
        })
    }
    if (action.type === 'REGISTER_SHOW') {
        return Object.assign({}, state, {
            register_show: action.payload
        })
    }
    if (action.type === 'FORGOT_SHOW') {
        return Object.assign({}, state, {
            forgot_show: action.payload
        })
    }
    if (action.type === 'REGISTER_FORCE_TEACHER_SHOW') {
        return Object.assign({}, state, {
            register_force_teacher_show: action.payload
        })
    }

    if (action.type === 'IS_LOGGED') {
        return Object.assign({}, state, {
            isLoggedIn: action.payload
        })
    }
    if (action.type === 'IS_LOADING') {
        return Object.assign({}, state, {
            isLoading: action.payload
        })
    }
    if (action.type === 'USER_TYPE_ID') {
        return Object.assign({}, state, {
            user_type_id: action.payload
        })
    }
    if (action.type === 'PROFILE_DATA_UPDATE') {
        return Object.assign({}, state, {
            profile_data: action.payload
        })
    }
    if (action.type === 'SWEET_ALERT_SUCCESS_JSON') {
        return Object.assign({}, state, {
            sweet_alert_success_json: action.payload
        })
    }
    if (action.type === 'SWEET_ALERT_FAILED_JSON') {
        return Object.assign({}, state, {
            sweet_alert_failed_json: action.payload
        })
    }
    if (action.type === 'COURSE_RATING_JSON') {
        return Object.assign({}, state, {
            course_rating_json: action.payload
        })
    }
    if (action.type === 'COURSE_REPORT_JSON') {
        return Object.assign({}, state, {
            course_report_json: action.payload
        })
    }
    if (action.type === 'SHOW_LOADER_TOGGLE') {
        return Object.assign({}, state, {
            show_loader: action.payload
        })
    }
    return state
}
const store = createStore(reducer)

export default store