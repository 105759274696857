import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';

import StudentSidebar from '../Elements/StudentSidebar';
import { getStudentCurrentTeacher, getStudentFavouriteTeacher, getStudentPastTeacher, fetchBalance } from '../../Utils/Utils';
import RatingComponent from '../common_components/RatingComponent';
import AvailaibleBalanceComponent from '../common_components/AvailableBalanceComponent';

class MyTeachers extends React.Component {
  constructor() {
    super();
    this.state = {
      active_show: 'active',
      following_show: '',
      completed_show: '',

      current_teachers: { rows: [] },
      favourites_teachers: { rows: [] },
      past_teachers: { rows: [] },
      offset: 0,
      balance: 0
    };

  }

  async componentDidMount() {
    this.setState({
      current_teachers: await getStudentCurrentTeacher(this.state.offset),
      favourites_teachers: await getStudentFavouriteTeacher(this.state.offset),
      past_teachers: await getStudentPastTeacher(this.state.offset),
      balance: await fetchBalance()
    });
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <StudentSidebar current_page={'myteachers'} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent">
                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li className={this.state.active_show}>
                    <Link onClick={(e) => this.setState({ active_show: "active", following_show: "", completed_show: "" })}>Current</Link>
                  </li>
                  <li className={this.state.following_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", following_show: "active", completed_show: "" })} >Following</Link>
                  </li>
                  <li className={this.state.completed_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", following_show: "", completed_show: "active" })} >History</Link>
                  </li>

                </ul>


                <div class="tab-content">
                  <div role="tabpanel" className={this.state.active_show + " tab-pane"} id="current">
                    <AvailaibleBalanceComponent balance={this.state.balance} />
                    {
                      (this.state.current_teachers.rows.length === 0)
                        ?
                        <div className="dash-mycourses-module">
                          <p>No Active Teachers.</p>
                        </div>
                        :
                        this.state.current_teachers.rows.map((teacher_row) => (
                          <>
                            <div class="dash-myteachers-module">
                              <div class="pull-left">
                                <img src={teacher_row.profile_picture} class="img-circle" alt="" />
                              </div>
                              <div class="media-body">
                                <h5><Link to={"/teacher/" + teacher_row.slug} >{teacher_row.first_name + " " + teacher_row.last_name}</Link></h5>
                                <p>{teacher_row.teacher_categories ? teacher_row.teacher_categories[0].category.name : ""}</p>
                                <ul class="list-inline">
                                  <RatingComponent
                                    rating={teacher_row.teacher_ratings}
                                  />

                                  <li class="dash-myteachers-module-lessons"><i class="far fa-copy"></i> 2</li>
                                </ul>
                              </div>
                            </div>
                          </>
                        ))
                    }
                  </div>

                  <div role="tabpanel" className={this.state.following_show + " tab-pane"} id="following">
                    <AvailaibleBalanceComponent balance={this.state.balance} />
                    {
                      (this.state.favourites_teachers.rows.length === 0)
                        ?
                        <div className="dash-mycourses-module">
                          <p>No Favourite Teachers.</p>
                        </div>
                        :
                        this.state.favourites_teachers.rows.map((teacher_row) => (
                          <>
                            <div class="dash-myteachers-module">
                              <div class="pull-left">
                                <img src={teacher_row.favourite_name.profile_picture} class="img-circle" alt="" />
                              </div>
                              <div class="media-body">
                                <h5><Link to={"/teacher/" + teacher_row.favourite_name.slug} >{teacher_row.favourite_name.first_name + " " + teacher_row.favourite_name.last_name} </Link></h5>
                                <p>{teacher_row.favourite_name.teacher_categories ? teacher_row.favourite_name.teacher_categories[0].category.name : ""}</p>
                                <ul class="list-inline">
                                  <RatingComponent
                                    rating={teacher_row.favourite_name.teacher_ratings}
                                  />
                                  <li class="dash-myteachers-module-lessons"><i class="far fa-copy"></i> 2 <Link className="btn btn-default dash-myteachers-module-follow-bt">Following</Link></li>
                                </ul>
                              </div>
                            </div>
                          </>
                        ))
                    }

                  </div>


                  <div role="tabpanel" className={this.state.completed_show + " tab-pane"} id="history">
                    <AvailaibleBalanceComponent balance={this.state.balance} />
                    {
                      (this.state.past_teachers.rows.length === 0)
                        ?
                        <div className="dash-mycourses-module">
                          <p>No Past Teachers.</p>
                        </div>
                        :
                        this.state.past_teachers.rows.map((teacher_row) => (
                          <>
                            <div class="dash-myteachers-module">
                              <div class="pull-left">
                                <img src={teacher_row.profile_picture} class="img-circle" alt="" />
                              </div>
                              <div class="media-body">
                                <h5><Link to={"/teacher/" + teacher_row.slug} >{teacher_row.first_name + " " + teacher_row.last_name}</Link></h5>
                                <p>{teacher_row.teacher_categories ? teacher_row.teacher_categories[0].category.name : ""}</p>
                                <ul class="list-inline">
                                  <RatingComponent
                                    rating={teacher_row.teacher_ratings}
                                  />
                                  <li class="dash-myteachers-module-lessons"><i class="far fa-copy"></i> 2</li>
                                </ul>
                              </div>
                            </div>
                          </>
                        ))
                    }

                  </div>

                </div>



              </div>
            </div>

          </div>
        </div>



        <Footer />
      </div>

    );
  }

}
export default MyTeachers;