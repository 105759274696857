import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './store/index'
window.store = store
window.$API_URL = 'https://www.conferenceme.com/backend/v2web/api/';
window.$HOST_URL = 'https://www.conferenceme.com/backend/';
window.$SOCKET_URL = 'https://www.conferenceme.com/';
// window.$API_URL = 'http://localhost:8000/backend/v2web/api/';
// window.$HOST_URL = 'http://localhost:8000/backend/';
// window.$SOCKET_URL = 'http://localhost:8000/';

//window.$API_URL = 'http://108.61.23.23:8001/v2web/api/';
//window.$HOST_URL = 'http://108.61.23.23:8001/';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
