import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';
import TeacherRegistrationSidebar from '../Elements/TeacherRegistrationSidebar';
import { getLanguage } from '../../Utils/Utils';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import SelectSearch from 'react-select-search';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class TeacherStepThree extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      selectedFileVideo: null,
      credential_overview: '',
      youtube_url: '',
      nativeLanguagesDataSend: [],
      alsoTeachLanguagesDataSend: [],

      page_type: 'add',

      src_crop_version: null,
      crop: {
        unit: '%',
        width: 100,
        aspect: 1 / 1,
      },
      imageBlob: '',
      srcImageTemp: "/img/uploadprofilepic.svg",

      dataNativeLanguage: [],
      dataLanguage: [],
      languageData: [],
      stepselector: 'stepthree',

      dataSubject: [],
      categoriesData: [],
      subcategoriesData: [],
      subjectsData: [],

      categoryLanguageData: [],
      subCategoryLanguageData: [],
      subjectLanguageData: [],

      subjectDisplay: ['none'],

    };

  }

  async componentDidMount() {
    this.setState({
      languageData: await getLanguage()
    })

    this.fetchCategories();
    this.getTeacherInformation();
  }


  getTeacherInformation = async () => {
    const token = localStorage.token;

    this.setState({
      loading: 1
    });
    const response = axios.get(
      window.$API_URL + 'teacher/teacherprofileget',
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then(async (response) => {
      this.setState({
        loading: 0
      });

      var teacherdata = response.data.response;

      if (teacherdata.credentials_overview) {
        this.setState({
          page_type: 'edit'
        });
      }


      var languageTeach = response.data.response.language_teach;
      var inc = 0;
      if (languageTeach.length > 0) {
        languageTeach.map(temp_value => {
          this.state.dataNativeLanguage.push(inc);
          this.state.nativeLanguagesDataSend.push(temp_value.language_id.toString());
          inc++;
        });
      } else {
        this.state.dataNativeLanguage.push(inc);
      }

      var languageSpeak = response.data.response.language_speak;
      var inc = 0;
      if (languageSpeak.length > 0) {
        languageSpeak.map(temp_value => {
          this.state.dataLanguage.push(inc);
          this.state.alsoTeachLanguagesDataSend.push(temp_value.language_id.toString());
          inc++;
        });
      } else {
        this.state.dataLanguage.push(inc);
      }

      var subjectInformations = response.data.response.teacher_categories;
      var inc = 0;
      if (subjectInformations.length > 0) {
        subjectInformations.map(temp_value => {
          this.state.dataSubject.push(inc);
          this.state.categoryLanguageData.push(temp_value.category_id.toString());
          this.updateCategoryData(temp_value.category_id, inc);
          this.state.subCategoryLanguageData.push(temp_value.subcategory_id.toString());
          this.updateSubCategoryData(temp_value.subcategory_id, inc)
          this.state.subjectLanguageData.push(temp_value.subject_id.toString());
          inc++;
        });
      } else {
        this.state.dataSubject.push(inc);
      }


      this.setState({
        youtube_url: teacherdata.youtube_url,
        credential_overview: teacherdata.credentials_overview,
        nativeLanguagesDataSend: this.state.nativeLanguagesDataSend,
      });


      if (teacherdata.image) {
        this.setState({
          srcImageTemp: teacherdata.filename,

        });
      }


    }).catch((error) => {
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
        }

      }
      this.setState({
        loading: 0
      });
    })
  }

  completeLater = async (e) => {
    window.location.href = '/teacher/stepfour';
  }


  processStepThree = async (e) => {
    this.setState({
      loading: 1
    });
    console.log(this.state.nativeLanguagesDataSend);
    const access_token = localStorage.getItem('token');
    const data = new FormData();
    data.append('file', this.state.selectedFile);
    data.append('video_file', this.state.selectedFileVideo == null ? "" : this.state.selectedFileVideo);
    data.append('youtube_url', this.state.youtube_url);
    data.append('credential_overview', this.state.credential_overview);
    data.append('nativeLanguage', this.state.nativeLanguagesDataSend.join("<#>"));
    data.append('alsoTeachLanguage', this.state.alsoTeachLanguagesDataSend.join("<#>"));
    data.append('categoryData', this.state.categoryLanguageData.join("<#>"));
    data.append('subCategoryData', this.state.subCategoryLanguageData.join("<#>"));
    data.append('subjectData', this.state.subjectLanguageData.join("<#>"));

    await axios.post(
      window.$API_URL + 'teacher/teacherprofileupdatetwo', data,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        }
      }
    ).then((response) => {
      this.setState({
        loading: 0
      });
      NotificationManager.success('Your presentation information has been updated successfully.', 'Success');

      if (this.state.page_type == 'add') {
        setTimeout(() => {
          window.location.href = '/teacher/stepfour';
        }, 500);
      } else {
        setTimeout(() => {
          window.location.href = '/teacher/myprofile';
        }, 500);
      }
    }).catch((error) => {
      console.log(error);
      this.setState({
        loading: 0
      });
      if (error.response.status === 422) {
        this.state.returnError = [];
        Object.keys(error.response.data.response).forEach((key) => {
          this.state.returnError.push(error.response.data.response[key]);
        });
        let errorList2 = this.state.returnError.map((char, i) => {
          return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
        });
        NotificationManager.error(errorList2, 'Validation Error');
      } else {
        NotificationManager.error(error.response.data.message, 'Validation Error');
      }
    });
  }


  appendChildNativeLanguage = (e) => {
    e.preventDefault();
    let { dataNativeLanguage } = this.state;
    dataNativeLanguage.push(dataNativeLanguage.length);
    this.setState({ dataNativeLanguage });
    console.log(dataNativeLanguage);
  };

  appendChildLanguage = (e) => {
    e.preventDefault();
    let { dataLanguage } = this.state;
    dataLanguage.push(dataLanguage.length);
    this.setState({ dataLanguage });
  };

  updateNativeLanguages = (nativeValue, idNum) => {
    //this.state.nativeLanguagesDataSend.push({[idNum]:nativeValue});
    if (nativeValue != "") {
      if (this.state.nativeLanguagesDataSend.indexOf(nativeValue) === -1) {
        this.state.nativeLanguagesDataSend[idNum] = nativeValue;
      } else {
        NotificationManager.error("The native language is already selected.", 'Validation Error');
        this.state.nativeLanguagesDataSend.splice(idNum, 1);
      }
    } else {
      this.state.nativeLanguagesDataSend.splice(idNum, 1);
    }
  }

  updateAlsoTeachLanguages = (alsoTeachValue, idNum) => {
    if (alsoTeachValue != "") {
      if (this.state.alsoTeachLanguagesDataSend.indexOf(alsoTeachValue) === -1) {
        this.state.alsoTeachLanguagesDataSend[idNum] = alsoTeachValue;
      } else {
        NotificationManager.error("This Language is already selected", 'Validation Error');
        this.state.alsoTeachLanguagesDataSend.splice(idNum, 1);
      }
    }
    else {
      this.state.alsoTeachLanguagesDataSend.splice(idNum, 1);
    }
  }

  removeNativeLanguage = (e, value) => {
    e.preventDefault();
    var index = this.state.dataNativeLanguage.indexOf(value);
    if (index !== -1) {
      this.state.dataNativeLanguage.pop();
    }

    var nameDt = this.state.nativeLanguagesDataSend;
    if (value in nameDt) {
      this.state.nativeLanguagesDataSend.splice(value, 1);
    }

    this.setState({
      dataNativeLanguage: this.state.dataNativeLanguage,
      nativeLanguagesDataSend: this.state.nativeLanguagesDataSend,
    });
  }

  removeLanguage = (e, value) => {
    e.preventDefault();
    var index = this.state.dataLanguage.indexOf(value);
    if (index !== -1) {
      this.state.dataLanguage.pop();
    }

    var nameDt = this.state.alsoTeachLanguagesDataSend;
    if (value in nameDt) {
      this.state.alsoTeachLanguagesDataSend.splice(value, 1);
    }

    this.setState({
      dataLanguage: this.state.dataLanguage,
      alsoTeachLanguagesDataSend: this.state.alsoTeachLanguagesDataSend,
    });
  }



  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ src_crop_version: reader.result });
        console.log(reader.result);
        //alert('jiii');
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  cropAndUploadPicture = () => {
    this.setState({
      srcImageTemp: this.state.croppedImageUrl,
      selectedFile: this.state.imageBlob,
      src_crop_version: null,
      croppedImageUrl: null
    });
    console.log(this.state.croppedImageUrl);
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  }
  onZoomChange = zoom => {
    this.setState({ zoom })
  }
  onImageLoaded = image => {
    this.imageRef = image;
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        this.setState({ imageBlob: blob })
      }, 'image/jpeg');
    });
  }


  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  onFileChangeHandlerVideo = event => {
    //console.log(event.target.files[0]);
    var sizeInBytes = event.target.files[0].size;
    var sizeInMbs = (sizeInBytes / 1000) / 1000;
    sizeInMbs = Math.round(sizeInMbs);

    if (sizeInMbs > 100) {
      NotificationManager.error("Please insert Video Less then 100MB", 'Validation Error');
    } else {
      this.setState({
        selectedFileVideo: event.target.files[0],
        loaded: 0,
      });
    }

  }

  appendChildSubject = (e) => {
    e.preventDefault();
    let { dataSubject } = this.state;
    dataSubject.push(dataSubject.length);
    this.setState({ dataSubject });
  };



  removeChildSubject = (e, value) => {
    e.preventDefault();
    var index = this.state.dataSubject.indexOf(value);
    if (index !== -1) {
      this.state.dataSubject.splice(index, 1);
    }

    var catDt = this.state.categoryLanguageData;
    if (value in catDt) {
      this.state.categoryLanguageData.splice(value, 1);
    }

    var subcatDt = this.state.subCategoryLanguageData;
    if (value in subcatDt) {
      this.state.subCategoryLanguageData.splice(value, 1);
    }

    var subjectDt = this.state.subjectLanguageData;
    if (value in subjectDt) {
      this.state.subjectLanguageData.splice(value, 1);
    }
    this.setState({
      dataSubject: this.state.dataSubject,
      categoryLanguageData: this.state.categoryLanguageData,
      subCategoryLanguageData: this.state.subCategoryLanguageData,
      subjectLanguageData: this.state.subjectLanguageData,
    });
  }

  fetchCategories = async () => {
    const response = axios.get(window.$HOST_URL + 'api/fetchallcategories/', {}, {}).then((response) => {
      this.setState({ categoriesData: response.data.data });

    }).catch((error) => { });
  }

  updateSubCategoryData = (subCategoryValue, idNum) => {
    if (subCategoryValue != "") {
      // if(this.state.subCategoryLanguageData.indexOf(subCategoryValue) === -1){
      //   this.state.subCategoryLanguageData[idNum] = subCategoryValue;
      // }
      this.state.subCategoryLanguageData[idNum] = subCategoryValue;
      const response = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${subCategoryValue}`, {}, {}).then((response) => {

        var thisSubjects = this.state.subjectsData;
        var subjectDisplay = this.state.subjectDisplay;
        thisSubjects[idNum] = response.data.data;

        var dataArray = response.data.data;
        if (dataArray.length > 0) {
          subjectDisplay[idNum] = 'block';
        } else {
          subjectDisplay[idNum] = 'none';
        }

        this.setState(
          {
            subjectsData: thisSubjects,
            subjectDisplay: subjectDisplay,

          });

        //  this.updateSubject();
      }).catch((error) => { });
    }
    else {
      this.state.subCategoryLanguageData.splice(idNum, 1);
    }
  }

  updateCategoryData = (categoryValue, idNum) => {
    if (categoryValue != "") {

      // if(this.state.categoryLanguageData.indexOf(categoryValue) === -1){
      //   this.state.categoryLanguageData[idNum] = categoryValue;
      // }
      this.state.categoryLanguageData[idNum] = categoryValue;

      const response = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${categoryValue}`, {}, {}).then((response) => {

        var thisSubCatergories = this.state.subcategoriesData;
        thisSubCatergories[idNum] = response.data.data;

        this.setState({ subcategoriesData: thisSubCatergories });
        //  this.updateSubject();
      }).catch((error) => { });

      // console.log(this.state.subcategoriesData);
    }
    else {
      this.state.categoryLanguageData.splice(idNum, 1);
    }
  }

  updateSubjectData = (subjectValue, idNum) => {
    if (subjectValue != "") {
      if (this.state.subjectLanguageData.indexOf(subjectValue) === -1) {
        this.state.subjectLanguageData[idNum] = subjectValue;
      } else {
        NotificationManager.error("This category is already selected.", 'Validation Error');
        this.state.subjectLanguageData.splice(idNum, 1);
      }
    }
    else {
      this.state.subjectLanguageData.splice(idNum, 1);
    }
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace">
            <div class="signupspace-ttl">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-8">
                  {this.state.page_type == 'add' ? <h3><b>Sign up</b> Teacher account</h3> : <h3><b>Update</b> Teacher account</h3>}
                </div>
                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">
                  {/*<Link onClick={this.completeLater} >Skip <span class="hidden-xs">and complete later</span> <i class="zmdi zmdi-long-arrow-right"></i></Link>*/}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4">
                <TeacherRegistrationSidebar step={this.state.stepselector} />
              </div>


              <div class="col-md-9 col-sm-8">
                <div class="signup-maincontent">

                  {/* <Form.Group>
                    <div class="signup-uploadprofilepic-module">
                      <label style={{ width: '100%' }}>
                        <img src={this.state.srcImageTemp} id="profile_picture_tt" class="pull-left" alt="" />

                        <h5>Upload profile picture</h5>

                        <input type="file" onChange={this.onSelectFile} style={{ display: 'none' }} />


                        <p >Less than 2MB</p>
                      </label>
                    </div>
                  </Form.Group> */}

                  <div class="">
                    <div class="row ">
                      <div class="col-md-1">
                        <img style={{ width: '40px', marginRight: '10px' }} src={this.state.srcImageTemp} id="profile_picture_tt" class="pull-left" alt="" />
                      </div>
                      <div class="col-md-5 ">
                        <label className="bt-custom btn-block">
                          Upload profile picture *<input type="file" onChange={this.onSelectFile} style={{ display: 'none' }} />
                        </label>
                        <p class="txt-aclaratory">Less than 2MB </p>
                      </div>
                    </div>


                  </div>

                  <div className={`modal login_signup_modal bcmtechar ${this.state.src_crop_version != null ? 'show' : ''}`} style={{ display: `${this.state.src_crop_version != null ? 'block' : 'none'}`, }} id="loginmodal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="" role="document">
                      <div className="modal-content" style={{ width: '80%', marginLeft: 'auto', marginTop: '30px', marginRight: 'auto' }}>
                        <div className="modal-body fixhight">
                          <div className="row">
                            <div className="col-md-6">
                              {this.state.src_crop_version && (
                                <div><ReactCrop
                                  src={this.state.src_crop_version}
                                  crop={this.state.crop}
                                  ruleOfThirds
                                  onImageLoaded={this.onImageLoaded}
                                  onComplete={this.onCropComplete}
                                  onChange={this.onCropChange}
                                /></div>
                              )}
                              <br />
                              <button className="btn btn-primary  btn btn-success grnsuccess small" onClick={(e) => this.setState({ src_crop_version: null, croppedImageUrl: null })}>Exit</button>
                            </div>

                            <div className="col-md-6">
                              {this.state.croppedImageUrl && (
                                <div>
                                  <div><img alt="Crop" style={{ maxWidth: '45%' }} src={this.state.croppedImageUrl} /></div>
                                  <br />
                                  <button className="btn btn-primary  btn btn-success grnsuccess small" onClick={this.cropAndUploadPicture}>Crop & Upload</button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Form.Group>
                    <Form.Control as="textarea" rows="3" placeholder="About Me" value={this.state.credential_overview} onChange={(e) => this.setState({ credential_overview: e.target.value })} >

                    </Form.Control>
                  </Form.Group>

                  <div class="signup-videointroduction">
                    {this.state.dataNativeLanguage.map(id => (
                      <NativeLanguage key={id} id={id} allLanguage={this.state.languageData} nativeLanguagesDataSend={this.state.nativeLanguagesDataSend} updateNativeLanguages={this.updateNativeLanguages}
                        removeNativeLanguage={this.removeNativeLanguage}
                      />
                    ))}

                    <div class="signup-addlink">
                      <Link onClick={this.appendChildNativeLanguage} ><i class="zmdi zmdi-plus-circle-o"></i> Add another language</Link>
                    </div>
                  </div>

                  <div class="signup-videointroduction">
                    {this.state.dataLanguage.map(id => (
                      <Language id={id} allLanguage={this.state.languageData} alsoTeachLanguagesDataSend={this.state.alsoTeachLanguagesDataSend} updateAlsoTeachLanguages={this.updateAlsoTeachLanguages}
                        removeLanguage={this.removeLanguage}
                      />
                    ))}

                    <div class="signup-addlink">
                      <Link onClick={this.appendChildLanguage} ><i class="zmdi zmdi-plus-circle-o"></i> Add another language</Link>
                    </div>
                  </div>

                  <div class="signup-videointroduction">
                    {this.state.dataSubject.map(id => (
                      <Subject id={id}
                        updateCategoryData={this.updateCategoryData} updateSubCategoryData={this.updateSubCategoryData}
                        updateSubjectData={this.updateSubjectData}
                        categoriesData={this.state.categoriesData} subCategoriesData={this.state.subcategoriesData}
                        subjectsData={this.state.subjectsData}
                        subjectDisplay={this.state.subjectDisplay}
                        categoryLanguageData={this.state.categoryLanguageData} subCategoryLanguageData={this.state.subCategoryLanguageData}
                        subjectLanguageData={this.state.subjectLanguageData}

                        removeChildSubject={this.removeChildSubject} />
                    ))}

                    <div class="signup-addlink">
                      <Link onClick={this.appendChildSubject} ><i class="zmdi zmdi-plus-circle-o"></i> Add another category block</Link>
                    </div>
                  </div>


                  <div class="signup-videointroduction">
                    <h4>Video introduction</h4>
                    <p>Video introduction is a mandatory requirement. Please read <Link target="_blank" to="/introduction-video-guidelines">Introduction Video Guidelines</Link> before uploading. You can also watch our <Link>example video</Link> as a reference.
                    </p>
                    <div class="row signup-videointroduction-upload">
                      <div class="col-md-4">
                        <label className="bt-custom btn-block">
                          Upload video <input type="file" onChange={this.onFileChangeHandlerVideo} style={{ display: 'none' }} />
                        </label><br />
                      </div>
                      <div class="col-md-1 text-center">
                        <p>OR</p>
                      </div>
                      <div class="col-md-7">
                        <Form.Group>
                          <Form.Control type="text" placeholder="Embebed YouTube video link" value={this.state.youtube_url} onChange={(e) => this.setState({ youtube_url: e.target.value })} />
                        </Form.Group>
                      </div>
                    </div>
                    <p class="txt-aclaratory">Max. file size 100MB | Max. length: 5 minutes | Min. length: 1 minute</p>
                  </div>




                  <div class="signup-maincontent-footer">
                    <div class="row">
                      <div class="col-md-6 col-sm-6 col-xs-6">
                        {/* <Link to="/teacher/steptwo"><i class="zmdi zmdi-long-arrow-left"></i> Back</Link> */}
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                        <Button onClick={this.processStepThree} variant="default" className="bt-custom ">Next</Button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


        <Footer />
        {this.state.loading ? <LoadingSpinner /> : ''}
      </div>

    );
  }

}



const NativeLanguage = ({ id, allLanguage, updateNativeLanguages, removeNativeLanguage, nativeLanguagesDataSend }) => (
  <div className="form-group">
    {(id > 0) ? <a href="#" onClick={(e) => removeNativeLanguage(e, id)} className="pull-right close-redicon" >x</a> : ""}
    <select className="form-control" value={nativeLanguagesDataSend[id]} onChange={(e) => updateNativeLanguages(e.target.value, id)}>
      <option value=''>Native Language:</option>
      {
        allLanguage.map((h, i) =>
          (<option key={i} value={h.value}>{h.name}</option>))
      }
    </select>
  </div>
);

const Language = ({ id, allLanguage, updateAlsoTeachLanguages, removeLanguage, alsoTeachLanguagesDataSend }) => (
  <div className="form-group">
    {(id > 0) ? <a href="#" onClick={(e) => removeLanguage(e, id)} className="pull-right close-redicon" >x</a> : ""}

    <select className="form-control" value={alsoTeachLanguagesDataSend[id]} onChange={(e) => updateAlsoTeachLanguages(e.target.value, id)}>
      <option value=''>I can also teach using:</option>
      {
        allLanguage.map((h, i) =>
          (<option key={i} value={h.value}>{h.name}</option>))
      }
    </select>
  </div>
);

const Subject = ({ id, updateCategoryData, updateSubCategoryData, updateSubjectData,
  categoriesData, subCategoriesData, subjectsData,
  categoryLanguageData, subCategoryLanguageData, subjectLanguageData,
  removeChildSubject, subjectDisplay }) => (
  <div className="">
    {(id > 0) ? <a href="#" onClick={(e) => removeChildSubject(e, id)} className="pull-right close-redicon" >x</a> : ""}

    <div className="form-group">
      <select className="form-control" value={categoryLanguageData[id]} onChange={(e) => updateCategoryData(e.target.value, id)}>
        <option value=''>Choose Category</option>
        {categoriesData.map((h, i) => (
          <option key={i} value={h.id}>{h.name}</option>
        ))}

      </select>
    </div>

    <div className="form-group">
      <select className="form-control" value={subCategoryLanguageData[id]} onChange={(e) => updateSubCategoryData(e.target.value, id)}>
        <option value=''>Choose a Subcategory</option>
        {

        }
        {(subCategoriesData[id] != undefined) ? subCategoriesData[id].map((h, i) => (
          <option key={i} value={h.value}>{h.name}</option>
        )) : ''}

      </select>
    </div>

    {(subjectDisplay[id] == "block") ?
      <div className="form-group">
        <select className="form-control" value={subjectLanguageData[id]} onChange={(e) => updateSubjectData(e.target.value, id)} >
          <option value=''>Choose Subject</option>
          {(subjectsData[id] != undefined) ? subjectsData[id].map((h, i) => (
            <option key={i} value={h.value}>{h.name}</option>
          )) : ''}
        </select>
      </div> : ""}

    <hr />
  </div>
);


export default TeacherStepThree;
