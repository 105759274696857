import React from 'react';
import { Link, } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy'
class TeacherCard extends React.Component {
  constructor(props) {
    super();
    this.state = {
    };
  }

  componentDidMount() {

  }
  countRating = (lesson_rating) => {
    var rating = 0;
    var count = 0;
    lesson_rating.forEach((data) => {
      rating = rating + data.rating;
      count++;
    });
    if (count === 0) {
      return (0).toFixed(1);
    } else {
      return (rating / count).toFixed(1);
    }

  }
  render() {

    return (
      <>

        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="coursespage-module">
            {
              (this.props.teacher_data.youtube_url !== null && this.props.teacher_data.youtube_url != "null" && this.props.teacher_data.youtube_url !== '')
                ?
                <div className="img-responsive cover-picture">
                  <ReactPlayer
                    url={this.props.teacher_data.youtube_url}
                    width='100%'
                    height='100%'
                    className=""
                  />
                </div>
                :
                (this.props.teacher_data.profile_video !== null && this.props.teacher_data.profile_video !== '')
                  ?
                  <div className="img-responsive cover-picture">
                    <ReactPlayer
                      url={this.props.teacher_data.profile_video}
                      width='100%'
                      height='100%'
                      className=""
                      controls={true}
                    />
                  </div>
                  :
                  <img
                    src={'/img/course_pic01.jpg'}
                    class="img-responsive cover-picture"
                    alt=""
                  />
            }



            <img
              src={(this.props.teacher_data.image !== null) ? this.props.teacher_data.profile_picture : "/img/uploadprofilepic.svg"}
              class="coursespage-module-profilepic img-circle"
              alt=""
            />
            <div class="coursespage-module-txt">
              <h6>{(this.props.teacher_data.teacher_categories[0] !== undefined) ? this.props.teacher_data.teacher_categories[0].category.name : ''}</h6>

              <h4><Link to={"/teacher/" + this.props.teacher_data.slug} style={{ textDecoration: "none" }}>{this.props.teacher_data.first_name + " " + this.props.teacher_data.last_name}</Link></h4>

              <ul class="list-inline coursespage-module-bot-icons">

                <li><i class="fa fa-globe"></i> {
                  (this.props.teacher_data.city !== '') ? this.props.teacher_data.city + ', ' : ''
                }{
                    (this.props.teacher_data.state !== '') ? this.props.teacher_data.state + ', ' : ''
                  }
                  {this.props.teacher_data.location_name.name}</li>

                <li><i class="fas fa-clock"></i> {this.props.teacher_data.timezone_name.description}</li>

              </ul>
            </div>
            <div class="coursespage-module-bot">

            </div>
            <div class="coursespage-module-bot">
              <ul class="list-inline coursespage-module-bot-icons">

                {/* <li>
                  <i class="fas fa-book"></i> {(this.props.teacher_data.teacher_courses.length + this.props.teacher_data.teacher_lessons.length)}
                </li> */}
                <li><i class="far fa-star"></i> {this.countRating(this.props.teacher_data.teacher_ratings)}</li>
                <li><i class="fas fa-globe"></i> {(this.props.teacher_data.language_teach[0] !== undefined) ? this.props.teacher_data.language_teach[0].language_teaches_temp.name : ''}</li>
              </ul>
              <div class="coursespage-module-bot-price">
                <div class="row gutter-20">
                  <div class="col-md-6 col-sm-6 col-xs-10">
                    <h5><Link to={"/teacher/" + this.props.teacher_data.slug}>View Profile</Link></h5>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                    <Link to={"/teacher/" + this.props.teacher_data.slug}><i class="fas fa-chevron-right"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }

}
export default TeacherCard;
