import React from 'react';

import Header from './Elements/Header';
import Footer from './Elements/Footer';
import SignupModal from './Elements/SignupModal';
import LandingBanner from './LandingRoot/LandingBanner';
import LandingCourses from './LandingRoot/LandingCourses';
import LandingMeet from './LandingRoot/LandingMeet';
import LandingCategories from './LandingRoot/LandingCategories';
import LandingCreateCourse from './LandingRoot/LandingCreateCourse';
import LandingHowItWorks from './LandingRoot/LandingHowItWorks';
import LandingTestimonial from './LandingRoot/LandingTestimonial';
import LandingJoinCommunity from './LandingRoot/LandingJoinCommunity';
import { fetchPopularCourses } from '../Utils/Utils'
import { connect } from 'react-redux';

class Landing extends React.Component {
  constructor() {
    super();
    this.state = {
      popular_courses: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      popular_courses: await fetchPopularCourses()
    })
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
  }

  handleChange(newData) {
    this.setState({
      stickyVariableValue: newData,
    });
  }
  render() {
    return (

      (this.state.popular_courses !== null)
        ? <>
          <Header />
          <LandingBanner />
          <LandingCourses popular_courses={this.state.popular_courses} />
          <LandingMeet />
          <LandingCategories />
          <LandingCreateCourse />
          <LandingHowItWorks />
          <LandingTestimonial />
          <LandingJoinCommunity />
          <Footer />
          <SignupModal /></>
        :
        ''

    );
  }

}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(Landing);
