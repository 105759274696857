import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

class LandingHowItWorks extends React.Component {
  showSignup = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: false });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
  }
  showSignupTeacher = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: true });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
  }
  render() {
    return (
      <>
        <div class="section section-howitworks-intro">
          <div class="container">
            <div class="section-howitworks-intro wow fadeIn" style={{ animationDelay: "0.4s" }} >
              <h4>HOW IT WORKS</h4>
              <h1>Get started in<br />three simple steps</h1>
            </div>
          </div>
        </div>

        <div class="section section-howitworks max-container">
          <div class="container">

            <img src="img/home_howitworks_pic_mob.jpg" class="img-responsive visible-xs" alt="" />

            <div class="row">
              <div class="col-md-6 col-sm-6 hidden-xs section-howitworks-left">
                <div class="section-howitworks-left-pic wow fadeInUp" style={{ animationDelay: "0.3s" }} ><img src="img/home_howitworks_pic.png" alt="" /></div>
              </div>

              <div class="col-md-6 col-sm-6">
                <div class="row">

                  <div class="col-md-1 col-sm-1 col-xs-1">
                    <img src="img/howitworks_numbers.png" class="howitowks-numbers" alt="" />
                  </div>
                  <div class="col-md-11 col-sm-11 col-xs-11 howitowks-modules">

                    <div class="howitworks-module wow fadeIn" style={{ animationDelay: "0.2s" }}  >
                      <h3>Choose your course</h3>
                      <p>Classes available around the clock and around the world.</p>
                    </div>
                    <div class="howitworks-module wow fadeIn" style={{ animationDelay: "0.4s" }} >
                      <h3>Schedule your instructor</h3>
                      <p>Real teachers so you get the attention and feedback you deserve.</p>
                    </div>
                    <div class="howitworks-module wow fadeIn" style={{ animationDelay: "0.6s" }} >
                      <h3>Click into class</h3>
                      <p>Cut the travel time, not the quality. Education no matter where you are.</p>
                    </div>

                    <Button onClick={(e) => this.showSignup(e)} variant="default" className="bt-custom">Get started</Button>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingHowItWorks);

