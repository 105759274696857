import React from 'react';
import { Link } from 'react-router-dom';
class StudentSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  componentDidMount() {
    console.log('i am called');
  }
  render() {
    return (
      <div class="dash-sidemenu">
        <ul>
          <li className={(this.props.current_page === 'mycourses') ? "active" : ""}><Link to="/student/courses">My Courses</Link></li>
          <li className={(this.props.current_page === 'mylessons') ? "active" : ""}><Link to="/student/lessons">My Lessons</Link></li>
          <li className={(this.props.current_page === 'myteachers') ? "active" : ''}><Link to="/student/teachers">My Teachers</Link></li>
          <li className={(this.props.current_page === 'mycalendar') ? "active" : ''}><Link to="/student/mycalender">Calendar</Link></li>
        </ul>
      </div>
    );
  }
}

export default StudentSidebar;
