import React from 'react';
import moment from 'moment';

class IncomingMessage extends React.Component {
  constructor(props) {
    super();
    this.state = {
    };

  }

  componentDidMount() {
    //console.log(this.props.message);
  }
  render() {
    return (
      <div class="incoming_msg">
        <div class="incoming_msg_img"> <img src={(this.props.teacher_profile_picture !== null) ? this.props.teacher_profile_picture : '/img/uploadprofilepic.svg'} /></div>
        <div class="received_msg">
          <div class="received_withd_msg">
            <p>{this.props.message.message}</p>
            <span class="time_date">{moment(this.props.message.createdAt).format("h:mm A")} | {moment(this.props.message.createdAt).format("MMM DD,YYYY")}</span>
            <div></div>
          </div>
        </div>
      </div>
    );
  }

}
export default IncomingMessage;
