import React from 'react';
import { Link } from 'react-router-dom';
import { getTeacherLessons } from '../../../Utils/Utils';
import CourseCard from '../../common_components/CourseCard';

class TeacherProfileLessons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teacher_lessons: []
    };
  }

  async componentDidMount() {
    this.setState({
      teacher_lessons: await getTeacherLessons(this.props.teacher_slug)
    });
  }

  render() {
    return (
      <>
        {
          (this.state.teacher_lessons.length > 0)
            ?
            <>
              <div class="row coursespage-module-ttl">
                <div class="col-md-6 col-sm-6 col-xs-8">
                  <h3>Teacher Lesson's</h3>
                </div>
               
              </div>
              <div class="row gutter-20">
                {
                  this.state.teacher_lessons.map((lesson) => (
                    <CourseCard course_detail={lesson} />
                  ))
                }

              </div>

              <div class="col-md-12 coursespage-module-ttl-right">
                  <Link to={"/teacher/" + this.props.teacher_slug + "/lessons"}>View More <i class="zmdi zmdi-long-arrow-right"></i></Link>
                </div>
            </>
            :
            ''
        }

      </>

    );
  }

}
export default TeacherProfileLessons;