import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { getTeacherProfile, getTeacherLessons } from '../../Utils/Utils';
import moment from 'moment';
import ReactPlayer from 'react-player/lazy'
import TeacherProfileLessons from './components/TeacherProfileLessons'
import TeacherProfileCourses from './components/TeacherProfileCourses'
import { connect } from 'react-redux';
import TeacherAvailabilityCalendar from './components/TeacherAvailabilityCalendar'



class TeacherProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
      teacher_profile: null,
      is_calendar_open: false,
      user_timezone: null,
    };
  }

  async componentDidMount() {
    await this.getUserProfile();
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      teacher_profile: await getTeacherProfile(this.props.match.params.slug)
    });
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
  }
  toggleAvailabilityCalendar = () => {
    this.setState({
      is_calendar_open: !this.state.is_calendar_open
    })
  }
  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {
      this.setState({
        user_timezone: response.data.timezone_name.name
      });
    }).catch((error) => {
      this.setState({
        user_timezone: null
      });
    });
  }
  render() {
    return (
      <div>

        <Header />

        <div class="coursespage-section">
          <div class="container">

            <div class="breadcrumb-custom">
              <Link>Home</Link> »
              <Link to="/teachers/find">Teachers</Link> »
              {(this.state.teacher_profile != null) ? this.state.teacher_profile.first_name + ' ' + this.state.teacher_profile.last_name : 'Teacher profile'}
            </div>
            {
              (this.state.teacher_profile != null)
                ?
                <div class="row">
                  <div class="col-md-8 col-sm-8">
                    <div class="teacherprofile-video">
                      {
                        (this.state.teacher_profile.youtube_url != null && this.state.teacher_profile.youtube_url != "null" && this.state.teacher_profile.youtube_url !== "")
                          ?
                          <ReactPlayer
                            url={this.state.teacher_profile.youtube_url}
                            width='100%'
                          />
                          :
                          (this.state.teacher_profile.profile_video != null && this.state.teacher_profile.profile_video !== '')
                            ?
                            <div className="img-responsive cover-picture">
                              <ReactPlayer
                                url={this.state.teacher_profile.profile_video}
                                width='100%'
                                height='100%'
                                className=""
                                controls={true}
                              />
                            </div>
                            :
                            <img src="/img/course_pic01.jpg" class="img-responsive cover-picture" alt="" />
                      }

                    </div>

                    <div class="visible-xs">
                      <div class="teacherprofile-namemodule">
                        <div class="pull-left">
                          <img src={(this.state.teacher_profile.image != null) ? this.state.teacher_profile.profile_picture : "/img/uploadprofilepic.svg"} class="img-circle" alt="" />
                        </div>
                        <div class="media-body">
                          <h4>{this.state.teacher_profile.first_name + ' ' + this.state.teacher_profile.last_name}</h4>
                          <p>{this.state.teacher_profile.teacher_categories[0] ? this.state.teacher_profile.teacher_categories[0].category.name : ""}</p>
                        </div>
                      </div>

                      { /* <div class="teacherprofile-col-availability">
                        <Link className="btn btn-default btn-block bt-custom" >Contact teacher</Link>
                    </div> */ }

                      {
                        (this.props.profile_data !== null)
                          ?
                          (this.props.profile_data.user_type_id === 1)
                            ?
                            <ul class="list-inline teacherprofile-col-availability">
                              <li><a onClick={(e) => this.setState({
                                is_calendar_open: true
                              })} className="pointer"><i class="far fa-calendar-alt"></i><span>Check availability</span></a></li>
                            </ul>
                            :
                            <div class="mywallet-availablebalance-module"><div class="row"><div class="col-md-12 col-sm-12 col-xs-12"><span class="small">Login as Student to Check Availability</span></div></div></div>
                          :
                          <div class="mywallet-availablebalance-module"><div class="row"><div class="col-md-12 col-sm-12 col-xs-12"><span class="small">Login as Student to Check Availability</span></div></div></div>
                      }
                    </div>


                    <div class="coursedetail-col visible-xs">
                      <ul class="coursedetail-col-list">
                        <li><i class="far fa-star"></i><span>5</span></li>
                        <li><i class="far fa-copy"></i><span>{this.state.teacher_profile.teacher_lessons.length} lessons</span></li>
                        <li><i class="far fa-copy"></i><span>{this.state.teacher_profile.teacher_courses.length} courses</span></li>
                        <li><i class="fas fa-globe"></i><span>
                          {this.state.teacher_profile.language_teach.map((lang, index) => (
                            (index > 0) ? " | " + lang.language_teaches_temp.name : lang.language_teaches_temp.name
                          ))}
                        </span></li>
                      </ul>
                    </div>

                    <div class="teacherprofile-namemodule hidden-xs">
                      <div class="pull-left">
                        <img src={(this.state.teacher_profile.image != null) ? this.state.teacher_profile.profile_picture : "/img/uploadprofilepic.svg"} class="img-circle" alt="" />
                      </div>
                      <div class="media-body">
                        <h4>{this.state.teacher_profile.first_name + ' ' + this.state.teacher_profile.last_name}</h4>
                        <p>{this.state.teacher_profile.teacher_categories[0] ? this.state.teacher_profile.teacher_categories[0].category.name : ""}</p>
                      </div>
                    </div>


                    <div class="coursedetail-main-module">
                      <p>{this.state.teacher_profile.credentials_overview}</p>
                    </div>

                    <div class="coursedetail-main-module">
                      <h3>Education & experience</h3>
                      {
                        this.state.teacher_profile.type_education.map((edu) => (
                          <>
                            <h4>{edu.name}</h4>
                            <p>{edu.description}</p>
                            <p><strong>From:</strong> {moment(edu.from_date).format("MMM DD,YYYY")}</p>
                            <p><strong>To:</strong> {
                              moment(edu.to_date).format("MMM DD,YYYY")
                            }</p>
                          </>
                        ))
                      }

                    </div>

                    <div class="coursedetail-main-module">
                      <h3>Work experience</h3>
                      {
                        this.state.teacher_profile.type_experience.map((exp) => (
                          <>
                            <h4>{exp.name}</h4>
                            <p>{exp.description}</p>
                            <p><strong>From:</strong> {moment(exp.from_date).format("MMM DD,YYYY")}</p>
                            <p><strong>To:</strong> {
                              (exp.is_present_working) ? 'Present' : moment(exp.to_date).format("MMM DD,YYYY")

                            }</p>
                          </>
                        ))
                      }
                    </div>

                    <div class="coursedetail-main-module">
                      <h3>Certificates</h3>
                      {
                        this.state.teacher_profile.type_certificate.map((crt) => (
                          <>
                            <h4>{crt.name}</h4>
                            <p>{crt.description}</p>
                            <p><strong>Issued On :</strong> {moment(crt.from_date).format("MMM DD,YYYY")}</p>
                            <p><strong>Issued By :</strong> {crt.issued_by}</p>
                          </>
                        ))
                      }
                    </div>

                    {
                      (this.state.is_calendar_open === true)
                        ?
                        <TeacherAvailabilityCalendar
                          slug={this.props.match.params.slug}
                          profile_data={this.props.profile_data}
                          user_timezone={this.state.user_timezone}
                          toggleAvailabilityCalendar={this.toggleAvailabilityCalendar}
                        />
                        :
                        ''
                    }
                  </div>

                  <div class="col-md-3 col-sm-4 coursedetail-col col-md-offset-1 col-sm-offset-0 hidden-xs">

                    <ul class="coursedetail-col-list">
                      <li><i class="far fa-star"></i><span>5</span></li>
                      <li><i class="far fa-copy"></i><span>{this.state.teacher_profile.teacher_lessons.length} lessons</span></li>
                      <li><i class="far fa-copy"></i><span>{this.state.teacher_profile.teacher_courses.length} courses</span></li>
                      <li><i class="fas fa-globe"></i><span>
                        {this.state.teacher_profile.language_teach.map((lang, index) => (
                          (index > 0) ? " | " + lang.language_teaches_temp.name : lang.language_teaches_temp.name
                        ))}
                      </span></li>
                    </ul>

                    {
                      /*
                    <div class="teacherprofile-col-availability">
                        <Link className="btn btn-default btn-block bt-custom">Contact teacher</Link>
                      </div>
                      */
                    }

                    {
                      (this.props.profile_data !== null)
                        ?
                        (this.props.profile_data.user_type_id === 1)
                          ?
                          <ul class="list-inline teacherprofile-col-availability">
                            <li><a className="pointer" onClick={(e) => this.setState({
                              is_calendar_open: true
                            })}>
                              <i class="far fa-calendar-alt"></i>
                              <span>Check availability</span>
                            </a></li>
                          </ul>
                          :
                          <div class="mywallet-availablebalance-module"><div class="row"><div class="col-md-12 col-sm-12 col-xs-12"><span class="small">Login as Student to Check Availability</span></div></div></div>
                        :
                        <div class="mywallet-availablebalance-module"><div class="row"><div class="col-md-12 col-sm-12 col-xs-12"><span class="small">Login as Student to Check Availability</span></div></div></div>
                    }

                  </div>

                </div>

                :
                ''
            }
            <TeacherProfileLessons
              teacher_slug={this.props.match.params.slug}
            />
            <TeacherProfileCourses
              teacher_slug={this.props.match.params.slug}
            />


          </div>
        </div>



        <Footer />
      </div>

    );
  }

}
const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherProfile);
