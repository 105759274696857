import React from 'react';
import { Link, } from 'react-router-dom';

class BrowseCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  render() {

    return (
      <>
        <div class="coursespage-section">
          <div class="container">

            <div class="row coursespage-module-ttl">
              <div class="col-md-6 col-sm-6 col-xs-8">
                <h3>Browse categories</h3>
              </div>
              
            </div>


            <div class="row gutter-10">
              {this.props.categories.map((category) => (
                <div class="col-md-3 col-sm-3 col-xs-6">
                  <Link to={"/category/" + category.id + "/" + category.slug}>
                    <div class="coursespage-categorybann-cover">
                      <div class="coursespage-categorybann">
                        <div class="coursespage-categorybann-pic">
                          <img className="img-responsive" src={category.category_picture} alt="Snow" style={{ width: "100%", height: '150px' }} />
                        </div>
                        <div class="coursespage-categorybann-centered">
                          <h4>{category.name}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}


            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 coursespage-module-ttl-right">
                <Link to="/courses/all">View More <i class="zmdi zmdi-long-arrow-right"></i></Link>
              </div>



          </div>
        </div>
      </>
    );
  }

}
export default BrowseCategories;
