import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';

import StudentSidebarTwo from '../Elements/StudentSidebarTwo';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';


class StudentChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      old_password: '',
      new_password: '',
      confirm_password: '',

    };

  }



  processChangePassword = async (e) => {
    const access_token = localStorage.getItem('token');
    this.setState({
      loading: 1
    });
    await axios.post(
      window.$API_URL + 'student/updateaccountsetting',
      {
        'old_password': this.state.old_password,
        'new_password': this.state.new_password,
        'confirm_password': this.state.confirm_password
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        }
      }
    ).then((response) => {
      this.setState({
        loading: 0
      });
      NotificationManager.success('Your password has been updated successfully.', 'Success');
      setTimeout(() => {
        window.location.href = '/student/courses';
      }, 500);
      //this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
      // window.location.href = '/student/stepone';
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (error.response.status === 422) {
        this.state.returnError = [];
        Object.keys(error.response.data.response).forEach((key) => {
          this.state.returnError.push(error.response.data.response[key]);
        });
        let errorList2 = this.state.returnError.map((char, i) => {
          return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
        });
        NotificationManager.error(errorList2, 'Validation Error');
      } else {
        NotificationManager.error(error.response.data.message, 'Validation Error');
      }
    });
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace">
            <div class="signupspace-ttl">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-8">
                  <h3><b>Change Password</b></h3>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4">
                <StudentSidebarTwo current_page={'changepassword'} />
              </div>

              <div class="col-md-9 col-sm-8">
                <div class="signup-maincontent">
                  <Form.Group>
                    <Form.Control type="password" value={this.state.old_password} placeholder="Old Password" onChange={(e) => this.setState({ old_password: e.target.value })} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control type="password" value={this.state.new_password} placeholder="New Password" onChange={(e) => this.setState({ new_password: e.target.value })} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control type="password" value={this.state.confirm_password} placeholder="Confirm Password" onChange={(e) => this.setState({ confirm_password: e.target.value })} />
                  </Form.Group>



                  <div class="signup-maincontent-footer">
                    <div class="row">
                      <div class="col-md-6 col-sm-6 col-xs-6">

                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                        <Button onClick={this.processChangePassword} variant="default" className="bt-custom ">Update</Button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <Footer />
        {this.state.loading ? <LoadingSpinner /> : ''}
      </div>

    );
  }

}
export default StudentChangePassword;
