import React from 'react';
import { Link, } from 'react-router-dom';


class AvailaibleBalanceComponent extends React.Component {
  constructor() {
    super();
    this.state = {

    };

  }

  render() {
    return (<div class="mywallet-availablebalance-module">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-6">
          <span class="small">Available $</span> <span class="numbig">{this.props.balance}</span>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 text-right">
          <Link class="btn btn-default" to="/student/addmoneywallet" role="button">Add money to wallet</Link>
          &nbsp;
          {/* <Link class="btn btn-default" to="/student/withdrawalmoney" role="button">Withdrawal money</Link> */}
        </div>
      </div>
    </div>);
  }

}
export default AvailaibleBalanceComponent;