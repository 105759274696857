import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import { transactionGetTeacher, fetchBalanceTeacher } from '../../Utils/Utils';
import moment from 'moment';
import Pagination from "react-js-pagination";


import TeacherProfileSidebar from '../Elements/TeacherProfileSidebar';

class TeacherFinancialCenter extends React.Component {
  constructor() {
    super();
    this.changeSearch = this.changeSearch.bind(this);
    this.state = {
      page: 1,
      per_page: 10,
      delay: 1,
      search_term: '',
      transactions: { data: [] },
      balance: 0,
      hold_balance: 0
    };

  }

  async componentDidMount() {
    var balanceData = await fetchBalanceTeacher();
    this.setState({
      transactions: await transactionGetTeacher(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      balance: balanceData.balance,
      hold_balance: balanceData.hold_balance,
    });
  }

  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term: search_term,
          transactions: await transactionGetTeacher(1, this.state.per_page, this.state.delay, search_term)
        });
      }, 1500)
    });
  }

  async handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber,
      transactions: { data: [] }
    })
    this.setState({
      page: pageNumber,
      transactions: await transactionGetTeacher(pageNumber, this.state.per_page, this.state.delay, this.state.search_term)
    });
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace-ttl">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-8">
                <h3><b>Financial Center</b></h3>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-3">
              <TeacherProfileSidebar step={'financialcenter'} />
            </div>

            <div class="col-md-9 col-sm-9">
              <div class="dash-maincontent">
                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li class="active">
                    <a href="#history" data-toggle="tab">Transaction history</a>
                  </li>

                  <li class="pull-right">
                    <Form.Control
                      className="dash-maincontent-search"
                      type="text"
                      placeholder="Search Transactions"
                      onChange={this.changeSearch}
                    />
                  </li>
                </ul>

                <div class="tab-content">

                  <input type="text" class="form-control visible-xs dash-maincontent-search-xsonly" placeholder="Search" />

                  <div role="tabpanel" class="tab-pane active" id="history">

                    <div class="mywallet-availablebalance-module">
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                          <span class="small">Available $</span> <span class="numbig">{this.state.balance}</span>
                          &nbsp; <span class="numbig">|</span> &nbsp;
                          <span class="small">Hold $</span> <span class="numbig"> {this.state.hold_balance ? this.state.hold_balance : 0}</span>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                          <Link class="btn btn-default" to="/teacher/withdrawalrequest" role="button">Withdrawal Request</Link>
                        </div>
                      </div>
                    </div>

                    {
                      this.state.transactions.data.map((trans) => (

                        <div class="dash-myteachers-module mywallet-module">
                          <div class="row">
                            <div class="col-md-8 col-sm-8 col-xs-8">
                              <div class="pull-left">

                                {(trans.paymentmode === 'paypal')
                                  ?
                                  <div class="numberCircle"><i class="fab fa-paypal"></i></div>
                                  : (trans.paymentmode === "card")
                                    ? <div class="numberCircle"><i class="far fa-credit-card"></i></div>
                                    : <div class="numberCircle"><i class="fas fa-book"></i></div>}

                              </div>
                              <div class="media-body">
                                <h5>{trans.transaction_group}</h5>
                                <p>{trans.transaction_description} on {moment(trans.created_at).format("MMM DD,YYYY h:mA")}</p>
                                {
                                  (trans.order_number !== null)
                                    ?
                                    <p>Order Number : {trans.order_number}</p>
                                    :
                                    ''
                                }
                                {
                                  (trans.transaction_reference !== null)
                                    ?
                                    <p>Transaction Reference : {trans.transaction_reference}</p>
                                    :
                                    ''
                                }
                              </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-4">
                              <div class="num">
                                $ {trans.amount}
                              </div>
                            </div>
                          </div>
                        </div>


                      ))
                    }
                    {
                      (this.state.transactions.data.length > 0)
                        ?
                        <div class="text-right">
                        <Pagination
                          activePage={this.state.transactions.page}
                          itemsCountPerPage={this.state.transactions.per_page}
                          totalItemsCount={this.state.transactions.total}
                          pageRangeDisplayed={this.state.transactions.total_pages}
                          onChange={this.handlePageChange.bind(this)}
                        />
                        </div>
                        :
                        ''
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>



        <Footer />
      </div >

    );
  }

}
export default TeacherFinancialCenter;
