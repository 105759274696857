import React from 'react';
import axios from 'axios';
import Header from './Elements/Header';
import Footer from './Elements/Footer';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'


class StudentVerifyEmail extends React.Component {
    email_token = this.props.match.params.email_token;
    constructor(props) {
        super(props);
        this.state = {
            verifyMessage: '',
            verifyMessageDesc: '',
            statusIcon: '',
            buttonMessage: ''
        }
    }
    componentDidMount() {
        this.verifyEmail();
    }
    verifyEmail = async () => {
        const response = await axios.post(
            window.$HOST_URL + 'verifyemail',
            {
                'email_token': this.email_token,
            },
            { headers: { 'Content-Type': 'application/json' } }
        ).then((response) => {
            console.log(response);
            this.setState({ verifyMessage: "Email Verified!!", verifyMessageDesc: "Thank you for verifying your email. You can start using platform now.", statusIcon: 'fa fa-3x fa-check', buttonMessage: 'Start Learning' });
            localStorage.setItem("is_email_verified", 1);
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ verifyMessage: "Email Already Verified!!", verifyMessageDesc: "Your email is already verified. You can login and start using platform.", statusIcon: 'fa fa-3x fa-check', buttonMessage: 'Start Learning' });

                console.log(error.response.data.message);
            } else if (error.response.status === 400) {
                console.log(error.response.data.message);
                this.setState({ verifyMessage: "No Such Token Found", verifyMessageDesc: "Please login if you have a valid account or register with a email address.", statusIcon: 'fa fa-3x fa-times', buttonMessage: 'Login/Register' });
            }

        });
    }
    render() {
        return (
            <div>
                <Header />
                <div class="coursespage-section">
                    <div class="terms">
                        <div class="container" style={{ minHeight: "500px" }}>
                            <h3>{this.state.verifyMessage}</h3>

                            <p>{this.state.verifyMessageDesc}</p>

                           
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default StudentVerifyEmail;
