import React from 'react';
import HeaderInner from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import { getStudentListForMessage, getmessagelistTeacher } from '../../Utils/Utils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';


class MyConversationTeacher extends React.Component {
  constructor() {
    super();
    this.state = {
      participant_list: [],
      message_list: [],
      is_inbox_active: true,
      is_trash_active: false
    };

  }

  async componentDidMount() {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      participant_list: await getStudentListForMessage(),
      message_list: await getmessagelistTeacher(0)
    })
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
  }
  moveToInbox = (e, group_id) => {
    e.preventDefault();
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    const access_token = localStorage.getItem('token');
    axios
      .post(window.$API_URL + 'teacher/movetoinbox', {
        group_ids: [group_id],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then(async (success) => {
        this.setState({
          message_list: await getmessagelistTeacher(1)
        })
        this.props.dispatch(
          { type: 'SHOW_LOADER_TOGGLE', payload: false }
        );
      }).catch((error) => {
        NotificationManager.error('Some Error Occured while Marking the message as Trash', 'Validation Error');
        this.props.dispatch(
          { type: 'SHOW_LOADER_TOGGLE', payload: false }
        );
      });
  }
  initiateChat = (student_id, teacher_name) => {
    const access_token = localStorage.getItem('token');
    var response = [];
    axios
      .post(window.$API_URL + 'teacher/initiatechat', {
        student_id: student_id
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {
        response = success.data.room_id;
        this.props.history.push('/myconversationteacher/message/' + response + '/?name=' + teacher_name + '&room_id=' + response);
      }).catch((error) => {
        response = [];
      });
  }

  moveToTrash = (e, group_id) => {
    e.preventDefault();
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    const access_token = localStorage.getItem('token');
    axios
      .post(window.$API_URL + 'teacher/movetotrash', {
        group_ids: [group_id],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then(async (success) => {
        this.setState({
          message_list: await getmessagelistTeacher(0)
        })
        this.props.dispatch(
          { type: 'SHOW_LOADER_TOGGLE', payload: false }
        );
      }).catch((error) => {
        NotificationManager.error('Some Error Occured while Marking the message as Trash', 'Validation Error');
        this.props.dispatch(
          { type: 'SHOW_LOADER_TOGGLE', payload: false }
        );
      });
  }

  switchMessage = async (trash) => {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    if (trash === 0) {
      this.setState({
        is_inbox_active: true,
        is_trash_active: false
      });
    } else {
      this.setState({
        is_inbox_active: false,
        is_trash_active: true
      });
    }
    this.setState({
      message_list: await getmessagelistTeacher(trash)
    })
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
  }

  render() {
    return (
      <div>

        <HeaderInner />

        <div className="container" style={{ minHeight: '300px' }}>
          <div className="breadcrumb-custom">
            <a href="#">Home</a> » My Conversation
          </div>
          <div className="row">

            <div className="col-md-4 col-lg-4">

              <div className="dash-maincontent">
                <ul className="nav nav-tabs dash-tabs" role="tablist">
                  <li className={"active"}>
                    <Link>Inbox</Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div role="tabpanel" className={"active tab-pane"} id="current">
                    {
                      this.state.participant_list.map((connect) => (
                        <div className="dash-myteachers-module pointer" onClick={(e) => this.initiateChat(connect.id, connect.first_name + ' ' + connect.last_name)}>
                          <div className="pull-left">
                            <img src={(connect.image !== null) ? connect.profile_picture : '/img/uploadprofilepic.svg'} className="img-circle" alt="" />
                          </div>
                          <div className="media-body">
                            <h5>{connect.first_name + ' ' + connect.last_name}</h5>
                            <p>Click to Start Conversation</p>
                          </div>
                        </div>
                      ))
                    }

                    {
                      (this.state.participant_list.length === 0)
                        ?
                        <p><br />No contacts to show</p>
                        :
                        ''
                    }
                  </div>
                </div>
              </div>

            </div>

            <div className="col-md-8 col-lg-8">


              <div className="dash-maincontent" style={{ maxHeight: '400px', overflow: 'auto' }}>
                <ul className="nav nav-tabs dash-tabs" role="tablist">
                  <li className={(this.state.is_inbox_active) ? 'active' : ''}>
                    <Link onClick={(e) => this.switchMessage(0)}>Inbox</Link>
                  </li>
                  <li className={(this.state.is_trash_active) ? 'active' : ''}>
                    <Link onClick={(e) => this.switchMessage(1)}>Trash</Link>
                  </li>
                </ul>
                {
                  (this.state.message_list.length === 0)
                    ?
                    <p><br />No Messages to show</p>
                    :
                    this.state.message_list.map((connect) => (
                      <div class="col-md-6">
                        <div className="dash-myteachers-module pointer " >
                          <div className="pull-left">
                            <img src={(connect.student_name.image !== null) ? connect.student_name.profile_picture : '/img/uploadprofilepic.svg'} className="img-circle" alt="" />
                          </div>
                          <div className="media-body">
                            <h5>{connect.student_name.first_name + ' ' + connect.student_name.last_name}</h5>
                            {
                              (connect.last_message[0] !== undefined)
                                ?
                                <p>{connect.last_message[0].message}</p>
                                :
                                <p>{'Cannot Retrieve Message'}</p>
                            }
                          </div>
                          <ul class="list-inline mywallet-nav">
                            <li>
                              {(this.state.is_inbox_active)
                                ?
                                <a onClick={(e) => this.moveToTrash(e, connect.group_id)}>
                                  Move to Trash
                                </a>
                                :
                                <a onClick={(e) => this.moveToInbox(e, connect.group_id)}>
                                  Move to Inbox
                                </a>
                              }

                            </li>
                            <li><a onClick={(e) => this.initiateChat(connect.primary_student_id, connect.student_name.first_name + ' ' + connect.student_name.last_name)}>See Conversation</a></li>
                          </ul>
                        </div>
                      </div>
                    ))
                }


              </div>


            </div>
          </div>
        </div>

        <Footer />
      </div >

    );
  }

}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(MyConversationTeacher);
