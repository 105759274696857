import React from 'react';
import { Link } from 'react-router-dom';
class TeacherProfileSidebar extends React.Component {
    constructor(props) {
        super(props);
      }
	render() {
		return (
			<div class="signup-sidemenu">
                <ul>
                    <li className={(this.props.step === "account-setting") ? 'active' : ''}><Link to="/teacher/account-setting" >Account Setting</Link></li>
                    <li className={(this.props.step === "financialcenter") ? 'active' : ''}><Link to="/teacher/financialcenter">Financial Center</Link></li>
                    
                </ul>
            </div>
		);
	}
}

export default TeacherProfileSidebar;
