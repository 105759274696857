import React from 'react';
class TeacherRegistrationSidebar extends React.Component {
    constructor(props) {
        super(props);
      }
	render() {
		return (
			<div class="signup-sidemenu">
                <ul>
                    <li className={(this.props.step === "stepone") ? 'active' : ''}>Personal info</li>
                    <li className={(this.props.step === "steptwo") ? 'active' : ''}>Location</li>
                    <li className={(this.props.step === "stepthree") ? 'active' : ''}>Presentation</li>
                    <li className={(this.props.step === "stepfour") ? 'active' : ''}>Education</li>
                    <li className={(this.props.step === "stepfive") ? 'active' : ''}>Work experience</li>
                    <li className={(this.props.step === "stepsix") ? 'active' : ''}>Certificates</li>
                </ul>
            </div>
		);
	}
}

export default TeacherRegistrationSidebar;
