import React from 'react';
import { Link, } from 'react-router-dom';
import HeaderInner from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import { studentProfileGet, fetchlessoncount, fetchCourseCount } from '../../Utils/Utils';
import moment from 'moment';

class StudentProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      profile_data: null,
      lesson_count: null,
      course_count: null
    };

  }

  async componentDidMount() {
    this.setState({
      profile_data: await studentProfileGet(),
      lesson_count: await fetchlessoncount(),
      course_count: await fetchCourseCount()
    })
    console.log(this.state.lesson_count);
  }

  render() {
    return (
      <div>

        <HeaderInner />

        <div class="container" style={{ minHeight: '600px' }}>
          <div class="breadcrumb-custom">
            <a href="#">Home</a> » My Profile
          </div>
          <div class="row">
            {
              (this.state.profile_data !== null)
                ?
                <>
                  <div class="col-md-8 col-sm-8">

                    <div class="teacherprofile-namemodule studentprofile-namemodule">
                      <img
                        src={(this.state.profile_data.image !== null) ? this.state.profile_data.filename : "/img/uploadprofilepic.svg"}
                        class="img-circle"
                        style={{ verticalAlign: "middle" }}
                        alt=""
                      />
                      <span>{this.state.profile_data.first_name + " " + this.state.profile_data.last_name}</span>
                    </div>

                    <div class="coursedetail-main-module">
                      <h4>About</h4>
                      <p>{this.state.profile_data.credentials_overview}</p>
                    </div>

                    <div class="coursedetail-col visible-xs">

                      <ul class="coursedetail-col-list">
                        <li><i class="far fa-calendar"></i><span>{moment(this.state.profile_data.dob).format("MMM DD,YYYY")}</span></li>
                        <li><i class="far fa-user"></i><span>{this.state.profile_data.gender}</span></li>
                        <li><i class="far fa-clock"></i><span>
                          {(this.state.profile_data.timezone !== null)
                            ?
                            this.state.profile_data.timezone_name.description
                            :
                            'Timezone Not Selected'
                          }
                        </span></li>
                        <li><i class="fas fa-map-marker-alt"></i><span>{(this.state.profile_data.city !== null) ? this.state.profile_data.city + ', ' : ''}
                          {
                            (this.state.profile_data.location !== null)
                              ?
                              this.state.profile_data.location_name.name
                              :
                              'Location Not Selected'
                          }</span></li>
                        <li><i class="fas fa-globe"></i><span>
                          {this.state.profile_data.language_name.map((lang, index) => (
                            (index > 0) ? " | " + lang.lang.name : lang.lang.name
                          ))}
                        </span></li>
                      </ul>

                    </div>

                    <div class="coursedetail-main-module">
                      <h4>Interests</h4>
                      {
                        this.state.profile_data.student_categories.map((cat) => (
                          <span class="label studentprofile-interest-label">{cat.category.name}</span>
                        ))
                      }
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4 coursedetail-col col-md-offset-1 col-sm-offset-0 hidden-xs">

                    <ul class="coursedetail-col-list">
                      <li><i class="far fa-calendar"></i><span>{moment(this.state.profile_data.dob).format("MMM DD,YYYY")}</span></li>
                      <li><i class="far fa-user"></i><span>{this.state.profile_data.gender}</span></li>
                      <li><i class="far fa-clock"></i><span>
                        {
                          (this.state.profile_data.timezone !== null)
                            ?
                            this.state.profile_data.timezone_name.description
                            :
                            'Timezone Not Selected'
                        }
                      </span></li>

                      <li><i class="fas fa-map-marker-alt"></i><span>{(this.state.profile_data.city !== null) ? this.state.profile_data.city + ', ' : ''}
                        {
                          (this.state.profile_data.location !== null)
                            ?
                            this.state.profile_data.location_name.name
                            :
                            'Location Not Selected'
                        }</span></li>

                      <li><i class="fas fa-globe"></i><span>
                        {this.state.profile_data.language_name.map((lang, index) => (
                          (index > 0) ? " | " + lang.lang.name : lang.lang.name
                        ))}
                      </span></li>

                      <li><i class="far fa-copy"></i>
                        {
                          (this.state.course_count !== null)
                            ?
                            <span>Course Completed: {this.state.course_count.completed_class} | Upcoming: {this.state.course_count.upcoming_classes}</span>
                            :
                            <span>Course Completed: 0 | Upcoming: 0</span>
                        }
                      </li>

                      <li><i class="far fa-clipboard"></i>
                        {
                          (this.state.lesson_count !== null)
                            ?
                            <span>Lesson Completed: {this.state.lesson_count.completed_class} | Upcoming: {this.state.lesson_count.upcoming_classes}</span>
                            :
                            <span>Lesson Completed: 0 | Upcoming: 0</span>
                        }

                      </li>
                      <li>
                        <Link
                          className="btn btn-default dash-mycourses-module-bot-bt-fill"
                          to="/student/profile/edit"
                        >Edit Profile
                        </Link>
                      </li>
                    </ul>

                  </div>
                </>
                :
                ''
            }


          </div>
        </div>

        <Footer />
      </div >

    );
  }

}
export default StudentProfile;
