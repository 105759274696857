import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../../Elements/HeaderInner';
import Footer from '../../Elements/Footer';

import TeacherSidebar from '../../Elements/TeacherSidebar';
import { getCourseDetail, getTeacherCourseBookingDetail, getTeacherCourseBookingStudentDetail } from '../../../Utils/Utils';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import * as moment from 'moment';

class TeacherLessonBookingDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: this.props.match.params.slug,
            active_show: 'active',
            upcoming_show: '',
            completed_show: '',

            page: 1,
            per_page: 5,
            delay: 1,
            search_term: '',
            completed_courses: { data: [] },
            courses_session_list: { data: [] },
            courses_student_list: { data: [] },
            typing: false,
            typingTimeout: 0,

            alert: "",
            deleteAlert: "",
            step: 'mylessons',
        };

    }

    async componentDidMount() {

        this.setState({
            completed_courses: await getCourseDetail(this.state.slug)
        });

    }


    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="row">
                        <div class="col-md-2 col-sm-2">
                            <TeacherSidebar step={this.state.step} />
                        </div>

                        <div class="col-md-10 col-sm-10">
                            <div class="dash-maincontent">


                                <ul class="nav nav-tabs dash-tabs" role="tablist">
                                    <li className="active">
                                        <Link> Lesson Booking Detail </Link>
                                    </li>
                                </ul>

                                <div class="tab-content">

                                    <div role="tabpanel" >

                                        {
                                            this.state.completed_courses.data.title ?
                                                <div class="dash-mycourses-module">

                                                    <h6>{this.state.completed_courses.data.category_name.name}</h6>
                                                    <h3>{this.state.completed_courses.data.title}</h3>
                                                    <div class="dash-mycourses-module-date">
                                                        <p>Starts: {moment(this.state.completed_courses.data.start_date).format("MM/DD/YYYY")} - Ends: {moment(this.state.completed_courses.data.end_date).format("MM/DD/YYYY")}  </p>
                                                    </div>

                                                    <div class="dash-mycourses-module-date">
                                                        <p>Sub category: {this.state.completed_courses.data.subcategory_name.name}</p>
                                                        <p>Subject: {this.state.completed_courses.data.subject_name ? this.state.completed_courses.data.subject_name.name : "N/A"}</p>
                                                    </div>


                                                    


                                                     

                                                    <div class="coursedetail-main-module">
                                                        <h4>Course content</h4>
                                                        <ul>
                                                            {
                                                                this.state.completed_courses.data.class_offerings_topic.map((topic) => (
                                                                    <li>{topic.name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div class="coursedetail-main-module">
                                                        <h4>Requirements</h4>
                                                        <ul>
                                                            {
                                                                this.state.completed_courses.data.class_offerings_requirement.map((req) => (
                                                                    <li>{req.name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div class="coursedetail-main-module">
                                                        <h4>Objective</h4>
                                                        <ul>
                                                            {
                                                                this.state.completed_courses.data.class_offerings_ojective.map((objective) => (
                                                                    <li>{objective.name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>

                                                </div>
                                                :
                                                ''
                                        }
                                    </div>



                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <Footer />
            </div >

        );
    }

}
export default TeacherLessonBookingDetail;
