import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';

import TeacherRegistrationSidebar from '../Elements/TeacherRegistrationSidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import moment from 'moment';


class TeacherStepFive extends React.Component {
    constructor() {
        super();
        this.state = {
            date: new Date(),
            dataExperience: [],
            educationDataSend: [],
            fromData: [],
            toData: "",
            employerData: [],
            positionData: [],
            employmentTypeData: [],
            dutiesData: [],
            fromWork: [],
            fromWorkToSend: [],
            toWork: [],
            toWorkToSend: [],
            checkbox_present: [],

            page_type: 'add',


            stepselector: 'stepfive'
        };

    }

    componentDidMount() {
        this.getTeacherInformation();
    }

    completeLater = async (e) => {
        window.location.href = '/teacher/stepsix';
    }

    getTeacherInformation = async () => {
        const token = localStorage.token;

        this.setState({
            loading: 1
        });
        const response = axios.get(
            window.$API_URL + 'teacher/teacherprofileget',
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then(async (response) => {
            this.setState({
                loading: 0
            });

            var typeEducation = response.data.response.type_experience;
            var typeEducationDt = response.data.response.type_experience;
            var inc = 0;

            if (typeEducationDt.length > 0) {
                typeEducationDt.map(temp_value => {
                    this.state.dataExperience.push(inc);
                    this.state.employerData.push(temp_value.name);
                    this.state.positionData.push(temp_value.employment_position);
                    this.state.employmentTypeData.push(temp_value.employment_type);
                    this.state.dutiesData.push(temp_value.description);
                    this.state.fromWork.push(new Date(temp_value.from_date));
                    this.state.fromWorkToSend.push(temp_value.from_date);
                    this.state.toWork.push(new Date(temp_value.from_date));
                    this.state.toWorkToSend.push(temp_value.to_date);
                    this.state.checkbox_present.push(temp_value.is_present_working);
                    inc++;
                });

                this.setState({ page_type: 'edit' });
            } else {
                this.state.dataExperience.push(inc);
            }

            this.setState({
                dataExperience: this.state.dataExperience,
                employerData: this.state.employerData,
                employmentTypeData: this.state.employmentTypeData,
                positionData: this.state.positionData,
                dutiesData: this.state.dutiesData,
                fromWork: this.state.fromWork,
                fromWorkToSend: this.state.fromWorkToSend,
                toWork: this.state.toWork,
                toWorkToSend: this.state.toWorkToSend,
                checkbox_present: this.state.checkbox_present,
            });

        }).catch((error) => {
            console.log(error);
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data.response).forEach((key) => {
                        this.state.returnError.push(error.response.data.response[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
                }

            }
            this.setState({
                loading: 0
            });
        })
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    checkBoxForPresent = (e, id) => {
        this.state.checkbox_present[id] = e.target.checked
        this.setState({
            checkbox_present: this.state.checkbox_present
        });
    }
    onChangeFromWork = (valueFrom, idNum) => {
        var date = moment(valueFrom).format("YYYY-MM-DD");

        if (valueFrom != "") {
            this.state.fromWork[idNum] = valueFrom;
            this.state.fromWorkToSend[idNum] = date;

        }
        else {
            this.state.fromWork.splice(idNum, 1);
            this.state.fromWorkToSend.splice(idNum, 1);
        }
        this.setState({
            fromWork: this.state.fromWork,
            fromWorkToSend: this.state.fromWorkToSend
        });

        // console.log(this.state.fromWork);
    }

    appendChildExperience = (e) => {
        e.preventDefault();
        let { dataExperience } = this.state;
        dataExperience.push(dataExperience.length);
        this.setState({ dataExperience });
    }

    onChangeToWork = (valueTo, idNum) => {
        var date = moment(valueTo).format("YYYY-MM-DD");

        if (valueTo != "") {
            this.state.toWork[idNum] = valueTo;
            this.state.toWorkToSend[idNum] = date;

        }
        else {
            this.state.toWork.splice(idNum, 1);
            this.state.toWorkToSend.splice(idNum, 1);
        }

        this.setState({
            toWork: this.state.toWork,
            toWorkToSend: this.state.toWorkToSend,
        });
    }

    updateDutiesData = (valueDuties, idNum) => {
        if (valueDuties != "") {
            this.state.dutiesData[idNum] = valueDuties;
        }
        else {
            this.state.dutiesData.splice(idNum, 1);
        }

        this.setState({
            dutiesData: this.state.dutiesData,
        });
    }

    updateEmploymentTypeData = (valueEmploymentType, idNum) => {
        if (valueEmploymentType != "") {
            this.state.employmentTypeData[idNum] = valueEmploymentType;

        }
        else {
            this.state.employmentTypeData.splice(idNum, 1);
        }
        this.setState({
            employmentTypeData: this.state.employmentTypeData,
        });
    }

    updatePositionData = (valuePosition, idNum) => {
        if (valuePosition != "") {
            this.state.positionData[idNum] = valuePosition;
        } else {
            this.state.positionData.splice(idNum, 1);
        }
        this.setState({
            positionData: this.state.positionData,
        });
    }

    updateEmployerData = (valueEmployer, idNum) => {
        if (valueEmployer != "") {
            this.state.employerData[idNum] = valueEmployer;
        } else {
            this.state.employerData.splice(idNum, 1);
        }
        this.setState({
            employerData: this.state.employerData,
        });
    }

    removeChild = (e, value) => {
        e.preventDefault();
        var index = this.state.dataExperience.indexOf(value);
        if (index !== -1) {
            this.state.dataExperience.pop();
        }


        var employerData = this.state.employerData;
        if (value in employerData) {
            this.state.employerData.splice(value, 1);
        }

        var positionData = this.state.positionData;
        if (value in positionData) {
            this.state.positionData.splice(value, 1);
        }

        var employmentTypeData = this.state.employmentTypeData;
        if (value in employmentTypeData) {
            this.state.employmentTypeData.splice(value, 1);
        }

        var dutiesData = this.state.dutiesData;
        if (value in dutiesData) {
            this.state.dutiesData.splice(value, 1);
        }

        var fromWork = this.state.fromWork;
        if (value in fromWork) {
            this.state.fromWork.splice(value, 1);
        }

        var fromWorkToSend = this.state.fromWorkToSend;
        if (value in fromWorkToSend) {
            this.state.fromWorkToSend.splice(value, 1);
        }

        var toWork = this.state.toWork;
        if (value in toWork) {
            this.state.toWork.splice(value, 1);
        }

        var toWorkToSend = this.state.toWorkToSend;
        if (value in toWorkToSend) {
            this.state.toWorkToSend.splice(value, 1);
        }

        var checkbox_present = this.state.checkbox_present;
        if (value in checkbox_present) {
            this.state.checkbox_present.splice(value, 1);
        }

        this.setState({
            dataExperience: this.state.dataExperience,
            employerData: this.state.employerData,
            positionData: this.state.positionData,
            employmentTypeData: this.state.employmentTypeData,
            dutiesData: this.state.dutiesData,
            fromWork: this.state.fromWork,
            fromWorkToSend: this.state.fromWorkToSend,
            toWork: this.state.toWork,
            toWorkToSend: this.state.toWorkToSend,
            checkbox_present: this.state.checkbox_present,
        });

    }


    processStepFive = async (e) => {
        const access_token = localStorage.getItem('token');
        this.setState({
            loading: 1
        });
        await axios.post(
            window.$API_URL + 'teacher/teacherprofileupdatefive',
            {
                employerData: this.state.employerData,
                positionData: this.state.positionData,
                dutiesData: this.state.dutiesData,
                employmentTypeData: this.state.employmentTypeData,
                fromWork: this.state.fromWorkToSend,
                toWork: this.state.toWorkToSend,
                is_present_working: this.state.checkbox_present,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': `${access_token}`,
                }
            }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            NotificationManager.success('Your work experience information has been updated successfully.', 'Success');

            if (this.state.page_type == 'add') {
                setTimeout(() => {
                    window.location.href = '/teacher/stepsix';
                }, 500);
            } else {
                setTimeout(() => {
                    window.location.href = '/teacher/myprofile';
                }, 500);
            }
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (error.response.status === 422) {
                this.state.returnError = [];
                Object.keys(error.response.data.response).forEach((key) => {
                    this.state.returnError.push(error.response.data.response[key]);
                });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error(error.response.data.message, 'Validation Error');
            }
        });
    }

    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="signupspace">
                        <div class="signupspace-ttl">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-8">
                                    {this.state.page_type == 'add' ? <h3><b>Sign up</b> Teacher account</h3> : <h3><b>Update</b> Teacher account</h3>}
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">
                                    {/*<Link onClick={this.completeLater} >Skip <span class="hidden-xs">and complete later</span> <i class="zmdi zmdi-long-arrow-right"></i></Link>*/}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <TeacherRegistrationSidebar step={this.state.stepselector} />
                            </div>

                            <div class="col-md-9 col-sm-8">
                                <div class="signup-maincontent">

                                    {this.state.dataExperience.map(id => (
                                        <Education id={id} updateEmployerData={this.updateEmployerData} updatePositionData={this.updatePositionData} updateEmploymentTypeData={this.updateEmploymentTypeData} updateDutiesData={this.updateDutiesData} onChangeFromWork={this.onChangeFromWork} fromData={this.state.fromData} onChangeToWork={this.onChangeToWork} fromWork={this.state.fromWork} toWork={this.state.toWork} checkBoxForPresent={this.checkBoxForPresent}
                                            checkbox_present={this.state.checkbox_present}
                                            employerData={this.state.employerData}
                                            employmentTypeData={this.state.employmentTypeData}
                                            positionData={this.state.positionData}
                                            dutiesData={this.state.dutiesData}
                                            removeChild={this.removeChild}
                                        />
                                    ))}

                                    <Link onClick={this.appendChildExperience}><i class="zmdi zmdi-plus-circle-o"></i> Add more work experience</Link>





                                    <div class="signup-maincontent-footer">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                {/* <Link to="/teacher/stepfour"><i class="zmdi zmdi-long-arrow-left"></i> Back</Link> */}
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                                <Button onClick={this.processStepFive} variant="default" className="bt-custom ">Next</Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <Footer />
                {this.state.loading ? <LoadingSpinner /> : ''}
            </div>

        );
    }

}


const Education = ({ id, updateEmployerData, updatePositionData, updateEmploymentTypeData, updateDutiesData,
    onChangeFromWork, fromData, onChangeToWork, toWork, fromWork, checkBoxForPresent, checkbox_present, employerData,
    employmentTypeData, positionData, dutiesData, removeChild }) => (

        <div className="row">
            {(id > 0) ? <a href="#" onClick={(e) => removeChild(e, id)} className="pull-right close-redicon" >x</a> : ""}



            <Form.Group>
                <Form.Control type="text" value={employerData[id]} onChange={(e) => updateEmployerData(e.target.value, id)} placeholder="Employer" />
            </Form.Group>

            <Form.Group>
                <Form.Control type="text" value={positionData[id]} onChange={(e) => updatePositionData(e.target.value, id)} placeholder="Position" />
            </Form.Group>

            <Form.Group>
                <Form.Control as="select" value={employmentTypeData[id]} onChange={(e) => updateEmploymentTypeData(e.target.value, id)}  >
                    <option>Work Type</option>
                    <option>Full Time</option>
                    <option>Part Time</option>
                    <option>Freelance</option>
                </Form.Control>
            </Form.Group>

            <Form.Group>
                <Form.Control as="textarea" rows="3" value={dutiesData[id]} placeholder="Duties" onChange={(e) => updateDutiesData(e.target.value, id)}  >

                </Form.Control>
            </Form.Group>

            <div class="row">
                <div class="col-md-6 col-sm-6">

                    <Form.Group>
                        <DatePicker
                            onChange={(e) => onChangeFromWork(e, id)}
                            selected={fromWork[id]}
                            className="form-control"
                            wrapperClassName="fullWidth"
                            placeholderText="From"
                        />
                    </Form.Group>

                </div>
                {(checkbox_present[id]) ? '' :
                    <div class="col-md-6 col-sm-6">
                        <Form.Group>
                            <DatePicker
                                onChange={(e) => onChangeToWork(e, id)}
                                selected={toWork[id]}
                                className="form-control"
                                wrapperClassName="fullWidth"
                                placeholderText="To"
                            />
                        </Form.Group>
                    </div>}
            </div>

            <div class="row signup-workexperience">
                <div class="col-md-6 col-sm-6 col-xs-6">
                    <p>Do you currently work here? &nbsp;&nbsp;
                        <input type="checkbox" checked={(checkbox_present[id]) ? 'checked' : ''} onClick={(e) => checkBoxForPresent(e, id)} /></p>

                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 text-right">

                </div>
            </div>

            <div class="signup-education-separator">
            </div>



        </div>
    );

export default TeacherStepFive;
