import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from 'axios';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';

import StudentSidebar from '../Elements/StudentSidebar';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Modal } from 'react-bootstrap';
import { studentCalendar } from '../../Utils/Utils'
class MyTeachers extends React.Component {
  constructor() {
    super();
    this.state = {
      events: [],
      user_timezone: null,
    };
  }

  async componentDidMount() {
    await this.getUserProfile();
    this.setState({
      events: await studentCalendar(this.state.user_timezone)
    })
  }
  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {
      this.setState({
        user_timezone: response.data.timezone_name.name
      });
    }).catch((error) => {
      this.setState({
        user_timezone: null
      });
    });
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <StudentSidebar current_page={'mycalendar'} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent dash-maincontent-calendar">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                  }}
                  //timeZone={'UTC'}
                  initialView='dayGridMonth'
                  editable={true}
                  selectable={false}
                  selectMirror={true}
                  dayMaxEvents={false}
                  eventStartEditable={false}
                  eventResizableFromStart={false}
                  eventDurationEditable={false}
                  weekends={this.state.weekendsVisible}
                  events={this.state.events}
                  initialEvents={this.state.myEventsList} // alternatively, use the `events` setting to fetch from a feed
                  select={this.handleDateSelect}
                  dateClick={this.handleDateClick}
                  //  eventContent={renderEventContent} // custom render function
                  eventClick={this.handleEventClick}
                  eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed

                />
              </div>
            </div>

          </div>
        </div>



        <Footer />
      </div>

    );
  }

}
export default MyTeachers;
