import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
class LandingCategories extends React.Component {
  render() {
    return (
      <div class="section section-categories">
        <div class="container">

          <div class="row">
            <div class="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0 section-categories-intro">
              <h1 class="wow fadeIn" style={{ animationDelay: "0.4s" }}  >Learn from highly qualified professional teachers, and real-world experts</h1>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
              <div class="row">

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/2/business"><img src="img/categories/ico_business.svg" alt="" />Business</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/1/arts-crafts-hobbies"><img src="img/categories/ico_design.svg" alt="" />Design</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/1/arts-crafts-hobbies"><img src="img/categories/ico_business.svg" alt="" />Photography</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/12/academic-disciplines"><img src="img/categories/ico_marketing.svg" alt="" />Academics</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/4/exam-preprations-professional-certifications"><img src="img/categories/ico_science.svg" alt="" />Exams Training</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/3/computing-it"><img src="img/categories/ico_computer.svg" alt="" />IT & Software</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/7/technical-and-skilled-trades"><img src="img/categories/ico_music.svg" alt="" />Technical Skills</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6 hidden-xs">
                  <div class="homecategories-module">
                    <Link to="/category/6/sports-and-health"><img src="img/categories/ico_sport.svg" alt="" />Sport</Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-6">
                  <div class="homecategories-module">
                    <Link to="/category/5/language-culture"><img src="img/categories/ico_language.svg" alt="" />Language</Link>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="text-center">
            <Button variant="default" className="bt-custom" >
              <Link to="/courses" style={{ "color": "white" }}>Browse categories</Link>
            </Button>
          </div>

        </div>
      </div>
    );
  }
}

export default LandingCategories;
