import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';

import TeacherSidebar from '../Elements/TeacherSidebar';
import { getTeacherCourseList, getTeacherCourseBookingListUpcoming, getTeacherCourseBookingListCompleted } from '../../Utils/Utils';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import * as moment from 'moment';
import moment_timezone from 'moment-timezone';

class MyCourses extends React.Component {
  constructor() {
    super();
    this.state = {
      active_show: 'active',
      upcoming_show: '',
      completed_show: '',

      page: 1,
      per_page: 5,
      delay: 1,
      search_term: '',
      courses_list: { data: [] },
      courses_list_upcoming: { data: [] },
      courses_list_completed: { data: [] },
      typing: false,
      typingTimeout: 0,

      page_upcoming: 1,
      per_page_upcoming: 5,
      delay_upcoming: 1,
      search_term_upcoming: '',
      courses_list_upcoming: { data: [] },
      typing_upcoming: false,
      typingTimeout_upcoming: 0,

      page_completed: 1,
      per_page_completed: 5,
      delay_completed: 1,
      search_term_completed: '',
      courses_list_completed: { data: [] },
      typing_completed: false,
      typingTimeout_completed: 0,



      alert: "",
      deleteAlert: "",

      step: 'mycourses',
      user_timezone: ''
    };

  }

  async componentDidMount() {
    await this.getUserProfile();
    this.setState({
      courses_list: await getTeacherCourseList(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      courses_list_upcoming: await getTeacherCourseBookingListUpcoming(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      courses_list_completed: await getTeacherCourseBookingListCompleted(this.state.page, this.state.per_page, this.state.delay, this.state.search_term)
    });
  }

  utcToUsaTimeConvert = (date) => {
    return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
  }

  utcToUsaTimeConvertDisplay = (date) => {
    return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD hh:mm A");
  }

  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {

      this.setState({
        user_timezone: response.data.timezone_name.name
      });

    }).catch((error) => {
      if (!error.response) {
        NotificationManager.error("Some Problem Occured in Fetching Teacher Availability", 'Validation Error');
        return;
      }
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
        }

      } else {
        NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
      }
    });
  }

  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term: search_term,
          courses_list: await getTeacherCourseList(1, this.state.per_page, this.state.delay, search_term)
        });
      }, 1500)
    });
  }

  changeSearchUpcoming = async (event) => {
    const self = this;
    var search_term_upcoming = event.target.value;
    if (self.state.typingTimeout_upcoming) {
      clearTimeout(self.state.typingTimeout_upcoming);
    }

    self.setState({
      //name: event.target.value,
      typing_upcoming: false,
      typingTimeout_upcoming: setTimeout(async () => {
        this.setState({
          page_upcoming: 1,
          search_term_upcoming: search_term_upcoming,
          courses_list_upcoming: await getTeacherCourseBookingListUpcoming(1, this.state.per_page_upcoming, this.state.delay_upcoming, search_term_upcoming)
        });
      }, 1500)
    });
  }

  changeSearchCompleted = async (event) => {
    const self = this;
    var search_term_completed = event.target.value;
    if (self.state.typingTimeout_completed) {
      clearTimeout(self.state.typingTimeout_completed);
    }

    self.setState({
      name: event.target.value,
      typing_completed: false,
      typingTimeout_completed: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term_completed: search_term_completed,
          courses_list_completed: await getTeacherCourseBookingListCompleted(1, this.state.per_page_completed, this.state.delay_completed, search_term_completed)
        });
      }, 1500)
    });
  }

  async handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber,
      courses_list: await getTeacherCourseList(pageNumber, this.state.per_page, this.state.delay, this.state.search_term)
    });
  }

  async handlePageChangeUpcoming(pageNumber) {
    this.setState({
      page_upcoming: pageNumber,
      courses_list_upcoming: await getTeacherCourseBookingListUpcoming(pageNumber, this.state.per_page_upcoming, this.state.delay_upcoming, this.state.search_term_upcoming)
    });
  }

  async handlePageChangeCompleted(pageNumber) {
    this.setState({
      page_completed: pageNumber,
      courses_list_completed: await getTeacherCourseBookingListCompleted(pageNumber, this.state.per_page_completed, this.state.delay_completed, this.state.search_term_completed)
    });
  }

  changeStatus = (id, status) => {
    if (status === 1) {
      const getAlert = () => (<SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, Deactivate it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={(e) => this.changeFinalStatus(id, status)}
        onCancel={() => this.removePopup()}
        focusCancelBtn
      >
        This will deactivate your Course!! You can enable it anytime.
      </SweetAlert>);
      this.setState({
        alert: getAlert()
      });
    } else {
      const getAlert = () => (<SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, Activate it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={(e) => this.changeFinalStatus(id, status)}
        onCancel={() => this.removePopup()}
        focusCancelBtn
      >
        This will Activate your Course!!
      </SweetAlert>);
      this.setState({
        alert: getAlert()
      });
    }
  }
  deleteLesson = (id) => {
    const getAlertDelete = () => (<SweetAlert
      warning
      showCancel
      confirmBtnText="Yes, Delete it!"
      confirmBtnBsStyle="danger"
      title="Are you sure?"
      onConfirm={(e) => this.deleteLessonCompletely(id)}
      onCancel={() => this.removePopupDelete()}
      focusCancelBtn
    >
      This Will Delete the Course. This step is not reversable!!
    </SweetAlert>);
    this.setState({
      deleteAlert: getAlertDelete()
    });
  }
  deleteLessonCompletely = (id, status) => {
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'teacher/courselessondelete',
      {
        lesson_id: id,
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        deleteAlert: ""
      });
      this.tablePageLoad(this.state.currentpage);
      this.setState({ loading: false });
      window.location.reload();

    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While updating the Status!! Please Try Again", 'Validation Error');
    });
  }
  changeFinalStatus = (id, status) => {
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'teacher/courselessonstatusupdate',
      {
        lesson_id: id,
        status: status
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        alert: ""
      });
      this.setState({ loading: false });
      window.location.reload();
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While updating the Status!! Please Try Again", 'Validation Error');
    });
  }
  removePopup = () => {
    this.setState({
      alert: ""
    });
  }
  removePopupDelete = () => {
    this.setState({
      deleteAlert: ""
    });
  }



  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <TeacherSidebar step={this.state.step} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent">

                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li className={this.state.active_show}>
                    <Link onClick={(e) => this.setState({ active_show: "active", upcoming_show: "", completed_show: "" })}>List</Link>
                  </li>
                  <li className={this.state.upcoming_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", upcoming_show: "active", completed_show: "" })}>Upcoming</Link>
                  </li>
                  <li className={this.state.completed_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", upcoming_show: "", completed_show: "active" })} >Completed</Link>
                  </li>

                  <li class="pull-right">
                    {
                      (this.state.active_show)
                        ?
                        <Form.Control
                          className="dash-maincontent-search"
                          type="text"
                          placeholder="Search Courses"
                          onChange={this.changeSearch}
                        />
                        :
                        (this.state.upcoming_show)
                          ?
                          <Form.Control
                            className="dash-maincontent-search"
                            type="text"
                            placeholder="Search Upcoming Courses"
                            onChange={this.changeSearchUpcoming}
                          />
                          :
                          <Form.Control
                            className="dash-maincontent-search"
                            type="text"
                            placeholder="Search Completed Courses"
                            onChange={this.changeSearchCompleted}
                          />

                    }


                  </li>

                  <div class="dash-mycourses-module-bot">
                    <Link to={`/teacher/createcourse`} className="btn btn-default dash-mycourses-module-bot-bt-out">Add Course</Link>
                  </div>

                </ul>


                <div class="tab-content">

                  <div role="tabpanel" className={this.state.active_show + " tab-pane"} id="activecourses">
                    {
                      this.state.courses_list.data ? this.state.courses_list.data.map((com_course) => (
                        <>
                          <div class="dash-mycourses-module col-md-6 col-xs-12">
                            <ul class="list-inline dash-teacher-mycourses-module-toplinks">
                              <li><Link to={`/teacher/editcourse/${com_course.slug}`}>Edit</Link></li>
                              <li><Link onClick={() => this.changeStatus(com_course.id, com_course.status)}>
                                {com_course.status == 1 ? "Pause" : "Resume"}
                              </Link></li>
                              <li><Link onClick={() => this.deleteLesson(com_course.id)}>Delete</Link></li>

                            </ul>
                            <h6>{com_course.category_name.name}</h6>
                            <h3>{com_course.title}</h3>
                            <div class="dash-mycourses-module-date">
                              {
                                (this.state.user_timezone === null)
                                  ?
                                  <p>Starts:
                                {
                                      moment(com_course.start_date).format("MMM DD,YYYY h:mmA")
                                    } - Ends: {
                                      moment(com_course.end_date).format("MMM DD,YYYY h:mmA")
                                    }</p>
                                  :
                                  <p>Starts:
                                {
                                      moment(com_course.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                    } - Ends: {
                                      moment(com_course.end_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                    }</p>
                              }

                            </div>
                            <div class="dash-mycourses-module-bot">
                              <Link to={`/teacher/course/${com_course.slug}`} className="btn btn-default dash-mycourses-module-bot-bt-out">View course details</Link>
                            </div>
                          </div>
                        </>
                      ))
                        : ""
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.courses_list.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.courses_list.page}
                            itemsCountPerPage={this.state.courses_list.per_page}
                            totalItemsCount={this.state.courses_list.total}
                            pageRangeDisplayed={this.state.courses_list.total_pages}
                            onChange={this.handlePageChange.bind(this)}
                          />
                        </div>
                        :
                        <div class="dash-mycourses-module">
                          <p>No courses has been added yet.</p>
                        </div>
                    }

                  </div>

                  <div role="tabpanel" className={this.state.upcoming_show + " tab-pane"} id="upcomingcourses">
                    {
                      this.state.courses_list_upcoming.data ? this.state.courses_list_upcoming.data.map((com_course) => (
                        <>
                          <div class="dash-mycourses-module col-md-6 col-xs-12">

                            <h6>{com_course.class_data.category_name.name}</h6>
                            <h3>{com_course.class_data.title}</h3>

                            <div class="dash-mycourses-module-date">
                              {
                                (this.state.user_timezone === null)
                                  ?
                                  <p>Starts:
            {
                                      moment(com_course.class_data.start_date).format("MMM DD,YYYY h:mmA")
                                    } - Ends: {
                                      moment(com_course.class_data.end_date).format("MMM DD,YYYY h:mmA")
                                    }</p>
                                  :
                                  <p>Starts:
            {
                                      moment(com_course.class_data.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                    } - Ends: {
                                      moment(com_course.class_data.end_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                    }</p>
                              }

                            </div>

                            <div class="dash-mycourses-module-bot">
                              <Link to={`/teacher/course-booking/${com_course.class_data.slug}`} className="btn btn-default dash-mycourses-module-bot-bt-out">View course details</Link>
                            </div>
                          </div>
                        </>
                      ))
                        : ""
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.courses_list_upcoming.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.courses_list_upcoming.page}
                            itemsCountPerPage={this.state.courses_list_upcoming.per_page}
                            totalItemsCount={this.state.courses_list_upcoming.total}
                            pageRangeDisplayed={this.state.courses_list_upcoming.total_pages}
                            onChange={this.handlePageChangeUpcoming.bind(this)}
                          />
                        </div>
                        :
                        <div class="dash-mycourses-module">
                          <p>No upcoming courses booking has been found.</p>
                        </div>
                    }

                  </div>



                  <div role="tabpanel" className={this.state.completed_show + " tab-pane"} id="completedcourses">
                    {
                      this.state.courses_list_completed.data ? this.state.courses_list_completed.data.map((com_course) => (
                        <>
                          <div class="dash-mycourses-module col-md-6 col-xs-12">

                            <h6>{com_course.class_data.category_name.name}</h6>
                            <h3>{com_course.class_data.title}</h3>

                            <div class="dash-mycourses-module-date">
                              {
                                (this.state.user_timezone === null)
                                  ?
                                  <p>Starts:
            {
                                      moment(com_course.class_data.start_date).format("MMM DD,YYYY h:mmA")
                                    } - Ends: {
                                      moment(com_course.class_data.end_date).format("MMM DD,YYYY h:mmA")
                                    }</p>
                                  :
                                  <p>Starts:
            {
                                      moment(com_course.class_data.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                    } - Ends: {
                                      moment(com_course.class_data.end_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                    }</p>
                              }

                            </div>

                            <div class="dash-mycourses-module-bot">
                              <Link to={`/teacher/course-booking/${com_course.class_data.slug}`} className="btn btn-default dash-mycourses-module-bot-bt-out">View course details</Link>
                            </div>
                          </div>
                        </>
                      ))
                        : ""
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.courses_list_completed.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.courses_list_completed.page}
                            itemsCountPerPage={this.state.courses_list_completed.per_page}
                            totalItemsCount={this.state.courses_list_completed.total}
                            pageRangeDisplayed={this.state.courses_list_completed.total_pages}
                            onChange={this.handlePageChangeCompleted.bind(this)}
                          />
                        </div>
                        :
                        <div class="dash-mycourses-module">
                          <p>No completed courses booking has been found.</p>
                        </div>
                    }
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>

        {this.state.alert}
        {this.state.deleteAlert}
        {this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div>

    );
  }

}
export default MyCourses;
