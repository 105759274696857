import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';

import TeacherSidebar from '../Elements/TeacherSidebar';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from '../../Utils/Utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';


class MyTeachers extends React.Component {
  constructor() {
    super();
    this.state = {
      active_show: 'active',
      following_show: '',
      completed_show: '',

      loading: 0,
      alert: "",
      deleteAlert: "",

      weekendsVisible: true,
      currentEvents: [],

      step: 'mycalendar',
    };

  }

  async componentDidMount() {
    await this.getUserProfile();
    this.getTeacherAvailability();

    console.log(INITIAL_EVENTS);
  }

  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {

      this.setState({
        user_timezone: response.data.timezone_name.name
      });

    }).catch((error) => {
      if (!error.response) {
        NotificationManager.error("Some Problem Occured in Fetching Teacher Availability", 'Validation Error');
        return;
      }
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
        }

      } else {
        NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
      }
    });
  }


  getTeacherAvailability = () => {
    console.log(this.state.user_timezone);
    this.setState({
      loading: 1,
      alert: ''
    });
    const token = localStorage.token;
    const response = axios.post(
      window.$API_URL + 'teacher/getteacheravailability',
      {
        slug: "",
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((response) => {
      var response = response.data;
      var response_data = response.data.availability;
      var booked_data = response.data.booked;
      var schedule_session = response.data.schedule_session;

      var schedule_events = [];
      response_data.map(avl => {

        var start_date_temp = moment(avl.from_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
        var end_date_temp = moment(avl.end_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");

        var data_obj = { id: avl.id, title: "\nAvailable", start: start_date_temp, end: end_date_temp };
        schedule_events.push(data_obj);
      });

      booked_data.map(avl => {
        var start_date_temp = moment(avl.start_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
        var end_date_temp = moment(avl.end_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");

        var data_obj = { id: avl.id, title: "\nBooked", start: start_date_temp, end: end_date_temp, color: 'purple' };
        schedule_events.push(data_obj);
      });

      schedule_session.map(avl => {

        var start_date_temp = moment(avl.start_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
        var end_date_temp = moment(avl.end_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");

        var data_obj = { id: avl.id, title: "\nSession Scheduled", start: start_date_temp, end: end_date_temp, color: 'red' };
        schedule_events.push(data_obj);
      });

      this.setState({
        myEventsList: schedule_events,
        loading: 0
      });
    }).catch((error) => {
      this.setState({
        loading: 0
      });

      console.log(error);
      if (!error.response) {
        NotificationManager.error(error, 'Validation Error');
        return;
      }
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("Some Problem Occured in API", 'Validation Error');
        }

      } else {
        NotificationManager.error("Some Problem Occured in API", 'Validation Error');
      }
    });
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    })
  }

  handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection
    let start = selectInfo.startStr;
    let end = selectInfo.endStr;
    // console.log(selectInfo.allDay);
    // if (title) {
    //     calendarApi.addEvent({
    //         id: createEventId(),
    //         title,
    //         start: selectInfo.startStr,
    //         end: selectInfo.endStr,
    //         allDay: selectInfo.allDay
    //     })
    // }

    var todaydate = moment().format("YYYY-MM-DD HH:mm:ss");
    var start_tmp = moment(start).format("YYYY-MM-DD HH:mm:ss");
    var end_tmp = moment(end).format("YYYY-MM-DD HH:mm:ss");

    var fmt = "YYYY-MM-DD HH:mm:ss";
    var zone = this.state.user_timezone;
    console.log(zone);
    var m = moment.tz(start_tmp, zone).format();
    var m_end = moment.tz(end_tmp, zone).format();


    var start_final_tosend = moment.tz(m, 'utc').format();
    var end_final_tosend = moment.tz(m_end, 'utc').format();



    if (start > todaydate) {
      this.setState({
        loading: 1
      });
      const token = localStorage.token;
      const response = axios.post(
        window.$API_URL + 'teacher/getteacheravailabilityslot',
        {
          start_time: start_final_tosend,
          end_time: end_final_tosend
        },
        { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
      ).then((response) => {
        this.setState({
          loading: 0
        });
        if (!response.data.available && !response.data.session_scheduled && !response.data.class_booked) {
          const getAlert = () => (<SweetAlert
            info
            showCancel={true}
            confirmBtnText="Continue"
            confirmBtnBsStyle="success"
            title="Set Availability"
            onConfirm={(e) => this.confirmAvailibilitySlot(start_final_tosend, end_final_tosend)}
            onCancel={() => this.setState({ alert: "" })}
          //focusCancelBtn

          >
            Do you want to mark the selected dates as available?

          </SweetAlert>);
          this.setState({
            alert: getAlert()
          });
        } else {
          const getAlert = () => (<SweetAlert
            warning
            showCancel={false}
            confirmBtnText="Ok!"
            confirmBtnBsStyle="danger"
            title="An Error Occurred"
            onConfirm={(e) => this.setState({ alert: "" })}
          // onCancel={() => this.removePopup()}
          //focusCancelBtn
          >
            The availibility already set on selected timeperiod.
          </SweetAlert>);
          this.setState({
            alert: getAlert()
          });
        }
      }).catch((error) => {
        this.setState({
          loading: 0
        });
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data.response).forEach((key) => {
              this.state.returnError.push(error.response.data.response[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error("Some Problem Occured in API", 'Validation Error');
          }

        }
      });

    } else {
      const getAlert = () => (<SweetAlert
        warning
        showCancel={false}
        confirmBtnText="Ok!"
        confirmBtnBsStyle="danger"
        title="An Error Occurred"
        onConfirm={(e) => this.setState({ alert: "" })}
      // onCancel={() => this.removePopup()}
      //focusCancelBtn
      >
        The availibility can only be set for future dates.
      </SweetAlert>);
      this.setState({
        alert: getAlert()
      });

    }
  }

  confirmAvailibilitySlot = (start, end) => {
    this.setState({
      loading: 1
    });
    const token = localStorage.token;
    const response = axios.post(
      window.$API_URL + 'teacher/setteacheravailability',
      {
        start_time: start,
        end_time: end
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((response) => {
      this.setState({
        loading: 0,
        alert: ''
      });
      if (response.data.status == "success") {
        const getAlert = () => (<SweetAlert
          success
          showCancel={false}
          confirmBtnText="Ok"
          confirmBtnBsStyle="success"
          title="Availibility Set"
          onConfirm={(e) => this.getTeacherAvailability()}
        >
          Your availability has been set on requested timeframe.
        </SweetAlert>);
        this.setState({
          alert: getAlert()
        });
      }
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      console.log(error);
      if (!error.response) {
        NotificationManager.error("Some Problem Occured in API", 'Validation Error');
        return;
      }
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data).forEach((key) => {
            this.state.returnError.push(error.response.data[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("Some Problem Occured in API", 'Validation Error');
        }

      } else {
        NotificationManager.error("Some Problem Occured in API", 'Validation Error');
      }
    });

  }

  handleEventClick = (clickInfo) => {

    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //     clickInfo.event.remove()
    // }
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    })
  }


  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <TeacherSidebar step={this.state.step} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent dash-maincontent-calendar">
                {/* <img src="/img/calendar.jpg" class="img-responsive" alt=""/>  */}

                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  }}
                  timeZone={this.state.user_timezone}
                  initialView='timeGridWeek'
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={false}
                  eventStartEditable={false}
                  eventResizableFromStart={false}
                  eventDurationEditable={false}
                  weekends={this.state.weekendsVisible}
                  events={this.state.myEventsList}
                  initialEvents={this.state.myEventsList} // alternatively, use the `events` setting to fetch from a feed
                  select={this.handleDateSelect}
                  eventContent={renderEventContent} // custom render function
                  eventClick={this.handleEventClick}
                  eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                /* you can update a remote database when these fire:
                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
                */
                />


              </div>
            </div>

          </div>
        </div>


        {this.state.alert}
        {this.state.deleteAlert}
        {this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div>

    );
  }

}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
      <i>{event.title}</i>
    </li>
  )
}

export default MyTeachers;
