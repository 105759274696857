import React from 'react';
import axios from 'axios';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { fetchBalanceTeacher, fetchPaypalAuth, fetchPaypalUrl } from '../../Utils/Utils';

import TeacherProfileSidebar from '../Elements/TeacherProfileSidebar';

class TeacherWithdrawalRequest extends React.Component {
  constructor() {
    super();
    this.state = {
      amount: '',
      number: '',
      name: '',
      routing_number: '',
      ssn: '',

      loading: 0,

      paypal_amount: '',
      paypal_email: '',

      payment_type: 'card',
      card_show: "active",
      paypal_show: "",
      paypal_auth: null,

      paypal_url: '',

      stripe_account_id: '',
      stripe_account_status: '',

      stripeloginlink: '',
      country: ''


    };

  }

  async componentDidMount() {
    this.getTeacherInformation();
    this.setState({
      balance: await fetchBalanceTeacher(),
      paypal_auth: await fetchPaypalAuth(),
      paypal_url: await fetchPaypalUrl(),
    });

    if (this.state.paypal_auth !== null) {
      this.setState({
        paypal_email: this.state.paypal_auth.email
      });
    }

    console.log(this.state.paypal_auth);
  }

  getTeacherInformation = async () => {
    const token = localStorage.token;

    this.setState({
      loading: 1
    });
    const response = axios.get(
      window.$API_URL + 'teacher/teacherprofileget',
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then(async (response) => {
      this.setState({
        loading: 0
      });

      var teacherdata = response.data.response;


      this.setState({
        stripe_account_id: teacherdata.stripe_account_id,
        stripe_account_status: teacherdata.stripe_account_status
      });

      if (teacherdata.stripe_account_id != null) {
        if (teacherdata.stripe_account_status != "Enabled") {
          this.recheckStripeAccountStatus();
        }
      }


    }).catch((error) => {
      console.log(error);
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
        }

      }
      this.setState({
        loading: 0
      });
    })
  }

  recheckStripeAccountStatus = async () => {
    const token = localStorage.token;

    this.setState({
      loading: 1
    });
    const response = axios.post(
      window.$API_URL + 'payment/recheckstripeaccountstatus', {
      stripe_account_id: this.state.stripe_account_id,
    },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then(async (response) => {
      this.setState({
        loading: 0
      });

      var dataValue = response.data.message;


      this.setState({
        stripe_account_status: dataValue
      });

      if (dataValue == "Pending") {
        this.setState({
          stripeloginlink: response.data.data.url
        });
      }



    }).catch((error) => {
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
        }

      }
      this.setState({
        loading: 0
      });
    })
  }

  makePayment = () => {
    const access_token = localStorage.getItem('token');
    this.setState({
      loading: 1
    });
    axios
      .post(window.$API_URL + 'payment/withdrawlamount', {
        amount: this.state.amount,
        account_number: this.state.number,
        account_name: this.state.name,
        routing_number: this.state.routing_number,
        payment_type: this.state.payment_type,
        paypal_amount: this.state.paypal_amount,
        paypal_email: this.state.paypal_email,

        stripe_account_id: this.state.stripe_account_id,
        stripe_account_status: this.state.stripe_account_status,
        ssn: this.state.ssn,
        country: this.state.country,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {
        this.setState({
          loading: 0
        });
        console.log(success);
        if (success.data.status == "failed") {
          NotificationManager.error(success.data.message, 'Error');
        } else {
          if (this.state.payment_type == "card" && this.state.stripe_account_id == null) {
            window.location.replace(success.data.data.url);

            // this.props.dispatch({
            //   type: 'SWEET_ALERT_SUCCESS_JSON', payload: {
            //     title: 'Bank Account Submitted',
            //     content: "Your bank account has been added. You'll be able to withdrawal amount once it gets verified. ",
            //     showCancel: false,
            //     showConfirm: true,
            //     confirmBtnText: 'Okay',
            //     confirmBtnBsStyle: 'success',
            //     success_callback: () => window.location.href = '/teacher/financialcenter',
            //     cancel_callback: () => ''
            //   }
            // });
          } else {
            this.props.dispatch({
              type: 'SWEET_ALERT_SUCCESS_JSON', payload: {
                title: 'Withdrawal Request Successful',
                content: 'Thanks for the request Amount will be transfer to your account soon. ',
                showCancel: false,
                showConfirm: true,
                confirmBtnText: 'Okay',
                confirmBtnBsStyle: 'success',
                success_callback: () => window.location.href = '/teacher/financialcenter',
                cancel_callback: () => ''
              }
            });
          }

        }

      }).catch((error) => {
        this.setState({
          loading: 0
        });
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data.response).forEach((key) => {
              this.state.returnError.push(error.response.data.response[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error(error.response.data.message, 'Validation Error');
          }
        }

      });

  }

  unlinkPaypalAuth = () => {
    const access_token = localStorage.getItem('token');
    this.setState({
      loading: 1
    });
    axios
      .post(window.$API_URL + 'payment/unlinkpaypalauth', {

      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {
        this.setState({
          loading: 0
        });
        console.log(success);
        if (success.data.status == "failed") {
          NotificationManager.error(success.data.message, 'Error');
        } else {
          NotificationManager.success(success.data.message, 'Success');
          setTimeout(() => window.location.reload(), 3000);
        }

      }).catch((error) => {
        this.setState({
          loading: 0
        });
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data.response).forEach((key) => {
              this.state.returnError.push(error.response.data.response[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error("Some Error occured while making the payment. Please contact helpdesk if amount has been deducted!", 'Validation Error');
          }
        }

      });

  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace-ttl">
            <h3>Withdrawal Request</h3>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-4">
              <TeacherProfileSidebar step={'financialcenter'} />
            </div>

            <div className="col-md-9 col-sm-8">

              <div className="signup-maincontent">

                <div className="col-md-12 col-sm-12" style={{ marginBottom: '20px' }}>
                  <ul class="nav nav-tabs dash-tabs" role="tablist">

                    <li className={this.state.card_show}>
                      <Link onClick={(e) => this.setState({ card_show: "active", paypal_show: "", payment_type: "card" })}>Bank Account</Link>
                    </li>
                    <li className={this.state.paypal_show}>
                      <Link onClick={(e) => this.setState({ card_show: "", paypal_show: "active", payment_type: "paypal" })}>Paypal</Link>
                    </li>



                  </ul>
                </div>

                <div class="tab-content">

                  <div role="tabpanel" className={this.state.card_show + " tab-pane"} id="cardd">

                    {
                      (this.state.stripe_account_id != null && this.state.stripe_account_status == "Enabled")
                        ?
                        <>
                          <div className="col-md-6 col-sm-12">
                            <Form.Group>
                              <div className="input-group">
                                <span class="input-group-addon"><i class="glyphicon glyphicon-usd"></i></span>
                                <Form.Control
                                  type="text"
                                  placeholder="Withdrawal Amount"
                                  onChange={(e) => this.setState({ amount: e.target.value })}
                                />
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-md-6 col-sm-12">

                            <Form.Group>
                              <Form.Control type="text" placeholder="Bank Account Ref" readOnly={true} value={this.state.stripe_account_id} />
                            </Form.Group>

                          </div>

                          <div className="col-md-12 ">
                            <Form.Group>
                              <small class="text-muted ">
                                <i>You're ready to get payment in your verified bank account. Above is your bank account refrence. </i>

                              </small>
                            </Form.Group>
                          </div>
                        </>
                        :
                        <>
                          {
                            (this.state.stripe_account_status == null)
                              ?
                              <>
                                <div className="col-md-6 col-sm-12">
                                  <Form.Group>
                                    <Form.Control as="select" value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}   >
                                      <option>Select Country</option>
                                      <option value="AU">Australia</option>
                                      <option value="AT">Austria</option>
                                      <option value="BE">Belgium</option>
                                      <option value="BG">Bulgaria</option>
                                      <option value="CA">Canada</option>
                                      <option value="CY">Cyprus</option>
                                      <option value="CZ">Czech Republic</option>
                                      <option value="DK">Denmark</option>
                                      <option value="EE">Estonia</option>
                                      <option value="FI">Finland</option>
                                      <option value="FR">France</option>
                                      {/* <option value="CA">Germany</option> */}
                                      <option value="GR">Greece</option>
                                      <option value="HK">Hong Kong</option>
                                      <option value="HU">Hungary</option>
                                      <option value="IE">Ireland</option>
                                      <option value="IT">Italy</option>
                                      <option value="JP">Japan</option>
                                      <option value="LV">Latvia</option>
                                      <option value="LT">Lithuania</option>
                                      <option value="LU">Luxembourg</option>
                                      <option value="MT">Malta</option>
                                      {/* <option value="CA">Mexico</option> */}
                                      <option value="NL">Netherlands</option>
                                      <option value="NZ">New Zealand</option>
                                      <option value="NO">Norway</option>
                                      <option value="PL">Poland</option>
                                      <option value="PT">Portugal</option>
                                      <option value="RO">Romania</option>
                                      <option value="SG">Singapore</option>
                                      <option value="SK">Slovakia</option>
                                      <option value="SI">Slovenia</option>
                                      <option value="ES">Spain</option>
                                      <option value="SE">Sweden</option>
                                      <option value="CH">Switzerland</option>
                                      <option value="GB">United Kingdom</option>
                                      <option value="US">United States</option>
                                    </Form.Control>
                                  </Form.Group>

                                </div>



                                <div className="col-md-12 ">
                                  <Form.Group>
                                    <small class="text-muted ">
                                      <i>We will redirect you to our secure Stripe Platform to enter your personal and banking details. Once they are verfied you will be able to withdrawal money in your bank account.</i>

                                    </small>
                                  </Form.Group>
                                </div>
                              </>
                              :
                              <>
                                <div className="col-md-12 ">
                                  <Form.Group>
                                    <small class="text-muted ">
                                      <i>Your bank verification are still in progress. Once they are verified you can withdrawal money into that.</i>

                                    </small>
                                  </Form.Group>
                                </div>
                              </>
                          }
                        </>
                    }

                  </div>


                  <div role="tabpanel" className={this.state.paypal_show + " tab-pane"} id="paypall">

                    {
                      (this.state.paypal_auth !== null)
                        ?
                        <>
                          <div className="col-md-6 col-sm-12">


                            <Form.Group>
                              <div className="input-group">
                                <span class="input-group-addon"><i class="glyphicon glyphicon-usd"></i></span>
                                <Form.Control
                                  type="text"
                                  placeholder="Withdrawal Amount"
                                  onChange={(e) => this.setState({ paypal_amount: e.target.value })}
                                />
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-md-6 col-sm-12">

                            <Form.Group>
                              <Form.Control type="text" placeholder="Email Address" onChange={(e) => this.setState({ paypal_email: e.target.value })} readOnly={true} value={this.state.paypal_email} />
                            </Form.Group>

                          </div>
                        </>
                        :
                        <>
                          <div className="col-md-12 col-sm-12">
                            <a
                              role="button"
                              onClick={(e) => window.location.href = this.state.paypal_url}
                            >
                              <img src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/login/connectwithpaypalbutton.png" />

                            </a>
                          </div>
                        </>
                    }


                  </div>
                </div>






                <div>

                  <div className="signup-maincontent-footer">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-6">

                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12 text-right">
                        {
                          (this.state.card_show === 'active' && this.state.stripe_account_status != 'Pending')
                            ?
                            <button
                              className="btn btn-default bt-custom"
                              role="button"
                              onClick={(e) => this.makePayment()}
                            > Submit
                            </button>
                            :
                            (this.state.paypal_auth !== null && this.state.paypal_show === 'active')
                              ?
                              <div>
                                <button
                                  className="btn btn-default bt-custom"
                                  role="button"
                                  onClick={(e) => this.makePayment()}
                                > Withdraw
                              </button>
                              &nbsp;
                              <button
                                  className="btn btn-default dash-mycourses-module-bot-bt-out"
                                  role="button"
                                  onClick={(e) => this.unlinkPaypalAuth()}
                                > Unlink existing PayPal
                              </button>
                              </div>
                              :
                              ''
                        }

                        {
                          (this.state.card_show === 'active' && this.state.stripe_account_status == 'Pending')
                            ?
                            <a
                              href={this.state.stripeloginlink}
                              className="btn btn-default bt-custom"
                              role="button"
                              target="_blank"
                            > Login to Stripe
                            </a>
                            :
                            ""
                        }


                      </div>
                    </div>
                  </div>

                </div>



              </div>
            </div>

          </div>
        </div>


        { this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div >

    );
  }

}
const mapStateToProps = (state) => {
  return {
    sweet_alert_success_json: state.sweet_alert_success_json,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherWithdrawalRequest);
