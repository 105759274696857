import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';


class LessonDetail extends React.Component {
  constructor() {
    super();
    this.state = {

    };

  }

  render() {
    return (
      <div>

        <Header />


        <div class="coursespage-section">
          <div class="container">

            <div class="breadcrumb-custom">
              <Link>Home</Link> » <Link>Lessons</Link> » Lesson detail
                        </div>

            <div class="row">
              <div class="col-md-8 col-sm-8">
                <div class="coursedetail-main">

                  <div class="coursedetail-main-intro">
                    <h6>CATEGORY</h6>
                    <h2>Illustration techniques to unlock your creativity</h2>

                    <div class="coursedetail-col-profile visible-xs">
                      <img src="img/uploadprofilepic.svg" class="img-circle" style="vertical-align: middle;" alt="" />
                      <span>By <Link>Tom Martin</Link></span>
                    </div>

                    <div class="coursedetail-main-pic">
                      <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                    </div>

                  </div>


                  <div class="coursedetail-col visible-xs">
                    <h3>US$ 14,99</h3>
                    <Link className="btn btn-default btn-block bt-custom" href="#" role="button">Book now</Link>

                    <ul class="coursedetail-col-list">
                      <li><i class="far fa-star"></i><span>5</span></li>
                      <li><i class="far fa-copy"></i><span>1 lessons</span></li>
                      <li><i class="far fa-clock"></i><span>60 minute lesson</span></li>
                      <li><i class="far fa-chart-bar"></i><span>Expert level</span></li>
                      <li><i class="fas fa-globe"></i><span>English</span></li>
                    </ul>
                  </div>

                  <div class="coursedetail-main-module">
                    <h4>Description</h4>
                    <p>Taking chances, making mistakes, playing, going beyond your limits and being free are all starting points for developing your imagination, language and voice.</p>
                    <p>In this course, illustrator Adolfo Serra will teach you, step by step, the processes to experiment and unlock your creativity with manual techniques that will help you create a freehand illustrated notebook.</p>
                  </div>



                  <div class="coursedetail-main-module">
                    <h4>Lesson content</h4>
                    <ul>
                      <li>Introduction to techniques</li>
                      <li>Color palettes</li>
                      <li>Materials, pencils and markers</li>
                      <li>Tricks to release your illustrator hand</li>
                    </ul>
                  </div>


                  <div class="coursedetail-main-module">
                    <h4>Requirements</h4>
                    <ul>
                      <li>Schedule at least 12 hours in advance.</li>
                      <li>Basic knowledge of Adobe Illustrator or Photoshop.</li>
                      <li>Materials: notebook, pencils, markers.</li>
                      <li>Intermediate knowledge of color techniques.</li>
                    </ul>
                  </div>


                </div>
              </div>

              <div class="col-md-3 col-sm-4 coursedetail-col col-md-offset-1 col-sm-offset-0 hidden-xs">

                <h3>US$ 49,99</h3>
                <Link className="btn btn-default btn-block bt-custom" href="#" role="button">Book now</Link>

                <div class="coursedetail-col-profile">
                  <img src="img/uploadprofilepic.svg" class="img-circle" style="vertical-align: middle;" alt="" />
                  <span>By <Link>Tom Martin</Link></span>
                </div>

                <ul class="coursedetail-col-list">
                  <li><i class="far fa-star"></i><span>5</span></li>
                  <li><i class="far fa-copy"></i><span>1 lesson</span></li>
                  <li><i class="far fa-clock"></i><span>30 minute lesson</span></li>
                  <li><i class="far fa-chart-bar"></i><span>Expert level</span></li>
                  <li><i class="fas fa-globe"></i><span>English</span></li>
                </ul>

              </div>

            </div>

          </div>
        </div>

        <Footer />
      </div>

    );
  }

}
export default LessonDetail;
