import React from 'react';
import { Link, } from 'react-router-dom';
import CourseCard from '../../common_components/CourseCard';

class LanguageCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  render() {

    return (
      <>
        <div class="coursespage-section">
          <div class="container">

            <div class="row coursespage-module-ttl">
              <div class="col-md-6 col-sm-6 col-xs-8">
                <h3>Group courses</h3>
              </div>
              
            </div>

            <div class="row gutter-20">

              {
                this.props.courses.map((course) => (
                  <CourseCard course_detail={course} />
                ))
              }

            </div>

            <div class="col-md-12  coursespage-module-ttl-right">
                <Link to="/category/5/language-culture">View More <i class="zmdi zmdi-long-arrow-right"></i></Link>
              </div>

          </div>
        </div>
      </>
    );
  }

}
export default LanguageCourses;
