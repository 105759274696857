import React from 'react';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';

class IntroductionVideoGuidelines extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="terms">
            <div class="container">

              <h3>Introduction Video Guidelines</h3>

              <h5>Creating the Self-Introduction Video</h5>
              <p>A self-introduction video is required and is easy to do. An excellent self-recorded introduction video will help students form a first impression and establish your presence in the course. It creates personal connections and builds rapport with your prospective students. </p>

              <h5>Video Content</h5>
              <p>Your video content should be 1-3 minutes long </p>

              <p>Share a bit of yourself with prospective students. This is an opportunity for you to provide some background information about who you are, such as your professional and personal background, your hobbies, interests, etc. Be concise and to the point, at minimum you should include: </p>
              <ul>
                  <li>✓ Your name, where you are from and your educational background</li>
                  <li>✓ Why do you love teaching a particular subject/lesson?</li>
                  <li>✓ Your experiences related to the course or subject, past teaching jobs, experiences with student age, subject, etc.</li>
                  <li>✓ If you are fluent in foreign languages, please make sure to say something in those languages. </li>
              </ul>

              <p>We recommend that you plan it out before recording. Writing a script in advance will help prepare you to talk easily and not miss important details. Practice your script a few times so you can speak naturally without looking at the script paper. </p>
              

              <h5>Video Recording preparations:</h5>
              <p><b>Background</b> – Choose your background, ideally a light-colored wall. Ensure the background decorations (pictures, posters, stickers etc.) are neat, tidy, and not distracting. </p>
              <p><b>Lighting</b>–a well-lit environment with good natural lighting is best for your video and teaching setting. Otherwise, ensure that the light source (lamp) is not directly behind you and not casting any shadows on your face. Also, make sure your light source is not pointing directly into the camera. </p>   
              <p><b>Sound</b>– Make sure to eliminate any background, wind, or distracting noises, record in quiet surroundings and speak in clear voice. Try not to be too close to or too far from the mic.   </p>   
              <p><b>Dress</b>– Dress appropriately as if you were teaching the lesson in-person.  Students form their first impression from your video so make sure you are presentable and professional. Smart casual clothing is acceptable. </p>   
            
              <h5>Tips for Video Recording </h5>
              <p>When recording </p>

              <ul>
                  <li>✓ Make sure you record your video horizontally not vertically </li>
                  <li>✓ Place camera at eye level on a stable surface</li>
                  <li>✓ Look into the camera and smile </li>
                  <li>✓ Relax, be yourself, as natural as possible, and do not read directly from a script </li>
                  <li>✓ Be concise, speak clearly, at an even pace, and do not speak too fast  </li>
                  <li>✓ Show your personality and your excitement. </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default IntroductionVideoGuidelines;