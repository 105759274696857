import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../../Elements/HeaderInner';
import Footer from '../../Elements/Footer';
import TeacherSidebar from '../../Elements/TeacherSidebar';

import { getCategory, getLanguage, getCourseDetail } from '../../../Utils/Utils';
import DatePicker from "react-datepicker";
import Datetime from "react-datetime";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import * as moment from 'moment';

class EditCourse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: this.props.match.params.slug,
            stepone: 'active',
            steptwo: '',
            stepthree: '',

            steponeblock: '',
            steptwoblock: '',
            stepthreeblock: '',

            category_id: '',
            subcategory_id: '',
            subject_id: '',
            course_title: '',
            description: '',

            lesson_count: '',
            lesson_duration: '',

            course_price: '',
            course_level_id: '',
            course_instruction_id: '',
            course_suitability_id: '',

            language_id: '',

            categoriesData: [],
            subcategoriesData: [],
            subjectsData: [],
            topicsData: [],

            dataTopic: [0],
            dataObjective: [0],
            dataRequirement: [0],

            topicArrayData: [],
            objectiveArrayData: [],
            requirementArrayData: [],
            reqirementArrayData: [],
            tempObjective: "",
            topicVisibility: [
            ],
            objectiveVisibility: [
            ],
            requirementVisibility: [
            ],
            dataCourseMaterial: [0],
            courseMaterialFinalArr: [],
            dataCourseMaterial: [0],
            courseMaterialVisibilityType: [],
            courseMaterialVisibilityTitle: [],
            courseMaterialVisibilitySession: [],
            courseMaterialVisibilityName: [],

            tempCourseMaterialType: '',
            tempCourseMaterialTitle: '',
            tempCourseMaterialSession: '',
            tempCourseMaterialName: '',

            courseMaterialFinalArr: [],
            subCategoryLabel: "Choose a Subcategory for this course:",
            subjectLabel: "Choose a Subject for this course:",
            currentCategoryText: '',
            sessionDuration: '',
            courseDuration: '',
            numberOfSession: '',

            dataSessions: [],

            sessionDate: [],
            timeData: [],
            sessionDateToSend: [],
            addsessionbutton: 'none',
            sessionAddText: "Add Session",

            step: 'mylessons',

            sessionFinalArray: [],
            sessionOptionData: [1, 2],

            completecategorydata: [],
            validDates: '',

            course_level: [{ value: 1, name: "Beginner" }, { value: 2, name: "Intermedatry" }, { value: 3, name: "Advance" }],
            course_suitability: [{ value: 1, name: "Everyone" }, { value: 2, name: "Child (Age under 5  )" }, { value: 3, name: "Child ( Age 5-12 )" }, { value: 4, name: "Teen ( Age 13-18 )" }, { value: 5, name: "Adult (Age 18-64)" }, { value: 6, name: "Senior (Age 64+)" }, { value: 7, name: "Elementary School (Grades 1-6)" }, { value: 8, name: "Middle School (Grades 7-8)" }, { value: 9, name: "High School (Grades 9-12)" }, { value: 10, name: "University - Undergraduate" }, { value: 11, name: "University - Post Graduate" }],
            course_instruction: [{ value: 1, name: "Professional Course" }, { value: 2, name: "Tutoring Course" }, { value: 3, name: "Subject Matter Exper Course" }],
        };

    }

    async componentDidMount() {
        await this.getUserProfile();
        this.fetchCourseDetails();

        const category_data = await getCategory();
        let categoriesData = [];
        for (let i = 0; i < category_data.length; ++i) {
            console.log('asdasd');
            var d = category_data[i];
            categoriesData.push({ 'value': d.id, 'name': d.name });
        }

        var yesterday = Datetime.moment().subtract(1, 'day');
        var valid = function (current) {
            return current.isAfter(yesterday);
        };

        this.setState({
            language_data: await getLanguage(),
            categoriesData: categoriesData,
            completecategorydata: category_data,
            validDates: valid
        })


    }

    getUserProfile = async () => {
        const token = localStorage.token;
        const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
            headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
        }
        ).then((response) => {

            this.setState({
                user_timezone: response.data.timezone_name.name
            });

            //  console.log(this.state.user_timezone);
        }).catch((error) => {
            if (!error.response) {
                NotificationManager.error("Some Problem Occured in Fetching Teacher Availability", 'Validation Error');
                return;
            }
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data).forEach((key) => {
                        this.state.returnError.push(error.response.data[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
                }

            } else {
                NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
            }
        });
    }

    fetchCourseDetails = async () => {
        const token = localStorage.token;
        const response = axios.post(
            window.$API_URL + 'course/getcoursedetail',
            { slug: this.state.slug },
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then((response) => {
            this.setState({
                loading: 0.
            });

            var response = response.data;
           

            var class_offerings_options = response.data.class_offerings_options;
            var class_materials = response.data.class_materials;
            var class_schedules = response.data.class_schedules;
            class_offerings_options.map(temp_value => {
                console.log(temp_value.type);
                if (temp_value.type == "objective") {
                    this.state.objectiveVisibility.push(temp_value.name);
                } else if (temp_value.type == "requirement") {
                    this.state.requirementVisibility.push(temp_value.name);
                } else if (temp_value.type == "topic") {
                    this.state.topicVisibility.push(temp_value.name);
                }
            });


            class_materials.map(temp_value => {
                this.state.courseMaterialVisibilityType.push(temp_value.file_type);
                this.state.courseMaterialVisibilityTitle.push(temp_value.file_title);
                this.state.courseMaterialFinalArr.push({
                    'type': temp_value.file_type,
                    'title': temp_value.file_title,
                    'session': temp_value.session,
                    'uploaded_file': temp_value.uploaded_file
                });
            });

            const responseSC = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${response.data.category_name.id}`, {}, {}).then((responseSC) => {
                this.setState({ subcategoriesData: responseSC.data.data });
            }).catch((error) => { });

            const responseSS = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${response.data.subcategory_name.id}`, {}, {}).then((responseSS) => {
                this.setState({ subjectsData: responseSS.data.data });
            }).catch((error) => { });

           
            

            this.setState({
                objectiveVisibility: this.state.objectiveVisibility,
                requirementVisibility: this.state.requirementVisibility,
                topicVisibility: this.state.topicVisibility,
                courseMaterialFinalArr: this.state.courseMaterialFinalArr,
                courseMaterialVisibilityTitle: this.state.courseMaterialVisibilityTitle,
                courseMaterialVisibilityType: this.state.courseMaterialVisibilityType,
                course_price: response.data.price,
                course_title: response.data.title,
                description: response.data.description,
                category_id: response.data.category_id,
                subcategory_id: response.data.subcategory_id,
                subject_id: response.data.subject_id,
                topic_id: response.data.topic,
                course_level_id: parseInt(response.data.level),
                course_suitability_id: parseInt(response.data.suitability),
                course_instruction_id: response.data.instruction_id,
                language_id: response.data.language_id,
                
                
                lesson_count: this.state.lesson_count,
                lesson_duration: this.state.lesson_duration,

                addsessionbutton: 'block'
            });

            class_schedules.map(temp_value => {

                var startDateTime = moment(temp_value.start_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
                var endDateTime = moment(temp_value.end_date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
                var classschedulename = temp_value.name;
                var sessionDuration = temp_value.duration;



                var dateVar = moment(startDateTime).format("YYYY-MM-DD");
                var timeVar = moment(startDateTime).format("hh:mm A");

                this.state.sessionslugs.push(temp_value.slug);
                this.state.sessionFinalArray.push({ 'name': classschedulename, 'date': dateVar, 'time': timeVar, 'duration': sessionDuration, 'startDateTime': startDateTime, 'endDateTime': endDateTime, 'slug': temp_value.slug });

                var thissessiondate = new Date(moment(dateVar).format());
                var thistimeVar = moment(startDateTime).format("HH:mm:ss");


                this.state.sessionDate.push(thissessiondate);
                this.state.timeData.push(thistimeVar);

                console.log(this.state.sessionFinalArray);

            });





            let courseDuration = this.state.courseDuration;
            let sessionDuration = this.state.sessionDuration;
            let finalSessions = courseDuration / sessionDuration;
            let sessionOptionData = [];

            let { dataSessions } = this.state;
            for (let i = 0; i < finalSessions; ++i) {
                var dtvl = i + 1;
                sessionOptionData.push(dtvl);
                dataSessions.push(dataSessions.length);
                this.setState({ dataSessions });
                this.setState({ sessionOptionData });
            }



        }).catch((error) => {

        });
    }

    showStepOne = (e) => {
        e.preventDefault();
        this.setState({
            stepone: 'active',
            steptwo: '',
            stepthree: '',

            steponeblock: '',
            steptwoblock: 'hidden',
            stepthreeblock: 'hidden'
        });
    }

    showStepTwo = (e) => {
        e.preventDefault();
        this.setState({
            stepone: '',
            steptwo: 'active',
            stepthree: '',

            steponeblock: 'hidden',
            steptwoblock: '',
            stepthreeblock: 'hidden'
        });


    }

    showStepThree = (e) => {
        e.preventDefault();
        this.setState({
            stepone: '',
            steptwo: '',
            stepthree: 'active',

            steponeblock: 'hidden',
            steptwoblock: 'hidden',
            stepthreeblock: ''

        });


        this.updateSessionInTable();
    }

    courseMaterialFileHandler = async (event, i) => {
        const token = localStorage.token;
        const data = new FormData();
        //   data.append('documentId', i);
        data.append('file', event);
        this.setState({
            loading: 1
        });
        const response = axios.post(
            window.$API_URL + 'api/course/coursematerialuploads',
            data,
            { headers: { 'Content-Type': '', 'x-access-token': `${token}` } }
        ).then((response) => {
            this.setState({
                loading: 0.
            });
            if (response.data.filename) {
                this.state.tempCourseMaterialName = response.data.filename;
            } else {
                NotificationManager.error("Some Error Occured while uploading the Document", 'Upload Error');
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: 0
            });
            NotificationManager.error("File Cannot be uploaded!! Please try again.", 'Upload Error');
        });
    }

    updateSubcategories = (event) => {
        this.setState({ subcategoriesData: [], subjectsData: [], category_id: event, subcategory_id: null, subject_id: null  });

        const response = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${event}`, {}, {}).then((response) => {
            this.setState({ subcategoriesData: response.data.data });
        }).catch((error) => { });
    }
    updateSubject = (e) => {
        console.log(e);
        this.setState({ subjectsData: [], subcategory_id: e, subject_id: null });
        const response = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${e}`, {}, {}).then((response) => {
            this.setState({ subjectsData: response.data.data });
        }).catch((error) => { });
    }

    updateTopic = (event) => {
        this.setState({ subject_id: event });
    }

    updateLessonCount = (event) => {
        let dataSessions = this.state.dataSessions;
        for (let i = 0; i < event; ++i) {
            var dtvl = i + 1;
            dataSessions.push(dataSessions.length);
            this.setState({ dataSessions });
        }
        this.setState({ lesson_count: event });
    }

    updateLessonDuration = (event) => {
        this.setState({ lesson_duration: event });
    }

    languageSelect = (e) => {
        this.setState({
            language_id: e
        })
    }



    updateCourseTitle = (event) => {
        this.setState({
            course_title: event,
        });
    }

    updateCoursePrice = (event) => {
        this.setState({
            course_price: event,
        });
    }

    updateCourseLevel = (event) => {
        this.setState({
            course_level_id: event,
        });
    }

    updateCourseInstructionStyle = (event) => {
        this.setState({
            course_instruction_id: event,
        });
    }
    updateCourseSuitability = (event) => {
        this.setState({
            course_suitability_id: event,
        });
    }

    updateCourseDescription = (event) => {
        this.setState({
            description: event,
        });
    }

    appendTopic = (e) => {
        e.preventDefault();
        let { dataTopic } = this.state;
        dataTopic.push(dataTopic.length);
        this.setState({ dataTopic });
    }

    appendObjective = (e) => {
        e.preventDefault();
        let { dataObjective } = this.state;
        dataObjective.push(dataObjective.length);
        this.setState({ dataObjective });
    }

    appendRequirement = (e) => {
        e.preventDefault();
        let { dataRequirement } = this.state;
        dataRequirement.push(dataRequirement.length);
        this.setState({ dataRequirement });
    }

    updateTopicInput = (value, id) => {
        this.setState({
            tempTopic: value
        });
        if (value != "") {
            if (this.state.topicArrayData.indexOf(value) === -1) {
                this.state.topicArrayData[id] = value;
            } else {
                NotificationManager.error("Invalid Value", 'Validation Error');
                this.state.topicArrayData.splice(id, 1);
            }
        }
        else {
            this.state.topicArrayData.splice(id, 1);
        }
    }

    saveCourseMaterial = (value, id, e) => {

        e.preventDefault();
        if (this.state.tempCourseMaterialType == "") {
            NotificationManager.error("Please select type.", 'Validation Error');
        } else if (this.state.tempCourseMaterialTitle == "") {
            NotificationManager.error("Please enter value in title.", 'Validation Error');
        } else if (this.state.tempCourseMaterialSession == "") {
            NotificationManager.error("Please select session #.", 'Validation Error');
        } else if (this.state.tempCourseMaterialName == "") {
            NotificationManager.error("Please upload file.", 'Validation Error');
        } else {
            this.state.courseMaterialVisibilityType.push(this.state.tempCourseMaterialType);
            this.state.courseMaterialVisibilityTitle.push(this.state.tempCourseMaterialTitle);
            this.state.courseMaterialVisibilitySession.push(this.state.tempCourseMaterialSession);
            this.state.courseMaterialVisibilityName.push(this.state.tempCourseMaterialName);

            this.setState({
                tempCourseMaterialType: "",
                tempCourseMaterialTitle: "",
                tempCourseMaterialSession: "",
                tempCourseMaterialName: ""
            });



            this.state.courseMaterialFinalArr.push({
                'type': this.state.tempCourseMaterialType,
                'title': this.state.tempCourseMaterialTitle,
                'session': this.state.tempCourseMaterialSession,
                'name': this.state.tempCourseMaterialName,
                'uploaded_file': this.state.tempCourseMaterialName
            });

            console.log(this.state.courseMaterialFinalArr);
        }

    }


    saveTopicInput = (e, value, id) => {
        e.preventDefault();
        if (value != "") {
            if (this.state.topicVisibility.indexOf(value) === -1) {
                this.state.topicVisibility[id] = value;
            }
        } else {
        }
        this.setState({
            topicVisibility: this.state.topicVisibility,
            tempTopic: ""
        });
        console.log(this.state.topicVisibility);
    }

    updateObjectiveInput = (value, id) => {
        console.log(value);
        this.setState({
            tempObjective: value
        });
        if (value != "") {
            if (this.state.objectiveArrayData.indexOf(value) === -1) {
                this.state.objectiveArrayData[id] = value;
            } else {
                NotificationManager.error("Invalid Value", 'Validation Error');
                this.state.objectiveArrayData.splice(id, 1);
            }
        }
        else {
            this.state.objectiveArrayData.splice(id, 1);
        }

    }
    saveObjectiveInput = (e, value) => {
        e.preventDefault();
        this.state.objectiveVisibility[0] = value;
        this.setState({
            objectiveVisibility: this.state.objectiveVisibility,
        });
    }

    updateRequirementInput = (value, id) => {
        console.log(value);
        this.setState({
            tempRequirement: value
        });
        if (value != "") {
            if (this.state.reqirementArrayData.indexOf(value) === -1) {
                this.state.reqirementArrayData[id] = value;
            } else {
                NotificationManager.error("Invalid Value", 'Validation Error');
                this.state.reqirementArrayData.splice(id, 1);
            }
        }
        else {
            this.state.reqirementArrayData.splice(id, 1);
        }

    }
    saveRequirementInput = (e, value) => {
        e.preventDefault();
        this.state.requirementVisibility[0] = value;
        this.setState({
            requirementVisibility: this.state.requirementVisibility
        });
    }

    deleteTopic = (e, value) => {
        e.preventDefault();

        if (value !== -1) {
            this.state.topicVisibility.splice(value, 1);
            this.state.dataTopic.splice(value, 1);
        }
        this.setState({
            topicVisibility: this.state.topicVisibility,
            dataTopic: this.state.dataTopic
        });
        //  topicVisibility
    }

    deleteObjective = (e, id, value) => {
        e.preventDefault();
        //  console.log(id);
        //  console.log(value);
        var index = this.state.objectiveVisibility.indexOf(value);
        //  console.log(index);
        if (index !== -1) {
            this.state.objectiveVisibility.splice(index, 1);
        }
        this.setState({
            objectiveVisibility: this.state.objectiveVisibility
        });
        //  topicVisibility
    }

    deleteRequirement = (e, id, value) => {
        e.preventDefault();
        //  console.log(id);
        //  console.log(value);
        var index = this.state.requirementVisibility.indexOf(value);
        //  console.log(index);
        if (index !== -1) {
            this.state.requirementVisibility.splice(index, 1);
        }
        this.setState({
            requirementVisibility: this.state.requirementVisibility
        });
        //  topicVisibility
    }

    editTopic = (e, id, value) => {
        e.preventDefault();
        console.log(value);
        var index = this.state.topicVisibility.indexOf(value);
        if (index !== -1) {
            this.state.topicVisibility.splice(index, 1);
        }
        this.setState({
            topicVisibility: this.state.topicVisibility,
            tempTopic: value
        });
    }

    editObjective = (e, id, value) => {
        e.preventDefault();
        var index = this.state.objectiveVisibility.indexOf(value);
        if (index !== -1) {
            this.state.objectiveVisibility.splice(index, 1);
        }
        this.setState({
            objectiveVisibility: this.state.objectiveVisibility,
            tempObjective: value
        });
    }


    editRequirement = (e, id, value) => {
        e.preventDefault();
        var index = this.state.requirementVisibility.indexOf(value);
        if (index !== -1) {
            this.state.requirementVisibility.splice(index, 1);
        }
        this.setState({
            requirementVisibility: this.state.requirementVisibility,
            tempRequirement: value
        });
    }

    deleteCourseMaterial = (e, id) => {
        e.preventDefault();
        //console.log(id);
        for (var i = this.state.courseMaterialFinalArr.length - 1; i >= 0; --i) {
            if (this.state.courseMaterialFinalArr[i].uploaded_file == id.uploaded_file) {
                this.state.courseMaterialFinalArr.splice(i, 1);
            }
        }

        this.setState({
            courseMaterialFinalArr: this.state.courseMaterialFinalArr
        });
        console.log(this.state.courseMaterialFinalArr);
    }

    updateCourseDuration = (event) => {
        this.setState({
            courseDuration: event,
            sessionDuration: "",
            dataSessions: [],
            addsessionbutton: 'none'
        });

    }

    updateNumberOfSession = (event) => {
        this.setState({
            numberOfSession: event,
        });

        console.log(this.state.sessionDate);
        console.log(this.state.timeData);

    }

    updateSessionDuration = (event) => {

        let sessionOptionData = [1, 2];
        this.setState({ sessionOptionData });

        if (this.state.courseDuration == "") {
            NotificationManager.error("Please select course duration first.", 'Validation Error');
        } else {
            this.setState({
                sessionDuration: event,
                dataSessions: [],
                addsessionbutton: 'block'
            });

            this.state.dataSessions = [];

            let courseDuration = this.state.courseDuration;
            let sessionDuration = event;
            let finalSessions = courseDuration / sessionDuration;

            /* console.log(courseDuration);
            console.log(sessionDuration);
            console.log(finalSessions);*/

            console.log(this.state.dataSessions);
            let dataSessions = this.state.dataSessions;
            let timeData = this.state.timeData;
            let sessionOptionData = [];

            for (let i = 0; i < finalSessions; ++i) {
                var dtvl = i + 1;
                sessionOptionData.push(dtvl);
                dataSessions.push(dataSessions.length);
                timeData.push("");
                this.setState({ dataSessions });
                this.setState({ timeData });
                this.setState({ sessionOptionData });
            }


        }

    }

    updateSessionInTable = () => {
        this.state.sessionFinalArray = [];
        this.setState({
            sessionFinalArray: []
        });
        let sessionDate = this.state.sessionDate;
        let sessionFinalArray = this.state.sessionFinalArray;
        let sessionDuration = this.state.sessionDuration;


        for (let i = 0; i < sessionDate.length; ++i) {
            let incval = i + 1;
            var d = sessionDate[i];

            var startDateTime = moment(d).format("YYYY-MM-DD hh:mm:ss");
            var endDateTime = moment(startDateTime).add(sessionDuration, 'hours').format('YYYY-MM-DD HH:mm:ss');  // see the cloning?

            var dateVar = moment(startDateTime).format("YYYY-MM-DD");
            var timeVar = moment(startDateTime).format("hh:mm A");



            //var dateVar = sessionDate[i].getFullYear()+'-'+(sessionDate[i].getMonth()+1)+'-'+sessionDate[i].getDate();

            sessionFinalArray.push({ 'name': 'Session ' + incval, 'date': dateVar, 'time': timeVar, 'duration': sessionDuration, 'startDateTime': startDateTime, 'endDateTime': endDateTime });

        }


        const token = localStorage.token;
        this.setState({
            loading: 1
        });
        const response = axios.post(
            window.$API_URL + 'teacher/checkcourseschedule',
            {
                sessionFinalArray: sessionFinalArray,
            },
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then((response) => {
            this.setState({
                loading: 0
            });

            this.setState({ sessionFinalArray });
            this.setState({
                sessionAddText: "Update Session"
            });

            // NotificationManager.success('Success', 'Course Created Successfully',4000,()=> {
            // });
        }).catch((error) => {
            if (error.response.data) {
                this.state.returnError = [];
                Object.keys(error.response.data).forEach((key) => {
                    this.state.returnError.push(error.response.data[key]);
                });
                this.state.showing = true;
                this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error("An Error Occured. Please try again", 'Validation Error');
            }
            this.setState({
                loading: 0
            });
        });

    }


    onChangeDate = (valueTo, idNum) => {
        var date = moment(valueTo).format("YYYY-MM-DD HH:mm:ss");
        if (valueTo != "") {
            this.state.sessionDate[idNum] = valueTo;
            this.state.sessionDateToSend[idNum] = date;
        }
        else {
            this.state.sessionDate.splice(idNum, 1);
            this.state.sessionDateToSend.splice(idNum, 1);
        }

        this.setState({
            sessionDate: this.state.sessionDate,
            sessionDateToSend: this.state.sessionDateToSend,
        });
    }

    updateTimeData = (value, idNum) => {
        if (value != "") {
            this.state.timeData[idNum] = value;
            /*if(this.state.timeData.indexOf(value) === -1){
            this.state.timeData[idNum] = value;
            }else{
            //NotificationManager.error("Invalid Value",'Validation Error');
            //this.state.timeData.splice(idNum, 1);
            }*/
        }
        else {
            //this.state.timeData.splice(idNum, 1);
        }

        this.setState({
            timeData: this.state.timeData,
        });

    }

    updateCourse = (e) => {
        e.preventDefault();
        const token = localStorage.token;
        this.setState({
            loading: 1
        });
        const response = axios.post(
            window.$API_URL + 'teacher/updatelesson',
            {
                category_id: this.state.category_id,
                subcategory_id: this.state.subcategory_id,
                subject_id: this.state.subject_id,
                title: this.state.course_title,
                description: this.state.description,
                lesson_count: this.state.lesson_count,
                lesson_duration: this.state.lesson_duration,
                language_id: this.state.language_id,
                //price: this.state.course_price,
                level: this.state.course_level_id,
                instruction_id: this.state.course_instruction_id,
                suitability: this.state.course_suitability_id,
                // topics: this.state.topicVisibility,
                // objective: this.state.objectiveVisibility,
                // requirement: this.state.requirementVisibility,
                // courseMaterialFinalArr: this.state.courseMaterialFinalArr,
                // sessionFinalArray: this.state.sessionFinalArray,
                slug: this.state.slug
            },
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            NotificationManager.success('Success', 'Your lesson has been updated successfully.', 4000, () => {
                //  window.location.reload();
            });
            setTimeout(() => window.location = "/teacher/lessons", 4000);
        }).catch((error) => {
            if (error.response.data) {
                this.state.returnError = [];
                Object.keys(error.response.data).forEach((key) => {
                    this.state.returnError.push(error.response.data[key]);
                });
                this.state.showing = true;
                this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error("There was some problem in Creating the Lesson. Please try again", 'Validation Error');
            }
            this.setState({
                loading: 0
            });
        });
    }





    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="signupspace">

                        <div class="signupspace-ttl">
                            <h3>Update lesson</h3>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                            <TeacherSidebar step={this.state.step} />
                            </div>


                            <div class="col-md-9 col-sm-8" >
                                <div class="signup-maincontent" hidden={this.state.steponeblock}>

                                    <Form.Group>
                                        <div className="btn-group btn-block">
                                            <SelectSearch
                                                options={this.state.categoriesData}
                                                value={this.state.category_id}
                                                name="category_id"
                                                placeholder="Choose a category for this lesson *"
                                                className="select-search fullWidth"
                                                search={true}
                                                onChange={(e) => this.updateSubcategories(e)}
                                            />
                                        </div>

                                    </Form.Group>

                                    <Form.Group>
                                        <div className="btn-group btn-block">
                                            <SelectSearch
                                                options={this.state.subcategoriesData}
                                                value={this.state.subcategory_id}
                                                name="subcategory_id"
                                                placeholder="Choose a subcategory for this lesson *"
                                                className="select-search fullWidth"
                                                search={false}
                                                onChange={(e) => this.updateSubject(e)}
                                            />
                                        </div>

                                    </Form.Group>

                                    <Form.Group>
                                        <div className="btn-group btn-block">
                                            <SelectSearch
                                                options={this.state.subjectsData}
                                                value={this.state.subject_id}
                                                name="subject_id"
                                                placeholder="Choose a subject for this lesson"
                                                className="select-search fullWidth"
                                                search={true}
                                                onChange={(e) => this.updateTopic(e)}
                                            />
                                        </div>

                                    </Form.Group>




                                    <Form.Group>
                                        <Form.Control type="text" value={this.state.course_title} placeholder="Title" onChange={(e) => this.setState({ course_title: e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control as="textarea" value={this.state.description} rows="3" placeholder="Lesson overview" onChange={(e) => this.setState({ description: e.target.value })}>

                                        </Form.Control>
                                    </Form.Group>

                                  

                                    <Form.Group>
                                        <SelectSearch
                                            options={this.state.course_suitability}
                                            value={this.state.course_suitability_id}
                                            name="course_suitability_id"
                                            placeholder="Choose suitability"
                                            className="select-search fullWidth"
                                            search={true}
                                            onChange={(e) => this.updateCourseSuitability(e)}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <SelectSearch
                                            options={this.state.course_instruction}
                                            value={this.state.course_instruction_id}
                                            name="course_instruction_id"
                                            placeholder="Select instruction for lesson"
                                            className="select-search fullWidth"
                                            search={true}
                                            onChange={(e) => this.updateCourseInstructionStyle(e)}
                                        />
                                    </Form.Group>

                                    <Form.Group>

                                        <SelectSearch
                                            options={this.state.course_level}
                                            value={this.state.course_level_id}
                                            name="course_level_id"
                                            placeholder="Choose lesson level"
                                            className="select-search fullWidth"
                                            search={true}
                                            onChange={(e) => this.updateCourseLevel(e)}
                                        />
                                    </Form.Group>



                                    <div class="signup-maincontent-footer">
                                        <div class="text-right">
                                            <Link onClick={(e) => this.updateCourse(e)} className="btn btn-default bt-custom">Update Lesson</Link>
                                        </div>
                                    </div>

                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>



                <Footer />
            </div>

        );
    }

}


const Topic = ({ id, appendTopic, updateTopicInput, saveTopicInput, topicVisibility, tempTopic, deleteTopic }) => (
    <Form.Group>
        {(id > 0) ? <a href="#" onClick={(e) => deleteTopic(e, id)} className="pull-right">x</a> : ""}
        <Form.Control onBlur={(e) => saveTopicInput(e, e.target.value, id)} onChange={(e) => updateTopicInput(e.target.value, id)} type="text" placeholder="List topic" />
    </Form.Group>
);


const CourseSession = ({ id, validDates, onChangeDate, sessionDate, updateTimeData }) => (

    <Form.Group >
        <Datetime
            onChange={(e) => onChangeDate(e, id)}
            value={sessionDate[id]}
            placeholder="Select Date and Time for Session"
            inputProps={{ placeholder: 'Select Date and Time for Session ' }}
            isValidDate={validDates}
        />
    </Form.Group >
);

export default EditCourse;
