import React from 'react';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import Autosuggest from 'react-autosuggest';
import { searchClasses } from '../../Utils/Utils'
class LandingBanner extends React.Component {
  constructor(props) {
    super();
    this.state = {
      suggestions: [],
      courses: [
      ],
      value: ''
    }
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp('\\b' + escapedValue, 'i');

    return this.state.courses.filter(person => regex.test(this.getSuggestionValue(person)));
  }

  getSuggestionValue(suggestion) {
    return `${suggestion.title + ' - ' + suggestion.category_name.name + ' - ' + suggestion.subcategory_name.name}`;
  }

  renderSuggestion(suggestion, { query }) {
    //console.log(suggestion);
    const suggestionText = `${suggestion.title + ' - ' + suggestion.category_name.name + ' - ' + suggestion.subcategory_name.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    //const parts = [];

    return (
      <span className={'suggestion-content '}

        style={(suggestion.banner !== null) ? { backgroundImage: 'url(' + suggestion.banner + ')', backgroundSize: '48px 48px' } : { backgroundImage: 'url("/img/course_pic01.jpg")', backgroundSize: '48px 48px' }}

        onClick={(e) => window.location.href = '/coursedetail/' + suggestion.slug}>
        <span className="name">
          {
            parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>{part.text}</span>
              );
            })
          }
        </span>
      </span>
    );
  }

  onChange = async (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
    if (newValue.length >= 2) {
      this.setState({
        courses: await searchClasses(newValue)
      })
      this.onSuggestionsFetchRequested(this.state);
    } else {
      this.setState({
        courses: []
      })
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "What do you want to learn? Type min 3 chars to search",
      value,
      onChange: this.onChange,
      className: "form-control"
    };

    return (
      <div class="hero max-container">
        <div class="container">
          <div class="row">
            <div class="hero-box col-md-5 col-sm-5">
              <h1 class="wow fadeInRight" style={{ animationDelay: "0.2s" }}>
                Learn <span class="hero-box-h1-special">anything</span>,
                            <span class="hero-box-h1-special">anywhere</span> with a live teacher
                        </h1>
              <p class="wow fadeInLeft" style={{ animationDelay: "0.4s" }}>Our platform lets you take control of your learning experience.</p>

              <div class="form-group has-feedback hero-input wow fadeIn" style={{ animationDelay: "0.4s" }} >
                <Autosuggest
                  suggestions={this.state.suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}

                />

                <span class="form-control-feedback" aria-hidden="true"><i class="zmdi zmdi-search"></i></span>
              </div>


            </div>
            <div class="col-md-7 col-sm-7 hidden-xs hero-right">
              <div class="hero-right-pic">
                <img src="img/hero_pic.png" class="img-responsive wow fadeInDown" style={{ animationDelay: "0.6s" }} alt="" />
              </div>
            </div>
          </div>

          <div class="hero-mob-pic visible-xs"><img src="img/hero_pic_mob.jpg" class="img-responsive" alt="" /></div>

        </div>
      </div>
    );
  }
}

export default LandingBanner;
