import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, } from 'react-router-dom';

class LandingCourses extends React.Component {
  render() {
    return (
      <>
        <div class="section section-courses-intro">
          <div class="container">
            <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
              <h1 class="wow fadeInUp" style={{ animationDelay: "0.3s" }} >Browse courses</h1>
              <p class="wow fadeInUp" style={{ animationDelay: "0.6s" }} >Whether you need help with a school subject, discover a new hobby, want to learn a new language or skill, there is a course for you.</p>
            </div>
          </div>
        </div>


        <div class="section-courses">
          <div class="container">
            <div class="homecourses-modules">
              <div class="row">
                {
                  this.props.popular_courses.data.map((course, index) => (
                    <div class="col-md-3 col-sm-4 col-xs-6">
                      <div class="homecourses-module wow fadeInUp" style={{ animationDelay: "0.2s" }} >

                        <img src={(course.cover_picture !== null) ? course.banner : '/img/course_pic01.jpg'} class="img-responsive cover-picture" alt="" />
                        <img src={(course.class_teacher.image !== null) ? course.class_teacher.profile_picture : "/img/uploadprofilepic.svg"} class="homecourses-module-profilepic img-circle" alt="" />
                        <div class="homecourses-module-txt">
                          <h6>{course.category_name.name}</h6>
                          <h4><Link to={"/coursedetail/" + course.slug}> {course.title}</Link></h4>
                        </div>

                        <div class="homecourses-module-bot">
                          <p>By {course.class_teacher.first_name + " " + course.class_teacher.last_name}</p>
                        </div>

                      </div>
                    </div>
                  ))
                }


              </div>
            </div>

            <div class="text-center">
              <Link to="/courses" variant="default" className="bt-custom">
                View all courses
                </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LandingCourses;
