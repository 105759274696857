import React from 'react';
import { Link } from 'react-router-dom';
class StudentSidebarTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  componentDidMount() {
    console.log('i am called');
  }
  render() {
    return (
      <div class="dash-sidemenu">
        <ul>
          <li className={(this.props.current_page === 'profile') ? "active" : ""}>
            <Link to="/student/profile">Profile</Link>
          </li>

          <li className={(this.props.current_page === 'mywallet') ? "active" : ""}>
            <Link to="/student/financialcenter">My Wallet</Link>
          </li>
          
          <li className={(this.props.current_page === 'account-setting') ? "active" : ""}>
            <Link to="/student/account-setting">Account Setting</Link>
          </li>
          {/*
           <li className={(this.props.current_page === 'mycourses') ? "active" : ""}>
            <Link to="/student/courses">Settings</Link>
          </li>
          <li className={(this.props.current_page === 'mycourses') ? "active" : ""}>
            <Link to="/student/courses">Help center</Link>
            </li> 
            */
          }
        </ul>
      </div>
    );
  }
}

export default StudentSidebarTwo;
