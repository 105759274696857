import React from 'react';

class LeftBar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="signup-sidemenu">
        <ul>
          <li className={(this.props.step === "stepone") ? 'active' : ''}>Personal info</li>
          <li className={(this.props.step === "steptwo") ? 'active' : ''}>Interests</li>
          <li className={(this.props.step === "stepthree") ? 'active' : ''}>Connect</li>
        </ul>
      </div>
    );
  }
}

export default LeftBar;