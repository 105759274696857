import React from 'react';
import { Link } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import LoadingSpinner from './LoadingSpinner';

class Footer extends React.Component {
  showSignup = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
  }
  showLogin = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: true });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
  }

  logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  render() {
    return (
      <>
        <div class="footer-custom">
          <div class="container">
            <div class="row">

              <div class="col-md-4 col-sm-4">
                <img src="/img/logo_wh.svg" alt="" />
                <p>ConferenceMe is an online learning platform with a state-of-the-art user-friendly virtual classroom.</p>
              </div>

              <div class="col-md-2 col-sm-3 col-md-offset-4 col-sm-offset-2">
                <ul>
                  <li><Link to="/about-us">About</Link></li>
                  <li><Link to="courses">Courses</Link></li>
                  {/* <li><Link>Become a teacher</Link></li> */}
                  <li><Link to='/contact-us'>Contact</Link></li>
                  <li><Link to='/faq'>FAQ</Link></li>
                </ul>
              </div>

              <div class="col-md-2 col-sm-3">
                <ul>
                  {
                    (this.props.profile_data === null)
                      ?
                      <>
                        <li><Link onClick={(e) => this.showSignup(e)}>Sign up</Link></li>
                        <li><Link onClick={(e) => this.showLogin(e)}>Login</Link></li>
                      </>
                      :
                      <li><Link onClick={(e) => this.logout()}>Logout</Link></li>
                  }

                  <li><Link to="/privacy-policy">Privacy policy</Link></li>
                  <li><Link to="/terms-and-conditions">Terms of service</Link></li>
                </ul>
              </div>

            </div>
          </div>
          <NotificationContainer enterTimeout="6000"/>
          {
            (this.props.sweet_alert_success_json !== null)
              ?
              <SweetAlert
                success
                showCancel={this.props.sweet_alert_success_json.showCancel}
                showConfirm={this.props.sweet_alert_success_json.showConfirm}
                confirmBtnText={this.props.sweet_alert_success_json.confirmBtnText}
                confirmBtnBsStyle={this.props.sweet_alert_success_json.confirmBtnBsStyle}
                cancelBtnText={'Cancel'}
                cancelBtnBsStyle={'danger'}
                title={this.props.sweet_alert_success_json.title}
                onConfirm={this.props.sweet_alert_success_json.success_callback}
                onCancel={this.props.sweet_alert_success_json.cancel_callback}
              >
                {this.props.sweet_alert_success_json.content}
              </SweetAlert>
              :
              ''
          }
          {
            (this.props.sweet_alert_failed_json !== null)
              ?
              <SweetAlert
                danger
                showCancel={this.props.sweet_alert_failed_json.showCancel}
                showConfirm={this.props.sweet_alert_failed_json.showConfirm}
                confirmBtnText={this.props.sweet_alert_failed_json.confirmBtnText}
                confirmBtnBsStyle={this.props.sweet_alert_failed_json.confirmBtnBsStyle}
                cancelBtnText={'Cancel'}
                cancelBtnBsStyle={'danger'}
                title={this.props.sweet_alert_failed_json.title}
                onConfirm={this.props.sweet_alert_failed_json.success_callback}
                onCancel={this.props.sweet_alert_failed_json.cancel_callback}
              >
                {this.props.sweet_alert_failed_json.content}
              </SweetAlert>
              :
              ''
          }

        </div>
        {
          (this.props.show_loader === true)
            ?
            <LoadingSpinner />
            :
            ''
        }

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sweet_alert_success_json: state.sweet_alert_success_json,
    show_loader: state.show_loader,
    profile_data: state.profile_data,
    sweet_alert_failed_json: state.sweet_alert_failed_json
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
