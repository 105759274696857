import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../../Elements/HeaderInner';
import Footer from '../../Elements/Footer';

import TeacherSidebar from '../../Elements/TeacherSidebar';
import { getCourseDetail, getTeacherCourseBookingDetail, getTeacherCourseBookingStudentDetail } from '../../../Utils/Utils';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import * as moment from 'moment';
import moment_timezone from 'moment-timezone';

class TeacherCourseBookingDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: this.props.match.params.slug,
            active_show: 'active',
            upcoming_show: '',
            completed_show: '',

            page: 1,
            per_page: 5,
            delay: 1,
            search_term: '',
            completed_courses: { data: [] },
            courses_session_list: { data: [] },
            courses_student_list: { data: [] },
            typing: false,
            typingTimeout: 0,

            alert: "",
            deleteAlert: "",
            step: 'mycourses',
            user_timezone: ''
        };

    }

    async componentDidMount() {
        await this.getUserProfile();
        this.setState({
            completed_courses: await getCourseDetail(this.state.slug),
            courses_session_list: await getTeacherCourseBookingDetail(this.state.slug, this.state.page, this.state.per_page, this.state.delay),
            courses_student_list: await getTeacherCourseBookingStudentDetail(this.state.slug)
        });

    }

    utcToUsaTimeConvert = (date) => {
        return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
    }

    utcToUsaTimeConvertDisplay = (date) => {
        return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD hh:mm A");
    }

    getUserProfile = async () => {
        const token = localStorage.token;
        const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
            headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
        }
        ).then((response) => {

            this.setState({
                user_timezone: response.data.timezone_name.name
            });

        }).catch((error) => {
            if (!error.response) {
                NotificationManager.error("Some Problem Occured in Fetching Teacher Availability", 'Validation Error');
                return;
            }
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data.response).forEach((key) => {
                        this.state.returnError.push(error.response.data.response[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
                }

            } else {
                NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
            }
        });
    }

    statusValue = (value) => {
        if (value == 0) {
            return ("Pending");
          } else if (value == 1) {
            return ("Approved");
          } else if (value == 2) {
            return ("Completed");
          } else if (value == 4) {
            return ("Cancelled by Student");
          } else if (value == 5) {
            return ("Cancelled by Teacher");
          } else if (value == 3) {
            return ("Rejected");
          } else {
            return ("NA");
          }
    }


    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="row">
                        <div class="col-md-2 col-sm-2">
                            <TeacherSidebar step={this.state.step} />
                        </div>

                        <div class="col-md-10 col-sm-10">
                            <div class="dash-maincontent">


                                <ul class="nav nav-tabs dash-tabs" role="tablist">
                                    <li className="active">
                                        <Link> Course Booking Detail </Link>
                                    </li>
                                </ul>

                                <div class="tab-content">

                                    <div role="tabpanel" >

                                        {
                                            this.state.completed_courses.data.title ?
                                                <div class="dash-mycourses-module">

                                                    <h6>{this.state.completed_courses.data.category_name.name}</h6>
                                                    <h3>{this.state.completed_courses.data.title}</h3>
                                                    <div class="dash-mycourses-module-date">
                                                        <p>Starts: {moment(this.state.completed_courses.data.start_date).format("MM/DD/YYYY")} - Ends: {moment(this.state.completed_courses.data.end_date).format("MM/DD/YYYY")}  </p>
                                                    </div>

                                                    <div class="dash-mycourses-module-date">
                                                        <p>Sub category: {this.state.completed_courses.data.subcategory_name.name}</p>
                                                        <p>Subject: { this.state.completed_courses.data.subject_name ? this.state.completed_courses.data.subject_name.name : "N/A"}</p>
                                                    </div>


                                                    <table className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Start</th>
                                                                <th>End</th>
                                                                <th>Status</th>
                                                                <th>Meeting</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.courses_session_list.data ?
                                                                    this.state.courses_session_list.data.map((schedule) => (
                                                                        <>

                                                                            <tr>
                                                                                <td>{moment(schedule.start_date).format("MM/DD/YYYY hh:mm A")}</td>
                                                                                <td>{moment(schedule.end_date).format("MM/DD/YYYY hh:mm A")}</td>
                                                                                <td>{this.statusValue(schedule.status)}</td>
                                                                                <td>
                                                                                    {
                                                                                        (schedule.class_meeting != null) ?
                                                                                            (this.utcToUsaTimeConvert(moment().format()) > this.utcToUsaTimeConvert(moment(schedule.start_date).add(-15, 'minutes').format()) && this.utcToUsaTimeConvert(moment().format()) < this.utcToUsaTimeConvert(moment(schedule.end_date).add(+15, 'minutes').format()))
                                                                                            ?
                                                                                                <a className="btn btn-default dash-mycourses-module-bot-bt-out" target="_blank" href={schedule.class_meeting.host_url}>Join</a> :
                                                                                                <a className="btn btn-default dash-mycourses-module-bot-bt-out disabled" title="You can't Join at this time." >Join</a> :
                                                                                            <a className="btn btn-default dash-mycourses-module-bot-bt-out disabled" title="You can't Join at this time." href="asdas">Join</a>
                                                                                    }
                                                                                </td>
                                                                                
                                                                            </tr>

                                                                        </>
                                                                    )) : ""
                                                            }
                                                        </tbody>
                                                    </table>


                                                    <div class="coursedetail-main-module">
                                                        <h4>Enrolled Students</h4>
                                                        <ul>
                                                            {
                                                                this.state.courses_student_list.data.map((student) => (
                                                                    <li>{student.class_student.first_name + " " + student.class_student.last_name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>

                                                    <div class="coursedetail-main-module">
                                                        <h4>Course content</h4>
                                                        <ul>
                                                            {
                                                                this.state.completed_courses.data.class_offerings_topic.map((topic) => (
                                                                    <li>{topic.name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div class="coursedetail-main-module">
                                                        <h4>Requirements</h4>
                                                        <ul>
                                                            {
                                                                this.state.completed_courses.data.class_offerings_requirement.map((req) => (
                                                                    <li>{req.name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div class="coursedetail-main-module">
                                                        <h4>Objective</h4>
                                                        <ul>
                                                            {
                                                                this.state.completed_courses.data.class_offerings_ojective.map((objective) => (
                                                                    <li>{objective.name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>

                                                </div>
                                                :
                                                ''
                                        }
                                    </div>



                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <Footer />
            </div >

        );
    }

}
export default TeacherCourseBookingDetail;
