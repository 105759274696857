import React from 'react';
import moment from 'moment';


class OutgoingMessage extends React.Component {
  constructor(props) {
    super();
    this.state = {
    };
  }

  componentDidMount() {
  }
  render() {
    return (
      <div class="outgoing_msg">
        <div class="sent_msg">
          <p>{this.props.message.message}</p>
          <span class="time_date">{moment(this.props.message.createdAt).format("h:mm A")} | {moment(this.props.message.createdAt).format("MMM DD,YYYY")}</span>
        </div>
      </div>

    );
  }

}
export default OutgoingMessage;
