import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { fetchTeacherLessonsAll } from '../../Utils/Utils';
import CourseCard from '../common_components/CourseCard';
import Pagination from "react-js-pagination";
class TeacherProfileLessonsList extends React.Component {
  constructor(props) {
    super(props);
    this.changeSearch = this.changeSearch.bind(this);
    this.state = {
      page: 1,
      per_page: 2,
      delay: 1,
      search_term: '',
      classes: { data: [] },
    };
    //console.log(this.props.match.params.slug);
  }

  async componentDidMount() {
    this.setState({
      classes: await fetchTeacherLessonsAll(this.state.page, this.state.per_page, this.state.delay, this.state.search_term, this.props.match.params.slug),
      //category_name: await getCategoryName(this.props.match.params.category_id)
    });
  }

  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term: search_term,
          classes: await fetchTeacherLessonsAll(1, this.state.per_page, this.state.delay, search_term, this.props.match.params.slug)
        });
      }, 1500)
    });
  }

  async handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber
    })
    this.setState({
      page: pageNumber,
      classes: await fetchTeacherLessonsAll(pageNumber, this.state.per_page, this.state.delay, this.state.search_term, this.props.match.params.slug)
    });
  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="container">
            <div class="row coursecategory-searchmodule">
              <div class="col-md-6 col-sm-6">
                <Link to="/courses"><i class="zmdi zmdi-long-arrow-left"></i> Back to all courses</Link>
              </div>
              <div class="col-md-4 col-md-offset-2 col-sm-4 col-sm-offset-2">
                <Form.Control
                  className="dash-maincontent-search"
                  type="text"
                  placeholder="Search Courses"
                  onChange={this.changeSearch}
                />
              </div>
            </div>
            <div class="coursespage-module-ttl">
              <h3>Teacher Lesson's </h3>
            </div>

            <div class="row gutter-20">
              {
                this.state.classes.data.map((class_data) => (
                  <CourseCard course_detail={class_data} />
                ))
              }

            </div>
            {
              (this.state.classes.data.length > 0)
                ?
                <div class="text-right">
                <Pagination
                  activePage={this.state.classes.page}
                  itemsCountPerPage={this.state.classes.per_page}
                  totalItemsCount={this.state.classes.total}
                  pageRangeDisplayed={this.state.classes.total_pages}
                  onChange={this.handlePageChange.bind(this)}
                />
                </div>
                :
                ''
            }
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default TeacherProfileLessonsList;