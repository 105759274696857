import React from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';



class StudentStepTwo extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <div className="signupspace">
            <div className="signupspace-ttl">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-8">
                  <h3><b>Sign up</b> Student account</h3>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">
                  <a href="#">Skip <span className="hidden-xs">and complete later</span> <i className="zmdi zmdi-long-arrow-right"></i></a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-4">
                <div className="signup-sidemenu">
                  <ul>
                    <li>Personal info</li>
                    <li className="active">Interests</li>
                    <li>Connect</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9 col-sm-8">
                <div className="signup-maincontent signup-interests">
                  <h4>What do you want to learn in ConferenceMe?</h4>
                  <div className="section-introbanners">
                    <div className="row gutter-10">
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-4 col-xs-6">
                        <a href="#">
                          <div className="introbann-cover">
                            <div className="container-introbann">
                              <div className="introbann-pic"><img src="img/introbann01.jpg" alt="Snow" style={{ width: "100%" }} /></div>
                              <div className="centered"><h4>Marketing</h4></div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="txt-aclaratory">You can select multiple categories.</p>
                  <div className="signup-maincontent-footer">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-6">
                        <a href="#"><i className="zmdi zmdi-long-arrow-left"></i>Back</a>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-6 text-right">
                        <a className="btn btn-default bt-custom" href="#" role="button">Next</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default StudentStepTwo;
