import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';

import TeacherSidebar from '../Elements/TeacherSidebar';
import {
  getTeacherLessonList, getTeacherLessonBookingListUpcoming,
  getTeacherLessonBookingListCompleted, getTeacherLessonBookingListRequests
} from '../../Utils/Utils';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import * as moment from 'moment';
import moment_timezone from 'moment-timezone';

class MyLessons extends React.Component {
  constructor() {
    super();
    this.state = {
      active_show: 'active',
      upcoming_show: '',
      completed_show: '',
      request_show: '',

      page: 1,
      per_page: 5,
      delay: 1,
      search_term: '',
      lessons_list: { data: [] },
      typing: false,
      typingTimeout: 0,

      page_upcoming: 1,
      per_page_upcoming: 5,
      delay_upcoming: 1,
      search_term_upcoming: '',
      courses_list_upcoming: { data: [] },
      typing_upcoming: false,
      typingTimeout_upcoming: 0,

      page_completed: 1,
      per_page_completed: 5,
      delay_completed: 1,
      search_term_completed: '',
      courses_list_completed: { data: [] },
      typing_completed: false,
      typingTimeout_completed: 0,

      page_request: 1,
      per_page_request: 5,
      dela_requesty: 1,
      search_term_request: '',
      lessons_list_request: { data: [] },
      typing_request: false,
      typingTimeout_request: 0,


      alert: "",
      deleteAlert: "",

      step: 'mylessons',

      user_timezone: null
    };

  }


  async componentDidMount() {
    await this.getUserProfile();
    this.setState({
      lessons_list: await getTeacherLessonList(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      courses_list_upcoming: await getTeacherLessonBookingListUpcoming(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      courses_list_completed: await getTeacherLessonBookingListCompleted(this.state.page, this.state.per_page, this.state.delay, this.state.search_term),
      lessons_list_request: await getTeacherLessonBookingListRequests(this.state.page, this.state.per_page, this.state.delay)
    });
  }

  utcToUsaTimeConvert = (date) => {
    return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
  }

  utcToUsaTimeConvertDisplay = (date) => {
    return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD hh:mm A");
  }


  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {

      this.setState({
        user_timezone: response.data.timezone_name.name
      });

    }).catch((error) => {
      if (!error.response) {
        NotificationManager.error("Some Problem Occured in Fetching Teacher Availability", 'Validation Error');
        return;
      }
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
        }

      } else {
        NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
      }
    });
  }

  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term: search_term,
          lessons_list: await getTeacherLessonList(1, this.state.per_page, this.state.delay, search_term)
        });
      }, 1500)
    });
  }

  changeSearchUpcoming = async (event) => {
    const self = this;
    var search_term_upcoming = event.target.value;
    if (self.state.typingTimeout_upcoming) {
      clearTimeout(self.state.typingTimeout_upcoming);
    }

    self.setState({
      //name: event.target.value,
      typing_upcoming: false,
      typingTimeout_upcoming: setTimeout(async () => {
        this.setState({
          page_upcoming: 1,
          search_term_upcoming: search_term_upcoming,
          courses_list_upcoming: await getTeacherLessonBookingListUpcoming(1, this.state.per_page_upcoming, this.state.delay_upcoming, search_term_upcoming)
        });
      }, 1500)
    });
  }

  changeSearchCompleted = async (event) => {
    const self = this;
    var search_term_completed = event.target.value;
    if (self.state.typingTimeout_completed) {
      clearTimeout(self.state.typingTimeout_completed);
    }

    self.setState({
      name: event.target.value,
      typing_completed: false,
      typingTimeout_completed: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term_completed: search_term_completed,
          courses_list_completed: await getTeacherLessonBookingListCompleted(1, this.state.per_page_completed, this.state.delay_completed, search_term_completed)
        });
      }, 1500)
    });
  }

  async handlePageChange(pageNumber) {
    this.setState({
      page: pageNumber,
      lessons_list: await getTeacherLessonList(pageNumber, this.state.per_page, this.state.delay, this.state.search_term)
    });
  }

  async handlePageChangeUpcoming(pageNumber) {
    this.setState({
      page_upcoming: pageNumber,
      courses_list_upcoming: await getTeacherLessonBookingListUpcoming(pageNumber, this.state.per_page_upcoming, this.state.delay_upcoming, this.state.search_term_upcoming)
    });
  }

  async handlePageChangeCompleted(pageNumber) {
    this.setState({
      page_completed: pageNumber,
      courses_list_completed: await getTeacherLessonBookingListCompleted(pageNumber, this.state.per_page_completed, this.state.delay_completed, this.state.search_term_completed)
    });
  }

  async handlePageChangeRequest(pageNumber) {
    this.setState({
      page_request: pageNumber,
      courses_list_completed: await getTeacherLessonBookingListRequests(pageNumber, this.state.per_page_completed, this.state.delay_completed)
    });
  }

  changeStatus = (id, status) => {
    if (status === 1) {
      const getAlert = () => (<SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, Deactivate it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={(e) => this.changeFinalStatus(id, status)}
        onCancel={() => this.removePopup()}
        focusCancelBtn
      >
        This will deactivate your Lesson!! You can enable it anytime.
      </SweetAlert>);
      this.setState({
        alert: getAlert()
      });
    } else {
      const getAlert = () => (<SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, Activate it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={(e) => this.changeFinalStatus(id, status)}
        onCancel={() => this.removePopup()}
        focusCancelBtn
      >
        This will Activate your Course!!
      </SweetAlert>);
      this.setState({
        alert: getAlert()
      });
    }
  }
  deleteLesson = (id) => {
    const getAlertDelete = () => (<SweetAlert
      warning
      showCancel
      confirmBtnText="Yes, Delete it!"
      confirmBtnBsStyle="danger"
      title="Are you sure?"
      onConfirm={(e) => this.deleteLessonCompletely(id)}
      onCancel={() => this.removePopupDelete()}
      focusCancelBtn
    >
      This Will Delete the Course. This step is not reversable!!
    </SweetAlert>);
    this.setState({
      deleteAlert: getAlertDelete()
    });
  }
  deleteLessonCompletely = (id, status) => {
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'teacher/courselessondelete',
      {
        lesson_id: id,
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        deleteAlert: ""
      });
      this.tablePageLoad(this.state.currentpage);
      this.setState({ loading: false });
      window.location.reload();
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While updating the Status!! Please Try Again", 'Validation Error');
    });
  }
  changeFinalStatus = (id, status) => {
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'teacher/courselessonstatusupdate',
      {
        lesson_id: id,
        status: status
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        alert: ""
      });
      this.setState({ loading: false });
      window.location.reload();
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While updating the Status!! Please Try Again", 'Validation Error');
    });
  }
  removePopup = () => {
    this.setState({
      alert: ""
    });
  }
  removePopupDelete = () => {
    this.setState({
      deleteAlert: ""
    });
  }


  acceptLesson = (id, title, inst_name, date) => {

    const getAlert = () => (<SweetAlert
      warning
      showCancel={true}
      showConfirm={true}
      confirmBtnText="Yes"
      cancelBtnText="No"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="warning"
      title="Accept Lesson Confirmation"
      onConfirm={(e) => this.lessonAccept(id)}
      onCancel={(e) => this.setState({ alert: "" })}
    >
      Are you sure you want to accept lesson named
      <b>{title}</b> on <b>{date}</b>.  ?
    </SweetAlert>);
    this.setState({
      alert: getAlert()
    });

  }

  lessonAccept = (id) => {
    //  alert(id);
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'teacher/acceptlesson',
      {
        lesson_id: id,
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        loading: false,
        alert: ""
      });
      NotificationManager.success('You have accepted lesson request successfully.');
      setTimeout(() => window.location.reload(), 3000);
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While Accepting the Lesson!! Please Try Again", 'Error');
    });

  }


  rejectLesson = (id, title, inst_name, date) => {

    const getAlert = () => (<SweetAlert
      warning
      showCancel={true}
      showConfirm={true}
      confirmBtnText="Yes"
      cancelBtnText="No"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="warning"
      title="Reject Lesson Confirmation"
      onConfirm={(e) => this.lessonReject(id)}
      onCancel={(e) => this.setState({ alert: "" })}
    >
      Are you sure you want to reject lesson named
      <b>{title}</b> on <b>{date}</b>.  ?
    </SweetAlert>);
    this.setState({
      alert: getAlert()
    });

  }

  lessonReject = (id) => {
    //  alert(id);
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'teacher/rejectlesson',
      {
        lesson_id: id,
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        loading: false,
        alert: ""
      });
      NotificationManager.success('You have rejected a lesson request from student.');
      setTimeout(() => window.location.reload(), 3000);
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While Rejecting the Lesson!! Please Try Again", 'Error');
    });
  }

  lessonCancel = (id) => {
    //  alert(id);
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'teacher/cancellesson',
      {
        lesson_id: id,
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        loading: false,
        alert: ""
      });
      NotificationManager.success('You have cancelled the lesson successfully.');
      setTimeout(() => window.location.reload(), 3000);
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("Some Error occured While Cancelling the Class!! Please Try Again", 'Error');
    });

  }
  cancelLesson = (id, title, inst_name, date) => {

    const getAlert = () => (<SweetAlert
      warning
      showCancel={true}
      showConfirm={true}
      confirmBtnText="Yes"
      cancelBtnText="No"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="warning"
      title="Lesson Cancel Confirmation"
      onConfirm={(e) => this.lessonCancel(id)}
      onCancel={(e) => this.setState({ alert: "" })}
    >
      You have requested to Cancel Lesson Title <b>{title}</b>  on <b>{date}</b>. <br />Are you sure you want to cancel ?
    </SweetAlert>);
    this.setState({
      alert: getAlert()
    });

  }



  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <TeacherSidebar step={this.state.step} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent">

                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li className={this.state.active_show}>
                    <Link onClick={(e) => this.setState({ active_show: "active", upcoming_show: "", completed_show: "", request_show: "" })}>List</Link>
                  </li>
                  <li className={this.state.upcoming_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", upcoming_show: "active", completed_show: "", request_show: "" })}>Upcoming</Link>
                  </li>
                  <li className={this.state.completed_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", upcoming_show: "", completed_show: "active", request_show: "" })} >Completed</Link>
                  </li>
                  <li className={this.state.request_show}>
                    <Link onClick={(e) => this.setState({ active_show: "", upcoming_show: "", completed_show: "", request_show: "active" })} >Requests</Link>
                  </li>

                  <li class="pull-right">
                    {
                      (this.state.active_show)
                        ?
                        <Form.Control
                          className="dash-maincontent-search"
                          type="text"
                          placeholder="Search Lessons"
                          onChange={this.changeSearch}
                        />
                        :
                        (this.state.upcoming_show)
                          ?
                          <Form.Control
                            className="dash-maincontent-search"
                            type="text"
                            placeholder="Search Upcoming Lessons"
                            onChange={this.changeSearchUpcoming}
                          />
                          :
                          (this.state.completed_show)
                            ?
                            <Form.Control
                              className="dash-maincontent-search"
                              type="text"
                              placeholder="Search Completed Lessons"
                              onChange={this.changeSearchCompleted}
                            />
                            :
                            ""


                    }


                  </li>

                  <div class="dash-mycourses-module-bot">
                    <Link to={`/teacher/createlesson`} className="btn btn-default dash-mycourses-module-bot-bt-out">Add Lesson</Link>
                  </div>

                </ul>


                <div class="tab-content">

                  <div role="tabpanel" className={this.state.active_show + " tab-pane"} id="activecourses">
                    {
                      this.state.lessons_list.data ? this.state.lessons_list.data.map((com_course) => (
                        <>
                          <div class="dash-mycourses-module col-md-6 col-xs-12">
                            <ul class="list-inline dash-teacher-mycourses-module-toplinks">
                              <li><Link to={`/teacher/editlesson/${com_course.slug}`}>Edit</Link></li>
                              <li><Link onClick={() => this.changeStatus(com_course.id, com_course.status)}>
                                {com_course.status == 1 ? "Pause" : "Resume"}
                              </Link></li>
                              <li><Link onClick={() => this.deleteLesson(com_course.id)}>Delete</Link></li>

                            </ul>
                            <h6>{com_course.category_name.name}</h6>
                            <h3>{com_course.title}</h3>
                            <div class="dash-mycourses-module-date">
                              {/* <p>Started: Mar 23, 2020 - Ends: Aug 30, 2020 </p> */}
                            </div>
                            <div class="dash-mycourses-module-bot">
                              <Link to={`/teacher/lesson/${com_course.slug}`} className="btn btn-default dash-mycourses-module-bot-bt-out">View lesson details</Link>
                            </div>
                          </div>
                        </>
                      ))
                        : ""
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.lessons_list.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.lessons_list.page}
                            itemsCountPerPage={this.state.lessons_list.per_page}
                            totalItemsCount={this.state.lessons_list.total}
                            pageRangeDisplayed={this.state.lessons_list.total_pages}
                            onChange={this.handlePageChange.bind(this)}
                          />
                        </div>
                        :
                        <div class="dash-mycourses-module">
                          <p>No lessons has been added yet.</p>
                        </div>
                    }

                  </div>

                  <div role="tabpanel" className={this.state.upcoming_show + " tab-pane"} id="activecourses">
                    {
                      this.state.courses_list_upcoming.data ? this.state.courses_list_upcoming.data.map((com_course) => (
                        <>
                          <div class="dash-mycourses-module col-md-6 col-xs-12">

                            <h6>{com_course.class_data.category_name.name}</h6>
                            <h3>{com_course.class_data.title}</h3>
                            <div class="dash-mycourses-module-date">
                              <p>Student: {com_course.class_student.first_name + " " + com_course.class_student.last_name}</p>
                              {
                                (this.state.user_timezone !== null)
                                  ?
                                  <p>Starts: {moment(com_course.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")} - Ends: {moment(com_course.end_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")}  </p>
                                  :
                                  <p>Starts: {moment(com_course.start_date).format("MMM DD,YYYY h:mmA")} - Ends: {moment(com_course.end_date).format("MMM DD,YYYY h:mmA")}  </p>
                              }
                            </div>
                            <div class="dash-mycourses-module-bot">
                              <Link to={`/teacher/lesson-booking/${com_course.class_data.slug}`} className="btn btn-default dash-mycourses-module-bot-bt-out">View booking details</Link>

                              {
                                (com_course.class_meeting != null) ?
                                  (this.utcToUsaTimeConvert(moment().format()) > this.utcToUsaTimeConvert(moment(com_course.start_date).add(-15, 'minutes').format()) && this.utcToUsaTimeConvert(moment().format()) < this.utcToUsaTimeConvert(moment(com_course.end_date).add(+15, 'minutes').format())) ?
                                    <a className="btn btn-default dash-mycourses-module-bot-bt-out" target="_blank" href={com_course.class_meeting.host_url}>Join</a> :
                                    <Link onClick={(e) => e.preventDefault()} className="btn btn-default dash-mycourses-module-bot-bt-fill disabled" title="You can't Join at this time." target="_blank" >Join</Link> :
                                  <Link onClick={(e) => e.preventDefault()} className="btn btn-default dash-mycourses-module-bot-bt-fill disabled" title="You can't Join at this time." target="_blank" >Join</Link>
                              }


                              {
                                (com_course.status == 1 && com_course.status != 4 && com_course.status != 5) ?
                                  (this.utcToUsaTimeConvert(moment().add(+24, 'hours').format()) < this.utcToUsaTimeConvert(com_course.start_date)) ?
                                    <Link type="button" className="btn"
                                      onClick={(e) => this.cancelLesson(com_course.id, com_course.class_data.title, com_course.class_data.class_teacher.first_name + " " + com_course.class_data.class_teacher.last_name, this.utcToUsaTimeConvert(com_course.start_date))}>
                                      Cancel Lesson
                                                                        </Link> :
                                    "" :
                                  ""
                              }

                            </div>
                          </div>
                        </>
                      ))
                        : ""
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.courses_list_upcoming.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.courses_list_upcoming.page}
                            itemsCountPerPage={this.state.courses_list_upcoming.per_page}
                            totalItemsCount={this.state.courses_list_upcoming.total}
                            pageRangeDisplayed={this.state.courses_list_upcoming.total_pages}
                            onChange={this.handlePageChangeUpcoming.bind(this)}
                          />
                        </div>
                        :
                        <div class="dash-mycourses-module">
                          <p>No upcoming courses booking has been found.</p>
                        </div>
                    }


                  </div>



                  <div role="tabpanel" className={this.state.completed_show + " tab-pane"} id="completedcourses">
                    {
                      this.state.courses_list_completed.data ? this.state.courses_list_completed.data.map((com_course) => (
                        <>
                          <div class="dash-mycourses-module  col-md-6 col-xs-12">

                            <h6>{com_course.class_data.category_name.name}</h6>
                            <h3>{com_course.class_data.title}</h3>
                            <div class="dash-mycourses-module-date">
                              <p>Student: {com_course.class_student.first_name + " " + com_course.class_student.last_name}</p>
                              {
                                (this.state.user_timezone !== null)
                                  ?
                                  <p>Starts: {moment(com_course.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")} - Ends: {moment(com_course.end_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")}  </p>
                                  :
                                  <p>Starts: {moment(com_course.start_date).format("MMM DD,YYYY h:mmA")} - Ends: {moment(com_course.end_date).format("MMM DD,YYYY h:mmA")}  </p>
                              }
                            </div>
                            <div class="dash-mycourses-module-bot">
                              <Link to={`/teacher/lesson-booking/${com_course.class_data.slug}`} className="btn btn-default dash-mycourses-module-bot-bt-out">View booking details</Link>
                            </div>
                          </div>
                        </>
                      ))
                        : ""
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.courses_list_completed.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.courses_list_completed.page}
                            itemsCountPerPage={this.state.courses_list_completed.per_page}
                            totalItemsCount={this.state.courses_list_completed.total}
                            pageRangeDisplayed={this.state.courses_list_completed.total_pages}
                            onChange={this.handlePageChangeCompleted.bind(this)}
                          />
                        </div>
                        :
                        <div class="dash-mycourses-module">
                          <p>No completed courses booking has been found.</p>
                        </div>
                    }
                  </div>

                  <div role="tabpanel" className={this.state.request_show + " tab-pane"} id="reuqestcourses">
                    {
                      this.state.lessons_list_request.data ? this.state.lessons_list_request.data.map((com_course) => (
                        <>
                          <div class="dash-mycourses-module col-md-6 col-xs-12">

                            <h6>{com_course.class_data.category_name.name}</h6>
                            <h3>{com_course.class_data.title}</h3>
                            <div class="dash-mycourses-module-date">
                              <p>Student: {com_course.class_student.first_name + " " + com_course.class_student.last_name}</p>
                              {
                                (this.state.user_timezone !== null)
                                  ?
                                  <p>Starts: {moment(com_course.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")} - Ends: {moment(com_course.end_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")}  </p>
                                  :
                                  <p>Starts: {moment(com_course.start_date).format("MMM DD,YYYY h:mmA")} - Ends: {moment(com_course.end_date).format("MMM DD,YYYY h:mmA")}  </p>
                              }


                            </div>
                            <div class="dash-mycourses-module-bot">
                              <Link className="btn btn-default dash-mycourses-module-bot-bt-out" onClick={(e) => this.acceptLesson(com_course.id, com_course.class_data.title, com_course.class_data.class_teacher.first_name + " " + com_course.class_data.class_teacher.last_name, this.utcToUsaTimeConvert(com_course.start_date))}>Accept</Link>
                              <Link className="btn " onClick={(e) => this.rejectLesson(com_course.id, com_course.class_data.title, com_course.class_data.class_teacher.first_name + " " + com_course.class_data.class_teacher.last_name, this.utcToUsaTimeConvert(com_course.start_date))}>Reject</Link>
                            </div>
                          </div>
                        </>
                      ))
                        : ""
                    }
                    <div class="clearfix"></div>
                    {
                      (this.state.lessons_list_request.data.length > 0)
                        ?
                        <div class="row text-right">
                          <Pagination
                            activePage={this.state.lessons_list_request.page}
                            itemsCountPerPage={this.state.lessons_list_request.per_page}
                            totalItemsCount={this.state.lessons_list_request.total}
                            pageRangeDisplayed={this.state.lessons_list_request.total_pages}
                            onChange={this.handlePageChangeCompleted.bind(this)}
                          />
                        </div>
                        :
                        <div class="dash-mycourses-module">
                          <p>No new lesson request has been found.</p>
                        </div>
                    }
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>

        {this.state.alert}
        {this.state.deleteAlert}
        {this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div>

    );
  }

}
export default MyLessons;
