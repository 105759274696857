import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import './App.css';

//import ProtectedRoute from './Route/ProtectedRoute';

import Landing from './Components/Landing';
import ImpersonateUser from './Components/ImpersonateUser';

import TeacherStepOne from './Components/TeacherRegistration/TeacherStepOne';
import TeacherStepTwo from './Components/TeacherRegistration/TeacherStepTwo';
import TeacherStepThree from './Components/TeacherRegistration/TeacherStepThree';
import TeacherStepFour from './Components/TeacherRegistration/TeacherStepFour';
import TeacherStepFive from './Components/TeacherRegistration/TeacherStepFive';
import TeacherStepSix from './Components/TeacherRegistration/TeacherStepSix';
import TeacherChangePassword from './Components/TeacherProfile/TeacherChangePassword';

import StudentStepOne from './Components/StudentRegistration/StudentStepOne';
import StudentStepTwo from './Components/StudentRegistration/StudentStepTwo';
import StudentStepThree from './Components/StudentRegistration/StudentStepThree';

import StudentProfile from './Components/Student/StudentProfile';
import StudentCourses from './Components/Student/MyCourses';
import StudentLessons from './Components/Student/MyLessons';
import StudentTeachers from './Components/Student/MyTeachers';
import StudentCalender from './Components/Student/MyCalender';
import StudentFinancialCenter from './Components/Student/StudentFinancialCenter';
import StudentAddMoneyWallet from './Components/Student/StudentAddMoneyWallet';
import StudentWithdrawalRequest from './Components/Student/StudentWithdrawalRequest';
import StudentChangePassword from './Components/Student/StudentChangePassword';
import StudentProfileEdit from './Components/Student/StudentProfileEdit';

import TeacherCourses from './Components/Teacher/MyCourses';
import TeacherLessons from './Components/Teacher/MyLessons';
import TeacherStudents from './Components/Teacher/MyStudents';
import TeacherCalender from './Components/Teacher/MyCalender';
import TeacherMyProfile from './Components/Teacher/MyProfile';
import TeacherFinancialCenter from './Components/Teacher/TeacherFinancialCenter';
import TeacherWithdrawalRequest from './Components/Teacher/TeacherWithdrawalRequest';
import MyNotifications from './Components/MyNotifications';
import StudentVerifyEmail from './Components/StudentVerifyEmail';



import CreateCourse from './Components/Teacher/Courses/CreateCourse';
import EditCourse from './Components/Teacher/Courses/EditCourse';
import EditLesson from './Components/Teacher/Courses/EditLesson';
import CreateLesson from './Components/Teacher/Courses/CreateLesson';
import TeacherCourseDetail from './Components/Teacher/Courses/TeacherCourseDetail';
import TeacherCourseBookingDetail from './Components/Teacher/Courses/TeacherCourseBookingDetail';
import TeacherLessonDetail from './Components/Teacher/Courses/TeacherLessonDetail';
import TeacherLessonBookingDetail from './Components/Teacher/Courses/TeacherLessonBookingDetail';

import TeacherProfile from './Components/Front/TeacherProfile';

import CoursesList from './Components/Front/CoursesList';

import CategoryCoursesList from './Components/Front/CategoryCoursesList';
import CourseListAll from './Components/Front/CourseListAll';

import CourseDetail from './Components/Front/CourseDetail';
import LessonDetail from './Components/Front/LessonDetail';
import CategoryLessonsList from './Components/Front/CategoryLessonsList';
import CourseListCategoryWise from './Components/Front/CourseListCategoryWise';
import CoursesListPopular from './Components/Front/CoursesListPopular';
import CoursesListNew from './Components/Front/CoursesListNew';
import TeacherProfileLessonsList from './Components/Front/TeacherProfileLessonsList';
import TeacherProfileCoursesList from './Components/Front/TeacherProfileCoursesList';
import TeachersFind from './Components/Front/TeachersFind';
import TermsComponent from './Components/Front/TermsComponent';
import PrivacyPolicy from './Components/Front/PrivacyPolicy';
import AboutUs from './Components/Front/AboutUs';
import IntroductionVideoGuidelines from './Components/Front/IntroductionVideoGuidelines';
import ContactUs from './Components/Front/ContactUs';
import ResetPassword from './Components/Front/ResetPassword';

import LessonsPrivateList from './Components/Front/LessonsPrivateList';
import MyConversation from './Components/Conversation/MyConversation'
import MyConversationTeacher from './Components/Conversation/MyConversationTeacher'

import MyConversationMessage from './Components/Conversation/MyConversationMessage'
import MyConversationTeacherMessage from './Components/Conversation/MyConversationTeacherMessage'
import TeacherAccountSettings from './Components/TeacherProfile/TeacherAccountSettings';
import StudentAccountSettings from './Components/Student/StudentAccountSetting';
import Faq from './Components/Front/Faq';
import PaypalAuth from './Components/PaypalAuth';

import AppFaq from './Components/Front/AppFaq';
import AppPrivacyPolicy from './Components/Front/AppPrivacyPolicy';
import AppAboutUs from './Components/Front/AppAboutUs';
import AppTermsComponent from './Components/Front/AppTermsComponent';


class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/paypal-auth" component={PaypalAuth} />

            <Route path="/teacher/stepone" component={TeacherStepOne} />
            <Route path="/teacher/steptwo" component={TeacherStepTwo} />
            <Route path="/teacher/stepthree" component={TeacherStepThree} />
            <Route path="/teacher/stepfour" component={TeacherStepFour} />
            <Route path="/teacher/stepfive" component={TeacherStepFive} />
            <Route path="/teacher/stepsix" component={TeacherStepSix} />
            <Route path="/teacher/change-password" component={TeacherChangePassword} />
            <Route path="/teacher/account-setting" component={TeacherAccountSettings} />

            <Route path="/student/profile" component={StudentProfile} exact />
            <Route path="/student/profile/edit" component={StudentProfileEdit} exact />
            <Route path="/student/stepone" component={StudentStepOne} />
            <Route path="/student/steptwo" component={StudentStepTwo} />
            <Route path="/student/stepthree" component={StudentStepThree} />

            <Route path="/student/courses" component={StudentCourses} />
            <Route path="/student/lessons" component={StudentLessons} />
            <Route path="/student/teachers" component={StudentTeachers} />
            <Route path="/student/mycalender" component={StudentCalender} />
            <Route path="/student/financialcenter" component={StudentFinancialCenter} />
            <Route path="/student/addmoneywallet" component={StudentAddMoneyWallet} />
            <Route path="/student/withdrawalmoney" component={StudentWithdrawalRequest} />

            <Route path="/student/change-password" component={StudentChangePassword} />
            <Route path="/student/account-setting" component={StudentAccountSettings} />

            <Route path="/teacher/courses" component={TeacherCourses} />
            <Route path="/teacher/lessons" component={TeacherLessons} />
            <Route path="/teacher/students" component={TeacherStudents} />
            <Route path="/teacher/mycalendar" component={TeacherCalender} />
            <Route path="/teacher/myprofile" component={TeacherMyProfile} />
            <Route path="/teacher/financialcenter" component={TeacherFinancialCenter} />
            <Route path="/teacher/withdrawalrequest" component={TeacherWithdrawalRequest} />

            <Route path="/teacher/createcourse" component={CreateCourse} />
            <Route path="/teacher/editcourse/:slug" component={EditCourse} />
            <Route path="/teacher/editlesson/:slug" component={EditLesson} />
            <Route path="/teacher/createlesson" component={CreateLesson} />
            <Route path="/teacher/course/:slug" component={TeacherCourseDetail} />
            <Route path="/teacher/course-booking/:slug" component={TeacherCourseBookingDetail} />
            <Route path="/teacher/lesson/:slug" component={TeacherLessonDetail} />
            <Route path="/teacher/lesson-booking/:slug" component={TeacherLessonBookingDetail} />
            <Route path="/myconversation/message/:message_id" component={MyConversationMessage} exact />
            <Route path="/myconversation" component={MyConversation} exact />
            <Route path="/myconversationteacher" component={MyConversationTeacher} exact />
            <Route path="/myconversationteacher/message/:message_id" component={MyConversationTeacherMessage} exact />
            <Route path="/courses" component={CoursesList} exact />
            <Route path="/courses/popular" component={CoursesListPopular} exact />
            <Route path="/courses/new" component={CoursesListNew} exact />
            <Route path="/lessons/private" component={LessonsPrivateList} exact />
            <Route path="/front/categorycourseslist" component={CategoryCoursesList} />
            <Route path="/coursedetail/:slug" component={CourseDetail} />
            <Route path="/category/:category_id/:category_slug" component={CourseListCategoryWise} />
            <Route path="/courses/all" component={CourseListAll} />
            <Route path="/front/lessondetail" component={LessonDetail} />
            <Route path="/front/categorylessonslist" component={CategoryLessonsList} />

            <Route path="/teacher/:slug" component={TeacherProfile} exact={true} />
            <Route path="/teacher/:slug/lessons"
              component={TeacherProfileLessonsList}
              exact={true}
            />
            <Route path="/teacher/:slug/courses"
              component={TeacherProfileCoursesList}
              exact={true}
            />

            <Route path="/teachers/find/"
              component={TeachersFind}
              exact={true}
            />

            <Route path="/notifications" component={MyNotifications} />
            <Route path="/student/verify-email/:email_token" component={StudentVerifyEmail} />
            <Route path="/terms-and-conditions" component={TermsComponent} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/introduction-video-guidelines" component={IntroductionVideoGuidelines} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/faq" component={Faq} />

            <Route path="/impersonate/:token" component={ImpersonateUser} />

            <Route path="/app-faq" component={AppFaq} />
            <Route path="/app-privacy-policy" component={AppPrivacyPolicy} />
            <Route path="/app-about-us" component={AppAboutUs} />
            <Route path="/app-terms-and-conditions" component={AppTermsComponent} />


          </Switch>
        </Router>
      </div>

    );
  }
}

export default App;
