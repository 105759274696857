import React from 'react';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';

class AppAboutUs extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div class="coursespage-section">
          <div class="terms">
            <div class="container">

              <h3>About Us</h3>

              {/* <h5>Aliquam ut dictum turpis</h5> */}
              <p>Founded in 2002, Xtenfer Consulting Inc. (XCI) DBA as CONFERENCEME is an IT firm specializing in education, training, and management consulting providing customized education and training services and products to high performing commercial and government clients. </p>

              <p>The CONFERENCEME team is comprised of accomplished global experts with multi-disciplinary backgrounds in both synchronous and asynchronous education, our depth of IT expertise provides us the capability, flexibility, and agility to rapidly develop and deploy tailored functional resolutions to complex problems. </p>
              <p>CONFERENCEME leverages proven experience and expertise in conjunction with a global network of talented professionals to offer an unmatched training and education platform designed specifically for online live instructions and supported by our 24/7/365 live Helpdesk.  </p>

              <p>The CONFERENCEME platform is user empathetic with focus on time efficiency making it easy to harness the creativity of the diverse teaching and learning styles and backgrounds by equipping teachers and students with the right tools necessary to facilitate a successful and enjoyable learning experience.  Our team’s shared values foster a community driven by a common goal of making teaching and learning universal, accessible, and affordable. Whether you are looking to learn a new skill, prepare for an exam, learn a new language, or get help with any subject, CONFERENCEME’s platform will empower you to do so safely and with ease.</p>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AppAboutUs;