import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';
import TeacherRegistrationSidebar from '../Elements/TeacherRegistrationSidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import moment from 'moment';

class TeacherStepSix extends React.Component {
    constructor() {
        super();
        this.state = {
            date: new Date(),

            dataExperience: [],

            certificateNameData: [],
            certificateDateData: [],
            certificateDateDataToSend: [],
            issuedByData: [],
            descriptionData: [],
            fromWork: [],
            toWork: [],
            uploadedDocumentFinalString: [],

            page_type: 'add',

            stepselector: 'stepsix'
        };

    }

    componentDidMount() {
        this.getTeacherInformation();
    }

    completeLater = async (e) => {
        window.location.href = '/teacher/courses';
    }

    getTeacherInformation = async () => {
        const token = localStorage.token;

        this.setState({
            loading: 1
        });
        const response = axios.get(
            window.$API_URL + 'teacher/teacherprofileget',
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then(async (response) => {
            this.setState({
                loading: 0
            });

            var typeEducation = response.data.response.type_certificate;
            var typeEducationDt = response.data.response.type_certificate;
            var inc = 0;
            if (typeEducationDt.length > 0) {
                typeEducationDt.map(temp_value => {
                    this.state.dataExperience.push(inc);
                    this.state.certificateNameData.push(temp_value.name);
                    this.state.issuedByData.push(temp_value.issued_by);
                    this.state.descriptionData.push(temp_value.description);
                    this.state.certificateDateData.push(new Date(temp_value.from_date));
                    this.state.certificateDateDataToSend.push(temp_value.from_date);
                    this.state.uploadedDocumentFinalString.push(temp_value.document);
                    inc++;
                });
                this.setState({ page_type: 'edit' });
            } else {
                this.state.dataExperience.push(inc);
            }

            this.setState({
                dataExperience: this.state.dataExperience,
                certificateNameData: this.state.certificateNameData,
                issuedByData: this.state.issuedByData,
                descriptionData: this.state.descriptionData,
                certificateDateData: this.state.certificateDateData,
                certificateDateDataToSend: this.state.certificateDateDataToSend,
                uploadedDocumentFinalString: this.state.uploadedDocumentFinalString,
            });


        }).catch((error) => {
            console.log(error);
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data.response).forEach((key) => {
                        this.state.returnError.push(error.response.data.response[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
                }

            }
            this.setState({
                loading: 0
            });
        })
    }

    appendChildExperience = (e) => {
        e.preventDefault();
        let { dataExperience } = this.state;
        dataExperience.push(dataExperience.length);
        this.setState({ dataExperience });
    }


    updateDescriptionData = (valueDescription, idNum) => {
        if (valueDescription != "") {
            this.state.descriptionData[idNum] = valueDescription;
        } else {
            this.state.descriptionData.splice(idNum, 1);
        }
        this.setState({
            descriptionData: this.state.descriptionData
        });
    }

    updateCertificateDateData = (value, idNum) => {
        var date = moment(value).format("YYYY-MM-DD");
        if (value != "") {
            this.state.certificateDateData[idNum] = value;
            this.state.certificateDateDataToSend[idNum] = date;

        }
        else {
            this.state.certificateDateData.splice(idNum, 1);
            this.state.certificateDateDataToSend.splice(idNum, 1);
        }

        this.setState({
            certificateDateData: this.state.certificateDateData,
            certificateDateDataToSend: this.state.certificateDateDataToSend
        });

    }

    updateIssuedByData = (value, idNum) => {
        if (value != "") {
            this.state.issuedByData[idNum] = value;

        }
        else {
            this.state.issuedByData.splice(idNum, 1);
        }

        this.setState({
            issuedByData: this.state.issuedByData,
        });
    }

    updateCertificateNameData = (value, idNum) => {
        if (value != "") {
            this.state.certificateNameData[idNum] = value;

        }
        else {
            this.state.certificateNameData.splice(idNum, 1);
        }
        this.setState({
            certificateNameData: this.state.certificateNameData,
        });
    }

    documentUploadHandler = async (event, i) => {
        const token = localStorage.token;
        const data = new FormData();
        data.append('file', event);
        this.setState({
            loading: 1
        });
        const response = axios.post(
            window.$API_URL + 'teacher/teacherprofileupdatefourdocumentuploads',
            data,
            { headers: { 'Content-Type': '', 'x-access-token': `${token}` } }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            if (response.data.filename) {
                this.state.uploadedDocumentFinalString[i] = response.data.filename;
                this.setState({
                    uploadedDocumentFinalString: this.state.uploadedDocumentFinalString
                });
            } else {
                NotificationManager.error("Some Error Occured while uploading the Document", 'Upload Error');
            }
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            NotificationManager.error("File Cannot be uploaded!! Please try again.", 'Upload Error');
        });
    }

    removeChild = (e, value) => {
        e.preventDefault();
        var index = this.state.dataExperience.indexOf(value);
        if (index !== -1) {
            this.state.dataExperience.pop();
        }


        var certificateNameData = this.state.certificateNameData;
        if (value in certificateNameData) {
            this.state.certificateNameData.splice(value, 1);
        }

        var issuedByData = this.state.issuedByData;
        if (value in issuedByData) {
            this.state.issuedByData.splice(value, 1);
        }

        var descriptionData = this.state.descriptionData;
        if (value in descriptionData) {
            this.state.descriptionData.splice(value, 1);
        }

        var certificateDateData = this.state.certificateDateData;
        if (value in certificateDateData) {
            this.state.certificateDateData.splice(value, 1);
        }

        var certificateDateDataToSend = this.state.certificateDateDataToSend;
        if (value in certificateDateDataToSend) {
            this.state.certificateDateDataToSend.splice(value, 1);
        }


        var uploadedDocumentFinalString = this.state.uploadedDocumentFinalString;
        if (value in uploadedDocumentFinalString) {
            this.state.uploadedDocumentFinalString.splice(value, 1);
        }




        this.setState({
            dataExperience: this.state.dataExperience,
            certificateNameData: this.state.certificateNameData,
            issuedByData: this.state.issuedByData,
            descriptionData: this.state.descriptionData,
            certificateDateData: this.state.certificateDateData,
            certificateDateDataToSend: this.state.certificateDateDataToSend,
            documentUploads: this.state.documentUploads,
            uploadedDocumentFinalString: this.state.uploadedDocumentFinalString,

        });

    }

    processStepSix = async (e) => {
        const access_token = localStorage.getItem('token');
        this.setState({
            loading: 1
        });
        await axios.post(
            window.$API_URL + 'teacher/teacherprofileupdatesix',
            {
                certificateNameData: this.state.certificateNameData,
                certificateDateData: this.state.certificateDateDataToSend,
                issuedByData: this.state.issuedByData,
                descriptionData: this.state.descriptionData,
                documentUploads: this.state.uploadedDocumentFinalString
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': `${access_token}`,
                }
            }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            NotificationManager.success('Your certificate information has been updated successfully.', 'Success');
            if (this.state.page_type == 'add') {
                setTimeout(() => {
                    window.location.href = '/teacher/courses';
                }, 500);
            } else {
                setTimeout(() => {
                    window.location.href = '/teacher/myprofile';
                }, 500);
            }
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (error.response.status === 422) {
                this.state.returnError = [];
                Object.keys(error.response.data.response).forEach((key) => {
                    this.state.returnError.push(error.response.data.response[key]);
                });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error(error.response.data.message, 'Validation Error');
            }
        });
    }

    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="signupspace">
                        <div class="signupspace-ttl">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-8">
                                    {this.state.page_type == 'add' ? <h3><b>Sign up</b> Teacher account</h3> : <h3><b>Update</b> Teacher account</h3>}
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">
                                    <Link onClick={this.completeLater} >Skip <span class="hidden-xs">and complete later</span> <i class="zmdi zmdi-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <TeacherRegistrationSidebar step={this.state.stepselector} />
                            </div>

                            <div class="col-md-9 col-sm-8">
                                <div class="signup-maincontent">
                                    {this.state.dataExperience.map(id => (
                                        <Certificate id={id} updateCertificateNameData={this.updateCertificateNameData} updateIssuedByData={this.updateIssuedByData} updateCertificateDateData={this.updateCertificateDateData} updateDescriptionData={this.updateDescriptionData} documentUploadHandler={this.documentUploadHandler} certificateDateData={this.state.certificateDateData}
                                            certificateNameData={this.state.certificateNameData}
                                            issuedByData={this.state.issuedByData}
                                            descriptionData={this.state.descriptionData}
                                            certificateDateDataToSend={this.state.certificateDateDataToSend}
                                            removeChild={this.removeChild}
                                            uploadedDocumentFinalString={this.state.uploadedDocumentFinalString}
                                        />
                                    ))}

                                    <Link onClick={this.appendChildExperience} ><i class="zmdi zmdi-plus-circle-o"></i> Add more certificates</Link>


                                    <div class="signup-maincontent-footer">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                            {/* <Link to="/teacher/stepfive"><i class="zmdi zmdi-long-arrow-left"></i> Back</Link> */}
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                                <Button onClick={this.processStepSix} variant="default" className="bt-custom ">Next</Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>


                <Footer />
                {this.state.loading ? <LoadingSpinner /> : ''}
            </div>

        );
    }

}




const Certificate = ({ id, updateCertificateNameData, updateIssuedByData, updateCertificateDateData, updateDescriptionData,
    documentUploadHandler, certificateDateData, uploadedDocumentFinalString, certificateNameData, issuedByData, descriptionData, certificateDateDataToSend, removeChild }) => (

        <div className="row">
            {(id > 0) ? <a href="#" onClick={(e) => removeChild(e, id)} className="pull-right close-redicon" >x</a> : ""}

            <Form.Group>
                <Form.Control type="text" value={certificateNameData[id]} onChange={(e) => updateCertificateNameData(e.target.value, id)} placeholder="Certificate title" />
            </Form.Group>

            <Form.Group>
                <Form.Control type="text" value={issuedByData[id]} onChange={(e) => updateIssuedByData(e.target.value, id)} placeholder="Issued by" />
            </Form.Group>

            <Form.Group>
                <DatePicker
                    onChange={(e) => updateCertificateDateData(e, id)}
                    selected={certificateDateData[id]}
                    className="form-control"
                    wrapperClassName="fullWidth"
                    placeholderText="Certification date"
                />
            </Form.Group>

            <Form.Group>
                <Form.Control value={descriptionData[id]} onChange={(e) => updateDescriptionData(e.target.value, id)} as="textarea" rows="3" placeholder="Description">

                </Form.Control>
            </Form.Group>

            <div class="signup-education-upload">
                <label className="btn bt-custom">
                    Upload document <input type="file" style={{ display: 'none' }} onChange={(e) => documentUploadHandler(e.target.files[0], id)} />
                </label>
                <span className="download_doc_link">{(uploadedDocumentFinalString[id]) ? <a href={`${window.$HOST_URL}api/chatattachment/${uploadedDocumentFinalString[id]}`}><i className="fa fa-2x fa-paperclip" aria-hidden="true"></i></a> : ''}</span>
            </div>

            <div class="signup-education-separator">

            </div>
        </div>


    );


export default TeacherStepSix;
