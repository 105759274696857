import React from 'react';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


class Faq extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="terms">
            <div class="container">

              <h3>Frequently Asked Questions (FAQ)</h3>

              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I edit my profile/change my Time zone?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little Arrow Head -> select Profile -> Edit Profile </p>
                    <div class="">
                      <img src="img/image-cfme-2.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I change/reset my password?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little arrow head and select Change Password  </p>

                    <div class="">
                      <img src="img/image-cfme-1.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I deactivate my account?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little arrow head and select Account Settings  </p>


                    <div class="">
                      <img src="img/web/student_deactivate.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I change my Privacy Settings?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little arrow head and select Account Settings  </p>



                    <div class="">
                      <img src="img/web/student_privacy.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I change my Notification settings?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div class="">
                      <img src="img/web/student_notifications.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <h4 style={{ marginLeft: "2em" }}>Instructor Account and Profile </h4>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I change my Notification settings?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div class="">
                      <img src="img/web/student_notifications.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I change my Time zone? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div class="">
                      <img src="img/web/teacher_timezone1.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                    <br />
                    <div class="">
                      <img src="img/web/teacher_timezone2.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I change my Notification settings?  </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little arrow head and select Account Settings  </p>

                    <div class="">
                      <img src="img/web/teacher_notification.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I change/reset my password?   </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little arrow head and select Account Settings  </p>

                    <div class="">
                      <img src="img/web/teacher_reset_password.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I change my Privacy Settings?  </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little arrow head and select Account Settings  </p>

                    <div class="">
                      <img src="img/web/teacher_privacy.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I add my lessons or courses?  </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Dashboard in the top bar and click the Add Course button to add a new course and navigate to My Lessons to add a new lesson.   </p>

                    <div class="">
                      <img src="img/web/teacher_add_course.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I edit my availability? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Dashboard in the top bar and then click on the Calender in the sidebar annd then you can drag mouse on the time to select your availability. </p>

                    <div class="">
                      <img src="img/web/teacher_set_availability.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I edit or add to my Work Experience, Education, or Certificates? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Navigate to your Profile Picture on the top right corner and click the little arrow head and select Profile. Scroll down to bottom and click on the Edit links on section you want to update.  </p>

                    <div class="">
                      <img src="img/web/teacher_education_certification.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I add more lessons in different languages? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p> You can always choose the language in each lesson or course you create. So in this way the lesson can be in same or different language as per your requirement.</p>

                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How are teacher ratings calculated?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p> Ratings are calculated based on the ratings received from students after the completion of the lessons.</p>

                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How can I deactivate my teacher profile? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div class="">
                      <img src="img/web/teacher_deactivate.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How do I get paid?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul>
                      <li>✓ Account </li>
                      <li>✓ Paypal </li>
                      <li>✓ Apple Pay</li>
                    </ul>
                    <br />
                    <div class="">
                      <img src="img/web/teacher_withdrawal_1.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                    <br />
                    <div class="">
                      <img src="img/web/teacher_withdrawal_1.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                    <br />

                    <div class="">
                      <img src="img/web/teacher_withdrawal_2.png" class="img-responsive" alt="" style={{ width: "50%" }} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion>










            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Faq;