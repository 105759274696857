import React from 'react';
import { Modal, Form, InputGroup, Button } from 'react-bootstrap';
import { Link, Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getProfileInformation } from '../../Utils/Utils';
import { connect } from 'react-redux';
import LoadingSpinner from '../Elements/LoadingSpinner';
import { useLocation } from 'react-router-dom'

class Header extends React.Component {
  constructor(props) {
    
    if (props.location.pathname == "/") {
      document.body.classList.remove('body-lightgrey');
    } else {
      document.body.classList.add('body-lightgrey');
    }
    super();
    this.state = {
      student_show: 'active',
      teacher_show: '',

      email: '',
      password: '',
      login_terms: false,

      student_email: '',
      student_password: '',
      student_cpassword: '',
      student_terms: '',

      teacher_email: '',
      teacher_password: '',
      teacher_cpassword: '',
      teacher_terms: '',

      forgot_email: '',

      is_menu_outer_open: false,

      loading: 0
    };
  }

  async componentDidMount() {
    const user_check_api = await getProfileInformation();
    if (user_check_api !== null) {
      this.props.dispatch({ type: 'PROFILE_DATA_UPDATE', payload: user_check_api });
    }
  }

  showLogin = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: true });
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
    this.props.dispatch({ type: 'FORGOT_SHOW', payload: false });
  }

  showSignup = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
    this.props.dispatch({ type: 'FORGOT_SHOW', payload: false });
  }

  showForgot = (e) => {
    e.preventDefault();
    this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
    this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
    this.props.dispatch({ type: 'FORGOT_SHOW', payload: true });
  }

  handleCheckClick = () => {
    this.setState({ login_terms: !this.state.login_terms });
  }

  handleCheckClickStudentRegister = () => {
    this.setState({ student_terms: !this.state.student_terms });
  }

  handleCheckClickTeacherRegister = () => {
    this.setState({ teacher_terms: !this.state.teacher_terms });
  }
  logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  loginUser = async () => {

    this.setState({
      loading: 1
    });

    const response = await axios.post(
      window.$API_URL + 'login',
      {
        'email': this.state.email,
        'password': this.state.password,
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then((response) => {
      this.setState({
        loading: 0
      });
      if (response.data.response.is_email_verified === 0) {
        localStorage.setItem("email", response.data.response.email);
        localStorage.setItem("first_name", response.data.response.first_name);
        localStorage.setItem("last_name", response.data.response.last_name);
        localStorage.setItem("is_email_verified", response.data.response.is_email_verified);
        this.setState({
          isEmailNotVefified: true
        });
      } else {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", response.data.response.email);
        localStorage.setItem("first_name", response.data.response.first_name);
        localStorage.setItem("last_name", response.data.response.last_name);
        localStorage.setItem("is_email_verified", response.data.response.is_email_verified);
        localStorage.setItem("is_profile_complete", response.data.response.is_profile_complete);
        localStorage.setItem("user_type_id", response.data.response.user_type_id);
        if (response.data.response.user_type_id === 2) {
          localStorage.setItem("is_introduction_complete", response.data.response.is_introduction_complete);
          localStorage.setItem("is_video_introduction_complete", response.data.response.is_video_introduction_complete);
          localStorage.setItem("is_background_one_complete", response.data.response.is_background_one_complete);
          localStorage.setItem("is_background_two_complete", response.data.response.is_background_two_complete);
          localStorage.setItem("is_background_three_complete", response.data.response.is_background_three_complete);
        }

        this.props.dispatch({ type: 'IS_LOGGED', payload: true });
        this.props.dispatch({ type: 'USER_TYPE_ID', payload: response.data.response.user_type_id });

        if (response.data.response.is_deactivate === 1) {

          if (response.data.response.user_type_id === 1) {
            if (response.data.response.is_profile_complete === 0) {
              setTimeout(() => {
                window.location.href = '/student/stepone';
              }, 2000);
            } else {
              if (this.props.location.pathname === '/') {
                setTimeout(() => {
                  window.location.href = '/student/courses';
                }, 3000);
              } else {
                window.location.reload();
              }

            }
          } else {
            if (response.data.response.is_profile_complete === 0) {
              setTimeout(() => {
                window.location.href = '/teacher/stepone';
              }, 2000);
            } else if (response.data.response.is_introduction_complete === 0) {
              setTimeout(() => {
                window.location.href = '/teacher/steptwo';
              }, 2000);
            } else if (response.data.response.is_video_introduction_complete === 0) {
              setTimeout(() => {
                window.location.href = '/teacher/stepthree';
              }, 2000);
            } else if (response.data.response.is_background_one_complete === 0) {
              setTimeout(() => {
                window.location.href = '/teacher/stepfour';
              }, 2000);
            } else if (response.data.response.is_background_two_complete === 0) {
              setTimeout(() => {
                window.location.href = '/teacher/stepfive';
              }, 2000);
            } else if (response.data.response.is_background_three_complete === 0) {
              setTimeout(() => {
                window.location.href = '/teacher/stepsix';
              }, 2000);
            } else {
              setTimeout(() => {
                window.location.href = '/teacher/courses'
              }, 2000);;
            }
          }

          NotificationManager.success('Welcome Back. ', 'Success');

        }




        if (response.data.response.user_type_id === 1) {
          if (response.data.response.is_profile_complete === 0) {
            window.location.href = '/student/stepone';
          } else {
            if (this.props.location.pathname === '/') {
              window.location.href = '/student/courses';
            } else {
              window.location.reload();
            }

          }
        } else {
          if (response.data.response.is_profile_complete === 0) {
            window.location.href = '/teacher/stepone';
          } else if (response.data.response.is_introduction_complete === 0) {
            window.location.href = '/teacher/steptwo';
          } else if (response.data.response.is_video_introduction_complete === 0) {
            window.location.href = '/teacher/stepthree';
          } else if (response.data.response.is_background_one_complete === 0) {
            window.location.href = '/teacher/stepfour';
          } else if (response.data.response.is_background_two_complete === 0) {
            window.location.href = '/teacher/stepfive';
          } else if (response.data.response.is_background_three_complete === 0) {
            window.location.href = '/teacher/stepsix';
          } else {
            window.location.href = '/teacher/courses';
          }
        }


      }
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
        else if (error.response.status === 401) {
          this.state.returnError = [];
          this.state.returnError.push(error.response.data);
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
      }
    });
  }

  forgotPassword = async () => {
    const response = await axios.post(
      window.$API_URL + 'forgotpassword',
      {
        'email': this.state.forgot_email
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then((response) => {
      NotificationManager.success('Success', 'An email has been sent to your email on the account to reset your password.', 4000, () => {
        //window.location.reload();
      });
      setTimeout(() => window.location = "/", 4000);
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
        else if (error.response.status === 401) {
          this.state.returnError = [];
          this.state.returnError.push(error.response.data);
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
      }
    });
  }

  signupStudent = async () => {
    if (!this.state.student_terms) {
      NotificationManager.error("Please accept terms and conditions.", 'Validation Error');
      return;
    }
    this.setState({
      loading: 1
    });
    const response = await axios.post(
      window.$API_URL + 'register',
      {
        'email': this.state.student_email,
        'password': this.state.student_password,
        'confirm_password': this.state.student_cpassword,
        'user_type': 1
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then((response) => {
      this.setState({
        loading: 0
      });

      NotificationManager.success('Success', response.data.message, 4000, () => {
        //  window.location.reload();
      });
      setTimeout(() => window.location = "/", 2000);
      this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
        else if (error.response.status === 401) {
          this.state.returnError = [];
          this.state.returnError.push(error.response.data);
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
      }
    });
  }

  signupTeacher = async () => {
    if (!this.state.teacher_terms) {
      NotificationManager.error("Please accept terms and conditions.", 'Validation Error');
      return;
    }
    this.setState({
      loading: 1
    });

    const response = await axios.post(
      window.$API_URL + 'register',
      {
        'email': this.state.teacher_email,
        'password': this.state.teacher_password,
        'confirm_password': this.state.teacher_cpassword,
        'user_type': 2
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then((response) => {
      this.setState({
        loading: 0
      });
      NotificationManager.success('Success', response.data.message, 4000, () => {
        //  window.location.reload();
      });
      setTimeout(() => window.location = "/", 2000);
      this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
        else if (error.response.status === 401) {
          this.state.returnError = [];
          this.state.returnError.push(error.response.data);
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        }
      }

    });
  }

  responseFacebook = async (rsp) => {
    if (rsp.status == 'unknown') {
      alert('There was a problem in Facebook Login/Register');
    } else {
      var name = rsp.name;
      var email = rsp.email;
      const response = await axios.post(
        window.$API_URL + 'studentfacebookregister',
        {
          'email': email,
          'name': name,
        },
        { headers: { 'Content-Type': 'application/json' } }
      ).then((response) => {
        if (response.data.is_email_verified === 0) {
        } else {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("first_name", response.data.first_name);
          localStorage.setItem("last_name", response.data.last_name);
          localStorage.setItem("is_email_verified", response.data.is_email_verified);
          localStorage.setItem("is_profile_complete", response.data.is_profile_complete);
          localStorage.setItem("user_type_id", response.data.user_type_id);
          if (response.data.user_type_id === 2) {
            localStorage.setItem("is_introduction_complete", response.data.is_introduction_complete);
            localStorage.setItem("is_video_introduction_complete", response.data.is_video_introduction_complete);
            localStorage.setItem("is_background_one_complete", response.data.is_background_one_complete);
            localStorage.setItem("is_background_two_complete", response.data.is_background_two_complete);
            localStorage.setItem("is_background_three_complete", response.data.is_background_three_complete);
          }
          this.setState({
            isLoggedFb: true,
            userTypeId: response.data.user_type_id
          });

          if (response.data.user_type_id === 2) {
            window.location.href = '/teacher/courses';
          } else {
            if (response.data.is_profile_complete === 0) {
              window.location.href = '/student/stepone';
            } else {
              window.location.href = '/student/courses';
            }
          }

        }
      }).catch((error) => {
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data).forEach((key) => {
              this.state.returnError.push(error.response.data[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          }
          else if (error.response.status === 401) {
            this.state.returnError = [];
            this.state.returnError.push(error.response.data);
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          }
        }
      });
    }
  }

  responseGoogle = async (resp) => {
    console.log(resp);
    if (resp.profileObj !== undefined) {
      var profileObject = resp.profileObj;
      var name = profileObject.name;
      var email = profileObject.email;
      const response = await axios.post(
        window.$API_URL + 'studentgoogleregister',
        {
          'email': email,
          'name': name,
          'access_token': resp.tokenId
        },
        { headers: { 'Content-Type': 'application/json' } }
      ).then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", response.data.response.email);
        localStorage.setItem("first_name", response.data.response.first_name);
        localStorage.setItem("last_name", response.data.response.last_name);
        localStorage.setItem("is_email_verified", response.data.response.is_email_verified);
        localStorage.setItem("is_profile_complete", response.data.response.is_profile_complete);
        localStorage.setItem("user_type_id", response.data.response.user_type_id);
        if (response.data.response.user_type_id === 2) {
          localStorage.setItem("is_introduction_complete", response.data.response.is_introduction_complete);
          localStorage.setItem("is_video_introduction_complete", response.data.response.is_video_introduction_complete);
          localStorage.setItem("is_background_one_complete", response.data.response.is_background_one_complete);
          localStorage.setItem("is_background_two_complete", response.data.response.is_background_two_complete);
          localStorage.setItem("is_background_three_complete", response.data.response.is_background_three_complete);
          window.location.href = '/teacher/courses';
        } else {
          if (response.data.response.is_profile_complete === 0) {
            window.location.href = '/student/stepone';
          } else {
            window.location.href = '/student/courses';
          }
        }
      }).catch((error) => {
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data).forEach((key) => {
              this.state.returnError.push(error.response.data[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          }
          else if (error.response.status === 401) {
            this.state.returnError = [];
            this.state.returnError.push(error.response.data);
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          }
        }
      });
    } else {
      //alert('There was a problem logging in with Google');
    }
  }

  responseGoogleTeacher = async (resp) => {
    console.log(resp);
    if (resp.profileObj !== undefined) {
      var profileObject = resp.profileObj;
      var name = profileObject.name;
      var email = profileObject.email;
      const response = await axios.post(
        window.$API_URL + 'teachersignupgoogle',
        {
          'email': email,
          'name': name,
          'access_token': resp.tokenId
        },
        { headers: { 'Content-Type': 'application/json' } }
      ).then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", response.data.response.email);
        localStorage.setItem("first_name", response.data.response.first_name);
        localStorage.setItem("last_name", response.data.response.last_name);
        localStorage.setItem("is_email_verified", response.data.response.is_email_verified);
        localStorage.setItem("is_profile_complete", response.data.response.is_profile_complete);
        localStorage.setItem("user_type_id", response.data.response.user_type_id);
        if (response.data.response.user_type_id === 2) {
          localStorage.setItem("is_introduction_complete", response.data.response.is_introduction_complete);
          localStorage.setItem("is_video_introduction_complete", response.data.response.is_video_introduction_complete);
          localStorage.setItem("is_background_one_complete", response.data.response.is_background_one_complete);
          localStorage.setItem("is_background_two_complete", response.data.response.is_background_two_complete);
          localStorage.setItem("is_background_three_complete", response.data.response.is_background_three_complete);
          if (response.data.response.is_profile_complete === 0) {
            window.location.href = '/teacher/stepone';
          } else if (response.data.response.is_introduction_complete === 0) {
            window.location.href = '/teacher/steptwo';
          } else if (response.data.response.is_video_introduction_complete === 0) {
            window.location.href = '/teacher/stepthree';
          } else {
            window.location.href = '/teacher/courses';
          }

        } else {
          if (response.data.response.is_profile_complete === 0) {
            window.location.href = '/student/stepone';
          } else {
            window.location.href = '/student/courses';
          }
        }
      }).catch((error) => {
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data).forEach((key) => {
              this.state.returnError.push(error.response.data[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          }
          else if (error.response.status === 401) {
            this.state.returnError = [];
            this.state.returnError.push(error.response.data);
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          }
        }
      });
    } else {
      //alert('There was a problem logging in with Google');
    }
  }


  render() {
    return (
      <>
        <nav class="navbar navbar-default navbar-fixed-top navbar-custom">
          <div class="container">
            <div class="navbar-header">
              <button type="button" onClick={(e) => this.setState({
                is_menu_outer_open: !this.state.is_menu_outer_open
              })} className={(this.state.is_menu_outer_open) ? "navbar-toggle" : 'navbar-toggle collapsed '} class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <Link className="navbar-brand" to="/"><img src="/img/logo.svg" alt="" /></Link>
            </div>
            <div id="navbar" className={(this.state.is_menu_outer_open) ? "navbar-collapse collapse in" : 'navbar-collapse collapse  '} >
              <ul class="nav navbar-nav navbar-right navbar-nav-right">

                {
                  (this.props.profile_data !== null)
                    ?
                    <>
                      <li>{
                        (this.props.profile_data.user_type_id == 1) ?
                          <Link  className={this.props.location.pathname == '/courses' || this.props.location.pathname == '/courses/all' ? 'active' : ''}  to="/courses">Courses</Link> : ''
                      }</li>

                      <li>{
                        (this.props.profile_data.user_type_id == 1) ?
                          <Link className={this.props.location.pathname == '/teachers/find' ? 'active' : ''} to="/teachers/find">Find Teachers</Link> : ''
                      }
                      </li>
                      <li>
                        {
                          (this.props.profile_data.user_type_id == 1) ?
                            <Link to="/student/courses">Dashboard</Link> : <Link to="/teacher/courses">Dashboard </Link>
                        }
                      </li>
                      <li>
                        <Link className={this.props.location.pathname == '/notifications' ? 'active' : ''}  to="/notifications/">Notifications { /*<span class="badge badge-custom">6</span> */}
                        </Link>
                      </li>
                      {
                        (this.props.profile_data !== null)
                          ?
                          (this.props.profile_data.user_type_id == 1)
                            ?
                            <li><Link to="/myconversation/">Messages {/*<span class="badge badge-custom">3</span>*/}	</Link></li>
                            :
                            <li><Link to="/myconversationteacher/">Messages {/*<span class="badge badge-custom">3</span>*/}	</Link></li>
                          :
                          ''
                      }
                      <li class="navbar-custom-internal-profilepic">
                        <div className={(this.state.is_menu_open) ? "btn-group  open" : 'btn-group '}>
                          <Link className="dropdown-toggle" onClick={(e) => this.setState({
                            is_menu_open: !this.state.is_menu_open
                          })}>
                            <img src={
                              (this.props.profile_data !== null)
                                ?
                                (this.props.profile_data.image !== null)
                                  ?
                                  this.props.profile_data.profile_picture
                                  :
                                  '/img/uploadprofilepic.svg'
                                :
                                '/img/uploadprofilepic.svg'
                            } class="img-circle" alt="" /> <i class="fas fa-chevron-down"></i>
                          </Link>
                          <ul class="dropdown-menu">
                            {
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.user_type_id == 1) ?
                                <li><Link to="/student/profile">Profile</Link></li> :
                                <li><Link to="/teacher/myprofile">Profile</Link></li>
                              :
                              ""
                          }
                          {
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.user_type_id == 1) ?
                                <li><Link to="/student/account-setting">Account Settings</Link></li> :
                                <li><Link to="/teacher/account-setting">Account Settings</Link></li>
                              :
                              ""
                          }
                          {/* <li><Link>Account settings</Link></li> */}
                          {
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.user_type_id == 1) ?
                                <li><Link to="/student/financialcenter">Financial Center</Link></li> :
                                <li><Link to="/teacher/financialcenter">Financial Center</Link></li>
                              :
                              ""
                          }
                            { /* <li><Link>Help center</Link></li> */}
                            <li><Link onClick={(e) => this.logout()}>Log out</Link></li>
                          </ul>
                        </div>
                      </li>
                    </>
                    :
                    <>
                      <li><Link  className={this.props.location.pathname == '/courses' || this.props.location.pathname == '/courses/all' ? 'active' : ''} to="/courses">Courses</Link></li>
                      <li><Link className={this.props.location.pathname == '/teachers/find' ? 'active' : ''} to="/teachers/find">Find Teachers</Link></li>
                      <li><Link onClick={(e) => this.showLogin(e)} >Login</Link></li>
                      <li><Link className="visible-xs" onClick={(e) => this.showSignup(e)} >Sign up for free</Link></li>
                      <li>
                        <Link className="btn btn-default bt-custom nav-bt-special hidden-xs" onClick={(e) => this.showSignup(e)} >Sign up for free</Link>
                      </li>
                    </>
                }

              </ul>
            </div>
          </div>
        </nav>


        <Modal
          animation={false}
          show={this.props.register_show}
          className="modal-signup"
          onHide={(e) => this.props.dispatch({
            type: 'REGISTER_SHOW',
            payload: false,
          })}
        >
          <Modal.Header closeButton>
          </Modal.Header>

          <Modal.Body>
            <h3>Create account</h3>
            <ul class="nav nav-tabs modal-signup-tabs" role="tablist">

              <li className={(!this.props.register_force_teacher_show) ? this.state.student_show : ''} >
                <Link onClick={(e) => {
                  this.setState({ student_show: "active", teacher_show: "" })
                  this.props.dispatch({
                    type: 'REGISTER_FORCE_TEACHER_SHOW',
                    payload: false,
                  });
                }} >Student</Link>
              </li>

              <li className={(!this.props.register_force_teacher_show) ? this.state.teacher_show : 'active'} >
                <Link onClick={(e) => this.setState({ student_show: "", teacher_show: "active" })} >Teacher</Link>
              </li>

            </ul>

            <div class="tab-content">
              <div role="tabpanel" className={((!this.props.register_force_teacher_show) ? this.state.student_show : '') + " tab-pane"} id="student">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" onChange={(e) => this.setState({ student_email: e.target.value })} value={this.state.student_email} placeholder="Enter email" />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Control type="password" onChange={(e) => this.setState({ student_password: e.target.value })} value={this.state.student_password} placeholder="Password" />
                </Form.Group>

                <Form.Group controlId="formConfirmPassword">
                  <Form.Control type="password" onChange={(e) => this.setState({ student_cpassword: e.target.value })} value={this.state.student_cpassword} placeholder="Confirm Password" />
                </Form.Group>

                <div class="checkbox">
                  <label>
                    <InputGroup.Checkbox checked={this.state.student_terms} onChange={this.handleCheckClickStudentRegister} className="check-square" aria-label="Checkbox for following text input" />
                  Accept <Link target="_blank" to="/terms-and-conditions">terms & conditions</Link>
                  </label>
                </div>

                <Button variant="default" onClick={this.signupStudent} className={"bt-custom btn-block"}>Sign up as a student</Button>
                <GoogleLogin
                  clientId="1078526800840-16t4g1nptovelk4vsp7va2bs19qqgg6b.apps.googleusercontent.com"
                  //buttonText=" Login with Google"
                  //icon={false}
                  // disabledStyle={true}
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  //className={"bt-custom btn-block modal-signup-google-bt"}
                  //cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <a
                      class="btn btn-default bt-custom btn-block modal-signup-google-bt"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div class="pull-left"><i class="fab fa-google"></i></div>
		                  Sign up with Google
                    </a>
                  )}
                />
                <Button variant="default" className={"bt-custom btn-block modal-signup-facebook-bt"}>
                  <div class="pull-left"><i class="fab fa-google"></i></div>
                                Sign up with Facebook
                            </Button>

                <div class="modal-signup-footer">Already have a student account? <Link onClick={(e) => this.showLogin(e)}>Log in</Link></div>

              </div>

              <div role="tabpanel" className={((!this.props.register_force_teacher_show) ? this.state.teacher_show : 'active') + " tab-pane"} id="teacher">
                <Form.Group controlId="formBasicEmailTeacher">
                  <Form.Control type="email" onChange={(e) => this.setState({ teacher_email: e.target.value })} value={this.state.teacher_email} placeholder="Enter email" />
                </Form.Group>

                <Form.Group controlId="formPasswordTeacher">
                  <Form.Control type="password" onChange={(e) => this.setState({ teacher_password: e.target.value })} value={this.state.teacher_password} placeholder="Password" />
                </Form.Group>

                <Form.Group controlId="formConfirmPasswordTeacher">
                  <Form.Control type="password" onChange={(e) => this.setState({ teacher_cpassword: e.target.value })} value={this.state.teachert_cpassword} placeholder="Confirm Password" />
                </Form.Group>

                <div class="checkbox">
                  <label>
                    <InputGroup.Checkbox checked={this.state.teacher_terms} onChange={this.handleCheckClickTeacherRegister} className="check-square" aria-label="Checkbox for following text input" />
                                    Accept <Link target="_blank" to="/terms-and-conditions">terms & conditions</Link>
                  </label>
                </div>

                <Button variant="default" onClick={this.signupTeacher} className={"bt-custom btn-block"}>Sign up as a teacher</Button>
                <GoogleLogin
                  clientId="1078526800840-16t4g1nptovelk4vsp7va2bs19qqgg6b.apps.googleusercontent.com"
                  onSuccess={this.responseGoogleTeacher}
                  onFailure={this.responseGoogleTeacher}
                  render={renderProps => (
                    <a
                      class="btn btn-default bt-custom btn-block modal-signup-google-bt"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div class="pull-left"><i class="fab fa-google"></i></div>
		                  Sign up with Google
                    </a>
                  )}
                />
                <Button variant="default" className={"bt-custom btn-block modal-signup-facebook-bt"}>
                  <div class="pull-left"><i class="fab fa-google"></i></div>
                                Sign up with Facebook
                            </Button>

                <div class="modal-signup-footer">Already have a teacher account? <Link onClick={(e) => this.showLogin(e)}>Log in</Link></div>

              </div>

            </div>
          </Modal.Body>
        </Modal>


        <Modal
          animation={false}
          show={this.props.login_show}
          className="modal-signup"
          onHide={(e) => this.props.dispatch({
            type: 'LOGIN_SHOW',
            payload: false,
          })}
        >
          <Modal.Header closeButton>
          </Modal.Header>

          <Modal.Body>
            <h3>Login to account</h3>


            <div class="tab-content">
              <div role="tabpanel" className={"active tab-pane"} id="logintab">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Enter email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Control type="password" placeholder="Password" onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password} />
                </Form.Group>
                <div class="checkbox">

                </div>

                <Button variant="default" onClick={this.loginUser} className={"bt-custom btn-block"}>Login</Button>



                <GoogleLogin
                  clientId="1078526800840-16t4g1nptovelk4vsp7va2bs19qqgg6b.apps.googleusercontent.com"
                  //buttonText=" Login with Google"
                  //icon={false}
                  // disabledStyle={true}
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  //className={"bt-custom btn-block modal-signup-google-bt"}
                  //cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <a
                      class="btn btn-default bt-custom btn-block modal-signup-google-bt"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div class="pull-left"><i class="fab fa-google"></i></div>
		                  Sign up with Google
                    </a>
                  )}
                />


                <FacebookLogin
                  appId="197675757950687"
                  autoLoad={false}
                  fields="name,email,picture"
                  //onClick={componentClicked}
                  tag="button"
                  cssClass="bt-custom btn-block modal-signup-facebook-bt btn btn-default"
                  icon="pull-left fab fa-facebook"
                  callback={this.responseFacebook}

                />

                <div class="modal-signup-footer">
                  <div class="col-md-6">No account? <Link onClick={(e) => this.showSignup(e)}>Sign up</Link></div>
                  <div class="col-md-6"><Link onClick={(e) => this.showForgot(e)}>Forgot Password</Link></div>
                  
                  </div>

              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          animation={false}
          show={this.props.forgot_show}
          className="modal-signup"
          onHide={(e) => this.props.dispatch({
            type: 'FORGOT_SHOW',
            payload: false,
          })}
        >
          <Modal.Header closeButton>
          </Modal.Header>

          <Modal.Body>
            <h3>Forgot Password</h3>


            <div class="tab-content">
              <div role="tabpanel" className={"active tab-pane"} id="logintab">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Enter email" onChange={(e) => this.setState({ forgot_email: e.target.value })} value={this.state.forgot_email} />
                </Form.Group>

               

                <Button variant="default" onClick={this.forgotPassword} className={"bt-custom btn-block"}>Submit</Button>



                

                <div class="modal-signup-footer">
                  Remember Password? <Link onClick={(e) => this.showLogin(e)}>Login</Link>
                  
                  </div>

              </div>
            </div>
          </Modal.Body>
        </Modal>

        {this.state.loading ? <LoadingSpinner /> : ''}
      </ >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login_show: state.login_show,
    register_show: state.register_show,
    forgot_show: state.forgot_show,
    profile_data: state.profile_data,
    register_force_teacher_show: state.register_force_teacher_show
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));