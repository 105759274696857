import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../../Elements/HeaderInner';
import Footer from '../../Elements/Footer';

import TeacherSidebar from '../../Elements/TeacherSidebar';
import { getCourseDetail } from '../../../Utils/Utils';
import Pagination from "react-js-pagination";
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import * as moment from 'moment';

class TeacherCourseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.params.slug,
      active_show: 'active',
      upcoming_show: '',
      completed_show: '',
      page: 1,
      per_page: 5,
      delay: 1,
      search_term: '',
      completed_courses: { data: [] },
      typing: false,
      typingTimeout: 0,
      alert: "",
      deleteAlert: "",
      step: 'mycourses',
      user_timezone: null
    };

  }

  async componentDidMount() {
    await this.getUserProfile();
    this.setState({
      completed_courses: await getCourseDetail(this.state.slug)
    });

  }

  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {
      this.setState({
        user_timezone: response.data.timezone_name.name
      });

    }).catch((error) => {
      if (!error.response) {
        NotificationManager.error("Some Problem Occured in Fetching Teacher Availability", 'Validation Error');
        return;
      }
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
        }
      } else {
        NotificationManager.error("Some Problem Occured in Timezone API", 'Validation Error');
      }
    });
  }
  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <TeacherSidebar step={this.state.step} />
            </div>

            <div class="col-md-10 col-sm-10">
              <div class="dash-maincontent">


                <ul class="nav nav-tabs dash-tabs" role="tablist">
                  <li className="active">
                    <Link> Course Detail </Link>
                  </li>
                </ul>

                <div class="tab-content">

                  <div role="tabpanel" >

                    {
                      this.state.completed_courses.data.title ?
                        <div class="dash-mycourses-module">

                          <h6>{this.state.completed_courses.data.category_name.name}</h6>
                          <h3>{this.state.completed_courses.data.title}</h3>
                          <div class="dash-mycourses-module-date">
                            <p>Starts: {
                              (this.state.user_timezone !== null)
                                ?
                                moment(this.state.completed_courses.data.start_date).tz(this.state.user_timezone).format("MM/DD/YYYY")
                                :
                                moment(this.state.completed_courses.data.start_date).format("MM/DD/YYYY")
                            } - Ends: {
                                (this.state.user_timezone !== null)
                                  ?
                                  moment(this.state.completed_courses.data.end_date).tz(this.state.user_timezone).format("MM/DD/YYYY")
                                  :
                                  moment(this.state.completed_courses.data.end_date).format("MM/DD/YYYY")
                              }  </p>
                          </div>

                          <div class="dash-mycourses-module-date">
                            <p>Sub category: {this.state.completed_courses.data.subcategory_name.name}</p>
                            <p>Subject: {this.state.completed_courses.data.subject_name ? this.state.completed_courses.data.subject_name.name : "N/A"}</p>
                          </div>


                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Duration</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.completed_courses.data.class_schedules ?
                                  this.state.completed_courses.data.class_schedules.map((schedule) => (
                                    <>

                                      <tr>
                                        <td>{
                                          (this.state.user_timezone !== null)
                                            ?
                                            moment(schedule.start_date).tz(this.state.user_timezone).format("MM/DD/YYYY hh:mm A")
                                            :
                                            moment(schedule.start_date).format("MM/DD/YYYY hh:mm A")
                                        }</td>
                                        <td>{schedule.name}</td>
                                        <td>{schedule.duration + " Minutes"}</td>
                                      </tr>

                                    </>
                                  )) : ""
                              }
                            </tbody>
                          </table>

                          <div class="coursedetail-main-module">
                            <h4>Course content</h4>
                            <ul>
                              {
                                this.state.completed_courses.data.class_offerings_topic.map((topic) => (
                                  <li>{topic.name}</li>
                                ))
                              }
                            </ul>
                          </div>
                          <div class="coursedetail-main-module">
                            <h4>Requirements</h4>
                            <ul>
                              {
                                this.state.completed_courses.data.class_offerings_requirement.map((req) => (
                                  <li>{req.name}</li>
                                ))
                              }
                            </ul>
                          </div>
                          <div class="coursedetail-main-module">
                            <h4>Objective</h4>
                            <ul>
                              {
                                this.state.completed_courses.data.class_offerings_ojective.map((objective) => (
                                  <li>{objective.name}</li>
                                ))
                              }
                            </ul>
                          </div>

                        </div>
                        :
                        ''
                    }
                  </div>



                </div>

              </div>
            </div>

          </div>
        </div>


        <Footer />
      </div >

    );
  }

}
export default TeacherCourseDetail;
