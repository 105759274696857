import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Form } from 'react-bootstrap';
import AvailabilityCalendar from './components/AvailabilityCalendar';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { courseDetail, getProfileInformation } from '../../Utils/Utils'
import moment from 'moment';
class CourseDetail extends React.Component {
  constructor(props) {
    super();
    this.state = {
      course_detail: null,
      user_timezone: null,
      is_calendar_open: false,
      course_level: [{
        1: "Beginner",
        2: "Intermediatry",
        3: "Intermedatry",
    }],

    };
  }

  async componentDidMount() {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    await this.getUserProfile();
    console.log(this.state.user_timezone);
    this.setState({
      course_detail: await courseDetail(this.props.match.params.slug)
    })

    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );

  }
  checkLogin = async () => {
    var login_info = await getProfileInformation();
    if (login_info === null) {
      this.props.dispatch({ type: 'LOGIN_SHOW', payload: true });
    } else {
      if (login_info.user_type_id === 1) {
        this.checkWalletBalance();
      } else {
        alert('Please login with Student Role to purchase this Course.')
      }
    }
  }

  getUserProfile = async () => {
    const token = localStorage.token;
    const response = await axios.get(window.$HOST_URL + 'api/fetchusertimezone/', {
      headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` }
    }
    ).then((response) => {
      this.setState({
        user_timezone: response.data.timezone_name.name
      });
    }).catch((error) => {
      this.setState({
        user_timezone: null
      });
    });
  }
  countRating = (lesson_rating) => {
    var rating = 0;
    var count = 0;
    lesson_rating.forEach((data) => {
      rating = rating + data.rating;
      count++;
    });
    if (count === 0) {
      return (5).toFixed(1);
    } else {
      return (rating / count).toFixed(1);
    }

  }
  bookClass = () => {
    const access_token = localStorage.getItem('token');
    axios
      .post(window.$API_URL + 'student/coursebook', {
        "slug": this.props.match.params.slug,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {

        this.props.dispatch({
          type: 'SWEET_ALERT_SUCCESS_JSON', payload: {
            title: 'Course Booked',
            content: 'Congratulations! You have booked the course. Please visit my courses section to review the scheduled sessions.',
            showCancel: false,
            showConfirm: true,
            confirmBtnText: 'Proceed to Dashboard',
            confirmBtnBsStyle: 'success',
            success_callback: () => window.location.href = '/student/courses',
            cancel_callback: () => ''
          }
        });

      }).catch((error) => {
        console.log(error.response.data.message);
        if (error.response.data.message !== undefined) {
          if (error.response.message !== '') {
            NotificationManager.error(error.response.data.message, 'Validation Error');
          } else {
            NotificationManager.error('An error occurred while booking the course, Please try again later.', 'Validation Error');
          }
        } else {
          NotificationManager.error('An error occurred while booking the course, Please try again later.', 'Validation Error');
        }
      });
  }

  toggleAvailabilityCalendar = () => {
    this.setState({
      is_calendar_open: !this.state.is_calendar_open
    })
  }

  checkWalletBalance = () => {
    const access_token = localStorage.getItem('token');
    axios
      .post(window.$API_URL + 'payment/checkwalletbalanceforcourse', {
        "lesson_slug": this.props.match.params.slug,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {
        if (success.data.is_sufficient_balance === 0) {
          this.props.dispatch({
            type: 'SWEET_ALERT_FAILED_JSON', payload: {
              title: 'Insufficient Wallet Balance',
              content: "You don't have sufficient wallet balance.",
              showCancel: true,
              showConfirm: true,
              confirmBtnText: 'Add Balance',
              confirmBtnBsStyle: 'success',
              cancelBtnText: 'No Thanks',
              success_callback: () => window.location.href = '/student/addmoneywallet/?short=' + success.data.short,
              cancel_callback: () => { this.props.dispatch({ type: 'SWEET_ALERT_FAILED_JSON', payload: null }) }
            }
          });
        } else {
          if (this.state.course_detail.offering_type === 1) {
            this.props.dispatch({
              type: 'SWEET_ALERT_SUCCESS_JSON', payload: {
                title: 'Wallet Balance Available',
                content: 'Are you sure you want to continue booking the course? Your wallet will be deducted with the course amount.',
                showCancel: true,
                showConfirm: true,
                confirmBtnText: 'Proceed to Booking',
                confirmBtnBsStyle: 'success',
                success_callback: () => this.bookClass(),
                cancel_callback: () => { this.props.dispatch({ type: 'SWEET_ALERT_SUCCESS_JSON', payload: null }) }
              }
            });
          } else {
            this.setState({
              is_calendar_open: true
            })
          }

        }
        //response = success.data;
      }).catch((error) => {
        console.log(error);
        if (error.response.message !== undefined) {
          if (error.response.message !== '') {
            NotificationManager.error(error.response.message, 'Validation Error');
          }
        } else {
          NotificationManager.error('Some Error occured while fetching the Wallet Balance', 'Validation Error');
        }
        //response = [];
      });
  }
  render() {
    return (
      <div>

        <Header />


        <div class="coursespage-section">
          <div class="container">

            <div class="breadcrumb-custom">
              <Link to="/">Home</Link> » <a class="pointer" onClick={(e) => this.props.history.goBack()}>{
                (this.state.course_detail !== null)
                  ?
                  (this.state.course_detail.offering_type === 1)
                    ?
                    'Courses'
                    :
                    'Lessons'
                  :
                  'Courses'
              }</a> » {
                (this.state.course_detail !== null)
                  ?
                  this.state.course_detail.title
                  :
                  'Class Detail'
              }
            </div>

            <div class="row">
              {
                (this.state.course_detail !== null)
                  ?
                  <>
                    <div class="col-md-8 col-sm-8">
                      <div class="coursedetail-main">

                        <div class="coursedetail-main-intro">
                          <h6>{this.state.course_detail.category_name.name}</h6>
                          <h2>{this.state.course_detail.title}</h2>

                          <div class="coursedetail-col-profile visible-xs">
                            <img
                              src={(this.state.course_detail.class_teacher.image !== null) ? this.state.course_detail.class_teacher.profile_picture : "/img/uploadprofilepic.svg"}
                              class="img-circle"
                              style={{ verticalAlign: "middle" }}
                              alt=""
                            />
                            <span>By <Link to={"/teacher/" + this.state.course_detail.class_teacher.slug}>{this.state.course_detail.class_teacher.first_name + " " + this.state.course_detail.class_teacher.last_name}</Link></span>
                          </div>

                          <div class="coursedetail-main-pic">
                            <img
                              src={(this.state.course_detail.cover_picture !== null) ? this.state.course_detail.banner : "/img/course_pic01.jpg"}
                              class="img-responsive"
                              alt=""
                            />
                          </div>

                        </div>
                        {
                          (this.state.course_detail.offering_type === 1)
                            ?
                            <div class="coursedetail-main-info">
                              <b>Start:</b> {

                                (this.state.user_timezone !== null)
                                  ?
                                  moment(this.state.course_detail.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                  :
                                  moment(this.state.course_detail.start_date).format("MMM DD,YYYY h:mmA")
                              }
                            </div>
                            :
                            ''
                        }


                        <div class="coursedetail-col visible-xs">
                          <h3>$ {this.state.course_detail.price}</h3>
                          <button
                            className="btn btn-default btn-block bt-custom" role="button"
                            onClick={(e) => this.checkLogin()}
                          >Book now</button>

                          <ul class="coursedetail-col-list">
                            {
                              (this.state.course_detail.offering_type === 2)
                                ?
                                <li><i class="far fa-star"></i><span>{this.countRating(this.state.course_detail.lesson_rating)}</span></li>
                                :
                                ''
                            }
                            {
                              (this.state.course_detail.offering_type === 1)
                                ?
                                <li><i class="far fa-copy"></i><span>{this.state.course_detail.class_schedules.length} lessons</span></li>
                                :
                                ''
                            }
                            <li><i class="far fa-clock"></i><span>{this.state.course_detail.lesson_duration} minutes lesson</span></li>
                            <li><i class="far fa-chart-bar"></i><span>Intermediate level</span></li>
                            <li><i class="fas fa-globe"></i>
                              <span>{this.state.course_detail.class_language.name}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="coursedetail-main-module">
                          <h4>Description</h4>
                          <p>{this.state.course_detail.description}</p>
                        </div>
                        {
                          (this.state.course_detail.class_schedules.length > 0)
                            ?
                            <div class="coursedetail-main-module">
                              <h4>Course Schedule</h4>
                              <ul>
                                {
                                  this.state.course_detail.class_schedules.map((schedule, index) => (
                                    <li><b>{'Session ' + (index + 1)}:</b> {
                                      (this.state.user_timezone !== null)
                                        ?
                                        moment(schedule.start_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA") + ' - ' + moment(schedule.end_date).tz(this.state.user_timezone).format("MMM DD,YYYY h:mmA")
                                        :
                                        moment(schedule.start_date).format("MMM DD,YYYY h:mmA") + ' - ' + moment(schedule.end_date).format("MMM DD,YYYY h:mmA")
                                    }</li>
                                  ))
                                }
                              </ul>
                            </div>
                            :
                            ''
                        }

                        <div class="coursedetail-main-module">
                          <h4>Course content</h4>
                          <ul>
                            {
                              this.state.course_detail.class_offerings_topic.map((topic) => (
                                <li>{topic.name}</li>
                              ))
                            }
                          </ul>
                        </div>
                        <div class="coursedetail-main-module">
                          <h4>Requirements</h4>
                          <ul>
                            {
                              this.state.course_detail.class_offerings_requirement.map((req) => (
                                <li>{req.name}</li>
                              ))
                            }
                          </ul>
                        </div>
                        <div class="coursedetail-main-module">
                          <h4>Objective</h4>
                          <ul>
                            {
                              this.state.course_detail.class_offerings_ojective.map((objective) => (
                                <li>{objective.name}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      {
                        (this.state.is_calendar_open === true)
                          ?
                          <AvailabilityCalendar
                            slug={this.props.match.params.slug}
                            profile_data={this.props.profile_data}
                            user_timezone={this.state.user_timezone}
                            course_detail={this.state.course_detail}
                            toggleAvailabilityCalendar={this.toggleAvailabilityCalendar}
                          />
                          :
                          ''
                      }


                    </div>
                    <div class="col-md-3 col-sm-4 coursedetail-col col-md-offset-1 col-sm-offset-0 hidden-xs">

                      <h3>$ {this.state.course_detail.price}</h3>
                      <button
                        className="btn btn-default btn-block bt-custom" role="button"
                        onClick={(e) => this.checkLogin()}
                      >Book now</button>

                      <div class="coursedetail-col-profile">
                        <img
                          src={(this.state.course_detail.class_teacher.image !== null) ? this.state.course_detail.class_teacher.profile_picture : "/img/uploadprofilepic.svg"}
                          class="img-circle"
                          style={{ verticalAlign: "middle" }}
                          alt=""
                        />
                        <span>By <Link to={"/teacher/" + this.state.course_detail.class_teacher.slug}>{this.state.course_detail.class_teacher.first_name + " " + this.state.course_detail.class_teacher.last_name}</Link></span>
                      </div>

                      <ul class="coursedetail-col-list">
                        {
                          (this.state.course_detail.offering_type === 2)
                            ?
                            <li><i class="far fa-star"></i><span>{this.countRating(this.state.course_detail.lesson_rating)}</span></li>
                            :
                            ''
                        }

                        {
                          (this.state.course_detail.offering_type === 1)
                            ?
                            <li><i class="far fa-copy"></i><span>{this.state.course_detail.class_schedules.length} lessons</span></li>
                            :
                            ''
                        }


                        <li><i class="far fa-clock"></i><span>{this.state.course_detail.lesson_duration} minutes lesson</span></li>
                        <li><i class="far fa-chart-bar"></i><span>Intermediate level</span></li>
                        <li><i class="fas fa-globe"></i><span>{this.state.course_detail.class_language.name}</span></li>
                      </ul>

                    </div>

                  </>
                  :
                  ''
              }
            </div>

          </div>
        </div>


        <Footer />
      </div>

    );
  }

}
const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
