import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

class LandingMeet extends React.Component {
    showSignup = (e) => {
        e.preventDefault();
        this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: false });
        this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
        this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
      }
      showSignupTeacher = (e) => {
        e.preventDefault();
        this.props.dispatch({ type: 'REGISTER_FORCE_TEACHER_SHOW', payload: true });
        this.props.dispatch({ type: 'REGISTER_SHOW', payload: true });
        this.props.dispatch({ type: 'LOGIN_SHOW', payload: false });
      }

    render() {
        return (
            <div class="section section-meet max-container">
                <div class="container">
                    <div class="row">
                        <div class="visible-xs"><img src="img/home_meet_mob.jpg" class="img-responsive" alt="" /></div>
                        <div class="col-md-7 col-sm-6 hidden-xs section-meet-left">
                            <div class="section-meet-pic wow fadeInUp" style={{ animationDelay: "0.3s" }}><img src="img/home_meet02.png" alt="" /></div>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <h1 class="wow fadeInRight" style={{ animationDelay: "0.2s" }}>Meet face to face wherever you are</h1>
                            <p class="wow fadeInRight" style={{ animationDelay: "0.4s" }}>Classes are conducted with a live instructor teaching, guiding, and mentoring you in real time. You can interact, ask questions, receive real-time feedback and style your learning needs. </p>
                            <p class="wow fadeInRight" style={{ animationDelay: "0.6s" }} >These factors combined with our user-friendly Learning Platform and our Virtual Classroom result in an unparalleled learning experience. </p>
                            <Button onClick={(e) => this.showSignup(e)}  variant="default" className="bt-custom">Get started</Button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { profile_data: state.profile_data };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(LandingMeet);
