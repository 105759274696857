import React from 'react';



class AppFaq extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div class="coursespage-section">
          <div class="terms">
            <div class="container">

              <h3>Frequently Asked Questions (FAQ)</h3>

              <h5>Which is the best way to attend classes? </h5>
              <p>The best way to attend class for better experience with integrated audio, high-quality video, and content sharing, please download the Webex application from apple store. </p>
              <a class="btn btn-default bt-custom nav-bt-special" href="https://apps.apple.com/in/app/cisco-webex-meetings/id298844386">Download Webex App</a>

              <br />
              <br />
              <h5>How do I edit my profile/change my Time zone? </h5>

              <p>Navigate to Profile link at the bottom and click on Edit link to update profile and change timezone. </p>
              <div class="">
                <img src="img/app/IMG_0236.PNG" class="img-responsive" alt="" style={{ width: "49%" }} />
              </div>
              <br />



              <h5>How do I change/reset my password?</h5>
              <p>Navigate to Profile link at the bottom to Change Password  </p>

              <div class="">
                <img src="img/app/IMG_0222.PNG" class="img-responsive" alt="" style={{ width: "49%" }} />
              </div>
              <br />

              <h5>How do I deactivate my account/change my Privacy Settings/change my Notification settings? </h5>
              <p>Navigate to sidebar and click on Account Settings link.  </p>

              <br />
              <div class="">
                <img src="img/app/IMG_0230.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%" }} />

                &nbsp;
                <img src="img/app/IMG_0231.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%", marginLeft: "6px;" }} />
              </div>

              <div class="clearfix"> </div>
              <br />
              <h4>Instructor Account and Profile </h4>

              <h5>How do I change my Time zone? </h5>

              <p>Navigate to Profile and click on Edit link at top.  </p>

              <br />
              <div class="">
                <img src="img/app/IMG_0234.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%" }} />
              </div>
              <div class="clearfix"> </div>
              <br />
              <h5>How do I change my Notification Settings/Reset Password/Privacy Settings? </h5>
              <p>Navigate to sidebar and click on Account Settings link.  </p>

              <br />
              <div class="">
                <img src="img/app/IMG_0230.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%" }} />

                &nbsp;
                <img src="img/app/IMG_0231.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%", marginLeft: "6px;" }} />
              </div>

              <div class="clearfix"> </div>
              <br />


              <h5>How do I add my lessons and courses? </h5>
              <br />
              <div class="">
                <img src="img/app/IMG_0223.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%" }} />

                &nbsp;
                <img src="img/app/IMG_0224.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%", marginLeft: "6px;" }} />
              </div>

              <div class="clearfix"> </div>
              <br />
              <h5>How do I edit my availability? </h5>

              <div class="">
                <img src="img/app/IMG_0225.PNG" class="img-responsive" alt="" style={{ width: "49%" }} />
              </div>
              <br />

              <h5>How do I edit or add to my Work Experience, Education, or Certificates? </h5>

              <div class="">
                <img src="img/app/IMG_0234.PNG" class="img-responsive" alt="" style={{ width: "49%" }} />
              </div>
              <br />

              <h5>How do I add more lessons in different languages? </h5>
              <p> You can always choose the language in each lesson or course you create. So in this way the lesson can be in same or different language as per your requirement.</p>
              <br />
              <h5>How are teacher ratings calculated? </h5>
              <p> Ratings are calculated based on the ratings received from students after the completion of the lessons.</p>
              <br />

              <h5>How can I deactivate my teacher profile? </h5>
              <h5> How do I get paid?  </h5>

              <ul>
                <li>✓ Account </li>
                <li>✓ Paypal </li>
                <li>✓ Apple Pay</li>
              </ul>


              <br />
              <div class="">
                <img src="img/app/IMG_0227.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%" }} />

                &nbsp;
                <img src="img/app/IMG_0228.PNG" class="img-responsive pull-left" alt="" style={{ width: "49%", marginLeft: "6px;" }} />
              </div>

              <div class="clearfix"> </div>
              <br />



            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AppFaq;