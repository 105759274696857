import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';
import TeacherRegistrationSidebar from '../Elements/TeacherRegistrationSidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import moment from 'moment';

class TeacherStepFour extends React.Component {
    constructor() {
        super();
        this.state = {
            dataEducation: [],
            returnError: [],

            page_type: 'add',

            educationDataSend: [],
            fromData: [],
            fromDataToSend: [],
            toData: [],
            toDataToSend: [],
            institutionData: [],
            degreeData: [],
            studyData: [],
            descData: [],
            uploadedDocument: [],
            uploadedDocumentFinalString: [],
            stepselector: 'stepfour',
            loading: 0
        };

    }

    componentDidMount() {
        this.getTeacherInformation();


    }


    getTeacherInformation = async () => {
        const token = localStorage.token;

        this.setState({
            loading: 1
        });
        const response = axios.get(
            window.$API_URL + 'teacher/teacherprofileget',
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then(async (response) => {
            this.setState({
                loading: 0
            });

            var typeEducation = response.data.response.type_education;
            var typeEducationDt = response.data.response.type_education;
            var inc = 0;
            console.log(typeEducationDt);
            if (typeEducationDt.length > 0) {
                typeEducationDt.map(temp_value => {
                    this.state.dataEducation.push(inc);
                    this.state.institutionData.push(temp_value.name);
                    this.state.degreeData.push(temp_value.degree_type);
                    this.state.studyData.push(temp_value.area_of_study);
                    this.state.descData.push(temp_value.description);
                    this.state.fromData.push(new Date(temp_value.from_date));
                    this.state.fromDataToSend.push(temp_value.from_date);
                    this.state.toData.push(new Date(temp_value.to_date));
                    this.state.toDataToSend.push(temp_value.to_date);
                    this.state.uploadedDocumentFinalString.push(temp_value.document);
                    inc++;
                });

                this.setState({ page_type: 'edit' });
            } else {
                this.state.dataEducation.push(inc);
            }

            this.setState({
                dataEducation: this.state.dataEducation,
                institutionData: this.state.institutionData,
                degreeData: this.state.degreeData,
                studyData: this.state.studyData,
                descData: this.state.descData,
                fromData: this.state.fromData,
                fromDataToSend: this.state.fromDataToSend,
                toData: this.state.toData,
                toDataToSend: this.state.toDataToSend,
                uploadedDocumentFinalString: this.state.uploadedDocumentFinalString,
            });

        }).catch((error) => {
            console.log(error);
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data.response).forEach((key) => {
                        this.state.returnError.push(error.response.data.response[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
                }

            }
            this.setState({
                loading: 0
            });
        })
    }

    completeLater = async (e) => {
        window.location.href = '/teacher/stepfive';
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    documentUploadHandler = async (event, i) => {
        //this.state.uploadedDocument[i] = event;
        const token = localStorage.token;
        const data = new FormData();
        //   data.append('documentId', i);
        data.append('file', event);
        this.setState({
            loading: 1
        });
        const response = axios.post(
            window.$API_URL + 'teacher/teacherprofileupdatefourdocumentuploads',
            data,
            { headers: { 'Content-Type': '', 'x-access-token': `${token}` } }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            if (response.data.filename) {
                this.state.uploadedDocumentFinalString[i] = response.data.filename;
                this.setState({
                    uploadedDocumentFinalString: this.state.uploadedDocumentFinalString
                });
            } else {
                NotificationManager.error("Some Error Occured while uploading the Document", 'Upload Error');
            }
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            NotificationManager.error("File Cannot be uploaded!! Please try again.", 'Upload Error');
        });
    }

    appendChildEducation = (e) => {
        e.preventDefault();
        let { dataEducation } = this.state;
        dataEducation.push(dataEducation.length);
        this.setState({ dataEducation });
    }

    updateFromData = (valueFrom, idNum) => {

        console.log(valueFrom.getFullYear());
        console.log(valueFrom.getMonth());
        console.log(valueFrom.getDate());
        var date = moment(valueFrom).format("YYYY-MM-DD");
        //  console.log(date);
        if (valueFrom != "") {
            this.state.fromData[idNum] = new Date(valueFrom.getFullYear() + "/" + (valueFrom.getMonth() + 1) + "/" + valueFrom.getDate());
            this.state.fromDataToSend[idNum] = date;
        }
        else {
            this.state.fromData.splice(idNum, 1);
            this.state.fromDataToSend.splice(idNum, 1);
        }
        this.setState({
            fromData: this.state.fromData,
            fromDataToSend: this.state.fromDataToSend
        });
    }

    updateToData = (valueTo, idNum) => {
        var date = moment(valueTo).format("YYYY-MM-DD");
        if (valueTo != "") {
            this.state.toData[idNum] = valueTo;
            this.state.toDataToSend[idNum] = date;


        }
        else {
            this.state.toData.splice(idNum, 1);
            this.state.toDataToSend.splice(idNum, 1);
        }
        this.setState({
            toData: this.state.toData,
            toDataToSend: this.state.toDataToSend
        });
    }

    updateInstitutionData = (valueInstitution, idNum) => {
        if (valueInstitution != "") {
            this.state.institutionData[idNum] = valueInstitution;
        }
        else {
            this.state.institutionData.splice(idNum, 1);
        }

        this.setState({
            institutionData: this.state.institutionData
        });
    }

    updateDegreeData = (valueDegree, idNum) => {
        if (valueDegree != "") {
            this.state.degreeData[idNum] = valueDegree;
        }
        else {
            this.state.degreeData.splice(idNum, 1);
        }

        this.setState({
            degreeData: this.state.degreeData
        });
    }

    updateStudyData = (valueStudy, idNum) => {
        if (valueStudy != "") {
            this.state.studyData[idNum] = valueStudy;
        }
        else {
            this.state.studyData.splice(idNum, 1);
        }

        this.setState({
            studyData: this.state.studyData
        });
    }

    updateDescData = (valueDesc, idNum) => {
        if (valueDesc != "") {
            this.state.descData[idNum] = valueDesc;
        }
        else {
            this.state.descData.splice(idNum, 1);
        }

        this.setState({
            studyData: this.state.studyData
        });
    }


    removeChild = (e, value) => {
        e.preventDefault();
        var index = this.state.dataEducation.indexOf(value);
        if (index !== -1) {
            this.state.dataEducation.pop();
        }


        var nameDt = this.state.institutionData;
        if (value in nameDt) {
            this.state.institutionData.splice(value, 1);
        }

        var studyDt = this.state.studyData;
        if (value in studyDt) {
            this.state.studyData.splice(value, 1);
        }

        var degreeDt = this.state.degreeData;
        if (value in degreeDt) {
            this.state.degreeData.splice(value, 1);
        }

        var descDt = this.state.descData;
        if (value in descDt) {
            this.state.descData.splice(value, 1);
        }

        var fromData = this.state.fromData;
        if (value in fromData) {
            this.state.fromData.splice(value, 1);
        }

        var fromDataToSend = this.state.fromDataToSend;
        if (value in fromDataToSend) {
            this.state.fromDataToSend.splice(value, 1);
        }

        var toData = this.state.toData;
        if (value in toData) {
            this.state.toData.splice(value, 1);
        }

        var toDataToSend = this.state.toDataToSend;
        if (value in toDataToSend) {
            this.state.toDataToSend.splice(value, 1);
        }

        var uploadedDocumentFinalString = this.state.uploadedDocumentFinalString;
        if (value in uploadedDocumentFinalString) {
            this.state.uploadedDocumentFinalString.splice(value, 1);
        }

        this.setState({
            dataEducation: this.state.dataEducation,
            uploadedDocumentFinalString: this.state.uploadedDocumentFinalString,
            toDataToSend: this.state.toDataToSend,
            toData: this.state.toData,
            fromData: this.state.fromData,
            fromDataToSend: this.state.fromDataToSend,
            descData: this.state.descData,
            degreeData: this.state.degreeData,
            studyData: this.state.studyData,
            institutionData: this.state.institutionData,
        });

    }

    processStepFour = async (e) => {
        const access_token = localStorage.getItem('token');
        this.setState({
            loading: 1
        });
        await axios.post(
            window.$API_URL + 'teacher/teacherprofileupdatefour',
            {
                fromData: this.state.fromDataToSend,
                toData: this.state.toDataToSend,
                institutionData: this.state.institutionData,
                degreeData: this.state.degreeData,
                studyData: this.state.studyData,
                descData: this.state.descData,
                documentUploads: this.state.uploadedDocumentFinalString
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': `${access_token}`,
                }
            }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            NotificationManager.success('Your education information has been updated successfully.', 'Success');


            if (this.state.page_type == 'add') {
                setTimeout(() => {
                    window.location.href = '/teacher/stepfive';
                }, 500);
            } else {
                setTimeout(() => {
                    window.location.href = '/teacher/myprofile';
                }, 500);
            }
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (error.response.status === 422) {
                this.state.returnError = [];
                Object.keys(error.response.data.response).forEach((key) => {
                    this.state.returnError.push(error.response.data.response[key]);
                });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error(error.response.data.message, 'Validation Error');
            }
        });
    }

    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="signupspace">
                        <div class="signupspace-ttl">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-8">
                                    {this.state.page_type == 'add' ? <h3><b>Sign up</b> Teacher account</h3> : <h3><b>Update</b> Teacher account</h3>}
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">
                                    {/*<Link onClick={this.completeLater} >Skip <span class="hidden-xs">and complete later</span> <i class="zmdi zmdi-long-arrow-right"></i></Link>*/}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <TeacherRegistrationSidebar step={this.state.stepselector} />
                            </div>

                            <div class="col-md-9 col-sm-8">
                                <div class="signup-maincontent">

                                    {this.state.dataEducation.map(id => (
                                        <Education id={id} updateFromData={this.updateFromData}
                                            updateToData={this.updateToData} updateInstitutionData={this.updateInstitutionData}
                                            updateStudyData={this.updateStudyData} updateDegreeData={this.updateDegreeData}
                                            updateDescData={this.updateDescData} documentUploadHandler={this.documentUploadHandler}
                                            fromData={this.state.fromData} toData={this.state.toData}
                                            institutionData={this.state.institutionData}
                                            degreeData={this.state.degreeData}
                                            studyData={this.state.studyData}
                                            descData={this.state.descData}
                                            uploadedDocumentFinalString={this.state.uploadedDocumentFinalString}
                                            removeChild={this.removeChild}
                                        />
                                    ))}




                                    <Link onClick={this.appendChildEducation}><i class="zmdi zmdi-plus-circle-o"></i> Add more education experience</Link>

                                    <div class="signup-maincontent-footer">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                {/* <Link to="/teacher/stepthree"><i class="zmdi zmdi-long-arrow-left"></i> Back</Link> */}
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                                <Button onClick={this.processStepFour} variant="default" className="bt-custom ">Next</Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <Footer />
                {this.state.loading ? <LoadingSpinner /> : ''}
            </div>

        );
    }

}


const Education = ({ id, updateFromData, updateToData, updateInstitutionData, updateStudyData, updateDegreeData,
    updateDescData, documentUploadHandler, fromData, toData, institutionData,
    degreeData,
    studyData,
    descData, uploadedDocumentFinalString,
    removeChild }) => (

    <div className="row">
        {(id > 0) ? <a href="#" onClick={(e) => removeChild(e, id)} className="pull-right close-redicon" >x</a> : ""}

        <Form.Group>
            <Form.Control type="text" value={institutionData[id]} onChange={(e) => updateInstitutionData(e.target.value, id)} placeholder="University/Institute" />
        </Form.Group>

        <Form.Group>
            <Form.Control type="text" value={studyData[id]} onChange={(e) => updateStudyData(e.target.value, id)} placeholder="Major/Title" />
        </Form.Group>

        {/* <Form.Group>
                <Form.Control as="select" value={degreeData[id]} onChange={(e) => updateDegreeData(e.target.value, id)}  >
                    <option>Options</option>
                    <option>Account One</option>
                    <option>Account Two</option>
                    <option>Account Three</option>
                </Form.Control>
            </Form.Group> */}

        <Form.Group>
            <Form.Control as="textarea" rows="3" value={descData[id]} placeholder="Description" onChange={(e) => updateDescData(e.target.value, id)}  >

            </Form.Control>
        </Form.Group>


        <div class="signup-education-upload">

            <label className="btn bt-custom">
                Upload document <input type="file" style={{ display: 'none' }} onChange={(e) => documentUploadHandler(e.target.files[0], id)} />
            </label>
            <span className="download_doc_link">{(uploadedDocumentFinalString[id]) ? <a href={`${window.$HOST_URL}api/chatattachment/${uploadedDocumentFinalString[id]}`}><i className="fa fa-2x fa-paperclip" aria-hidden="true"></i></a> : ''}</span>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-6">

                <Form.Group>
                    <DatePicker
                        onChange={(e) => updateFromData(e, id)}
                        selected={fromData[id]}
                        className="form-control"
                        wrapperClassName="fullWidth"
                        placeholderText="From"
                    />
                </Form.Group>

            </div>
            <div class="col-md-6 col-sm-6">
                <Form.Group>
                    <DatePicker
                        onChange={(e) => updateToData(e, id)}
                        selected={toData[id]}
                        className="form-control"
                        wrapperClassName="fullWidth"
                        placeholderText="To"
                    />
                </Form.Group>
            </div>
        </div>

        <div class="signup-education-separator">

        </div>

    </div>
);


export default TeacherStepFour;
