import React from 'react';
import { Link } from 'react-router-dom';
class TeacherSidebar extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="dash-sidemenu">
                <ul>
                    <li className={(this.props.step === "mycourses") ? 'active' : ''}><Link to={`/teacher/courses`}>My Courses</Link></li>
                    <li className={(this.props.step === "mylessons") ? 'active' : ''}><Link to={`/teacher/lessons`}>My Lessons</Link></li>
                    <li className={(this.props.step === "mystudents") ? 'active' : ''}><Link to={`/teacher/students`}>My Students</Link></li>
                    <li className={(this.props.step === "mycalendar") ? 'active' : ''}><Link to={`/teacher/mycalendar`}>Calendar</Link></li>
                </ul>
            </div>
        );
    }
}

export default TeacherSidebar;
