import React from 'react';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { Form } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      cpassword: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  contactUs = () => {
    const access_token = localStorage.getItem('token');
    axios
      .post(window.$API_URL + 'resetpassword', {
        forgot_token: this.props.match.params.token,
        password: this.state.password,
        cpassword: this.state.cpassword,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {

        NotificationManager.success('', 'Your password has been changed.');
        setTimeout(() => window.location = "/", 3000);


      }).catch((error) => {
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data).forEach((key) => {
              this.state.returnError.push(error.response.data[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error("Some Error occured. Please try after few minutes.", 'Validation Error');
          }
        }

      });

  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="terms">
            <div class="container">

              <h3>Reset Password</h3>
              <div className="signup-maincontent row">
                <div className="row">
                  <div className="col-md-offset-3 col-sm-offset-3 col-md-6 col-sm-6">
                    <Form.Group>
                      <Form.Control type="password" placeholder="New Password" onChange={(e) => this.setState({ password: e.target.value })} />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-offset-3 col-sm-offset-3 col-md-6 col-sm-6">
                    <Form.Group>
                      <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => this.setState({ cpassword: e.target.value })} />
                    </Form.Group>
                  </div>
                </div>
                &nbsp;
                <div className="row">
                  <div className="col-md-offset-3 col-sm-offset-3 col-md-6 col-sm-6 text-center">
                    <button
                      className="btn btn-default bt-custom"
                      role="button"
                      onClick={(e) => this.contactUs()}
                    >Submit
                          </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div >
    );
  }
}
export default ResetPassword;