import React from 'react';
import axios from 'axios';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today


export const getTimezone = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  var timezone_array = [];
  await axios
    .get(window.$HOST_URL + 'api/fetchalltimezone', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((succes) => {
      response = succes.data.data;
    });
  response.map((success) => {
    var split_name = success.description.split(')');
    var first_name_timezone = split_name[0] + ")";
    var last_name_timezone = split_name[1];
    //console.log(last_name_timezone);
    var new_object = { value: success.id, name: first_name_timezone + ' ' + last_name_timezone };
    timezone_array.push(new_object);
  });
  return timezone_array;
};

export const getLocation = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  var location_array = [];
  await axios
    .get(window.$HOST_URL + 'api/fetchallcountries', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((succes) => {
      response = succes.data.data;
    });
  response.map((success) => {
    var new_object = { value: success.id, name: success.name };
    location_array.push(new_object);
  });
  return location_array;
};

export const getLocationPayment = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  var location_array = [];
  await axios
    .get(window.$HOST_URL + 'api/fetchallcountriespayment', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((succes) => {
      response = succes.data.data;
    });
  response.map((success) => {
    var new_object = { value: success.id, name: success.name };
    location_array.push(new_object);
  });
  return location_array;
};

export const getLanguage = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  var language_array = [];
  await axios
    .get(window.$HOST_URL + 'api/fetchalllanguages', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((succes) => {
      response = succes.data.data;
    });
  response.map((success) => {
    var new_object = { value: success.id, name: success.name };
    language_array.push(new_object);
  });
  return language_array;
};
export const getCategory = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$HOST_URL + 'api/fetchallcategories', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((succes) => {
      response = succes.data.data;
    });
  return response;
};

export const getProfileInformation = async () => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + 'checktoken', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data;
    }).catch((error) => {
      response = null;
    });
  return response;
};


export const getTeacherCourseList = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/coursesget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getTeacherCourseBookingListUpcoming = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/coursebookingget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getTeacherCourseBookingListCompleted = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/coursebookinggetcompleted?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getTeacherCourseBookingDetail = async (slug, page, per_page, delay) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/getbookedcourseinfo?slug=${slug}&page=${page}&per_page=${per_page}&delay=${delay}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getTeacherCourseBookingStudentDetail = async (slug) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/getbookedcoursestudents?slug=${slug}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}


export const getTeacherLessonList = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/lessonget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getTeacherLessonBookingListUpcoming = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/lessonbookingget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getTeacherLessonBookingListCompleted = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/lessonbookinggetcompleted?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getTeacherLessonBookingListRequests = async (page, per_page, delay) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `teacher/lessonbookinggetrequests?page=${page}&per_page=${per_page}&delay=${delay}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getCourseDetail = async (slug) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'course/getcoursedetail', {
      'slug': slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });

  return response;
};


export const getTeacherFollowList = async () => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + 'student/teacherfollowlist', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data;
    }).catch((error) => {
      response = [];
    });
  return response;
};

export const getStudentCompletedCourses = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `student/studentcoursegetcompleted?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getStudentCompletedLessons = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `student/studentlessongetcompleted?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getStudentActiveCourses = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `student/studentcourseget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getStudentActiveLessons = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .get(window.$API_URL + `student/studentlessonget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const getStudentCurrentTeacher = async (offset) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'student/studentcurrentteacher', {
      'offset': offset
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      response = [];
    });
  return response;
};

export const getStudentFavouriteTeacher = async (offset) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'student/studentfavouriteteacher', {
      'offset': offset
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      response = [];
    });
  return response;
};

export const getStudentPastTeacher = async (offset) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'student/getteacherpast', {
      'offset': offset
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      response = [];
    });
  return response;
};
export const getTeacherCurrentStudent = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + `teacher/currentstudents?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });

  return response;
};

export const getTeacherPastStudent = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + `teacher/paststudents?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });

  return response;
};

export const transactionGetTeacher = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + `payment/transactionget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });

  return response;
};

export const fetchBalanceTeacher = async () => {
  const access_token = localStorage.getItem('token');
  var response = 0;
  await axios
    .post(window.$API_URL + 'payment/fetchwalletbalanceteacher', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = 0;
    });

  return response;
}

export const fetchPaypalAuth = async () => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + 'payment/paypalauthcheck', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data;
    }).catch((error) => {
      response = null;
    });
  //console.log(response);
  return response;
}

export const fetchPaypalUrl = async () => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'payment/fetchpaypalurl', {}, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data;
    }).catch((error) => {
      response = null;
    });
  //console.log(response);
  return response;
}

// export const getTeacherCurrentStudent = async (page, per_page, delay, search_term) => {
//   const access_token = localStorage.getItem('token');
//   var response = null;
//   await axios
//     .get(window.$API_URL + `teacher/currentstudents?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {
//       headers: {
//         'Content-Type': 'application/json',
//         'x-access-token': `${access_token}`,
//       },
//     })
//     .then((success) => {
//       response = success.data;
//     }).catch((error) => {
//       response = [];
//     });

//   return response;
// };

// export const getTeacherPastStudent = async (page, per_page, delay, search_term) => {
//   const access_token = localStorage.getItem('token');
//   var response = null;
//   await axios
//     .get(window.$API_URL + `teacher/paststudents?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {

//       headers: {
//         'Content-Type': 'application/json',
//         'x-access-token': `${access_token}`,
//       },
//     })
//     .then((success) => {
//       response = success.data;
//     }).catch((error) => {
//       response = [];
//     });

//   return response;
// };

export const fetchPopularCourses = async (page = 1, per_page = 4, delay = 1, search_term = '') => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'landing/popularcourses', {
      'offset': 0,
      'limit': 4,
      "search_term": search_term,
      "page": page,
      per_page: per_page,
      delay: delay,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
};
export const fetchNewCourses = async (page = 1, per_page = 4, delay = 1, search_term = '') => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'landing/newcourses', {
      'offset': 0,
      'limit': 4,
      "search_term": search_term,
      "page": page,
      per_page: per_page,
      delay: delay,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
};

export const fetchTeacherLessonsAll = async (page = 1, per_page = 4, delay = 1, search_term = '', slug) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'courses/getalllessonofteacher/', {
      'offset': 0,
      'limit': 4,
      "search_query": search_term,
      "page": page,
      per_page: per_page,
      delay: delay,
      teacher_slug: slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const fetchTeacherCoursesAll = async (page = 1, per_page = 4, delay = 1, search_term = '', slug) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'courses/getallcourseofteacher/', {
      'offset': 0,
      'limit': 4,
      "search_query": search_term,
      "page": page,
      per_page: per_page,
      delay: delay,
      teacher_slug: slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const newPrivateLessons = async (page = 1, per_page = 4, delay = 1, search_term = '') => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'landing/newprivatelesson', {
      'offset': 0,
      'limit': 4,
      "search_term": search_term,
      "page": page,
      per_page: per_page,
      delay: delay,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const languageCourses = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'landing/languageclasses', {
      'offset': 0,
      'limit': 4
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      response = [];
    });
  return response;
}

export const courseDetail = async (slug) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'student/getlessondetail', {
      'slug': slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      if (error.response.status === 404) {
        window.location.href = '/';
      }
      response = null;
    });
  return response;
}

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'conferenceme test event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'other test event',
    start: todayStr + 'T12:00:00'
  }
]

export function createEventId() {
  return String(eventGuid++)
}
export const getAllClasses = async (page, per_page, delay, search_term, category_id = [], language = [], min_rate = 0, max_rate = 10000, country = [], subcategory_id = '') => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'courses/getallclasses', {
      "offset": 0,
      "limit": 4,
      "country": country,
      "native_speak": [],
      "speaks": language,
      "category_id": category_id,
      "subcategory_id": subcategory_id,
      "min_rate": min_rate,
      "max_rate": max_rate,
      "search_query": search_term,
      "page": page,
      per_page: per_page,
      delay: delay,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = null;
    });
  return response;
}

export const getCategoryName = async (category_id) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$HOST_URL + 'api/fetchcategoryname?category_id=' + category_id, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((succes) => {
      response = succes.data.data;
    });
  return response;
}

export const getTeacherProfile = async (teacher_slug) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'student/getteacherprofile', {
      'slug': teacher_slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      if (error.response.status === 404) {
        window.location.href = '/';
      }
      response = null;
    });

  return response;
}

export const getTeacherLessons = async (teacher_slug) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'student/getteacherlessons', {
      'slug': teacher_slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      response = [];
    });

  return response;
}

export const getTeacherCourses = async (teacher_slug) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'student/getteachercourses', {
      'slug': teacher_slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      response = [];
    });

  return response;
}

export const transactionGet = async (page, per_page, delay, search_term) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + `student/transactionget?page=${page}&per_page=${per_page}&delay=${delay}&search_term=${search_term}`, {

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });

  return response;
};

export const fetchBalance = async () => {
  const access_token = localStorage.getItem('token');
  var response = 0;
  await axios
    .post(window.$API_URL + 'payment/fetchwalletbalance', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.balance;
    }).catch((error) => {
      response = 0;
    });

  return response;
}

export const fetchBookingCharges = async () => {
  const access_token = localStorage.getItem('token');
  var response = 0;
  await axios
    .post(window.$API_URL + 'fetchadmininfo', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      let charges = success.data.data.booking_charges + success.data.data.withdrawal_charges
      response = charges;
      //response = success.data.data.booking_charges;
    }).catch((error) => {
      response = 0;
    });

  return response;
}


export const studentProfileGet = async () => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .get(window.$API_URL + `student/studentprofileget`, {

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.response;
    }).catch((error) => {
      response = null;
    });

  return response;
};

export const fetchlessoncount = async () => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'dashboard/fetchlessoncount', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = null;
    });

  return response;
}

export const fetchCourseCount = async () => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'dashboard/fetchcoursecount', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = null;
    });

  return response;
}

export const getTeacherList = async (page, per_page, delay, search_term, location, categories, languages, min_price, max_price, subcategory_id) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'student/getteacherlist', {
      "offset": 0,
      "limit": 4,
      "languages": location,
      "speaks": languages,
      "category": categories,
      "min_rate": min_price,
      "max_rate": max_price,
      "search_query": search_term,
      "page": page,
      "per_page": per_page,
      "delay": delay,
      "subcategory_id": subcategory_id,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data;
    }).catch((error) => {
      response = [];
    });

  return response;
}

export const getTeacherAvailability = async (lesson_slug, user_timezone) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  var my_booking = [];
  var events_array = [];
  var booked = [];
  await axios
    .post(window.$API_URL + 'student/getteacheravailability', {
      "slug": lesson_slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then(async (success) => {
      response = success.data.response.availability;
      my_booking = success.data.response.my_booking;
      booked = success.data.response.booked;
      await response.forEach(async (event, index) => {
        console.log(event);
        if (user_timezone === null) {
          events_array.push({
            id: index + 1,
            title: "\nAvailable",
            start: moment(event.from_date).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss")
          });
        } else {
          events_array.push({
            id: index + 1,
            title: "\nAvailable",
            start: moment(event.from_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss")
          });
        }
      });

      await my_booking.forEach(async (event, index) => {
        if (user_timezone === null) {
          events_array.push({
            id: index + 1,
            title: "\nMy Own Booking",
            start: moment(event.start_date).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss"),
            color: 'purple'
          });
        } else {
          events_array.push({
            id: index + 1,
            title: "\nMy Own Booking",
            start: moment(event.start_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss"),
            color: 'purple'
          });
        }
      });
      await booked.forEach(async (event, index) => {
        if (user_timezone === null) {
          events_array.push({
            id: index + 1,
            title: "\nTeacher is Booked",
            start: moment(event.start_date).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss"),
            color: 'red'
          });
        } else {
          events_array.push({
            id: index + 1,
            title: "\nTeacher is Booked",
            start: moment(event.start_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss"),
            color: 'red'
          });
        }
      });
    }).catch((error) => {
      response = [];
    });

  return events_array;
}

export const getTeacherAvailabilityOnly = async (lesson_slug, user_timezone) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  var my_booking = [];
  var events_array = [];
  var booked = [];
  await axios
    .post(window.$API_URL + 'student/getteacheravailabilityonly', {
      "slug": lesson_slug
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then(async (success) => {
      response = success.data.response;
      await response.forEach(async (event, index) => {
        if (user_timezone === null) {
          events_array.push({
            id: index + 1,
            title: "\nAvailable",
            start: moment(event.from_date).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss")
          });
        } else {
          events_array.push({
            id: index + 1,
            title: "\nAvailable",
            start: moment(event.from_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss")
          });
        }
      });

    }).catch((error) => {
      response = [];
    });

  return events_array;
}



export const studentCalendar = async (user_timezone = null) => {
  var events = [];
  var events_array = [];
  const access_token = localStorage.getItem('token');
  await axios
    .post(window.$API_URL + 'student/studentschedulemonth', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then(async (response) => {
      await response.data.response.rows.forEach(async (event, index) => {
        console.log(event);
        if (user_timezone === null) {
          events_array.push({
            id: index + 1,
            title: event.class_data.title,
            start: moment(event.start_date).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss")
          });
        } else {
          events_array.push({
            id: index + 1,
            title: event.class_data.title,
            start: moment(event.start_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(event.end_date).tz(user_timezone).format("YYYY-MM-DD HH:mm:ss")
          });
        }
      });
    }).catch((error) => {
      events_array = [];
    });

  return events_array;
}

export const getTeacherListForMessage = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'student/getteacherlistforchat', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data.rows;
    }).catch((error) => {
      response = [];
    });

  return response;
}

export const getmessagelist = async (trash) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'student/getmessagelist', {
      trash: trash,
      search_term: ''
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data.rows;
    }).catch((error) => {
      response = [];
    });

  return response;
}

export const getmessagelistTeacher = async (trash) => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'teacher/getmessagelist', {
      trash: trash,
      search_term: ''
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data.rows;
    }).catch((error) => {
      response = [];
    });

  return response;
}

export const getStudentListForMessage = async () => {
  const access_token = localStorage.getItem('token');
  var response = [];
  await axios
    .post(window.$API_URL + 'teacher/getteacherlistforchat', {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data.rows;
    }).catch((error) => {
      response = [];
    });

  return response;
}

export const searchClasses = async (search_term) => {
  const access_token = localStorage.getItem('token');
  var response = null;
  await axios
    .post(window.$API_URL + 'courses/search', {
      "search_term": search_term,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${access_token}`,
      },
    })
    .then((success) => {
      response = success.data.data;
    }).catch((error) => {
      response = null;
    });
  return response;
}
