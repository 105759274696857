import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';
import TeacherRegistrationSidebar from '../Elements/TeacherRegistrationSidebar';
import { getTimezone, getLocation } from '../../Utils/Utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';


class TeacherStepTwo extends React.Component {
    constructor() {
        super();
        this.state = {
            timezone_data: [],
            location_data: [],
            timezone: '',
            location: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            stepselector: 'steptwo',

            page_type: 'add',

        };

    }

    async componentDidMount() {
        this.setState({
            timezone_data: await getTimezone(),
            location_data: await getLocation()
        });

        this.getTeacherInformation();
    }


    getTeacherInformation = async () => {
        const token = localStorage.token;

        this.setState({
            loading: 1
        });
        const response = axios.get(
            window.$API_URL + 'teacher/teacherprofileget',
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then(async (response) => {
            this.setState({
                loading: 0
            });

            var teacherdata = response.data.response;


            this.setState({
                address1: teacherdata.address1,
                address2: teacherdata.address2,
                city: teacherdata.city,
                state: teacherdata.state,
                zipcode: teacherdata.zipcode,
                timezone: teacherdata.timezone,
                location: teacherdata.location,

            });

            
      if(teacherdata.address1){
        this.setState({
          page_type: 'edit'
        });
      }



        }).catch((error) => {
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data.response).forEach((key) => {
                        this.state.returnError.push(error.response.data.response[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
                }

            }
            this.setState({
                loading: 0
            });
        })
    }

    timeZoneSelect = (e) => {
        this.setState({
            timezone: e
        })
    }
    locationSelect = (e) => {
        console.log(e);
        this.setState({
            location: e
        })
    }


    processStepTwo = async (e) => {
        const access_token = localStorage.getItem('token');
        this.setState({
            loading: 1
        });
        await axios.post(
            window.$API_URL + 'teacher/teacherprofileupdateonenew',
            {
                'address1': this.state.address1,
                'address2': this.state.address2,
                'city': this.state.city,
                'state': this.state.state,
                'zipcode': this.state.zipcode,
                'timezone': this.state.timezone,
                'country_id': this.state.location,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': `${access_token}`,
                }
            }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            NotificationManager.success('Your location information has been updated successfully.', 'Success');

            if (this.state.page_type == 'add') {
                setTimeout(() => {
                    window.location.href = '/teacher/stepthree';
                }, 500);
            } else {
                setTimeout(() => {
                    window.location.href = '/teacher/myprofile';
                }, 500);
            }
            //this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
            // window.location.href = '/student/stepone';
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (error.response.status === 422) {
                this.state.returnError = [];
                Object.keys(error.response.data.response).forEach((key) => {
                    this.state.returnError.push(error.response.data.response[key]);
                });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error(error.response.data.message, 'Validation Error');
            }
        });
    }


    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="signupspace">
                        <div class="signupspace-ttl">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-8">
                                {this.state.page_type == 'add' ? <h3><b>Sign up</b> Teacher account</h3> : <h3><b>Update</b> Teacher account</h3>}
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <TeacherRegistrationSidebar step={this.state.stepselector} />
                            </div>

                            <div class="col-md-9 col-sm-8">
                                <div class="signup-maincontent">
                                    <Form.Group>
                                        <div className="btn-group btn-block">
                                            <SelectSearch
                                                options={this.state.location_data}
                                                value={this.state.location}
                                                name="country"
                                                placeholder="Choose your Country"
                                                className="select-search fullWidth"
                                                search={true}
                                                onChange={this.locationSelect}
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group>
                                        <div className="btn-group btn-block">
                                            <SelectSearch
                                                options={this.state.timezone_data}
                                                value={this.state.timezone}
                                                name="timezone"
                                                placeholder="Choose your Timezone"
                                                className="select-search fullWidth"
                                                search={true}
                                                onChange={this.timeZoneSelect}
                                                autocomplete="off"
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="text" value={this.state.address1} placeholder="Street address" onChange={(e) => this.setState({ address1: e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="text" value={this.state.address2} placeholder="Street address 2" onChange={(e) => this.setState({ address2: e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="text" value={this.state.city} placeholder="City" onChange={(e) => this.setState({ city: e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="text" value={this.state.state} placeholder="State" onChange={(e) => this.setState({ state: e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="text" value={this.state.zipcode} placeholder="Zip code" onChange={(e) => this.setState({ zipcode: e.target.value })} />
                                    </Form.Group>


                                    <div class="signup-maincontent-footer">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                {/* <Link to="/teacher/stepone"><i class="zmdi zmdi-long-arrow-left"></i> Back</Link> */}
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                                <Button onClick={this.processStepTwo} variant="default" className="bt-custom ">Next</Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
                {this.state.loading ? <LoadingSpinner /> : ''}
            </div>

        );
    }

}
export default TeacherStepTwo;
