import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';

import TeacherProfileSidebar from '../Elements/TeacherProfileSidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';

import axios from 'axios';


class TeacherAccountSettings extends React.Component {
    constructor() {
        super();
        this.state = {
            old_password: '',
            new_password: '',
            confirm_password: '',


            course_on_profile: 0,
            profile_on_search_engine: 0,
            is_send_request: 0,
            is_view_friends: 0,
            is_lesson_reviews: 0,
            message_send: '',

            promotion_notification: 0,
            lesson_reminder_notification: 0,
            view_profile_notification: 0,
            send_message_notification: 0,

            is_deactivate: 0,

            course_booking_email: 0,
            lesson_booking_email: 0,
            cancel_session_email: 0,
            accept_lesson_email: 0,
            reject_lesson_email: 0,

            alert: ''


        };

    }

    componentDidMount() {
        this.getTeacherInformation();
    }

    getTeacherInformation = async () => {
        const token = localStorage.token;

        this.setState({
            loading: 1
        });
        const response = axios.get(
            window.$API_URL + 'teacher/teacherprofileget',
            { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
        ).then(async (response) => {
            this.setState({
                loading: 0
            });

            var teacherdata = response.data.response;


            this.setState({
                course_on_profile: teacherdata.course_on_profile,
                profile_on_search_engine: teacherdata.profile_on_search_engine,
                is_send_request: teacherdata.is_send_request,
                is_view_friends: teacherdata.is_view_friends,
                is_lesson_reviews: teacherdata.is_lesson_reviews,
                message_send: teacherdata.message_send,

                promotion_notification: teacherdata.promotion_notification,
                lesson_reminder_notification: teacherdata.lesson_reminder_notification,
                view_profile_notification: teacherdata.view_profile_notification,
                send_message_notification: teacherdata.send_message_notification,

                course_booking_email: teacherdata.course_booking_email,
                lesson_booking_email: teacherdata.lesson_booking_email,
                cancel_session_email: teacherdata.cancel_session_email,
                accept_lesson_email: teacherdata.accept_lesson_email,
                reject_lesson_email: teacherdata.reject_lesson_email,



            });




        }).catch((error) => {
            if (error.response.data) {
                if (error.response.status === 422) {
                    this.state.returnError = [];
                    Object.keys(error.response.data.response).forEach((key) => {
                        this.state.returnError.push(error.response.data.response[key]);
                    });
                    this.state.showing = true;
                    this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                    let errorList2 = this.state.returnError.map((char, i) => {
                        return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                    });
                    NotificationManager.error(errorList2, 'Validation Error');
                } else {
                    NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
                }

            }
            this.setState({
                loading: 0
            });
        })
    }



    courseProfileFlag = async (value) => {
        //console.log(value);
        var thisflag = this.state.course_on_profile == 0 ? 1 : 0;
        this.setState({
            course_on_profile: thisflag,
        });
    }

    profileSearchEngineFlag = async (value) => {
        //console.log(value);
        var thisflag = this.state.profile_on_search_engine == 0 ? 1 : 0;
        this.setState({
            profile_on_search_engine: thisflag,
        });
    }

    sendRequestFlag = async (value) => {
        //console.log(value);
        var thisflag = this.state.is_send_request == 0 ? 1 : 0;
        this.setState({
            is_send_request: thisflag,
        });
    }

    viewFriendsFlag = async (value) => {
        //console.log(value);
        var thisflag = this.state.is_view_friends == 0 ? 1 : 0;
        this.setState({
            is_view_friends: thisflag,
        });
    }

    lessonReviewFlag = async (value) => {
        //console.log(value);
        var thisflag = this.state.is_lesson_reviews == 0 ? 1 : 0;
        this.setState({
            is_lesson_reviews: thisflag,
        });
    }



    deactivateProfile = async (value) => {

        const getAlert = () => (<SweetAlert
            warning
            showCancel={true}
            showConfirm={true}
            confirmBtnText="Yes"
            cancelBtnText="No"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="warning"
            title="Deactivate Profile Confirmation"
            onConfirm={(e) => this.setState({ is_deactivate: 1, alert: "" })}
            onCancel={(e) => this.setState({ is_deactivate: 0, alert: "" })}
        >
            Are you sure you want to deactivate your profile?
        </SweetAlert>);
        this.setState({
            alert: getAlert()
        });

        // //console.log(value);
        // var thisflag = this.state.is_deactivate == 0 ? 1 : 0;
        // this.setState({
        //     is_deactivate: thisflag,
        // });
    }


    messageSendValue = async (value) => {
        this.setState({
            message_send: value,
        });
    }

    updatePromotionFlag = async (value) => {
        var thisflag = this.state.promotion_notification == 0 ? 1 : 0;
        this.setState({
            promotion_notification: thisflag,
        });

    }

    updateLessonReminderFlag = async (value) => {
        var thisflag = this.state.lesson_reminder_notification == 0 ? 1 : 0;
        this.setState({
            lesson_reminder_notification: thisflag,
        });

    }

    updateSendMessageFlag = async (value) => {
        var thisflag = this.state.send_message_notification == 0 ? 1 : 0;
        this.setState({
            send_message_notification: thisflag,
        });

    }

    updateViewProfileFlag = async (value) => {
        var thisflag = this.state.view_profile_notification == 0 ? 1 : 0;
        this.setState({
            view_profile_notification: thisflag,
        });

    }

    courseBookingEmail = async (value) => {
        var thisflag = this.state.course_booking_email == 0 ? 1 : 0;
        this.setState({
            course_booking_email: thisflag,
        });

    }

    lessonBookingEmail = async (value) => {
        var thisflag = this.state.lesson_booking_email == 0 ? 1 : 0;
        this.setState({
            lesson_booking_email: thisflag,
        });

    }

    cancelSessionEmail = async (value) => {
        var thisflag = this.state.cancel_session_email == 0 ? 1 : 0;
        this.setState({
            cancel_session_email: thisflag,
        });

    }

    acceptLessonEmail = async (value) => {
        var thisflag = this.state.accept_lesson_email == 0 ? 1 : 0;
        this.setState({
            accept_lesson_email: thisflag,
        });

    }

    rejectLessonEmail = async (value) => {
        var thisflag = this.state.reject_lesson_email == 0 ? 1 : 0;
        this.setState({
            reject_lesson_email: thisflag,
        });

    }



    processChangePassword = async (e) => {
        const access_token = localStorage.getItem('token');
        this.setState({
            loading: 1
        });
        await axios.post(
            window.$API_URL + 'teacher/updateaccountsetting',
            {
                'old_password': this.state.old_password,
                'new_password': this.state.new_password,
                'confirm_password': this.state.confirm_password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': `${access_token}`,
                }
            }
        ).then((response) => {
            this.setState({
                loading: 0
            });
            NotificationManager.success('Your password has been updated successfully.', 'Success');
            setTimeout(() => {
                window.location.href = '/teacher/courses';
            }, 500);
            //this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
            // window.location.href = '/student/stepone';
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (error.response.status === 422) {
                this.state.returnError = [];
                Object.keys(error.response.data.response).forEach((key) => {
                    this.state.returnError.push(error.response.data.response[key]);
                });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error(error.response.data.message, 'Validation Error');
            }
        });
    }



    courseBookingEmail = async (value) => {
        var thisflag = this.state.course_booking_email == 0 ? 1 : 0;
        this.setState({
            course_booking_email: thisflag,
        });

    }

    lessonBookingEmail = async (value) => {
        var thisflag = this.state.lesson_booking_email == 0 ? 1 : 0;
        this.setState({
            lesson_booking_email: thisflag,
        });

    }

    cancelSessionEmail = async (value) => {
        var thisflag = this.state.cancel_session_email == 0 ? 1 : 0;
        this.setState({
            cancel_session_email: thisflag,
        });

    }

    acceptLessonEmail = async (value) => {
        var thisflag = this.state.accept_lesson_email == 0 ? 1 : 0;
        this.setState({
            accept_lesson_email: thisflag,
        });

    }

    rejectLessonEmail = async (value) => {
        var thisflag = this.state.reject_lesson_email == 0 ? 1 : 0;
        this.setState({
            reject_lesson_email: thisflag,
        });

    }




    processUpdateSetting = async (e) => {
        const access_token = localStorage.getItem('token');
        this.setState({
            loading: 1
        });
        await axios.post(
            window.$API_URL + 'teacher/updateprivacysetting',
            {
                course_on_profile: this.state.course_on_profile,
                profile_on_search_engine: this.state.profile_on_search_engine,
                is_send_request: this.state.is_send_request,
                is_view_friends: this.state.is_view_friends,
                is_lesson_reviews: this.state.is_lesson_reviews,
                message_send: this.state.message_send,
                is_deactivate: this.state.is_deactivate,
                promotion_notification: this.state.promotion_notification,
                lesson_reminder_notification: this.state.lesson_reminder_notification,
                view_profile_notification: this.state.view_profile_notification,
                send_message_notification: this.state.send_message_notification,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': `${access_token}`,
                }
            }
        ).then((response) => {
            this.setState({
                loading: 0
            });


            if (this.state.is_deactivate == 1) {
                NotificationManager.success('Your profile has been deactivated. You can login again to activate it. ', 'Success');
                localStorage.removeItem('token');
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            } else {
                NotificationManager.success('Your profile settings updated successfully. ', 'Success');

                setTimeout(() => {
                    window.location.href = '/teacher/account-setting';
                }, 500);
            }

            //this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
            // window.location.href = '/student/stepone';
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (error.response.status === 422) {
                this.state.returnError = [];
                Object.keys(error.response.data.response).forEach((key) => {
                    this.state.returnError.push(error.response.data.response[key]);
                });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error(error.response.data.message, 'Validation Error');
            }
        });
    }

    processEmailSetting = async (e) => {
        const access_token = localStorage.getItem('token');
        this.setState({
            loading: 1
        });
        await axios.post(
            window.$API_URL + 'teacher/updateemailsetting',
            {
                course_booking_email: this.state.course_booking_email,
                lesson_booking_email: this.state.lesson_booking_email,
                cancel_session_email: this.state.cancel_session_email,
                accept_lesson_email: this.state.accept_lesson_email,
                reject_lesson_email: this.state.reject_lesson_email
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': `${access_token}`,
                }
            }
        ).then((response) => {
            this.setState({
                loading: 0
            });



            NotificationManager.success('Your profile settings updated successfully. ', 'Success');

            setTimeout(() => {
                window.location.href = '/teacher/account-setting';
            }, 500);


            //this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
            // window.location.href = '/student/stepone';
        }).catch((error) => {
            this.setState({
                loading: 0
            });
            if (error.response.status === 422) {
                this.state.returnError = [];
                Object.keys(error.response.data.response).forEach((key) => {
                    this.state.returnError.push(error.response.data.response[key]);
                });
                let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                });
                NotificationManager.error(errorList2, 'Validation Error');
            } else {
                NotificationManager.error(error.response.data.message, 'Validation Error');
            }
        });
    }

    render() {
        return (
            <div>

                <Header />

                <div class="container">
                    <div class="signupspace">
                        <div class="signupspace-ttl">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-8">
                                    <h3><b>Account Notification Setting</b></h3>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <TeacherProfileSidebar step={'account-setting'} />
                            </div>

                            <div class="col-md-9 col-sm-8">


                                <div class="signup-maincontent">

                                    <div className="col-md-12">
                                        <h4 className="tran_hstry_head">Change Password: </h4>

                                    </div>

                                    <div className=" col-md-12 ">
                                        <Form.Group>
                                            <Form.Control type="password" value={this.state.old_password} placeholder="Old Password *" onChange={(e) => this.setState({ old_password: e.target.value })} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control type="password" value={this.state.new_password} placeholder="New Password *" onChange={(e) => this.setState({ new_password: e.target.value })} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control type="password" value={this.state.confirm_password} placeholder="Confirm Password *" onChange={(e) => this.setState({ confirm_password: e.target.value })} />
                                        </Form.Group>



                                        <div class="signup-maincontent-footer">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-6">

                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                                    <Button onClick={this.processChangePassword} variant="default" className="bt-custom ">Update</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <br />

                                    <div className="col-md-12">
                                        <h4 className="tran_hstry_head">I want to receive a notification for: </h4>

                                    </div>

                                    <div className="checklist col-md-12 checkbox">
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.updateSendMessageFlag(e.target.value)} checked={this.state.send_message_notification == 1} name="" />&nbsp;&nbsp;<span>   When someone sends me a message.</span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.updateViewProfileFlag(e.target.value)} checked={this.state.view_profile_notification == 1} name="" />&nbsp;&nbsp;<span>   When Someone views my profile </span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.updateLessonReminderFlag(e.target.value)} checked={this.state.lesson_reminder_notification == 1} name="" />&nbsp;&nbsp;<span>   Upcoming Lesson Reminders </span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.updatePromotionFlag(e.target.value)} checked={this.state.promotion_notification == 1} name="" />&nbsp;&nbsp;<span>   Promotions, course recommendations, and helpful tips and resources from us.</span></label>

                                    </div>




                                    <div className="col-md-12">
                                        <hr className="mt-none" />
                                        <h4 className="tran_hstry_head">Profile Page Settings </h4>

                                    </div>
                                    <div className="checklist col-md-12 checkbox">
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.courseProfileFlag(e.target.value)} checked={this.state.course_on_profile == 1} name="" />&nbsp;&nbsp; <span>  Show your profile on search engines</span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.profileSearchEngineFlag(e.target.value)} checked={this.state.profile_on_search_engine == 1} name="" />&nbsp;&nbsp;<span>  Show courses that you are offering on your profile page.</span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.lessonReviewFlag(e.target.value)} checked={this.state.is_lesson_reviews == 1} name="" />&nbsp;&nbsp;<span>  Allow people to view your lesson reviews.</span></label>
                                        <br />
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.deactivateProfile(e.target.value)} checked={this.state.is_deactivate == 1} name="" />&nbsp;&nbsp;<span>  Deactivate Profile.</span></label>
                                    </div>



                                    <div class="signup-maincontent-footer">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-xs-6">

                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                                <Button onClick={this.processUpdateSetting} variant="default" className="bt-custom ">Update</Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <h4 className="tran_hstry_head">I want to receive email notification for: </h4>

                                    </div>

                                    <div className="checklist col-md-12 checkbox">
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.courseBookingEmail(e.target.value)} checked={this.state.course_booking_email == 1} name="" />&nbsp;&nbsp;<span>   Course Booking Email</span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.lessonBookingEmail(e.target.value)} checked={this.state.lesson_booking_email == 1} name="" />&nbsp;&nbsp;<span>   Lesson Booking Email</span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.cancelSessionEmail(e.target.value)} checked={this.state.cancel_session_email == 1} name="" />&nbsp;&nbsp;<span>   Cancel Sessions Email</span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.acceptLessonEmail(e.target.value)} checked={this.state.accept_lesson_email == 1} name="" />&nbsp;&nbsp;<span>   Lesson Accept Email</span></label>
                                        <br />
                                        <label className="mb10"><input type="checkbox" value="1" onClick={(e) => this.rejectLessonEmail(e.target.value)} checked={this.state.reject_lesson_email == 1} name="" />&nbsp;&nbsp;<span>   Lesson Reject Email</span></label>

                                    </div>

                                    <div class="signup-maincontent-footer">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-xs-6">

                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6 text-right">
                                                <Button onClick={this.processEmailSetting} variant="default" className="bt-custom ">Update</Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
                {this.state.alert}

                {this.state.loading ? <LoadingSpinner /> : ''}
            </div>

        );
    }

}
export default TeacherAccountSettings;
