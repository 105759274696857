import React from 'react';

const LoadingSpinner = () => (
    <div className={'loader_block'}>
        <span>
            <img src="/img/loader.gif" alt="Loading..." />
        </span>
    </div>
);

export default LoadingSpinner;
