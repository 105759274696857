import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import SelectSearch from 'react-select-search';

class CourseReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issue_description: '',
      issue_id: ''
    };
  }

  reportClose = () => {
    this.props.dispatch({ type: 'COURSE_REPORT_JSON', payload: null });
  }

  issueSelect = (issue_id) => {
    this.setState({
      issue_id: issue_id
    })
  }

  lessonReport = async (id, class_id, teacher_id, rating, review) => {
    //  alert(id);
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'student/reportissue',
      {
        detail: this.props.course_report_json,
        issue_id: this.state.issue_id,
        issue_description: this.state.issue_description
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        loading: false,
        alert: "",
        rating: 1,
        review: ""
      });
      this.props.dispatch({ type: 'COURSE_REPORT_JSON', payload: null });
      NotificationManager.success('Your issue has been reported successfully.');
      setTimeout(() => window.location.reload(), 3000);
    }).catch((error) => {
      if (error.response.status === 400) {
        this.setState({ loading: false });
        NotificationManager.error(error.response.data.message, 'Invalid Request');
      } else {
        this.setState({ loading: false });
        NotificationManager.error('Please fill up all the fields', 'Validation Error');
      }

    });

  }

  render() {
    return (
      <>
        <SweetAlert
          danger
          showCancel={true}
          showConfirm={true}
          confirmBtnText="Send Report"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="warning"
          title="Report This Class"
          onConfirm={(e) => this.lessonReport()}
          onCancel={(e) => this.reportClose()}
          customIcon='/img/red-flag.png'
          customClass='smallerIcon signup-maincontent input'
        >
          <div class="coursedetail-main-module"><p>You want to Report the Class Title <b>{this.props.course_report_json.class_data.title}</b> taught by <b>{this.props.course_report_json.class_data.class_teacher.first_name + ' ' + this.props.course_report_json.class_data.class_teacher.last_name}</b>. Please Select the issue which occured during the class and also describe in detail what caused the issue</p></div>

          <Form.Group style={{ width: '100%' }}>
            <div className="btn-group btn-block">
              <SelectSearch
                options={[{
                  value: 1,
                  name: 'Instructor did not appear'
                }, {
                  value: 2,
                  name: 'Platform was not working'
                }]}
                value={this.state.issue_id}
                name="issue_id"
                placeholder="Choose what happened during the class?"
                className="select-search fullWidth"
                search={false}
                onChange={this.issueSelect}
              /></div></Form.Group>

          <Form.Group>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Write Your review here"
              onChange={(e) => this.setState({ issue_description: e.target.value })}
              style={{ width: "100%" }}
            >{this.state.issue_description}
            </Form.Control>
          </Form.Group>
        </SweetAlert>
      </>

    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(CourseReport);


