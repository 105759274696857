import React from 'react';
import { Modal, Form, InputGroup, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { getProfileInformation } from '../../Utils/Utils';
import { connect } from 'react-redux';

class HeaderInner extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      student_show: 'active',
      teacher_show: '',
      is_menu_open: false,
      is_menu_outer_open: false,
    };
  }

  async componentDidMount() {
    document.body.classList.add('body-lightgrey');
    const user_check_api = await getProfileInformation();
    if (user_check_api === null) {
      window.location.href = '/';
    } else {
      this.props.dispatch({ type: 'PROFILE_DATA_UPDATE', payload: user_check_api });
      if (user_check_api.user_type_id === 1) {
        if (user_check_api.is_profile_complete === 0) {
          if (this.props.location.pathname !== '/student/stepone' && this.props.location.pathname !== '/student/change-password') {
            window.location.href = '/student/stepone';
          }
        } else {
          if (this.props.location.pathname === '/student/stepone') {
            window.location.href = '/student/courses';
          }
        }
      } else {
        if (user_check_api.is_profile_complete === 0) {
          if (this.props.location.pathname !== '/teacher/stepone' && this.props.location.pathname !== '/teacher/change-password') {
            window.location.href = '/teacher/stepone';
          }
        } else if (user_check_api.is_introduction_complete === 0) {
          if (this.props.location.pathname !== '/teacher/steptwo' && this.props.location.pathname !== '/teacher/change-password') {
            window.location.href = '/teacher/steptwo';
          }
        } else if (user_check_api.is_video_introduction_complete === 0) {
          if (this.props.location.pathname !== '/teacher/stepthree' && this.props.location.pathname !== '/teacher/change-password') {
            window.location.href = '/teacher/stepthree';
          }
        }
        else if (user_check_api.is_background_one_complete === 0) {
          if (this.props.location.pathname !== '/teacher/stepfour' && this.props.location.pathname !== '/teacher/change-password') {
            window.location.href = '/teacher/stepfour';
          }
        }
        else if (user_check_api.is_background_two_complete === 0) {
          if (this.props.location.pathname !== '/teacher/stepfive' && this.props.location.pathname !== '/teacher/change-password') {
            window.location.href = '/teacher/stepfive';
          }
        }
      }
    }
  }

  logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  render() {
    return (
      <div>
        {
          (this.props.profile_data !== null)
            ?

            <nav class="navbar navbar-default navbar-fixed-top navbar-custom">
              <div class="container">
                <div class="navbar-header">
                  <button type="button" onClick={(e) => this.setState({
                    is_menu_outer_open: !this.state.is_menu_outer_open
                  })} className={(this.state.is_menu_outer_open) ? "navbar-toggle" : 'navbar-toggle collapsed '} data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                  <Link className="navbar-brand" to="/">
                    <img src="/img/logo.svg" alt="" />
                  </Link>
                </div>
                <div id="navbar" className={(this.state.is_menu_outer_open) ? "navbar-collapse collapse in" : 'navbar-collapse collapse  '} >
                  <ul class="nav navbar-nav navbar-right navbar-nav-right">


                    <li>{
                      (this.props.profile_data.user_type_id == 1) ?
                        <Link to="/courses">Courses</Link> : ''
                    }</li>

                    <li>{
                      (this.props.profile_data.user_type_id == 1) ?
                        <Link to="/teachers/find">Find Teachers</Link> : ''
                    }
                    </li>
                    <li>
                      {
                        (this.props.profile_data.user_type_id == 1) ?
                          <Link className={this.props.location.pathname == '/student/courses' ? 'active' : ''} to="/student/courses">Dashboard</Link> : <Link  className={this.props.location.pathname == '/teacher/courses' ? 'active' : ''} to="/teacher/courses">Dashboard </Link>
                      }
                    </li>
                    <li><Link className={this.props.location.pathname == '/notifications' ? 'active' : ''} to="/notifications">Notifications {/*<span class="badge badge-custom">6</span>*/}</Link></li>
                    {
                      (this.props.profile_data !== null)
                        ?
                        (this.props.profile_data.user_type_id == 1)
                          ?
                          <li><Link className={this.props.location.pathname.includes('myconversation') == true ? 'active' : ''} to="/myconversation/">Messages {/*<span class="badge badge-custom">3</span>*/}	</Link></li>
                          :
                          <li><Link  className={this.props.location.pathname.includes('myconversation') == true ? 'active' : ''}  to="/myconversationteacher/">Messages { /* <span class="badge badge-custom">3</span> */}	</Link></li>
                        :
                        ''
                    }

                      {
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.user_type_id == 1) ?
                                <li><Link className={this.props.location.pathname.includes('financialcenter') == true ? 'active' : ''} to="/student/financialcenter">Financial Center</Link></li> :
                                <li><Link className={this.props.location.pathname.includes('financialcenter') == true ? 'active' : ''} to="/teacher/financialcenter">Financial Center</Link></li>
                              :
                              ""
                          }


                    <li class="navbar-custom-internal-profilepic">
                      <div className={(this.state.is_menu_open) ? "btn-group  open" : 'btn-group '}>
                        <Link className="dropdown-toggle" onClick={(e) => this.setState({
                          is_menu_open: !this.state.is_menu_open
                        })}>
                          <img src={
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.image !== null)
                                ?
                                this.props.profile_data.profile_picture
                                :
                                '/img/uploadprofilepic.svg'
                              :
                              '/img/uploadprofilepic.svg'
                          } class="img-circle" alt="" /> <i class="fas fa-chevron-down"></i>
                        </Link>
                        <ul class="dropdown-menu">
                          {
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.user_type_id == 1) ?
                                <li><Link to="/student/profile">Profile</Link></li> :
                                <li><Link to="/teacher/myprofile">Profile</Link></li>
                              :
                              ""
                          }
                          {
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.user_type_id == 1) ?
                                <li><Link to="/student/account-setting">Account Settings</Link></li> :
                                <li><Link to="/teacher/account-setting">Account Settings</Link></li>
                              :
                              ""
                          }
                          {/* <li><Link>Account settings</Link></li> */}
                          {
                            (this.props.profile_data !== null)
                              ?
                              (this.props.profile_data.user_type_id == 1) ?
                                <li><Link to="/student/financialcenter">Financial Center</Link></li> :
                                <li><Link to="/teacher/financialcenter">Financial Center</Link></li>
                              :
                              ""
                          }
                          { /* <li><Link>Help center</Link></li> */}
                          <li><Link onClick={(e) => this.logout()}>Log Out</Link></li>
                        </ul>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </nav>

            :
            ''
        }

      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderInner));