import React from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LeftBar from '../StudentSignUpSteps/LeftBar'
import { getTimezone, getLocation, getLanguage, getCategory } from '../../Utils/Utils';
import { Form, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

class StudentStepOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date('2001'),
      timezone_data: [],
      location_data: [],
      language_data: [],
      category_data: [],
      first_name: '',
      last_name: '',
      dob: '',
      timezone: '',
      country: '',
      gender: '',
      location: '',
      language: [],
      category: [],
      is_stepone_active: true,
      is_steptwo_active: false,
      stepselector: 'stepone'
    }
  }
  async componentDidMount() {
    this.setState({
      timezone_data: await getTimezone(),
      location_data: await getLocation(),
      language_data: await getLanguage(),
      category_data: await getCategory()
    })
  }
  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  handleChange = date => {
    this.setState({
      startDate: date,
      dob: date
    });
  };

  languageSelect = (e) => {
    this.setState({
      language: e
    })
  }
  timeZoneSelect = (e) => {
    console.log(e);
    this.setState({
      timezone: e
    })
  }
  locationSelect = (e) => {
    console.log(e);
    this.setState({
      location: e
    })
  }
  genderSelect = (e) => {
    this.setState({
      gender: e
    })
  }

  addCategory = (e, cat_id) => {
    if (e.target.checked) {
      this.state.category.push(cat_id);
    } else {
      var index = this.state.category.indexOf(cat_id);
      this.state.category.splice(index, 1);
    }
    this.setState({
      category: this.state.category
    })
  }
  processStepOne = async (e) => {
    const access_token = localStorage.getItem('token');
    var dob_selected = this.formatDate(this.state.startDate);
    await axios.post(
      window.$API_URL + 'student/studentprofileupdate',
      {
        'preferred_language': this.state.language,
        'location': this.state.location,
        'timezone': this.state.timezone,
        'first_name': this.state.first_name,
        'last_name': this.state.last_name,
        'dob': dob_selected,
        'gender': this.state.gender,
        'categories': this.state.category,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        }
      }
    ).then((response) => {
      NotificationManager.success('Your basic information has been saved successfully.', 'Success');
      setTimeout(() => {
        window.location.href = '/student/stepthree';
      }, 3000);
      //this.props.dispatch({ type: 'REGISTER_SHOW', payload: false });
      // window.location.href = '/student/stepone';
    }).catch((error) => {
      if (error.response.status === 422) {
        this.state.returnError = [];
        Object.keys(error.response.data.response).forEach((key) => {
          this.state.returnError.push(error.response.data.response[key]);
        });
        let errorList2 = this.state.returnError.map((char, i) => {
          return <p key={i} style={{ listStyleType: "none" }}>{char.message}</p>
        });
        NotificationManager.error(errorList2, 'Validation Error');
      } else {
        NotificationManager.error(error.response.data.message, 'Validation Error');
      }
    });
  }
  render() {
    return (
      <div>
        <Header />
        <div className="container">
          <div className="signupspace">
            <div className="signupspace-ttl">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-8">
                  <h3><b>Sign up</b> Student account</h3>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">

                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-4">
                <LeftBar step={this.state.stepselector} />
              </div>
              {
                (this.state.is_stepone_active) ?
                  <>
                    <div className="col-md-9 col-sm-8">
                      <div className="signup-maincontent">
                        <Form.Group>
                          <Form.Control type="text" placeholder="First name" onChange={(e) => this.setState({ first_name: e.target.value })} />
                        </Form.Group>

                        <Form.Group>
                          <Form.Control type="text" placeholder="Last name" onChange={(e) => this.setState({ last_name: e.target.value })} />
                        </Form.Group>

                        <Form.Group>
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            className="form-control"
                            wrapperClassName="fullWidth"
                          />
                        </Form.Group>

                        <Form.Group>
                          <div className="btn-group btn-block">
                            <SelectSearch
                              options={[{
                                value: 'Male',
                                name: 'Male'
                              }, {
                                value: 'Female',
                                name: 'Female'
                              }]}
                              value=""
                              name="gender"
                              placeholder="Choose your Gender"
                              className="select-search fullWidth"
                              search={false}
                              onChange={this.genderSelect}
                            />
                          </div>
                        </Form.Group>


                        <Form.Group>
                          <div className="btn-group btn-block">
                            <SelectSearch
                              options={this.state.timezone_data}
                              value=""
                              name="timezone"
                              placeholder="Choose your Timezone"
                              className="select-search fullWidth"
                              search={true}
                              onChange={this.timeZoneSelect}
                            />
                          </div>
                        </Form.Group>

                        <Form.Group>
                          <div className="btn-group btn-block">
                            <SelectSearch
                              options={this.state.location_data}
                              value=""
                              name="country"
                              placeholder="Choose your Country"
                              className="select-search fullWidth"
                              search={true}
                              onChange={this.locationSelect}
                            />
                          </div>
                        </Form.Group>

                        <Form.Group>
                          <div className="btn-group btn-block">
                            <SelectSearch
                              options={this.state.language_data}
                              value=""
                              name="languages"
                              placeholder="Preferred Languages"
                              className="select-search fullWidth"
                              search={true}
                              multiple={true}
                              onChange={this.languageSelect}
                              printOptions="on-focus"
                              closeOnSelect={false}
                            />
                          </div>
                        </Form.Group>

                        <div className="signup-maincontent-footer">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-6">

                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-6 text-right">
                              <a className="btn btn-default bt-custom" href="#" role="button" onClick={(e) => this.setState({ is_stepone_active: false, is_steptwo_active: true, stepselector: 'steptwo' })}>Next
                          </a>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </>
                  :
                  ''}
              {
                (this.state.is_steptwo_active) ?
                  <>
                    <div className="col-md-9 col-sm-8">
                      <div className="signup-maincontent signup-interests">
                        <h4>What do you want to learn in ConferenceMe?</h4>
                        <div className="section-introbanners">
                          <div className="row gutter-10">
                            {
                              this.state.category_data.map(cat => (
                                <><div className="col-md-3 col-sm-4 col-xs-6" >
                                  <a href="#">
                                    <div className="introbann-cover">
                                      <div className="container-introbann">
                                        <div className="introbann-pic">
                                          <input
                                            type="checkbox" className="category_checkbox"
                                            id={"category_checkbox_" + cat.id}
                                            onClick={(e) => this.addCategory(e, cat.id)}
                                          />
                                          <label class="category_checkbox_label" for={"category_checkbox_" + cat.id}>
                                            <img src={cat.category_picture} alt="Snow" style={{ width: "100%" }} />
                                          </label>
                                        </div>
                                        <div className="centered">
                                          <h4>{cat.name}</h4>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div></>
                              ))
                            }


                          </div>
                        </div>
                        <p className="txt-aclaratory">You can select multiple categories.</p>
                        <div className="signup-maincontent-footer">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-6">
                              <a href="#"><i className="zmdi zmdi-long-arrow-left"></i>Back</a>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-6 text-right">
                              <a
                                className="btn btn-default bt-custom"
                                href="#"
                                role="button"
                                onClick={this.processStepOne}
                              >Next</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  ''}

            </div>
          </div>
        </div>

        <Footer />
      </div >
    );
  }
}

export default StudentStepOne;
