import React from 'react';
import { Link, } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import RatingComponent from './RatingComponent'
import moment_timezone from 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';


class CourseRowComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      user_timezone: "America/New_York",
      alert: "",
    };
  }

  ratingTrigger = (course) => {
    console.log(course);
    this.props.dispatch({ type: 'COURSE_RATING_JSON', payload: course });
  }

  statusText = (statusid) => {
    if(statusid == 0){
      return " | Pending";
    }else if(statusid == 3) {
      return " | Rejected";
    }else if(statusid == 4){
      return " | Cancelled by Student";
    }else if(statusid == 5){
      return " | Cancelled by Teacher";
    }
  }

  reportTrigger = (course) => {
    this.props.dispatch({ type: 'COURSE_REPORT_JSON', payload: course });
  }

  utcToUsaTimeConvert = (date) => {
    return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD HH:mm:ss");
  }

  utcToUsaTimeConvertDisplay = (date) => {
    return moment_timezone.utc(date).tz(this.state.user_timezone).format("YYYY-MM-DD hh:mm A");
  }

  lessonCancel = (id) => {
    //  alert(id);
    const token = localStorage.token;
    this.setState({ loading: true });
    const response = axios.post(
      window.$API_URL + 'student/cancellesson',
      {
        lesson_id: id,
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((success) => {
      this.setState({
        loading: false,
        alert: ""
      });
      NotificationManager.success('You have cancelled the session successfully.');
      setTimeout(() => window.location.reload(), 3000);
    }).catch((error) => {
      this.setState({ loading: false });
      NotificationManager.error("An error occurred while cancelling the session. Please try again later.", 'Error');
    });

  }
  cancelLesson = (id, title, inst_name, date) => {

    const getAlert = () => (<SweetAlert
      warning
      showCancel={true}
      showConfirm={true}
      confirmBtnText="Yes"
      cancelBtnText="No"
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="warning"
      title="Session Cancel Confirmation"
      onConfirm={(e) => this.lessonCancel(id)}
      onCancel={(e) => this.setState({ alert: "" })}
    >
      You have requested to Cancel Lesson Title <b>{title}</b>  on <b>{date}</b>. <br />Are you sure you want to cancel ?
    </SweetAlert>);
    this.setState({
      alert: getAlert()
    });

  }
  removePopup = () => {
    this.setState({
      alert: ""
    });
  }

  render() {
    return (
      <>
        <div class="dash-mycourses-module  col-md-6 col-xs-12">
          <h6>{this.props.com_course.class_data.category_name.name}</h6>
          <h3>{this.props.com_course.class_data.title}</h3>
          <div class="dash-mycourses-module-date"><
            Link><i class="fas fa-calendar-alt"></i>
            {
              ' ' +
                (this.props.user_timezone !== null)
                ?
                moment(this.props.com_course.start_date).tz(this.props.user_timezone).format("MMM DD,YYYY h:mm A")
                :
                moment(this.props.com_course.start_date).format("MMM DD,YYYY h:mm A")
            }
          </Link></div>
          <p class="dash-mycourses-module-name">By {this.props.com_course.class_data.class_teacher.first_name + ' ' + this.props.com_course.class_data.class_teacher.last_name}
            {/* <Link>Contact</Link> */}

            {this.statusText(this.props.com_course.status)}
            
          </p>
          <div class="dash-mycourses-module-bot">
            <Link
              className="btn btn-default dash-mycourses-module-bot-bt-out"
              to={"/coursedetail/" + this.props.com_course.class_data.slug}
            >
              {(this.props.com_course.class_data.offering_type === 2) ? 'Lesson details' : 'Course details'}

            </Link>
            {
              (this.props.is_completed) ?
                (this.props.com_course.class_data.lesson_rating.length === 0)
                  ?
                  <Link
                    className="btn btn-default dash-mycourses-module-bot-bt-fill"
                    onClick={(e) => this.ratingTrigger(this.props.com_course)}
                  >Rate & review
              </Link>
                  : <ul class="list-inline btn "><RatingComponent
                    rating={this.props.com_course.class_data.lesson_rating}
                  /></ul>
                : ''
            }
            {
              (this.props.is_completed) ?
                (moment(moment(this.props.com_course.end_date).add(15, 'minutes').utc().format()).isAfter(moment().utc().format()))
                  ?
                  (this.props.com_course.issue_raised === null)
                    ?
                    <Link
                      className="btn btn-default dash-mycourses-module-bot-bt-fill"
                      onClick={(e) => this.reportTrigger(this.props.com_course)}
                    >Report
                </Link>
                    : 'Issue Raised'
                  : ''
                : ''
            }

            {
              (!this.props.is_completed   && this.props.com_course.status != 0 && this.props.com_course.status != 3 && this.props.com_course.status != 4 && this.props.com_course.status != 5) ?
                (this.props.com_course.class_meeting != null) ?
                  (this.utcToUsaTimeConvert(moment().format()) > this.utcToUsaTimeConvert(moment(this.props.com_course.start_date).add(-15, 'minutes').format()) && this.utcToUsaTimeConvert(moment().format()) < this.utcToUsaTimeConvert(moment(this.props.com_course.end_date).add(+15, 'minutes').format())) ?
                    <a className="btn btn-default dash-mycourses-module-bot-bt-out" target="_blank" href={(this.props.com_course.class_data.offering_type === 2) ? this.props.com_course.class_meeting.attend_url : this.props.com_course.attend_url}>Join</a> :
                    <a onClick={(e) => e.preventDefault()} className="btn btn-default dash-mycourses-module-bot-bt-fill disabled" title="You can't Join at this time." target="_blank" href="#">Join</a> :
                  <a onClick={(e) => e.preventDefault()} className="btn btn-default dash-mycourses-module-bot-bt-fill disabled" title="You can't Join at this time." target="_blank" href="#">Join</a>
                : ""
            }

            {
              (!this.props.is_completed && this.props.com_course.status == 1 && this.props.com_course.status != 4 && this.props.com_course.status != 5) ?
                (this.utcToUsaTimeConvert(moment().add(+24, 'hours').format()) < this.utcToUsaTimeConvert(this.props.com_course.start_date)) ?
                  <Link type="button" className="btn"
                    onClick={(e) => this.cancelLesson(this.props.com_course.id, this.props.com_course.class_data.title, this.props.com_course.class_data.class_teacher.first_name + " " + this.props.com_course.class_data.class_teacher.last_name, this.utcToUsaTimeConvert(this.props.com_course.start_date))}>
                    Cancel
                                                                        </Link> :
                  "" :
                ""
            }

          </div>
        </div>
        {this.state.alert}
      </>

    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(CourseRowComponent);
