import React from 'react';
import axios from 'axios';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import Header from './Elements/HeaderInner';
import Footer from './Elements/Footer';
import LoadingSpinner from './Elements/LoadingSpinner';
import StudentSidebarTwo from './Elements/StudentSidebarTwo';
import queryString from 'query-string';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

class PaypalAuth extends React.Component {
  constructor(props) {
    super();
    this.state = {
      card_show: "",
      paypal_show: "active",
      loading: 0,
    }
  }
  componentDidMount() {
    const data = queryString.parse(this.props.location.search);
    if (data.code !== undefined && data.code !== '') {
      this.registerAuthPaypal(data.code);
    }
  }
  registerAuthPaypal = async (code) => {
    this.setState({
      loading: 1
    });
    const token = localStorage.token;
    const response = axios.post(
      window.$API_URL + 'student/paypal-auth',
      {
        code: code
      },
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then((response) => {
      this.setState({
        loading: 0
      });
      if (response.data.user.user_type_id === 2) {
        var section = 'teacher';
      } else {
        var section = 'student'
      }
      this.props.dispatch({
        type: 'SWEET_ALERT_SUCCESS_JSON', payload: {
          title: 'Paypal Account is Linked',
          content: 'Congratulations!! Your Paypal Account is now linked, you can withdraw funds in Paypal Account now.',
          showCancel: false,
          showConfirm: true,
          confirmBtnText: 'Ok',
          confirmBtnBsStyle: 'success',
          success_callback: () => window.location.href = '/' + section + '/financialcenter',
          cancel_callback: () => ''
        }
      });


    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (!error.response) {
        NotificationManager.error(error, 'Validation Error');
        return;
      }
      this.props.dispatch({
        type: 'SWEET_ALERT_FAILED_JSON', payload: {
          title: 'Error in Authorization',
          content: 'There is some problem in Authorizing Paypal. Please Try again or contact Helpdesk',
          showCancel: false,
          showConfirm: true,
          confirmBtnText: 'Ok',
          confirmBtnBsStyle: 'danger',
          cancelBtnText: '',
          success_callback: () => window.location.href = '/',
          cancel_callback: () => { }
        }
      });
    });
  }
  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace-ttl">
            <h3>Withdrawal Request</h3>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-4">
              <StudentSidebarTwo current_page={'mywallet'} />
            </div>

            <div className="col-md-9 col-sm-8">

              <div className="signup-maincontent">

                <div className="col-md-12 col-sm-12" style={{ marginBottom: '20px' }}>
                  <ul class="nav nav-tabs dash-tabs" role="tablist">

                    <li className={this.state.card_show}>
                      <Link onClick={(e) => this.setState({ card_show: "active", paypal_show: "", payment_type: "card" })}>Bank Account</Link>
                    </li>
                    <li className={this.state.paypal_show}>
                      <Link onClick={(e) => this.setState({ card_show: "", paypal_show: "active", payment_type: "paypal" })}>Paypal</Link>
                    </li>
                  </ul>
                </div>

                <div class="tab-content">
                  <div role="tabpanel" className={this.state.card_show + " tab-pane"} id="cardd">
                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Withdrawal Amount" onChange={(e) => this.setState({ amount: e.target.value })} />
                      </Form.Group>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Account Name" onChange={(e) => this.setState({ name: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Account Number" onChange={(e) => this.setState({ number: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Routing Number" onChange={(e) => this.setState({ routing_number: e.target.value })} >

                        </Form.Control>
                      </Form.Group>

                    </div>


                    <div className="col-md-12 ">
                      <Form.Group>
                        <small class="text-muted ">
                          <i>We don't store your bank account information. We are taking this to use it for one time to transfer money to your bank account.</i>

                        </small>
                      </Form.Group>
                    </div>
                  </div>


                  <div role="tabpanel" className={this.state.paypal_show + " tab-pane"} id="paypall">

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Withdrawal Amount" onChange={(e) => this.setState({ paypal_amount: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Email Address" onChange={(e) => this.setState({ paypal_email: e.target.value })} />
                      </Form.Group>
                    </div>


                  </div>
                </div>

                <div>

                  <div className="signup-maincontent-footer">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-6">

                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-6 text-right">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div >

    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(mapDispatchToProps)(PaypalAuth);
