import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { getTeacherList, getLocation, getCategory, getLanguage } from '../../Utils/Utils';
import TeacherCard from '../common_components/TeacherCard';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SelectSearch from 'react-select-search';
import axios from 'axios';


class TeachersFind extends React.Component {
  constructor(props) {
    super();
    this.changeSearch = this.changeSearch.bind(this);
    this.state = {
      page: 1,
      per_page: 8,
      delay: 1,
      search_term: '',
      classes: { data: [] },
      is_filter_open: false,
      location_data: [],
      category_data: [],
      category_data_json: [],
      subcategory_id: '',
      subcategoriesData: [],
      categories: [],
      location: [],
      language: [],
      min_price: 0,
      max_price: 0
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      classes: await getTeacherList(this.state.page, this.state.per_page, this.state.delay, this.state.search_term, this.state.location, this.state.categories, this.state.language, this.state.min_price, this.state.max_price, this.state.subcategory_id),

    });
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
    this.setState({
      location_data: await getLocation(),
      category_data: await getCategory(),
      language_data: await getLanguage(),
    });
    //structuring category data
    var category_data_array = [];
    this.state.category_data.map((cat) => {
      category_data_array.push({
        value: cat.id,
        name: cat.name
      })
    })
    this.setState({
      category_data_json: category_data_array
    })
    //ends here
  }

  locationSelect = (e) => {
    console.log(e);
    this.setState({
      location: e
    })
  }

  updateSubcategory = (e) => {
    console.log(e);
    this.setState({
      subcategory_id: e
    })
  }

  categorySelect = (e) => {


    this.setState({ subcategoriesData: [], categories: [e], subcategory_id: "" });

    const response = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${e}`, {}, {}).then((response) => {
      this.setState({ subcategoriesData: response.data.data });
    }).catch((error) => { });

  }

  languageSelect = (e) => {
    this.setState({
      language: e
    })
  }

  async handlePageChange(pageNumber) {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      page: pageNumber
    })
    this.setState({
      page: pageNumber,
      classes: await getTeacherList(pageNumber, this.state.per_page, this.state.delay, this.state.search_term, this.state.location, this.state.categories, this.state.language, this.state.min_price, this.state.max_price, this.state.subcategory_id)
    });
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
  }

  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.setState({
          page: 1,
          search_term: search_term,
          classes: await getTeacherList(1, this.state.per_page, this.state.delay, search_term, this.state.location, this.state.categories, this.state.language, this.state.min_price, this.state.max_price, this.state.subcategory_id)
        });
      }, 1500)
    });
  }

  applyFilters = () => {
    this.handlePageChange(1);
    this.setState({
      is_filter_open: false
    })
  }

  resetFilters = async () => {
    await this.setState({
      min_price: 0,
      max_price: 0,
      language: [],
      location: [],
      categories: []
    });
    this.handlePageChange(1);
  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="container" style={{ minHeight: '600px' }}>
            <div class="row coursecategory-searchmodule">
              <div class="col-md-6 col-sm-6" >
                <Link to="/"><i class="zmdi zmdi-long-arrow-left"></i> Back to Homepage</Link>
              </div>

              {
                /*
              <div class="col-md-2 col-sm-2">
                 <p className="pointer" onClick={(e) => this.setState({
                   is_filter_open: !this.state.is_filter_open
                 })}> Open Filter <i class="fas fa-filter"></i></p>
               </div>
              <div class="col-md-2 col-sm-2">
                <p> Applied Filters : {(this.state.location.length + this.state.language.length + this.state.categories.length) + ((this.state.min_price !== 0) ? 1 : 0) + ((this.state.max_price !== 0) ? 1 : 0)}</p>
              </div>

              <div class="col-md-2 col-sm-2">
                <p className="pointer" onClick={(e) => this.resetFilters()}>Reset Filters <i class="fas fa-power-off"></i></p>
              </div>
                */
              }

            </div>

            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col-md-12 col-sm-12">

                <div className="col-md-3 col-sm-6">
                  <label>Filter by Category</label>
                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={this.state.category_data_json}
                        value={this.state.categories}
                        placeholder="Filter by Categories"
                        className="select-search fullWidth"
                        search={true}
                        onChange={(e) => this.categorySelect(e)}
                        //multiple={true}
                        autoFocus={false}
                        printOptions="on-focus"
                      //closeOnSelect={true}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-md-3 col-sm-6">
                  <label>Filter by Subcategory</label>
                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={this.state.subcategoriesData}
                        value={this.state.subcategory_id}
                        placeholder="Filter by Subcategory"
                        className="select-search fullWidth"
                        search={true}
                        onChange={(e) => this.updateSubcategory(e)}
                        //multiple={true}
                        autoFocus={false}
                        printOptions="on-focus"
                      //closeOnSelect={false}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-md-2 col-sm-6">
                  <label>Filter by Location</label>
                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={this.state.location_data}
                        value={this.state.location}
                        placeholder="Filter by location"
                        className="select-search fullWidth"
                        search={true}
                        onChange={(e) => this.locationSelect(e)}
                        multiple={true}
                        autoFocus={false}
                        printOptions="on-focus"
                        closeOnSelect={true}
                      />
                    </div>
                  </Form.Group>
                </div>




                <div className="col-md-2 col-sm-6">
                  <label>Filter by Language</label>
                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={this.state.language_data}
                        value={this.state.language}
                        placeholder="Filter by Language"
                        className="select-search fullWidth"
                        search={true}
                        onChange={(e) => this.languageSelect(e)}
                        multiple={true}
                        autoFocus={false}
                        printOptions="on-focus"
                        closeOnSelect={true}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-md-2 col-sm-6" style={{ float: 'left' }}>
                  <br />
                  <button className="btn btn-default bt-custom"
                    role="button"
                    onClick={(e) => this.applyFilters()}
                    style={{ padding: '12px 10px' }}
                  >
                    Apply
                  </button>
                  &nbsp;
                  <button className="btn btn-default bt-custom"
                    role="button"
                    onClick={(e) => this.resetFilters()}
                    style={{ padding: '12px 10px' }}
                  >
                    Reset
                  </button>

                </div>

                {/* <div className="col-md-1 col-sm-6">
                  <br />


                </div> */}

                {/* <div className="col-md-3 col-sm-6">

                  <div className="col-md-6 col-sm-6">
                    <label>Min Price</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder="Min Price"
                        onChange={(e) => this.setState({
                          min_price: e.target.value,
                          max_price: (parseInt(e.target.value) + parseInt(e.target.value))

                        })}
                        value={this.state.min_price}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <label>Max Price</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder="Max Price"
                        onChange={(e) => this.setState({ max_price: e.target.value })}
                        value={this.state.max_price}
                      />
                    </Form.Group>
                  </div>

                </div> */}
              </div>

            </div>


            <div class="coursespage-module-ttl">
              <h3>Find Teacher </h3>
            </div>

            <div class="row gutter-20">
              {
                (this.state.classes.data.length === 0)
                  ?
                  <p>No Teachers Found</p>
                  :
                  this.state.classes.data.map((class_data) => (
                    <TeacherCard teacher_data={class_data} />
                  ))
              }

            </div>
            {
              (this.state.classes.data.length > 0)
                ?
                <div class="text-right">
                  <Pagination
                    activePage={this.state.classes.page}
                    itemsCountPerPage={this.state.classes.per_page}
                    totalItemsCount={this.state.classes.total}
                    pageRangeDisplayed={this.state.classes.total_pages}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                :
                ''
            }
          </div>
        </div>
        {
          /*
        <Modal
                  animation={false}
                  show={this.state.is_filter_open}
                  onHide={(e) => this.setState({
                    is_filter_open: !this.state.is_filter_open
                  })}
                //dialogClassName="custom-modal"
                >
                  <Modal.Header closeButton>
                    Find Teachers by filter
                </Modal.Header>
        
                  <Modal.Body>
                    <div class="row">
                      <div className="col-md-12 col-sm-12 signup-maincontent ">
        
                        <div className="col-md-6 col-sm-12">
                          <label>Filter by Location</label>
                          <Form.Group>
                            <div className="btn-group btn-block">
                              <SelectSearch
                                options={this.state.location_data}
                                value={this.state.location}
                                placeholder="Filter by location"
                                className="select-search fullWidth"
                                search={true}
                                onChange={(e) => this.locationSelect(e)}
                                multiple={true}
                                autoFocus={false}
                                printOptions="on-focus"
                                closeOnSelect={false}
                              />
                            </div>
                          </Form.Group>
                        </div>
        
        
                        <div className="col-md-6 col-sm-12">
                          <label>Filter by Category</label>
                          <Form.Group>
                            <div className="btn-group btn-block">
                              <SelectSearch
                                options={this.state.category_data_json}
                                value={this.state.categories}
                                placeholder="Filter by Categories"
                                className="select-search fullWidth"
                                search={true}
                                onChange={(e) => this.categorySelect(e)}
                                multiple={true}
                                autoFocus={false}
                                printOptions="on-focus"
                                closeOnSelect={false}
                              />
                            </div>
                          </Form.Group>
                        </div>
        
                        <div className="col-md-6 col-sm-12">
                          <label>Filter by Language</label>
                          <Form.Group>
                            <div className="btn-group btn-block">
                              <SelectSearch
                                options={this.state.language_data}
                                value={this.state.language}
                                placeholder="Filter by Language"
                                className="select-search fullWidth"
                                search={true}
                                onChange={(e) => this.languageSelect(e)}
                                multiple={true}
                                autoFocus={false}
                                printOptions="on-focus"
                                closeOnSelect={false}
                              />
                            </div>
                          </Form.Group>
                        </div>
        
                        <div className="col-md-3 col-sm-6">
                          <label>Min Price</label>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Min Price"
                              onChange={(e) => this.setState({
                                min_price: e.target.value,
                                max_price: (parseInt(e.target.value) + parseInt(e.target.value))
        
                              })}
                              value={this.state.min_price}
                            />
                          </Form.Group>
                        </div>
        
                        <div className="col-md-3 col-sm-6">
                          <label>Max Price</label>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Max Price"
                              onChange={(e) => this.setState({ max_price: e.target.value })}
                              value={this.state.max_price}
                            />
                          </Form.Group>
                        </div>
        
                        <div className="col-md-6 col-sm-12">
                          <button className="btn btn-default bt-custom"
                            role="button"
                            onClick={(e) => this.applyFilters()}
                          >
                            Apply Filters
                          </button>
                        </div>
        
        
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
          */
        }


        <Footer />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(TeachersFind);