import React from 'react';
//import Background from '../images/banner.jpg';
class LandingTestimonial extends React.Component {
	render() {
		return (
			<div class="section-testimonial max-container">
                <div class="row no-gutter">
                    
                    <div class="col-md-6 col-sm-6">
                    <div class="signup-left">
                    <div class="container">
                        <div class="col-md-6 col-sm-6">
                        <h2>“I have completed five lessons and am amazed at how much I have already learned. Not only does my teacher is very nice, she also sends me worksheets and small sound clips that I work on at my own pace.”</h2>
                        <h3>- Adrien Ramirez</h3>
                        <p>Learning Photography in ConferenceMe</p>
                        </div>
                    </div>
                    </div>
                    </div>
                    
                    <div class="col-md-6 col-sm-6 hidden-xs">
                    <div class="signup-right"></div>
                    </div>
                    
                </div>
            </div>
		);
	}
}

export default LandingTestimonial;
