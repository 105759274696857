import React from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import HeaderInner from '../Elements/HeaderInner';
import LeftBar from '../StudentSignUpSteps/LeftBar'
import { getTeacherFollowList } from '../../Utils/Utils';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

class StudentStepThree extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
   teacher_list: [],
   teacher_followed: []
  }
 }

 async componentDidMount() {
  this.setState({
   teacher_list: await getTeacherFollowList()
  })
  this.state.teacher_list.map((teacher) => {
   if (teacher.teacher_follows !== null) {
    this.state.teacher_followed.push(teacher.id);
   }
  })
  this.setState({
   teacher_followed: this.state.teacher_followed
  })
  console.log(this.state.teacher_followed);
 }

 followTeacher = async (teacher_id, follow_status) => {
  const access_token = localStorage.getItem('token');
  await axios.post(
   window.$API_URL + 'student/studentfollowteacher',
   {
    'teacher_id': teacher_id,
    'follow_status': follow_status,
   },
   {
    headers: {
     'Content-Type': 'application/json',
     'x-access-token': `${access_token}`,
    }
   }
  ).then((response) => {
   if (follow_status) {
    this.state.teacher_followed.push(teacher_id);
   } else {
    var index = this.state.teacher_followed.indexOf(teacher_id);
    this.state.teacher_followed.splice(index, 1);
   }
   this.setState({
    teacher_followed: this.state.teacher_followed
   });
   console.log(this.state.teacher_followed);
  }).catch((error) => {
   if (error.response.status === 422) {
    this.state.returnError = [];
    Object.keys(error.response.data.response).forEach((key) => {
     this.state.returnError.push(error.response.data.response[key]);
    });
    let errorList2 = this.state.returnError.map((char, i) => {
     return <p key={i} style={{ listStyleType: "none" }}>{char.message}</p>
    });
    NotificationManager.error(errorList2, 'Validation Error');
   } else {
    NotificationManager.error(error.response.data.message, 'Validation Error');
   }
  });
 }

 render() {
  return (
   <div>
    <HeaderInner />
    <div className="container">
     <div className="signupspace">
      <div className="signupspace-ttl">
       <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-8">
         <h3><b>Sign up</b> Student account</h3>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-4 signupspace-ttl-right">
         <Link to="/student/courses">Skip <span className="hidden-xs">and complete later</span> <i className="zmdi zmdi-long-arrow-right"></i></Link>
        </div>
       </div>
      </div>
      <div className="row">
       <div className="col-md-3 col-sm-4">
        <LeftBar step="stepthree" />
       </div>
       <div className="col-md-9 col-sm-8">
        <div className="signup-maincontent">
         <h4>Connect with teachers from the subjects that interest you</h4>
         {
          (this.state.teacher_list.length > 0)
           ?
           <>
            <div className="section-followteachers-scroll">
             <div className="section-followteachers">
              {
               this.state.teacher_list.map((teacher) => (
                <>
                 <div className="followteacher-module">
                  <div className="row">
                   <div className="col-md-6 col-sm-8 col-xs-8">
                    <img src={teacher.image_fullpath ? teacher.image_fullpath : "/img/uploadprofilepic.svg"} className="img-circle pull-left" alt="Teacher Name" />
                    <div>
                     <h5>{teacher.first_name + ' ' + teacher.last_name}</h5>
                     <p>{teacher.teacher_categories[0].category.name}</p>
                    </div>
                   </div>
                   <div className="col-md-6 col-sm-4 col-xs-4 text-right">

                    <a className="btn btn-default" href="#" role="button" onClick={(e) => (teacher.teacher_follows !== null || this.state.teacher_followed.includes(teacher.id)) ? this.followTeacher(teacher.id, 0) : this.followTeacher(teacher.id, 1)}>
                     {(this.state.teacher_followed.includes(teacher.id)) ? 'Unfollow' : 'Follow'}
                    </a>
                   </div>
                  </div>
                 </div>
                </>
               ))
              }
             </div>
            </div>
            <p className="txt-aclaratory">You can select multiple teachers</p>
           </>
           :
           <p>Sorry no matching teacher found</p>
         }


         <div className="signup-maincontent-footer">
          <div className="row">
           <div className="col-md-6 col-sm-6 col-xs-8">

           </div>
           <div className="col-md-6 col-sm-6 col-xs-4 text-right">
            <Link className="btn btn-default bt-custom" to="/student/courses" role="button">Next</Link>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div >
    <Footer />
   </div >
  );
 }
}

export default StudentStepThree;
