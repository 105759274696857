import React from 'react';
import axios from 'axios';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import LoadingSpinner from '../Elements/LoadingSpinner';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { fetchBalanceTeacher, fetchPaypalAuth } from '../../Utils/Utils';
import queryString from 'query-string';
import StudentSidebarTwo from '../Elements/StudentSidebarTwo';


class StudentWithdrawalRequest extends React.Component {
  constructor() {
    super();
    this.state = {
      amount: '',
      number: '',
      name: '',
      routing_number: '',

      loading: 0,

      paypal_amount: '',
      paypal_email: '',

      payment_type: 'card',
      card_show: "active",
      paypal_show: "",
      paypal_auth: null
    };

  }

  async componentDidMount() {
    this.setState({
      balance: await fetchBalanceTeacher(),
      paypal_auth: await fetchPaypalAuth(),
    });
    if (this.state.paypal_auth !== null) {
      this.setState({
        paypal_email: this.state.paypal_auth.email
      });
    }
    console.log(this.state.paypal_auth);
  }

  makePayment = () => {
    const access_token = localStorage.getItem('token');
    this.setState({
      loading: 1
    });
    axios
      .post(window.$API_URL + 'student/withdrawlamount', {
        amount: this.state.amount,
        account_number: this.state.number,
        account_name: this.state.name,
        routing_number: this.state.routing_number,
        payment_type: this.state.payment_type,
        paypal_amount: this.state.paypal_amount,
        paypal_email: this.state.paypal_email,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        },
      })
      .then((success) => {
        this.setState({
          loading: 0
        });
        console.log(success);
        if (success.data.status == "failed") {
          NotificationManager.error(success.data.message, 'Error');
        } else {
          this.props.dispatch({
            type: 'SWEET_ALERT_SUCCESS_JSON', payload: {
              title: 'Withdrawal Request Successful',
              content: 'Thanks for the request Amount will be transfer to your account soon. ',
              showCancel: false,
              showConfirm: true,
              confirmBtnText: 'Okay',
              confirmBtnBsStyle: 'success',
              success_callback: () => window.location.href = '/student/financialcenter',
              cancel_callback: () => ''
            }
          });
        }

      }).catch((error) => {
        this.setState({
          loading: 0
        });
        if (error.response.data) {
          if (error.response.status === 422) {
            this.state.returnError = [];
            Object.keys(error.response.data.response).forEach((key) => {
              this.state.returnError.push(error.response.data.response[key]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            let errorList2 = this.state.returnError.map((char, i) => {
              return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
            });
            NotificationManager.error(errorList2, 'Validation Error');
          } else {
            NotificationManager.error("Some Error occured while making the payment. Please contact helpdesk if amount has been deducted!", 'Validation Error');
          }
        }

      });

  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="signupspace-ttl">
            <h3>Withdrawal Request</h3>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-4">
              <StudentSidebarTwo current_page={'mywallet'} />
            </div>

            <div className="col-md-9 col-sm-8">

              <div className="signup-maincontent">

                <div className="col-md-12 col-sm-12" style={{ marginBottom: '20px' }}>
                  <ul class="nav nav-tabs dash-tabs" role="tablist">

                    <li className={this.state.card_show}>
                      <Link onClick={(e) => this.setState({ card_show: "active", paypal_show: "", payment_type: "card" })}>Bank Account</Link>
                    </li>
                    <li className={this.state.paypal_show}>
                      <Link onClick={(e) => this.setState({ card_show: "", paypal_show: "active", payment_type: "paypal" })}>Paypal</Link>
                    </li>
                  </ul>
                </div>

                <div class="tab-content">

                  <div role="tabpanel" className={this.state.card_show + " tab-pane"} id="cardd">

                    <div className="col-md-6 col-sm-12">

                      <Form.Group>
                        <div className="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-usd"></i></span>
                          <Form.Control
                            type="text"
                            placeholder="Withdrawal Amount"
                            onChange={(e) => this.setState({ amount: e.target.value })}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Account Name" onChange={(e) => this.setState({ name: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Account Number" onChange={(e) => this.setState({ number: e.target.value })} />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Form.Group>
                        <Form.Control type="text" placeholder="Routing Number" onChange={(e) => this.setState({ routing_number: e.target.value })} >

                        </Form.Control>
                      </Form.Group>

                    </div>


                    <div className="col-md-12 ">
                      <Form.Group>
                        <small class="text-muted ">
                          <i>We don't store your bank account information. We are taking this to use it for one time to transfer money to your bank account.</i>

                        </small>
                      </Form.Group>
                    </div>
                  </div>


                  <div role="tabpanel" className={this.state.paypal_show + " tab-pane"} id="paypall">
                    {
                      (this.state.paypal_auth !== null)
                        ?
                        <>
                          <div className="col-md-6 col-sm-12">
                            <Form.Group>
                              <div className="input-group">
                                <span class="input-group-addon"><i class="glyphicon glyphicon-usd"></i></span>
                                <Form.Control
                                  type="text"
                                  placeholder="Withdrawal Amount"
                                  onChange={(e) => this.setState({ paypal_amount: e.target.value })}
                                />
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <Form.Group>
                              <Form.Control type="text" placeholder="Email Address" onChange={(e) => this.setState({ paypal_email: e.target.value })} readOnly={true} value={this.state.paypal_email} />
                            </Form.Group>
                          </div>
                        </>
                        :
                        <>
                          <div className="col-md-12 col-sm-12">
                            <a
                              role="button"
                              onClick={(e) => window.location.href = 'https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=AfG5_6Wvq0Mmzv-fRUzdUA__UpOdrQlF_HQR_NHCXXkVJjJC-8GZYxEmNrXnw4zO7va_WPljYU2HWLW-&scope=openid Email address&redirect_uri=https%3A%2F%2Fwww.conferenceme.com%2Fpaypal-auth'}
                            >
                              <img src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/login/connectwithpaypalbutton.png" />

                            </a>
                          </div>
                        </>
                    }

                  </div>
                </div>

                <div>

                  <div className="signup-maincontent-footer">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-6">

                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-6 text-right">
                        {
                          (this.state.card_show === 'active')
                            ?
                            <button
                              className="btn btn-default bt-custom"
                              role="button"
                              onClick={(e) => this.makePayment()}
                            > Submit
                          </button>
                            :
                            (this.state.paypal_auth !== null && this.state.paypal_show === 'active')
                              ?
                              <button
                                className="btn btn-default bt-custom"
                                role="button"
                                onClick={(e) => this.makePayment()}
                              > Withdraw using Paypal
                          </button>
                              :
                              ''
                        }



                      </div>

                    </div>
                  </div>

                </div>



              </div>
            </div>

          </div>
        </div>


        {this.state.loading ? <LoadingSpinner /> : ''}
        <Footer />
      </div >

    );
  }

}
const mapStateToProps = (state) => {
  return {
    sweet_alert_success_json: state.sweet_alert_success_json,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentWithdrawalRequest);
