import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';


class CategoryLessonsList extends React.Component {
    constructor() {
        super();
        this.state = {

        };

    }

    render() {
        return (
            <div>

                <Header />

                <div class="coursespage-section">
                    <div class="container">

                        <div class="row coursecategory-searchmodule">
                            <div class="col-md-6 col-sm-6">
                                <Link><i class="zmdi zmdi-long-arrow-left"></i> Back to all lessons</Link>
                            </div>
                            <div class="col-md-4 col-md-offset-2 col-sm-4 col-sm-offset-2">
                                <input type="email" class="form-control" placeholder="Search lesson" />
                            </div>
                        </div>

                        <div class="coursespage-module-ttl">
                            <h3>Financial lessons</h3>
                        </div>

                        <div class="row gutter-20">

                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely longer title should have a max...</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely invest in stock</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely invest in stock</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 hidden-sm col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely invest in stock</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely longer title should have a max...</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>




                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely longer title should have a max...</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely longer title should have a max...</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="coursespage-module">

                                    <img src="img/course_pic01.jpg" class="img-responsive" alt="" />
                                    <img src="img/uploadprofilepic.svg" class="coursespage-module-profilepic img-circle" alt="" />
                                    <div class="coursespage-module-txt">
                                        <h6>FINANCIAL</h6>
                                        <h4>Learn how to wisely longer title should have a max...</h4>
                                        <p>By <Link>Tom Martin</Link></p>
                                    </div>

                                    <div class="coursespage-module-bot">
                                        <ul class="list-inline coursespage-module-bot-icons">
                                            <li><i class="far fa-clock"></i> 45 min</li>
                                            <li><i class="fas fa-globe"></i> English</li>
                                            <li><i class="far fa-star"></i> 4.8</li>
                                        </ul>
                                        <div class="coursespage-module-bot-price">
                                            <div class="row gutter-20">
                                                <div class="col-md-6 col-sm-6 col-xs-10">
                                                    <h5>US$ 38,99</h5>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-2 text-right">
                                                    <Link><i class="fas fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>






                        </div>

                    </div>
                </div>




                <Footer />
            </div>

        );
    }

}
export default CategoryLessonsList;
