import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import moment from 'moment';
import ReactPlayer from 'react-player/lazy';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

class MyProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
      teacher_profile: null,
    };
  }

  async componentDidMount() {
    this.getTeacherInformation();
  }

  getTeacherInformation = async () => {
    const token = localStorage.token;

    this.setState({
      loading: 1
    });
    const response = axios.get(
      window.$API_URL + 'teacher/teacherprofileget',
      { headers: { 'Content-Type': 'application/json', 'x-access-token': `${token}` } }
    ).then(async (response) => {
      this.setState({
        loading: 0
      });

      var teacherdata = response.data.response;


      this.setState({
        teacher_profile: teacherdata
      });


    }).catch((error) => {
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data.response).forEach((key) => {
            this.state.returnError.push(error.response.data.response[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
        }

      }
      this.setState({
        loading: 0
      });
    })
  }

  render() {
    return (
      <div>

        <Header />

        <div class="coursespage-section">
          <div class="container">

            <div class="breadcrumb-custom">
              <Link>Home</Link> »
              <Link>Teachers</Link> »
              {(this.state.teacher_profile != null) ? this.state.teacher_profile.first_name + ' ' + this.state.teacher_profile.last_name : 'Teacher profile'}
            </div>
            {
              (this.state.teacher_profile != null)
                ?
                <div class="row">
                  <div class="col-md-8 col-sm-8">
                    <div class="teacherprofile-video sdasd">
                      {
                        (this.state.teacher_profile.youtube_url != null && this.state.teacher_profile.youtube_url != "null" && this.state.teacher_profile.youtube_url !== "")
                          ?
                          <ReactPlayer
                            url={this.state.teacher_profile.youtube_url}
                            width='100%'
                          />
                          :
                          (this.state.teacher_profile.profile_video != null && this.state.teacher_profile.profile_video !== '')
                            ?
                            <div className="img-responsive cover-picture">
                              <ReactPlayer
                                url={this.state.teacher_profile.profile_video}
                                width='100%'
                                height='100%'
                                className=""
                                controls={true}
                              />
                            </div>
                            :
                            <img src="/img/course_pic01.jpg" class="img-responsive cover-picture" alt="" />
                      }

                    </div>

                    <div class="visible-xs">
                      <div class="teacherprofile-namemodule">
                        <div class="pull-left">
                          <img src={(this.state.teacher_profile.image != null) ? this.state.teacher_profile.profile_picture : "/img/uploadprofilepic.svg"} class="img-circle" alt="" />
                        </div>
                        <div class="media-body">
                          <h4>{this.state.teacher_profile.first_name + ' ' + this.state.teacher_profile.last_name}</h4>
                          <p>{this.state.teacher_profile.teacher_categories[0] ? this.state.teacher_profile.teacher_categories[0].category.name : ""}</p>
                        </div>
                      </div>

                      {
                        /*
                       <div class="teacherprofile-col-availability">
                         <Link className="btn btn-default btn-block bt-custom" >Contact teacher</Link>
                       </div>
                        */
                      }

                      <ul class="list-inline teacherprofile-col-availability">
                        <li><Link><i class="far fa-calendar-alt"></i><span>Check availability</span></Link></li>
                      </ul>

                    </div>


                    <div class="coursedetail-col visible-xs">
                      <ul class="coursedetail-col-list">
                        <li><i class="far fa-star"></i><span>{moment(this.state.teacher_profile.dob).format("MM/DD/YYYY")}</span></li>
                        <li><i class="far fa-copy"></i><span>5 lessons</span></li>
                        <li><i class="far fa-clock"></i><span>30 minute lesson</span></li>
                        <li><i class="far fa-chart-bar"></i><span>Intermediate level</span></li>
                        <li><i class="fas fa-globe"></i><span>English</span></li>
                      </ul>
                    </div>

                    <div class="teacherprofile-namemodule hidden-xs">
                      <div class="pull-left">
                        <img src={(this.state.teacher_profile.image != null) ? this.state.teacher_profile.profile_picture : "/img/uploadprofilepic.svg"} class="img-circle" alt="" />
                      </div>
                      <div class="media-body">
                        <h4>{this.state.teacher_profile.first_name + ' ' + this.state.teacher_profile.last_name}</h4>
                        <p>{this.state.teacher_profile.teacher_categories[0] ? this.state.teacher_profile.teacher_categories[0].category.name : ""}</p>
                      </div>
                    </div>


                    <div class="coursedetail-main-module">
                      <p>{this.state.teacher_profile.credentials_overview}</p>
                    </div>

                    <div class="coursedetail-main-module">
                      <h3>Education & experience</h3>
                      {
                        this.state.teacher_profile.type_education.map((edu) => (
                          <>
                            <h4>{edu.name}</h4>
                            <p>{edu.description}</p>
                            <p><strong>From:</strong> {moment(edu.from_date).format("MMM DD,YYYY")}</p>
                            <p><strong>To:</strong> {
                              moment(edu.to_date).format("MMM DD,YYYY")
                            }</p>
                          </>
                        ))
                      }

                      <Link to="/teacher/stepfour" className="btn btn-default dash-mycourses-module-bot-bt-out">Edit</Link>

                    </div>

                    <div class="coursedetail-main-module">
                      <h3>Work experience</h3>
                      {
                        this.state.teacher_profile.type_experience.map((exp) => (
                          <>
                            <h4>{exp.name}</h4>
                            <p>{exp.description}</p>
                            <p><strong>From:</strong> {moment(exp.from_date).format("MMM DD,YYYY")}</p>
                            <p><strong>To:</strong> {
                              (exp.is_present_working) ? 'Present' : moment(exp.to_date).format("MMM DD,YYYY")

                            }</p>
                          </>
                        ))
                      }
                      <Link to="/teacher/stepfive" className="btn btn-default dash-mycourses-module-bot-bt-out">Edit</Link>
                    </div>

                    <div class="coursedetail-main-module">
                      <h3>Certificates</h3>
                      {
                        this.state.teacher_profile.type_certificate.map((crt) => (
                          <>
                            <h4>{crt.name}</h4>
                            <p>{crt.description}</p>
                            <p><strong>Issued On :</strong> {moment(crt.from_date).format("MMM DD,YYYY")}</p>
                            <p><strong>Issued By :</strong> {crt.issued_by}</p>
                          </>
                        ))
                      }
                      <Link to="/teacher/stepsix" className="btn btn-default dash-mycourses-module-bot-bt-out">Edit</Link>
                    </div>


                  </div>

                  <div class="col-md-3 col-sm-4 coursedetail-col col-md-offset-1 col-sm-offset-0 hidden-xs">

                    <ul class="coursedetail-col-list">
                      <li><i class="far fa-copy"></i><span>{moment(this.state.teacher_profile.dob).format("MM/DD/YYYY")}</span></li>
                      <li><i class="far fa-copy"></i><span> {this.state.teacher_profile.address1 + " "

                        + this.state.teacher_profile.city + " "
                        + this.state.teacher_profile.state
                      }</span></li>
                      <li><i class="far fa-copy"></i><span> {this.state.teacher_profile.country_name ? this.state.teacher_profile.country_name.name : ""}</span></li>
                      <li><i class="fas fa-globe"></i><span>
                        {this.state.teacher_profile.language_teach.map((lang, index) => (
                          (index > 0) ? " | " + lang.language_teaches_temp.name : lang.language_teaches_temp.name
                        ))}
                      </span></li>
                    </ul>

                    <div class="teacherprofile-col-availability">
                      <Link to="/teacher/stepone" className="btn btn-default btn-block bt-custom" style={{ textTransform: 'capitalize' }}>Update Profile</Link>
                    </div>
                    <div class="teacherprofile-col-availability">
                      <Link to="/teacher/stepthree" className="btn btn-default dash-mycourses-module-bot-bt-out btn-block " style={{ padding: '18px 32px' }}>Update Presentation</Link>
                    </div>

                    {/* <ul class="list-inline teacherprofile-col-availability">
                                            <li><Link><i class="far fa-calendar-alt"></i><span>Check availability</span></Link></li>
                                        </ul> */}

                  </div>

                </div>

                :
                ''
            }



          </div>
        </div>



        <Footer />
      </div >

    );
  }

}
export default MyProfile;
