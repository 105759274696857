import React from 'react';
import axios from 'axios';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/HeaderInner';
import Footer from '../Elements/Footer';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StudentSidebarTwo from '../Elements/StudentSidebarTwo';
import SelectSearch from 'react-select-search';
import { getTimezone, getLocation, getLanguage, getCategory, studentProfileGet } from '../../Utils/Utils';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class StudentProfileEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      timezone_data: [],
      location_data: [],
      language_data: [],
      category_data: [],
      category_data_json: [],
      profile_data: null,
      first_name: '',
      last_name: '',
      dob: '',
      gender: '',
      credentials_overview: '',
      timezone: '',
      location: '',
      langauges: [],
      categories: [],
      year: '',
      month: '',
      day: '',

      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1 / 1,
      profile_image: '',
      profile_image_init: 'images/original.jpg',
      src_crop_version: null,
      crop: {
        unit: '%',
        width: 100,
        aspect: 1 / 1,
      },
      imageBlob: ''
    };

  }
  languageSelect = (e) => {
    this.setState({
      language: e
    })
  }
  timeZoneSelect = (e) => {
    console.log(e);
    this.setState({
      timezone: e
    })
  }
  locationSelect = (e) => {
    console.log(e);
    this.setState({
      location: e
    })
  }
  async componentDidMount() {
    this.setState({
      profile_data: await studentProfileGet(),
      timezone_data: await getTimezone(),
      location_data: await getLocation(),
      language_data: await getLanguage(),
      category_data: await getCategory()
    });
    if (this.state.profile_data !== null) {
      this.setState({
        first_name: this.state.profile_data.first_name,
        last_name: this.state.profile_data.last_name,
        credentials_overview: this.state.profile_data.credentials_overview,
        gender: this.state.profile_data.gender,
        dob: new Date(this.state.profile_data.dob),
        timezone: this.state.profile_data.timezone,
        location: this.state.profile_data.location,
      });
      this.formatDate(this.state.dob);
      // fetching student languages
      var student_languages = [];
      var student_categories = []
      this.state.profile_data.language_name.map((lang) => {
        student_languages.push(lang.language_id);
      })
      this.state.profile_data.student_categories.map((cat) => {
        student_categories.push(cat.category_id);
      })
      this.setState({
        languages: student_languages,
        language: student_languages,
        categories: student_categories
      })
      // ends here
      //structuring category data
      var category_data_array = [];
      this.state.category_data.map((cat) => {
        category_data_array.push({
          value: cat.id,
          name: cat.name
        })
      })
      this.setState({
        category_data_json: category_data_array
      })
      //ends here
    }
  }

  genderSelect = (e) => {
    this.setState({
      gender: e
    })
  }



  handleChange = date => {
    this.formatDate(date);
    this.setState({
      startDate: date,
      dob: date
    });
  };

  categorySelect = (e) => {
    console.log(e);
    this.setState({
      categories: e
    })
  }
  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.setState({
      year: year,
      month: month,
      day: day
    })
    return [year, month, day].join('-');
  }
  editProfile = () => {
    const access_token = localStorage.getItem('token');
    var dob_selected = this.formatDate(this.state.dob);
    axios.post(
      window.$API_URL + 'student/updateprofile',
      {
        'credentials_overview': this.state.credentials_overview,
        'language': this.state.language,
        'location': this.state.location,
        'timezone': this.state.timezone,
        'first_name': this.state.first_name,
        'last_name': this.state.last_name,
        'dob': dob_selected,
        'gender': this.state.gender,
        'categories': this.state.categories,
        'year': this.state.year,
        'month': this.state.month,
        'day': this.state.day
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${access_token}`,
        }
      }
    ).then((response) => {
      NotificationManager.success('Your profile has been updated successfully.', 'Success');
      setTimeout(() => {
        window.location.href = '/student/profile';
      }, 3000);
    }).catch((error) => {
      if (error.response.status === 422) {
        this.state.returnError = [];
        Object.keys(error.response.data.response).forEach((key) => {
          this.state.returnError.push(error.response.data.response[key]);
        });
        let errorList2 = this.state.returnError.map((char, i) => {
          return <p key={i} style={{ listStyleType: "none" }}>{char.message}</p>
        });
        NotificationManager.error(errorList2, 'Validation Error');
      } else {
        NotificationManager.error(error.response.data.message, 'Validation Error');
      }
    });
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ src_crop_version: reader.result });
        console.log(reader.result);
        //alert('jiii');
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  cropAndUploadPicture = () => {
    const token = localStorage.token;
    const data = new FormData();
    data.append('file', this.state.imageBlob);
    this.setState({
      loading: 1
    });
    const response = axios.post(
      window.$API_URL + 'student/addprofileimage', data,
      {
        headers: {
          'Content-Type': 'application/json', 'x-access-token': `${token}`
        }
      }
    ).then((success) => {
      this.setState({
        profile_image_init: success.data.filename,
        loading: 0,
        src_crop_version: null,
        croppedImageUrl: null
      });
      window.location.reload();
    }).catch((error) => {
      this.setState({
        loading: 0
      });
      if (error.response.data) {
        if (error.response.status === 422) {
          this.state.returnError = [];
          Object.keys(error.response.data).forEach((key) => {
            this.state.returnError.push(error.response.data[key]);
          });
          this.state.showing = true;
          this.setState({ returnError: this.state.returnError, showing: this.state.showing });
          let errorList2 = this.state.returnError.map((char, i) => {
            return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
          });
          NotificationManager.error(errorList2, 'Validation Error');
        } else {
          NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
        }
      }
    })
  }


  onFileChangeHandler = async event => {
    //  console.log(event.target.files[0]);
    this.setState({
      profile_image: event.target.files[0]
    });
    var upload_type = event.target.files[0].type;
    if (upload_type.search("image") !== -1) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      //  console.log("hii" + reader.result);
      reader.onload = async (e) => {
        var image = new Image();
        image.src = e.target.result;
        image.onload = (imgDetail) => {
          console.log(imgDetail);
          var height = imgDetail.target.height;
          var width = imgDetail.target.width;
          if (height === width) {
            console.log(height + "  " + width);
            console.log('ready to go');
            const token = localStorage.token;
            const data = new FormData();
            data.append('file', this.state.profile_image);

            this.setState({
              loading: 1
            });

            const response = axios.post(
              window.$API_URL + 'api/student/addprofileimage', data,
              {
                headers: {
                  'Content-Type': 'application/json', 'x-access-token': `${token}`
                }
              }
            ).then((success) => {
              this.setState({
                profile_image_init: success.data.filename,
                loading: 0
              });
              window.location.reload();
            }).catch((error) => {

              this.setState({
                loading: 0
              });
              if (error.response.data) {
                if (error.response.status === 422) {
                  this.state.returnError = [];
                  Object.keys(error.response.data).forEach((key) => {
                    this.state.returnError.push(error.response.data[key]);
                  });
                  this.state.showing = true;
                  this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                  let errorList2 = this.state.returnError.map((char, i) => {
                    return <li key={i} style={{ listStyleType: "none" }}>{char.message}</li>
                  });
                  NotificationManager.error(errorList2, 'Validation Error');
                } else {
                  NotificationManager.error("There was some problem in updating the profile. Please try again", 'Validation Error');
                }
              }
            })

          } else {
            NotificationManager.error("Please insert a Square Image", 'Image File Error');
          }
        }

      }
    } else {
      NotificationManager.error("Please Upload Image File", 'Image File Error');
    }
  }

  getBase64(file, cb) {
    let document = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
      //  document = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
    //  return document;
  }

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  /*onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
  }*/
  onCropComplete = crop => {
    this.makeClientCrop(crop);
    //   alert('ok');
  };

  onZoomChange = zoom => {
    this.setState({ zoom })
  }
  onImageLoaded = image => {
    this.imageRef = image;
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        this.setState({ imageBlob: blob })
      }, 'image/jpeg');
    });
  }


  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  render() {
    return (
      <div>

        <Header />

        <div class="container">
          <div class="breadcrumb-custom">
            <a href="#">Home</a> » Profile Edit
          </div>
          <div class="row">
            <div class="col-md-2 col-sm-2">
              <StudentSidebarTwo current_page={'profile'} />
            </div>

            <div className="col-md-9 col-sm-8 signup-maincontent ">
              {(this.state.profile_data !== null)
                ?
                <div class=" studentprofile-namemodule text-center">
                  <div class="col-md-6" style={{marginTop: '10px'}}>
                    <h4>{this.state.profile_data.first_name + " " + this.state.profile_data.last_name}</h4>
                    <p>{this.state.profile_data.email}</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src={(this.state.profile_data.image !== null) ? this.state.profile_data.filename : "/img/uploadprofilepic.svg"}
                      class="img-circle"
                      style={{ verticalAlign: "middle", width: '75px' }}
                      alt=""
                    />
                    

                    <label className="btn btn-primary btn-sm  btn btn-success grnsuccess small">Change Picture <input type="file" style={{ display: `${this.props.showModal ? 'block' : 'none'}`, }} onChange={this.onSelectFile} />
                    </label>
                    
                    <div className={`modal login_signup_modal bcmtechar ${this.state.src_crop_version != null ? 'show' : ''}`} style={{ display: `${this.state.src_crop_version != null ? 'block' : 'none'}`, }} id="loginmodal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                      <div className="" role="document">
                        <div className="modal-content" style={{ width: '80%', marginLeft: 'auto', marginTop: '30px', marginRight: 'auto' }}>
                          <div className="modal-body fixhight">
                            <div className="row">
                              <div className="col-md-6">
                                {this.state.src_crop_version && (
                                  <div><ReactCrop
                                    src={this.state.src_crop_version}
                                    crop={this.state.crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                  /></div>
                                )}
                                <br />
                                <button className="btn btn-primary  btn btn-success grnsuccess small" onClick={(e) => this.setState({ src_crop_version: null, croppedImageUrl: null })}>Exit</button>
                              </div>

                              <div className="col-md-6">
                                {this.state.croppedImageUrl && (
                                  <div>
                                    <div><img alt="Crop" style={{ maxWidth: '45%' }} src={this.state.croppedImageUrl} /></div>
                                    <br />
                                    <button className="btn btn-primary  btn btn-success grnsuccess small" onClick={this.cropAndUploadPicture}>Crop & Upload</button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                </div>
                :
                ''}

<div className="clearfix"></div>                      
              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <Form.Control className="star-required" type="text" placeholder="First name" onChange={(e) => this.setState({ first_name: e.target.value })} value={this.state.first_name} />
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <Form.Control type="text" placeholder="Last name" onChange={(e) => this.setState({ last_name: e.target.value })} value={this.state.last_name} />
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <DatePicker
                    selected={this.state.dob}
                    onChange={this.handleChange}
                    className="form-control"
                    wrapperClassName="fullWidth"
                    placeholder="Date of Birth"
                  />
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <div className="btn-group btn-block">
                    <SelectSearch
                      options={[{
                        value: 'Male',
                        name: 'Male'
                      }, {
                        value: 'Female',
                        name: 'Female'
                      }]}
                      value={this.state.gender}
                      name="gender"
                      placeholder="Choose your Gender"
                      className="select-search fullWidth"
                      search={false}
                      onChange={this.genderSelect}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-12 col-sm-12">
                <Form.Group>
                  <Form.Control as="textarea" rows="3" placeholder="About Me" onChange={(e) => this.setState({ credentials_overview: e.target.value })} value={this.state.credentials_overview} >
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <div className="btn-group btn-block">
                    <SelectSearch
                      options={this.state.timezone_data}
                      value={this.state.timezone}
                      name="timezone"
                      placeholder="Choose your Timezone"
                      className="select-search fullWidth"
                      search={true}
                      onChange={this.timeZoneSelect}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <div className="btn-group btn-block">
                    <SelectSearch
                      options={this.state.location_data}
                      value={this.state.location}
                      name="country"
                      placeholder="Choose your Country"
                      className="select-search fullWidth"
                      search={true}
                      onChange={this.locationSelect}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <div className="btn-group btn-block">
                    <SelectSearch
                      options={this.state.language_data}
                      value={this.state.languages}
                      name="languages"
                      placeholder="Preferred Languages"
                      className="select-search fullWidth"
                      search={true}
                      multiple={true}
                      onChange={this.languageSelect}
                      printOptions="on-focus"
                      closeOnSelect={false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12">
                <Form.Group>
                  <div className="btn-group btn-block">
                    <SelectSearch
                      options={this.state.category_data_json}
                      value={this.state.categories}
                      name="categories"
                      placeholder="Preferred Categories"
                      className="select-search fullWidth"
                      search={true}
                      multiple={true}
                      onChange={this.categorySelect}
                      printOptions="on-focus"
                      closeOnSelect={false}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-12 col-sm-12 text-right">
                <button className="btn btn-default bt-custom"
                  role="button"
                  onClick={(e) => this.editProfile()}
                >Update
                  </button>
              </div>

            </div>

          </div>
        </div>



        <Footer />
      </div >

    );
  }

}
const mapStateToProps = (state) => {
  return {
    sweet_alert_success_json: state.sweet_alert_success_json,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfileEdit);
