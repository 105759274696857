import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { getTeacherList } from '../../Utils/Utils';
import TeacherCard from '../common_components/TeacherCard';
import Pagination from "react-js-pagination";
class PrivacyPolicy extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="terms">
            <div class="container">

              <h1 dir="ltr" id="docs-internal-guid-982bc1ee-7fff-feaf-33d0-a0707aebd133">
                Xtenfer Inc. dba ConferenceMe Privacy Policy
</h1>
              <h2 dir="ltr">
                Last revised: September 30, 2020
</h2>
              <p dir="ltr">
                We request that you read this Privacy Policy carefully. Taken together, the
                provisions set forth in this Privacy Policy, the Terms of Use for the
                Website, and any additional conditions presented to you upon registration,
                detail the applicable conditions governing the use of information provided
                during registration or use of the Website.
</p>
              <p dir="ltr">
                Your privacy is top priority to us. We want to ensure that you enjoy a
                rewarding experience with us as much as possible. We want you to have
                complete confidence while using our services, ConferenceMe.com and our
                mobile site (collectively referred to as the “Website”). We are sensitive
                to the issue of confidentiality in online dealings and committed to
                transparency regarding our protection of individual privacy. This Privacy
                Policy is intended to inform users about the general approach we use in
                collecting, using and disseminating user information via the Website and
demonstrate our commitment to privacy and security and supplement our    <a href="https://conferenceme.com/terms">Terms of Use</a>.
</p>
              <p dir="ltr">
                Additionally, as the Website may be of interest to children under the age
                of 13 years old, we are committed to complying fully with the Children’s
                Online Privacy Protection Act (“COPPA”). Parents of children who are under
                the age of 13 should ensure that they understand our policies regarding
                personal information before entering any personally identifiable
                information on or through the Website.
</p>
              <h3 dir="ltr">
                I. Information We Collect and How It Is Used
</h3>
              <h4 dir="ltr">
                A. Introduction
</h4>
              <p dir="ltr">
                This Privacy Policy describes how we collect information from users of our
                Website, those who use our products and services (the “Services”) but do
                not have accounts ("Visitors"), and those register and subscribe to our
                services ("Members"). It describes what we do with the information we
                collect, and the choices Visitors and Members have concerning the
                collection and use of such information. We take special precautions and
                have implemented additional privacy safeguards when we collect personally
                identifiable information from children under the age of 13. For more
                information about how we handle children’s information, please see the
                “Children’s Privacy” section below.
</p>
              <h4 dir="ltr">
                B. Types of Information We Collect
</h4>
              <p dir="ltr">
                1. We collect information from Visitors and Members who access various
                parts of our Website and use different Services in a variety of ways,
                including through a mobile device. As a result, the type of information we
                collect depends on the ways Visitors and Members interact with us, our
                Website and/or our Service(s). We may offer websites, services or
                applications that permit browsing and do not require registration. We may
                also offer Members the ability to access their accounts online and offline,
                which may result in collection of different data.
</p>
              <p dir="ltr">
                2. Information that we may collect about you through interactions includes
                information that you give us (such as your name, address, email address,
                telephone number, date of birth, other contact information, surveys and
                other information input into the Website); automatic information (data
                resulting from your activity, such as transaction information, IP address,
                log in, email, passwords and browsing activity); mobile information
                (location services); and information from other sources, such as social
                security numbers for contractors and instructors. We may also gather
                additional information such as the type of device and browser you are
                using, the IP address of your device, information about your device’s
                operating system, and additional information associated with your device
                and browsing activity. We may also gather information collected through
                cookies, tags, tracers, tracking scripts, web beacons, and other
                technologies, as described further below. We may supplement the personal
                information you provide with publicly available information about you as
                well as information from other sources.
</p>
              <p dir="ltr">
                3. When we do collect personally identifiable information, we will use it
                for the purposes indicated in this policy.
</p>
              <p dir="ltr">
                4. Our Website may provide links to other websites that may have their own
                information collection practices. To the extent that you provide personally
                identifiable information to websites that link to or from our Website,
                different rules may apply to the use and disclosure of your personally
                identifiable information. To the extent that the Website includes
                third-party advertisements, the privacy policy of the advertiser providing
                the advertisement will govern the use of your personally identifiable
                information collected during your access to the advertisement. We do not
                control linking practices of advertisers or sponsors and do not monitor or
                claim responsibility for any content or privacy practices of such websites.
                Once you have left our Website, you must read the privacy policy of the
                advertiser or sponsor to see how your personally identifiable information
                will be handled.
</p>
              <p dir="ltr">
                5. On occasion, we may use an advertising server developed by a third party
                to display ads on our Website. Some of these ads may contain cookies that
                are set by third parties (advertisers, sponsors). We do not have access to
                these cookies or any information that they contain. This Privacy Policy
                does not cover any use of information that a third-party ad server may have
                collected from you.
</p>
              <p dir="ltr">
                6. We may use the Website as an entry into other networked web pages and
                websites operated by us and our subsidiaries and affiliates (together, the
                "Transaction Sites”), such as for credit card processing. Please note that
                individual Transaction Sites may adopt privacy terms particular to the
                purpose of such Transaction Site or the transaction(s) taking place on such
                Transaction Site. As Transaction Sites are generally affiliated with
                commercial transactions that you or your business may have with us,
                particularly those which are password-protected or are subject to
                subscriptions, the privacy terms for such sites may differ through terms in
                separate agreements which may supplement or supersede these terms.
</p>
              <h4 dir="ltr">
                C. Registration and Visitor Information
</h4>
              <p dir="ltr">
                1. Registration may be allowed or required on the Website. Members are
                asked to provide certain personal information when they sign up for our
                Services including name, address, telephone number, email address and
                billing information (such as a credit card number and expiration date). The
                personal information collected from Members during the registration process
                is used to manage each Member's account (such as for billing purposes) and
                may be shared with other Members to coordinate Services. This information
                is not normally shared with unaffiliated third parties, unless specifically
                stated otherwise or in special circumstances.
</p>
              <p dir="ltr">
                2. We employ other companies and individuals to perform functions on our
                behalf. They have access to personal information gathered through the
                Services needed to perform their functions but may not use it for other
                purposes.
</p>
              <p dir="ltr">
                3. We may promote the Website, Services, or a new service to members. You
                may manage your communication preferences within your account.
</p>
              <p dir="ltr">
                4. We may also generate non-identifying and aggregate profiles from
                personal information Members provide during registration (such as the total
                number, but not the names, of Members). As explained in more detail below,
                we may use this aggregated and non-identifying information to sell
                advertisements that appear on the Services.
</p>
              <p dir="ltr">
                5. While we make reasonable effort to prevent third party, non-affiliate
                access to personally identifiable information, you should keep in mind that
                data transmission over the Internet is not 100% secure and any information
                disclosed online can potentially be collected and used other than by the
                intended recipient. In view of the foregoing, we cannot warrant that your
                personally identifiable information will not be disclosed to or accessed by
                parties other than those authorized under this Privacy Policy.
</p>
              <h4 dir="ltr">
                D. Marketing and Third-Party Sponsors
</h4>
              <p dir="ltr">
                We may send, on behalf of third parties, promotions via mail or email to
                some or all of our Members. However, in no event do we sell or otherwise
                transfer to third parties the email addresses of our subscribers. We
                strictly maintain the confidentiality of our subscribers' email addresses,
                except as stated in this Privacy Policy. In addition, users are always
                given the opportunity of opting-out of these promotions.
</p>
              <h4 dir="ltr">
                E. Customer Surveys
</h4>
              <p dir="ltr">
                1. We may periodically conduct both business and individual customer
                surveys. We encourage our customers to participate in these surveys because
                they provide us with important information that helps us to improve the
                types of services we offer and how we provide them to you. Your personal
                information and responses will remain strictly confidential (except where
                required by law), even if a third party conducts the survey. Participation
                in our customer surveys is voluntary.
</p>
              <p dir="ltr">
                2. We take the information we receive from individuals responding to our
                Customer Surveys and aggregate it with the responses from other customers
                to create broader, generic responses to the survey questions (such as
                gender, age, residence, hobbies, education, employment, industry sector, or
                other demographic information). We then use the aggregated information to
                improve the quality of our services to you, and to develop new services and
                products. This aggregated, non-personally-identifying information may be
                shared with third parties.
</p>
              <h4 dir="ltr">
                F. "Cookies" Use by ConferenceMe
</h4>
              <p dir="ltr">
                A "cookie" is a small data file that can be placed on your hard drive when
                you visit certain websites. We may use cookies to collect, store, and
                sometimes track information for statistical purposes to improve the
                products and services we provide and to manage our telecommunications
                networks. If you are a Member, we will use a cookie to save your settings
                (which saves you time) and to provide customizable and personalized
                services. These cookies do not enable third parties to access any of your
                customer information. Advertisers and partners may also use their own
                cookies. We do not control the use of these cookies and expressly disclaim
                responsibility for information collected through them. We may also provide
                certain widgets or tools on our sites, such as tools that allow web surfers
                to easily share information on another platform, such as a social media
                platform. At other times, information from a third party may be embedded on
                our site, such as a map or information streaming from another site,
                including communications streaming from a third-party social media
                platform. These widgets, tools, and informational items often function
                using third-party cookies utilized by the third-party site, such as the
                social media platform. You may wish to review information at third party
                sites, such as social media platforms where you have an account, to
                determine how these third parties treat data that they obtain using
                cookies.
</p>
              <h4 dir="ltr">
                G. Other Technologies
</h4>
              <p dir="ltr">
                We may also use additional technologies such as pixel tags, web beacons,
                tracers, tracking scripts, clear GIFs and other technologies, and may
                permit our third-party service providers to use these technologies. We use
                these technologies for purposes such as measuring the effectiveness of our
                advertisements or other communications, determining viewing and response
                rates, and determining which offers to present to you on our own or on
                third party sites.
</p>
              <h4 dir="ltr">
                H. Change in Ownership
</h4>
              <p dir="ltr">
                1. As we continue to develop our business, we might sell or buy websites,
                subsidiaries, or business units. In such transactions, customer information
                generally is one of the transferred business assets but remains subject to
                the promises made in any pre-existing Privacy Notice (unless, of course,
                the customer consents otherwise).
</p>
              <p dir="ltr">
                2. In the event of a change in ownership, or a merger or an acquisition,
                all (or select aspects) of our customer information may be transferred. All
                our subscribers and registered users will be notified of any change in
                ownership, merger, or an acquisition of our assets by a third party.
                Subscribers and users may choose to access and modify any of their
                registration and subscription information at that time.
</p>
              <h4 dir="ltr">
                I. Special Cases
</h4>
              <p dir="ltr">
                While using our Service and/or website, you will have opportunities to post
                reviews or other personal information in public areas. Please understand
                that when you post such information, it is made public and is not subject
                to this policy. We are not responsible for any third party’s use of
                personal information you publicly disclose through our Service.
</p>
              <p dir="ltr">
                It is our policy not to use or share personal information about Visitors or
                Members in ways unrelated to the ones described above without also
                providing you an opportunity to opt out or otherwise prohibit such
                unrelated uses. However, we may disclose personal information about
                Visitors or Members, or information regarding your use of the Services or
                Web sites accessible through our Services, for any reason if, in our sole
                discretion, we believe that it is reasonable to do so, including: to
                satisfy laws, such as the Electronic Communications Privacy Act,
                regulations, or governmental or legal requests for such information; to
                disclose information that is necessary to identify, contact, or bring legal
action against someone who may be violating our    <a href="https://conferenceme.com/terms">Terms of Use</a> or other
    policies; to operate the Services properly; or to protect us and our
    Members. This includes exchanging information with other companies and
    organizations for fraud protection and credit risk reduction.
</p>
              <h4 dir="ltr">
                J. Children’s Privacy
</h4>
              <p dir="ltr">
                1. We encourage parents and guardians to spend time online with their
                children. We urge parents to instruct their children never to give out
                their real names, addresses, or phone numbers without the permission of a
                parent or guardian, when using the Internet. We recognize a special
                obligation to protect personally identifiable information obtained from
                children, as may be collected on the Website. We fully comply with the
                Children’s Online Privacy Protection Act (“COPPA”), which requires us take
                special precautions when collecting personally identifiable information
                from children under the age of 13.
</p>
              <p dir="ltr">
                2. In compliance with COPPA, we have adopted the following policies to keep
                children safe.
</p>
              <p dir="ltr">
                a. Personally identifiable information we collect from children under the
                age of 13 will be used solely by us or our agents for internal purposes
                only and will not be sold or otherwise distributed to third parties.
</p>
              <p dir="ltr">
                b. We will not knowingly allow children under the age of 13 to access any
                social media site that may exist on the Website, email accounts, or other
                services we provide that would allow them to publicly post or otherwise
                distribute personally identifiable information.
</p>
              <p dir="ltr">
                c. We will not knowingly ask a child under the age of 13 to divulge more
                information than is needed to participate in any activity, game, or
                service.
</p>
              <p dir="ltr">
                d. We will not knowingly keep any personally identifiable information that
                is inadvertently included in a child's email submission, help inquiry, or
                other one-time request, if we know that the child is under the age of 13.
                Information will be automatically purged or purged immediately after the
                issue has been resolved.
</p>
              <p dir="ltr">
                e. We will not knowingly post any personally identifiable information that
                is inadvertently included in a child's submission to a general audience
                contribution area if we know that the child is under the age of 13. These
                submissions will be deleted and handled anonymously thereafter.
</p>
              <p dir="ltr">
                f. We will not knowingly provide any direct link to websites that are
                unsuitable for children or teens.
</p>
              <p dir="ltr">
                g. Upon request, we will provide a parent or guardian with a description of
                the information that may have been provided about their child during a
                registration process for a program, service or contest on the Website.
</p>
              <p dir="ltr">
                3. Consistent with COPPA, there are few instances where we will collect
                information from children, such as:
</p>
              <p dir="ltr">
                a. Requesting the contact information of a parent or guardian for the sole
                purpose of providing notification to the parent or guardian;
</p>
              <p dir="ltr">
                b. Collecting children’s contact information on a one-time basis, such as
                when they enter a contest or ask us a question using the “contact us” link
                on our Website. We will use this information on a one-time basis solely to
                directly respond to the child’s request.
</p>
              <p dir="ltr">
                c. Collecting a child's contact information and the contact information of
                his/her parent or guardian solely to directly respond more than once to a
                specific request from the child (for example, to satisfy the request for a
                newsletter subscription). The parent or guardian will have an opportunity
                at any time to revoke the child's request (i.e., unsubscribe). We will not
                use the child's personal information to re-contact the child for any other
                purpose; and
</p>
              <p dir="ltr">
                d. Using cookies to collect non-personally-identifiable information on our
                Website for analytics and internal operations purposes.
</p>
              <h4 dir="ltr">
                K. Your California Privacy Rights
</h4>
              <p dir="ltr">
                California’s “Shine the Light” law permits California residents to annually
                request and obtain information free of charge about what personal
                information is disclosed to third parties for direct marketing purposes in
                the preceding calendar year.
</p>
              <h4 dir="ltr">
                L. ConferenceMe Live Release
</h4>
              <p dir="ltr">
                When attending ConferenceMe live group classes, other class participants
                will can see your name, your image, your comments, your voice, and your
                profile. We may also record the video and audio components of those classes
                and rebroadcast them on our sites or other sites or store them temporarily
                or permanently. In those instances, the public may also be able to see your
                name, your image, your voice, and your comments (collectively referred to
                herein as the “Released Subject Matter”). By attending ConferenceMe Live
                classes, you hereby freely and without restraint consent to and give unto
                ConferenceMe and its agents or assigns (the “Releases”) the unrestrained
                right in perpetuity to own, utilize, or alter the Released Subject Matter,
                in any manner ConferenceMe sees fit and for any purpose whatsoever, all of
                the foregoing to be without limitation of any kind. By participating in
                ConferenceMe classes, you authorize the Releases and grant unto them the
                unrestrained rights to utilize the Released Subject Matter in connection
                with education, distribution, advertising, publicity, public displays, and
                exhibitions. You hereby stipulate that the Released Subject Matter is the
                property of the Releases to do with it as it will. You hereby waive to the
                fullest extent that you may lawfully do so, any causes of action in law or
                equity you may have or may hereafter acquire against the Releasees or any
                of them for libel, slander, invasion of privacy, defamation, copyright or
                trademark violation, right of publicity, or false light arising out of or
                in connection with the utilization by the Releasees or another of the
                Released Subject Matter. I release and indemnify the Releases from and
                against any claims arising from any breach of this warranty and the
                exercise of the rights granted herein.
</p>
              <h4 dir="ltr">
                M. Sharing Information with Our Services
</h4>
              <h5 dir="ltr">
                Profile
</h5>
              <p dir="ltr">
                Your profile is fully visible to all Members, users and customers of our
                Services. It may also be visible to others on or off our Services (e.g.,
                users of third- party search engines). Excerpts from your profile may
                appear on the services of others (e.g., search engine results, mail and
                calendar applications that show a user a “mini” ConferenceMe profile of the
                person they are meeting or messaging, social media aggregators). “Old”
                profile information may remain on these services until they update their
                data cache with changes you made to your profile. Your settings, the degree
                of connection or interests you have with the viewing Member, the
                subscriptions they may have, their usage, and other variables impact the
                availability of your profile and whether they can view certain fields in
                your profile.
</p>
              <h3 dir="ltr">
                II. Credit Card Information
</h3>
              <h4 dir="ltr">
                A. Credit Card Information
</h4>
              <p dir="ltr">
                We are committed to handling your customer information with high standards
                of information security. Your credit card information is stored only in
                encrypted form and processed using a secured order server with Secure
                Socket Layer (SSL) technology through use of a third-party payment
                processor. We restrict access to your personally identifiable information
                only to employees or agents who need to know that information to provide
                products or services to you. While we make every effort to ensure the
                integrity and security of our network and systems, and those of our
                affiliates and agents, but we cannot guarantee that our security measures
                will prevent third-party "hackers" from illegally obtaining this
                information.
</p>
              <p dir="ltr">
                If you to prefer to place your order by telephone, you can call us during
                normal business hours at 619-560-5693 with your credit card and billing
                information.
</p>
              <h4 dir="ltr">
                B. What is your Credit Card Fraud Policy?
</h4>
              <p dir="ltr">
                In the unlikely event that you experience unauthorized use of your credit
                card, promptly report the fraudulent activity to your credit card company.
                Under the Fair Credit Billing Act, your liability may be limited for
                fraudulent charges. Please check with your credit card company to verify
                specific terms and conditions.
</p>
              <h3 dir="ltr">
                III. Communication to Members and Site Visitors
</h3>
              <p dir="ltr">
                We use personal information to communicate important information with our
                Members. Important information includes, but not limited to, student
                newsletters, instructor newsletters, lesson reminders, lesson journal
                entries, payment information, credit card update notifications, account
                status, legal updates, and other important account information. Members
                and, where applicable Visitors, agree to accept this communication from us.
                Please note that you cannot unsubscribe from certain correspondence from
                us, including messages relating to your account transactions.
</p>
              <h4 dir="ltr">
                A. Online Advertisements
</h4>
              <p dir="ltr">
                We occasionally display online advertisements on our Website and in our
                newsletters and alerts. We share aggregated and non-identifying information
                about our Visitors and Members collected through the registration process
                as well as through online surveys and promotions with these advertisers.
                Additionally, in some instances, we use this aggregated and non-identifying
                information to deliver tailored advertisements. For instance, an advertiser
                might tell us the audience they want to reach (for example, males between
                25 and 55 years of age) and provide us with an advertisement tailored to
                that audience. Based upon the aggregated and non-identifying information we
                have collected; we may then display the advertisement to the intended
                audience. However, we do not share personal information about its Visitors
                or Members with these advertisers to be used for other purposes.
</p>
              <h4 dir="ltr">
                B. Responses to Email Inquiries
</h4>
              <p dir="ltr">
                When Visitors or Members send email inquiries to us, we use the return
                email address to answer the email inquiry we receive. We do not use the
                return email address for any other purpose and do not share the return
                email address with any third party.
</p>
              <h4 dir="ltr">
                C. Our Commitment to Data Security
</h4>
              <p dir="ltr">
                Services and websites, we sponsor have security measures in place to
                protect the loss, misuse, and alteration of the information under our
                control. While we make every effort to ensure the integrity and security of
                our network and systems, we cannot guarantee that our security measures
                will prevent third-party "hackers" from illegally obtaining this
                information.
</p>
              <hr />
              <h3 dir="ltr">
                IV. Miscellaneous
</h3>
              <h4 dir="ltr">
                A. Where to Direct Questions About Our Privacy Policy
</h4>
              <p dir="ltr">
                If you have any questions about this Privacy Policy or the practices
                described herein, you may contact:
</p>
              <p dir="ltr">
                Attn: Xtenfer Inc., Inc. Customer Service
    <br />
    1000 S Ashley Dr. Suite 600
    <br />
    Tampa, FL 33602
</p>
              <h4 dir="ltr">
                B. Changes to This Policy
</h4>
              <p dir="ltr">
                As we update and expand our services, we may make changes to this policy.
                You should check back for updates to this policy from time to time. If the
                changes result in less protection for your personal information than those
                provided in this policy, we will make reasonable efforts to contact you to
                let you know. You acknowledge that your assent to the Terms of Use (which
                includes this Privacy Policy) after any changes made following your initial
                consent will satisfy the foregoing obligation. Similarly, your use of the
                ConferenceMe website or continued use of our Service after our posting of
                changes to this policy, or efforts to contact you, means that you agree to
                be bound by such changes. The most current version of this Privacy Policy
                can be reviewed by visiting our website and clicking on “Privacy Policy”
                located at the ConferenceMe website. The most current version of the
                Privacy Policy will supersede all previous versions.
</p>
              <h4 dir="ltr">
                C. Terms of Use, Arbitration and Governing Law
</h4>
              <p dir="ltr">
                If you choose to visit our Website or use our Services, your visit and any
dispute over privacy is subject to this Privacy Policy and our    <a href="https://conferenceme.com/terms">Terms of Use</a>, including
    limitations on damages, resolution of disputes through binding arbitration
    on an individual basis, and application of the law of the state of
    California. If you have any concern about privacy at our Website or from
    our Services, please contact us with a thorough written description, and we
    will try to resolve it. Our business changes constantly, and our Privacy
    Policy and the <a href="https://conferenceme.com/terms">Terms of Use</a>
    will change also. We may e-mail periodic reminders of our notices, terms
    and conditions, but you should check our Website frequently to see recent
    changes. Unless stated otherwise, our current Privacy Policy applies to all
    information that we have about you and your account.
</p>
              <p dir="ltr">
                USE OF THIS WEBSITE SIGNIFIES YOUR AGREEMENT TO THE PRIVACY POLICY.
</p>
              <p dir="ltr">
                Thank you.
</p>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default PrivacyPolicy;