import React from 'react';
class RatingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      star: 5,
      is_loaded: false
    };
  }

  componentDidMount() {
    if (this.props.rating.length > 0) {
      var total_rating = 0;
      this.props.rating.map((rating) => {
        total_rating = total_rating + rating.rating;
      })
      this.setState({
        star: Math.ceil(total_rating / this.props.rating.length),
        is_loaded: true
      });
    }
  }

  starLis = () => {
    var list_arr = [];
    for (let i = 1; i <= 5; i++) {
      if (i > this.state.star) {
        list_arr.push(<i class="fas fa-star star"></i>);
      } else {
        list_arr.push(<i class="fas fa-star star-active"></i>);
      }
    }
    return list_arr;
  };



  render() {

    return (
      <>
        {
          (this.state.is_loaded)
            ?
            <li>{this.starLis()}</li>
            :
            <li><i class="fas fa-star star"></i><i class="fas fa-star star"></i><i class="fas fa-star star"></i><i class="fas fa-star star"></i><i class="fas fa-star star"></i></li>
        }

      </>
    );
  }

}
export default RatingComponent;
