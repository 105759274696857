import React from 'react';
import { Link, } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import { getAllClasses, getLocation, getCategory, getLanguage } from '../../Utils/Utils';
import CourseCard from '../common_components/CourseCard';
import Pagination from "react-js-pagination";
import SelectSearch from 'react-select-search';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';


class CourseListAll extends React.Component {
  constructor(props) {
    super();
    this.changeSearch = this.changeSearch.bind(this);
    this.state = {
      page: 1,
      per_page: 8,
      delay: 1,
      search_term: '',
      subcategory_id: '',
      categories: [],
      subcategoriesData: [],
      location: [],
      language: [],
      min_price: 0,
      max_price: 10000,
      classes: { data: [] },
      is_filter_open: false,
      blur: '',
      zoom_effect_css: ''
    };
  }

  async componentDidMount() {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      classes: await getAllClasses(this.state.page, this.state.per_page, this.state.delay, this.state.search_term, this.state.categories, this.state.language, this.state.min_price, this.state.max_price, this.state.location, this.state.subcategory_id),
    });
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );

    this.setState({
      location_data: await getLocation(),
      category_data: await getCategory(),
      language_data: await getLanguage(),
    });
    //structuring category data
    var category_data_array = [];
    this.state.category_data.map((cat) => {
      category_data_array.push({
        value: cat.id,
        name: cat.name
      })
    })
    this.setState({
      category_data_json: category_data_array
    })
    //ends here
  }

  locationSelect = (e) => {
    console.log(e);
    this.setState({
      location: e
    })
  }

  updateSubcategory = (e) => {
    console.log(e);
    this.setState({
      subcategory_id: e
    })
  }

  categorySelect = (e) => {


    this.setState({ subcategoriesData: [], categories: [e], subcategory_id: "" });

    const response = axios.get(`${window.$HOST_URL}api/fetchsubcategoriesnew/?category_id=${e}`, {}, {}).then((response) => {
      this.setState({ subcategoriesData: response.data.data });
    }).catch((error) => { });

  }

  languageSelect = (e) => {
    this.setState({
      language: e
    })
  }

  applyFilters = () => {
    this.handlePageChange(1);
    this.setState({
      is_filter_open: false
    })
  }


  resetFilters = async () => {
    await this.setState({
      min_price: 0,
      max_price: 10000,
      language: [],
      location: [],
      categories: [],
      subcategory_id: ''
    });
    this.handlePageChange(1);
  }
  changeSearch = async (event) => {
    const self = this;
    var search_term = event.target.value;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        this.props.dispatch(
          { type: 'SHOW_LOADER_TOGGLE', payload: true }
        );
        this.setState({
          page: 1,
          search_term: search_term,
          classes: await getAllClasses(1, this.state.per_page, this.state.delay, search_term, this.state.categories, this.state.language, this.state.min_price, this.state.max_price, this.state.location, this.state.subcategory_id)
        });
        this.props.dispatch(
          { type: 'SHOW_LOADER_TOGGLE', payload: false }
        );
      }, 1500)
    });
  }

  async handlePageChange(pageNumber) {
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: true }
    );
    this.setState({
      page: pageNumber
    })
    this.setState({
      page: pageNumber,
      classes: await getAllClasses(pageNumber, this.state.per_page, this.state.delay, this.state.search_term, this.state.categories, this.state.language, this.state.min_price, this.state.max_price, this.state.location, this.state.subcategory_id)
    });
    this.props.dispatch(
      { type: 'SHOW_LOADER_TOGGLE', payload: false }
    );
  }

  render() {
    return (
      <div>
        <Header />
        <div class="coursespage-section">
          <div class="container" style={{ minHeight: '600px' }}>
            <div class="row coursecategory-searchmodule">
              <div class="col-md-3 col-sm-3">
                <Link to="/courses"><i class="zmdi zmdi-long-arrow-left"></i> Back to all courses</Link>
              </div>
              {
                /*
                          <div class="col-md-2 col-sm-2">
              
                              <p className="pointer" onClick={(e) => this.setState({
                                is_filter_open: !this.state.is_filter_open
                              })}> Open Filter <i class="fas fa-filter"></i></p>
              
                            </div>
              
                            <div class="col-md-2 col-sm-2">
                              <p> Applied Filters : {(this.state.location.length + this.state.language.length + this.state.categories.length) + ((this.state.min_price !== 0) ? 1 : 0) + ((this.state.max_price !== 0 && this.state.max_price !== 10000) ? 1 : 0)}</p>
                            </div>
              
                            <div class="col-md-2 col-sm-2">
                              <p className="pointer" onClick={(e) => this.resetFilters()}>Reset Filters <i class="fas fa-power-off"></i></p>
                            </div>
              
                */
              }

              <div class="col-md-3 col-sm-3" style={{ float: "right" }}>
                {
                  (this.state.classes.data.length > 0)
                    ?
                    <Form.Control
                      className="dash-maincontent-search"
                      type="text"
                      placeholder="Search Courses"
                      onChange={this.changeSearch}
                    />
                    :
                    ''
                }
              </div>

            </div>



            <div
              className={"row " + this.state.zoom_effect_css}
              style={{ marginTop: '15px' }}
              onMouseEnter={(e) => this.setState({
                blur: 'blur_class',
                zoom_effect_css: 'zoomEffect'
              })}
              onMouseLeave={(e) => this.setState({
                blur: '',
                zoom_effect_css: ''
              })}
            >
              <div className="col-md-10 col-sm-12">


                <div className="col-md-3 col-sm-6">
                  <label>Filter by Category</label>
                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={this.state.category_data_json}
                        value={this.state.categories}
                        placeholder="Filter by Categories"
                        className="select-search fullWidth"
                        search={true}
                        onChange={(e) => this.categorySelect(e)}

                        //multiple={true}
                        autoFocus={false}
                        printOptions="on-focus"
                      //closeOnSelect={false}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-md-3 col-sm-6">
                  <label>Filter by Subcategory</label>
                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={this.state.subcategoriesData}
                        value={this.state.subcategory_id}
                        placeholder="Filter by Subcategory"
                        className="select-search fullWidth"
                        search={true}
                        onChange={(e) => this.updateSubcategory(e)}
                        //multiple={true}
                        autoFocus={false}
                        printOptions="on-focus"
                      //closeOnSelect={false}
                      />
                    </div>
                  </Form.Group>
                </div>


                <div className="col-md-3 col-sm-6">
                  <label>Filter by Course Language</label>
                  <Form.Group>
                    <div className="btn-group btn-block">
                      <SelectSearch
                        options={this.state.language_data}
                        value={this.state.language}
                        placeholder="Filter by Language"
                        className="select-search fullWidth"
                        search={true}
                        onChange={(e) => this.languageSelect(e)}
                        multiple={true}
                        autoFocus={false}
                        printOptions="on-focus"
                      // closeOnSelect={false}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-3 col-sm-6">

                  <div className="col-md-6 col-sm-6">
                    <label>Min Price</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder="Min Price"
                        onChange={(e) => this.setState({
                          min_price: e.target.value,
                          max_price: (parseInt(e.target.value) + parseInt(e.target.value))

                        })}
                        value={this.state.min_price}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <label>Max Price</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder="Max Price"
                        onChange={(e) => this.setState({ max_price: e.target.value })}
                        value={this.state.max_price}
                      />
                    </Form.Group>
                  </div>

                </div>
              </div>
              <div className="col-md-1 col-sm-6" style={{ float: 'left' }}>
                <br />
                <button className="btn btn-default bt-custom"
                  role="button"
                  onClick={(e) => this.applyFilters()}
                  style={{ padding: '12px 10px' }}
                >
                  Apply
                </button>

              </div>

              <div className="col-md-1 col-sm-6">
                <br />
                <button className="btn btn-default bt-custom"
                  role="button"
                  onClick={(e) => this.resetFilters()}
                  style={{ padding: '12px 10px' }}
                >
                  Reset
                </button>

              </div>
            </div>


            <div class="coursespage-module-ttl">
              <h3>All Courses</h3>
            </div>

            <div className={"row gutter-20 " + this.state.blur}>
              {
                (this.state.classes.data.length === 0)
                  ?
                  <p>Sorry No Courses Found</p>
                  :
                  this.state.classes.data.map((class_data) => (
                    <CourseCard course_detail={class_data} />
                  ))
              }

            </div>
            {
              (this.state.classes.data.length > 0)
                ?
                <div class="text-right">
                  <Pagination
                    activePage={this.state.classes.page}
                    itemsCountPerPage={this.state.classes.per_page}
                    totalItemsCount={this.state.classes.total}
                    pageRangeDisplayed={this.state.classes.total_pages}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                :
                ''
            }
          </div>
        </div>
        {
          /*
        
                <Modal
                  animation={false}
                  show={this.state.is_filter_open}
                  onHide={(e) => this.setState({
                    is_filter_open: !this.state.is_filter_open
                  })}
                //dialogClassName="custom-modal"
                >
                  <Modal.Header closeButton>
                    Find Teachers by filter
                </Modal.Header>
        
                  <Modal.Body>
                    <div class="row">
                      <div className="col-md-12 col-sm-12 signup-maincontent ">
        
                        <div className="col-md-6 col-sm-12">
                          <label>Filter by Location</label>
                          <Form.Group>
                            <div className="btn-group btn-block">
                              <SelectSearch
                                options={this.state.location_data}
                                value={this.state.location}
                                placeholder="Filter by location"
                                className="select-search fullWidth"
                                search={true}
                                onChange={(e) => this.locationSelect(e)}
                                multiple={true}
                                autoFocus={false}
                                printOptions="on-focus"
                                closeOnSelect={false}
                              />
                            </div>
                          </Form.Group>
                        </div>
        
        
                        <div className="col-md-6 col-sm-12">
                          <label>Filter by Category</label>
                          <Form.Group>
                            <div className="btn-group btn-block">
                              <SelectSearch
                                options={this.state.category_data_json}
                                value={this.state.categories}
                                placeholder="Filter by Categories"
                                className="select-search fullWidth"
                                search={true}
                                onChange={(e) => this.categorySelect(e)}
                                multiple={true}
                                autoFocus={false}
                                printOptions="on-focus"
                                closeOnSelect={false}
                              />
                            </div>
                          </Form.Group>
                        </div>
        
                        <div className="col-md-6 col-sm-12">
                          <label>Filter by Language</label>
                          <Form.Group>
                            <div className="btn-group btn-block">
                              <SelectSearch
                                options={this.state.language_data}
                                value={this.state.language}
                                placeholder="Filter by Language"
                                className="select-search fullWidth"
                                search={true}
                                onChange={(e) => this.languageSelect(e)}
                                multiple={true}
                                autoFocus={false}
                                printOptions="on-focus"
                                closeOnSelect={false}
                              />
                            </div>
                          </Form.Group>
                        </div>
        
                        <div className="col-md-3 col-sm-6">
                          <label>Min Price</label>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Min Price"
                              onChange={(e) => this.setState({
                                min_price: e.target.value,
                                max_price: (parseInt(e.target.value) + parseInt(e.target.value))
        
                              })}
                              value={this.state.min_price}
                            />
                          </Form.Group>
                        </div>
        
                        <div className="col-md-3 col-sm-6">
                          <label>Max Price</label>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Max Price"
                              onChange={(e) => this.setState({ max_price: e.target.value })}
                              value={this.state.max_price}
                            />
                          </Form.Group>
                        </div>
        
                        <div className="col-md-6 col-sm-12">
                          <button className="btn btn-default bt-custom"
                            role="button"
                            onClick={(e) => this.applyFilters()}
                          >
                            Apply Filters
                          </button>
                        </div>
        
        
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
        
          */
        }
        <Footer />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(CourseListAll);